import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { GetOneProgramData, SlotsPrograms, GetOneProgramParams } from 'interfaces/hooks/programsHooksInterfaces';
import { getFormattedDate } from 'utils/dateHelpers';
import { MedicalProgramBestDoctorServiceTypeHrResourceWrq } from '@bestdoctor/core-arch-api/models';
import { FetchError } from 'utils/FetchError';

export const useGetOneProgram = ({ programId, onError }: GetOneProgramParams): GetOneProgramData => {
  const {
    isFetching,
    isLoading,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    data: programData,
  } = useQuery(
    ['dataProgram', programId],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/programs/${programId}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!programId,
      onError,
      select: (res: { data: SlotsPrograms }) => {
        const { data } = res;
        const startDate = getFormattedDate(data.service_period?.start_date, 'dd.MM.yyyy');
        const endDate = getFormattedDate(data.service_period?.end_date, 'dd.MM.yyyy');

        const stringServiceTypes = data.service_types
          ?.map((el: MedicalProgramBestDoctorServiceTypeHrResourceWrq) => el.title)
          .join('. ');

        return {
          id: data.id,
          programUuid: data.uuid,
          programTitle: data.title,
          companyTitle: data.company?.title,
          serviceTypes: data.service_types,
          stringServiceTypes,
          city: data.city?.title || '',
          budget: data.slot_budget || 0,
          paidSlots: data.service_period?.paid_slots,
          medicalProgram: data.medical_program_file,
          startDate,
          endDate,
          period: `${startDate} - ${endDate}`,
          isVzr: data.is_vzr_cost_included_in_program_override,
          slotsCount: data.slots_count,
        };
      },
    }
  );

  return {
    isLoadingGetOneProgram: isLoading,
    isErrorGetOneProgram: error,

    fetchNextPage,

    hasNextPageGetOneProgram: hasNextPage,
    isFetchingNextPageGetOneProgram: isFetchingNextPage,
    isFetchingGetOneProgram: isFetching,

    refetchGetOneProgram: refetch,

    programData,
  };
};
