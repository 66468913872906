/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { InfoStaffModal } from 'shared/modals/InfoStaffModal/InfoStaffModal';
import { Loader } from 'shared/loaders/Loader';


import {
  PatientDataParams,
  IPatientForTable,
  TablePatientsHeadCell,
  TablePatientsEnhancedTableProps,
  ITablePatientsProps,
} from 'interfaces/patients/patientInterfaces';
import {
  slotsTableStatusCellStyle,
  tableCellCheckboxStyle,
  tableCellStyle,
  tableCheckboxStyle,
  tableRowStyle,
} from 'ui/tables/tables';
import { Sorting } from 'interfaces/other/otherInterfaces';
import { nextSortState } from 'utils/tablesMethods';
import { MAX_EMAIL_LENGTH, MAX_CONTENT_LENGTH, requiredTableFields } from 'utils/constants';
import { lineBreaker } from 'utils/lineBreaker';
import { truncateText } from 'utils/truncateText';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { Stack } from '@mui/system';
import { writeToClipboard } from 'utils/writeToClipboard';
import { useSnackbar } from 'notistack';
import { useGetOnePatient } from 'hooks/patients/useGetOnePatient';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { useDebounce } from 'utils/useDebounce';

const checkedIcon = <CheckBoxOutlinedIcon sx={{ color: '#ff6d6d' }} />;

function createData(
  company: string,
  fullname: string,
  birthday: string,
  program: string,
  period: string,
  type: string,
  id: number,
  uuid: string,
  greetings_email: string,
  policy_number: string,
  number: string,
  additional_info: string,
  patientType: string
): IPatientForTable {
  return {
    company,
    fullname,
    birthday,
    program,
    period,
    type,
    patientType,
    id,
    uuid,
    greetings_email,
    policy_number,
    number,
    additional_info,
  };
}
const headCells: TablePatientsHeadCell[] = [
  {
    id: 'program__service_period__company__title',
    numeric: false,
    disablePadding: false,
    label: 'Юрлицо',
  },
  {
    id: 'patient__last_name',
    numeric: false,
    disablePadding: false,
    label: 'Имя (ID)',
  },
  {
    id: 'patient__birth_date',
    numeric: false,
    disablePadding: false,
    label: 'Дата р.',
  },
  {
    id: 'program__title',
    numeric: false,
    disablePadding: false,
    label: 'Программа',
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
    label: 'Период обслуживания',
  },
  {
    id: 'greetings_email__icontains',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'policy_number__icontains',
    numeric: false,
    disablePadding: false,
    label: 'Номер полиса',
  },
  {
    id: 'number__icontains',
    numeric: false,
    disablePadding: false,
    label: 'Табельный номер',
  },
  {
    id: 'additional_info__icontains',
    numeric: false,
    disablePadding: false,
    label: 'Доп. информация',
  },
  {
    id: '_status',
    numeric: false,
    disablePadding: false,
    label: 'Тип',
  },
];

const EnhancedTableHead: React.FunctionComponent<TablePatientsEnhancedTableProps> = (
  props: TablePatientsEnhancedTableProps
) => {
  const { onSelectAllClick, numSelected, rowCount, orderingTableStaff, handleOrderingTableStaff, tableUserFields } =
    props;

  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if (!sortDirection) {
      handleOrderingTableStaff('');
    }
  }, [handleOrderingTableStaff, orderingTableStaff, sortDirection]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={tableCellStyle}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            checkedIcon={checkedIcon}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            sx={tableCheckboxStyle}
          />
        </TableCell>
        {headCells
          .filter(
            (item) =>
              tableUserFields?.some((field) => item.id === field) ||
              requiredTableFields?.some((field) => item.id === field.value)
          )
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sx={tableCellStyle}
            >
              <TableSortLabel
                active={!sortDirection || orderingTableStaff.replace('-', '') === `${headCell.id}`}
                direction={sortDirection}
                onClick={() => {
                  setSortDirection(nextSortState(sortDirection));
                  handleOrderingTableStaff(sortDirection === 'asc' ? `${headCell.id}` : `-${headCell.id}`);
                }}
                IconComponent={ExpandLessIcon}
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                {headCell.label}
                {orderingTableStaff.replace('-', '') === `${headCell.id}` ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

export const TableStaff: React.FC<ITablePatientsProps> = ({
  selectedCompanies,
  handleCloseTableTooltip,
  isPatients,
  orderingTableStaff,
  tableUserFields,
  handleCloseTypeSearchTooltip,

  isFetchingNextPageSearchPatients,
  isAddingForbiddenByDates,
  isDetachingForbiddenByDates,

  handleStaffCheck,
  setSelectedStaff,
  handleAvailabilityCompletedPatients,

  handleOrderingTableStaff,
  handleActionWithStaffTooltip,
  accessList,
  rolesData,
  defaultPermissions,
  isSlotsAviable,
  refetchSearchPatients
}) => {
  const [patientDataTable, setPatientDataTable] = useState<string | null>(null);
  const [patientData, setPatientData] = useState<PatientDataParams | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { isLoadingPatient, isErrorPatient, refetchPatient } = useGetOnePatient({
    patient: patientDataTable,
    onSuccess: (data) => setPatientData(data),
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    }
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [dataTypeChecking, setDataTypeChecking] = useState<string[]>([]);
  const [isInfoStaffModal, setInfoStaffModal] = useState(false);

  const staffArr: IPatientForTable[] = isPatients || [];

  const rows = staffArr.map((item) =>
    createData(
      item.company,
      item.fullname,
      item.birthday,
      item.program,
      item.period,
      item.type,
      item.id,
      item.uuid,
      item.greetings_email,
      item.policy_number,
      item.number,
      item.additional_info,
      item.patientType
    )
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.uuid);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
    setDataTypeChecking([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, uuid: string, type: string): void => {
    const selectedIndex = selected.indexOf(uuid);
    const dataTypeCheckingIndex = dataTypeChecking.indexOf(type);

    let newSelected: string[] = [];
    let newDataTypeChecking: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid);
      newDataTypeChecking = newDataTypeChecking.concat(dataTypeChecking, type);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newDataTypeChecking = newDataTypeChecking.concat(dataTypeChecking.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newDataTypeChecking = newDataTypeChecking.concat(dataTypeChecking.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newDataTypeChecking = newDataTypeChecking.concat(
        dataTypeChecking.slice(0, dataTypeCheckingIndex),
        dataTypeChecking.slice(dataTypeCheckingIndex + 1)
      );
    }

    setSelected(newSelected);
    setDataTypeChecking(newDataTypeChecking);
  };

  const isSelected = (uuid: string): boolean => selected.indexOf(uuid) !== -1;

  const handleCopyToClipboard = (text?: string): void => {
    writeToClipboard(text);
    enqueueSnackbar('Скопировано в буфер обмена!', { variant: 'info' });
  };

  const handleGetPatients = useDebounce(refetchPatient, 0)

  const handleClickInfoButton = (evt: React.SyntheticEvent, patientUuid: string): void => {
    evt.stopPropagation();

    setPatientDataTable(patientUuid);

    setInfoStaffModal(true);

    handleGetPatients();

    handleActionWithStaffTooltip(false);

    handleCloseTypeSearchTooltip();

    handleCloseTableTooltip();
  };

  useEffect(() => {
    handleStaffCheck(selected.length !== 0);
    setSelectedStaff(selected);
  }, [handleStaffCheck, setSelectedStaff, selected]);

  useEffect(() => {
    const checkType = dataTypeChecking.some((element) => element === 'Завершенный');

    handleAvailabilityCompletedPatients(checkType);
  }, [dataTypeChecking, handleAvailabilityCompletedPatients]);

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '12px' }}>
        <Paper sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}>
          <TableContainer
            sx={{
              overflow: 'visible',
            }}
          >
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                orderingTableStaff={orderingTableStaff}
                handleOrderingTableStaff={handleOrderingTableStaff}
                tableUserFields={tableUserFields}
              />
              <TableBody>
                {rows?.map((row, index) => {
                  const isItemSelected = isSelected(row.uuid);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      // onClick={(event) => handleClick(event, row.uuid, row.type)}
                      onClick={(evt) => handleClickInfoButton(evt, row?.uuid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uuid}
                      selected={isItemSelected}
                      sx={tableRowStyle}
                    >
                      <TableCell padding="checkbox" sx={tableCellCheckboxStyle}>
                        <Checkbox
                          onClick={(evt) => {
                            handleClick(evt, row.uuid, row.type);
                            evt.stopPropagation();
                          }}
                          color="primary"
                          checked={isItemSelected}
                          checkedIcon={checkedIcon}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: '#FF6D6D',
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" sx={tableCellStyle}>
                        {row.company}
                      </TableCell>
                      <TableCell sx={tableCellCheckboxStyle}>
                        {row.fullname}
                        <Box
                          sx={{
                            margin: '0',
                            padding: '0',
                            color: 'rgba(62, 65, 77, 0.6)',
                          }}
                        >
                          {`(ID: ${row.id})`}{' '}
                          {row.patientType === 'relative' && (
                            <Box
                              component="span"
                              sx={{
                                color: '#FF6D6D',
                                fontSize: '12px',
                              }}
                            >
                              Родственник
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      {tableUserFields?.some((field) => field === 'patient__birth_date') && (
                        <TableCell sx={tableCellStyle}>{row.birthday}</TableCell>
                      )}
                      <TableCell sx={tableCellStyle}>{lineBreaker(row.program)}</TableCell>
                      {tableUserFields?.some((field) => field === 'start_date') && (
                        <TableCell sx={tableCellStyle}>{lineBreaker(row.period)}</TableCell>
                      )}
                      {tableUserFields?.some((field) => field === 'greetings_email__icontains') &&
                        (row.greetings_email?.length > MAX_EMAIL_LENGTH ? (
                          <TooltipActionsInfo
                            title={
                              <Box
                                component="span"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleCopyToClipboard(row.greetings_email);
                                }}
                                sx={{
                                  cursor: 'pointer',
                                }}
                              >
                                {row.greetings_email}
                              </Box>
                            }
                            placement="left"
                          >
                            <TableCell sx={tableCellStyle}>
                              {truncateText(row.greetings_email, MAX_EMAIL_LENGTH)}
                            </TableCell>
                          </TooltipActionsInfo>
                        ) : (
                          <TableCell sx={tableCellStyle}>{row.greetings_email}</TableCell>
                        ))}
                      {tableUserFields?.some((field) => field === 'policy_number__icontains') &&
                        (row.policy_number?.length > MAX_CONTENT_LENGTH ? (
                          <TooltipActionsInfo title={row.policy_number} placement="left">
                            <TableCell sx={tableCellStyle}>
                              {truncateText(row.policy_number, MAX_CONTENT_LENGTH)}
                            </TableCell>
                          </TooltipActionsInfo>
                        ) : (
                          <TableCell sx={tableCellStyle}>{row.policy_number}</TableCell>
                        ))}
                      {tableUserFields?.some((field) => field === 'number__icontains') &&
                        (row.number?.length > MAX_CONTENT_LENGTH ? (
                          <TooltipActionsInfo title={row.number} placement="left">
                            <TableCell sx={tableCellStyle}>{truncateText(row.number, MAX_CONTENT_LENGTH)}</TableCell>
                          </TooltipActionsInfo>
                        ) : (
                          <TableCell sx={tableCellStyle}>{row.number}</TableCell>
                        ))}

                      {tableUserFields?.some((field) => field === 'additional_info__icontains') &&
                        (row.additional_info?.length > MAX_CONTENT_LENGTH ? (
                          <TooltipActionsInfo title={row.additional_info} placement="left">
                            <TableCell sx={tableCellStyle}>
                              {truncateText(row.additional_info, MAX_CONTENT_LENGTH)}
                            </TableCell>
                          </TooltipActionsInfo>
                        ) : (
                          <TableCell sx={tableCellStyle}>{row.additional_info}</TableCell>
                        ))}

                      <TableCell sx={() => slotsTableStatusCellStyle(row?.type)}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          {row.type}
                          <IconButton
                            aria-label="add to shopping cart"
                            onClick={(evt) => {
                              handleClickInfoButton(evt, row?.uuid);
                            }}
                          >
                            <InfoOutlinedIcon fontSize="small" sx={{ color: '#BCBEC8' }} />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {isFetchingNextPageSearchPatients && <Loader />}

      {!isLoadingPatient && !isErrorPatient && (
        <InfoStaffModal
          isAddingForbiddenByDates={isAddingForbiddenByDates}
          isDetachingForbiddenByDates={isDetachingForbiddenByDates}
          open={isInfoStaffModal}
          selectedCompanies={selectedCompanies}
          patientData={patientData}
          isLoadingPatient={isLoadingPatient}
          isErrorPatient={isErrorPatient}
          handleClose={() => setInfoStaffModal(false)}
          refetchPatient={refetchPatient}
          accessList={accessList}
          rolesData={rolesData}
          defaultPermissions={defaultPermissions}
          isSlotsAviable={isSlotsAviable}
          refetchSearchPatients={refetchSearchPatients}
        />
      )}
    </>
  );
};
