/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { ListPaymentResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/payments/types';

import queryString from 'query-string';

import { getPagesQuantity } from 'utils/hooksHelpers';
import { GetPaymentsHook, GetPaymentsHookData } from 'interfaces/finance/financeInterfaces';
import { format, parse } from 'date-fns';
import { SupplementaryAgreementsPaymentHrRq } from '@bestdoctor/core-arch-api/models';

export const useGetPayments = ({
  company,
  servicePeriod,
  isPaymentsAllowed,
  onSuccess,
  onError
}: GetPaymentsHook): GetPaymentsHookData => {
  const companyUuid: string | null = company && company?.uuid;

  const {
    isFetching,
    isLoading,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    data: dataPayments,
  } = useInfiniteQuery(
    ['dataPayments', servicePeriod?.uuid],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      interface UseGetPaymentsQueryParams {
        company?: string | null;
        per_page: number;
        page: number;
        service_period?: string;
      }

      const queryParams: UseGetPaymentsQueryParams = {
        per_page: 20,
        page: pageParam,
        company: companyUuid,
        service_period: servicePeriod?.uuid,
      };

      const res = await fetch(`${API_URL}/payments/?${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      enabled: !!companyUuid && isPaymentsAllowed,
      getNextPageParam: (lastPage: ListPaymentResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onSuccess,
      onError,
      select: (data: {
        pageParams: unknown[];
        pages: ListPaymentResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'][];
      }) => {
        // обработка данных платежей по слотам
        const pages: ListPaymentResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data'][] =
          data.pages.map(
            (page: ListPaymentResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => page.data
          );

        const slotsPayments: SupplementaryAgreementsPaymentHrRq[] = [];

        pages.forEach((arr) => {
          arr?.forEach((item) => slotsPayments.push(item));
        });

        // Any из за coroed
        const payments = slotsPayments.map((item: any) => {
          const startDatePay = item.payment_period.start_date
            ? format(parse(item.payment_period.start_date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
            : '';

          const endDatePay = item.payment_period.end_date
            ? format(parse(item.payment_period.end_date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
            : '';

          const beforeDatePay = item.pay_before_date ? format(parse(item.pay_before_date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy') : '';
          const actualDatePay = item.actual_payment_date ? format(parse(item.actual_payment_date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy') : '';
          const billDate = item.bill_date ? format(parse(item.bill_date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy') : '';

          return {
            companyTitle: item.company_title,
            startDatePay,
            endDatePay,
            periodPay: startDatePay && endDatePay ? `${startDatePay} - ${endDatePay}` : '',
            beforeDatePay,
            actualDatePay,
            amountPay: item.insurance_premium_sum ? Number(item.insurance_premium_sum) : 0,
            statusPay: item.status?.title,
            statusPayValue: item.status?.value,
            basisPay: item.supplementary_agreement_number ? item.supplementary_agreement_number : '',
            billFile: item.original_bill_file !== null ? item.original_bill_file : '-',
            billDate,
          };
        });

        return payments;
      },
    }
  );

  return {
    isLoadingPayments: isLoading,
    isErrorPayments: !!error,

    fetchNextPage,

    hasNextPagePayments: hasNextPage,
    isFetchingNextPagePayments: isFetchingNextPage,
    isFetchingPayments: isFetching,

    refetchPayments: refetch,

    dataPayments,
  };
};
