import React, { useCallback, ReactElement } from 'react';
import { useQueryClient } from 'react-query';

import { Box } from '@mui/system';
import { Dialog, IconButton, TextField, Typography } from '@mui/material';

import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';

import Stack from '@mui/material/Stack';
import { ru } from 'date-fns/locale';
import isSameDay from 'date-fns/isSameDay';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { TableHistoryActions } from 'shared/modals/HistoryActionsModal/TableHistoryActions/TableHistoryActions';
import { dialogTableModalStyle, modalTableHeaderStyle } from 'ui/modals/modalTable/modalTable';
import { modalTitleStyle } from 'ui/modals/modalGlobal';
import { inputFormStyle } from 'ui/inputs/inputsForm';

import { HistoryAction, IEventTypes, PatientDataParams } from 'interfaces/patients/patientInterfaces';
import { AutocompleteInputMultiple } from 'shared/inputs/AutocompleteInputMultiple/AutocompleteInputMultiple';

import { eventTypes } from 'utils/constants';
import { isValid } from 'date-fns';
import { isDateActual } from 'utils/dateHelpers';

interface DNA {
  [key: string]: number;
}

interface HistoryActionsModalModalProps {
  open: boolean;
  patientData: PatientDataParams;
  historyActions: HistoryAction[] | [];
  isLoadingCompanyActions: boolean;
  hasNextPageCompanyActions: boolean;
  isFetchingNextPageCompanyActions: boolean;

  startDate: Date | null;
  handleStartDate: (value: Date | null) => void;
  endDate: Date | null;
  handleEndDate: (value: Date | null) => void;
  eventType: IEventTypes[];
  handleEventType: (value: IEventTypes[]) => void;
  orderingTableHistory: string;
  handleOrderingTableHistory: (value: string) => void;

  fetchNextPageCompanyActions: () => Promise<void>;
  handleClose: () => void;
}

export const HistoryActionsModal: React.FC<HistoryActionsModalModalProps> = ({
  open,
  patientData,
  historyActions,

  isLoadingCompanyActions,
  hasNextPageCompanyActions,
  isFetchingNextPageCompanyActions,

  startDate,
  handleStartDate,
  endDate,
  handleEndDate,
  eventType,
  handleEventType,
  orderingTableHistory,
  handleOrderingTableHistory,

  fetchNextPageCompanyActions,
  handleClose,
}) => {
  const queryClient = useQueryClient();

  const rendersPickerDayStartDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = startDate !== null ? isSameDay(date, startDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDayEndDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = endDate !== null ? isSameDay(date, endDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={dialogTableModalStyle}>
      <Box sx={modalTableHeaderStyle}>
        <Box sx={{ marginBottom: '32px' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            История операций&nbsp;
            {patientData.full_name && (
              <Box component="span" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>{`(${patientData.full_name})`}</Box>
            )}
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px', marginBottom: '32px' }}>
        <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
          <Stack spacing={3} sx={{ width: '100% ', maxWidth: '300px' }}>
            <DesktopDatePicker
              label="Период данных с"
              value={startDate}
              renderDay={rendersPickerDayStartDate}
              PopperProps={{
                sx: datePickerPopperStyled,
              }}
              disabled={isLoadingCompanyActions}
              onChange={
                (newValue: Date | null) => {
                  if (newValue && isValid(newValue) && isDateActual(newValue)) {
                    handleStartDate(newValue);
                    queryClient.invalidateQueries('dataCompanyActions');
                  }
                }}
              renderInput={(params) => (
                <TextField
                  label="Период данных с"
                  variant="standard"
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingCompanyActions}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          sx={{ width: '28px', height: '28px' }}
                          // disabled={isLoadingAddPatient}
                          onClick={() => {
                            handleStartDate(null);
                            queryClient.invalidateQueries('dataCompanyActions');
                          }}
                        >
                          <CloseOutlinedIcon fontSize="small" />
                        </IconButton>

                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>
          <Stack spacing={3} sx={{ width: '100% ', maxWidth: '300px' }}>
            <DesktopDatePicker
              label="Период данных по"
              value={endDate}
              renderDay={rendersPickerDayEndDate}
              PopperProps={{
                sx: datePickerPopperStyled,
              }}
              disabled={isLoadingCompanyActions}
              onChange={
                (newValue: Date | null) => {
                  if (newValue && isValid(newValue) && isDateActual(newValue)) {
                    handleEndDate(newValue);
                    queryClient.invalidateQueries('dataCompanyActions');
                  }
                }}
              renderInput={(params) => (
                <TextField
                  label="Период данных с"
                  variant="standard"
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingCompanyActions}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          sx={{ width: '28px', height: '28px' }}
                          // disabled={isLoadingAddPatient}
                          onClick={() => {
                            handleEndDate(null);
                            queryClient.invalidateQueries('dataCompanyActions');
                          }}
                        >
                          <CloseOutlinedIcon fontSize="small" />
                        </IconButton>

                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>

        <AutocompleteInputMultiple
          label="Тип события"
          placeholder={eventType?.length === 0 || eventType ? '' : 'Тип события'}
          data={eventTypes}
          value={eventType}
          handleChange={useCallback(
            (_event: React.SyntheticEvent, newValue: IEventTypes[]) => {
              const array = newValue;
              const counter = array.reduce(
                // eslint-disable-next-line no-return-assign, no-sequences
                (acc: DNA, current: IEventTypes) => ((acc[current.title] = (acc[current.title] || 0) + 1), acc),
                {}
              );
              const result = array.filter((obj: IEventTypes) => counter[obj.title] === 1);

              const selectedOptions = result.reduce((arr: IEventTypes[], obj: IEventTypes) => {
                const exist: boolean = arr.some(({ title }) => obj.title === title);
                if (!exist) {
                  arr.push(obj);
                }
                return arr;
              }, []);

              handleEventType(selectedOptions);
              queryClient.invalidateQueries('dataCompanyActions');
            },
            [handleEventType, queryClient]
          )}
          check
          disableCloseOnSelect
        />
      </Box>

      <Box
        sx={{
          overflowX: 'scroll',
        }}
      >
        <TableHistoryActions
          historyActions={historyActions}
          isLoadingCompanyActions={isLoadingCompanyActions}
          hasNextPageCompanyActions={hasNextPageCompanyActions}
          isFetchingNextPageCompanyActions={isFetchingNextPageCompanyActions}
          fetchNextPageCompanyActions={fetchNextPageCompanyActions}
          orderingTableHistory={orderingTableHistory}
          handleOrderingTableHistory={handleOrderingTableHistory}
        />
      </Box>
    </Dialog>
  );
};
