import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BusinessExpensesRulesTextProps } from 'interfaces/hooks/patientsHooksInterfaces';
import React from 'react';

export const BusinessExpensesRulesText: React.FC<BusinessExpensesRulesTextProps> = ({ data }) => (
  <Box>
    {data?.businessExpensesWithholdingFromContractModification?.value === 'without_withholding' && (
      <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
        Расчеты производятся по дням без удержания РВД
      </Typography>
    )}
    {data?.businessExpensesWithholdingFromContractModification?.value === 'on_complete_termination' && (
      <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
        Расчеты производятся по дням, при расторжении договора удерживается РВД в размере{' '}
        {data?.businessExpensesPercent}% от уплаченной страховой премии.
      </Typography>
    )}
    {data?.businessExpensesWithholdingFromContractModification?.value === 'on_any_detachment' && (
      <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
        Расчеты производятся по дням, при исключении удерживается РВД в размере {data?.businessExpensesPercent}% от
        уплаченной страховой премии.
      </Typography>
    )}
  </Box>
);
