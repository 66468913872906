import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { ForceAttachPatientData, ForceAttachPatientParams } from 'interfaces/hooks/patientsHooksInterfaces';

export const useForceAttachPatient = ({onSuccess, onError}: ForceAttachPatientParams): ForceAttachPatientData => {
  const { mutate, isLoading } = useMutation(
    async (patientUuid: string) => {

      const data = {
        proposal_action_uuid: patientUuid
      };

      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/patient_attachment_proposal_action_attach/`, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      return res;
    },
    {
      onSuccess, onError
    }
  );

  return {
    handleForceAddPatient: mutate,
    isLoadingAddPatient: isLoading,
  };
};
