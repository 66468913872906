import { Sorting } from 'interfaces/other/otherInterfaces';
import { eventTypes } from './constants';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Sorting,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function throttle(callee: any, timeout: any) {
  let timer: any;

  return function perform(...args: any[]) {
    if (timer) return;

    timer = setTimeout(() => {
      callee(...args);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearTimeout(timer);
      timer = null;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    }, timeout);
  };
}

export const moneyRowCurrentView = (value?: number | string) => {
  if (value) {
    const money = new Intl.NumberFormat('ru', { style: 'currency', currency: 'RUB', useGrouping: true })
      .format(Number(value))
      .slice(0, -2);

    return money;
  }
  return null;
};

export const getEventTitle = (id: string): string => {
  const idArr = eventTypes.map((item) => item.id);
  const index = idArr.indexOf(id);
  return eventTypes[index].title;
};

export const getRowChangeString = (actionCategory: string, verboseName: string): string => {
  if (actionCategory !== 'C' || verboseName === '-') {
    return getEventTitle(actionCategory);
  }
  return `${getEventTitle(actionCategory)}/${verboseName}`;
};

export const getNotificationType = (notificationType: string): string => {
  if (notificationType === 'payments') {
    return 'Платежи';
  }
  if (notificationType === 'status_change') {
    return 'Смена статуса ДС';
  }
  if (notificationType === 'slot_action') {
    return 'Операции с пациентами';
  }
  return '';
};

export const getNotificationModalActionStaffText = (
  type: string | undefined,
  total: number | undefined,
  success: number | undefined
): string | undefined => {
  if (type === 'attach_from_file' || type === 'create_slots_from_file') {
    if (total === success) {
      return total ? `Успешно прикреплено ${total} пациентов` : 'Произошла ошибка. Исправьте ошибки в файле';
    }
    return `Прикреплено ${success}/${total} пациентов. Пожалуйста, продолжите операцию и исправьте ошибки в файле у неуспешных пациентов`;
  }
  if (type === 'detach_from_file' || type === 'detach_slots_from_file') {
    if (total === success) {
      return total ? `Успешно откреплено ${total} пациентов` : 'Произошла ошибка. Исправьте ошибки в файле';
    }
    return `Откреплено ${success}/${total} пациентов. Пожалуйста, продолжите операцию и исправьте ошибки в файле у неуспешных пациентов`;
  }
  return undefined;
};

export const nextSortState = (sortState): Sorting => {
  if (sortState === 'asc') return 'desc';
  if (sortState === 'desc') return undefined;
  return 'asc';
};
