import { linearProgressClasses } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { styled } from '@mui/system';

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  backgroundColor: '#ffe9e9',

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#ff6d6d',
  },
}));
