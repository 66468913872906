import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface CheckboxInputProps {
  name: string;
  label: any;
  control: Control<any>;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({ name, label, control }) => (
  <FormControlLabel
    control={
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
        )}
      />
    }
    label={label}
  />
);
