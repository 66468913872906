export const whiteColor = '#fff';
export const blackColor = '#000';

export const themeColor = '#ff6d6d';
export const themeColorLowOpacity = 'rgba(255, 109, 109, 0.8)';
export const themeColorHighOpacity = 'rgba(255, 109, 109, 0.2)';

export const guyabanoColor = '#f8f8f8';
export const grayColor = '#81838a';
export const graylightColor = '#bcbec8';
export const grayDarkColor = '#3e414d';
export const grayLightDarkOpacity = 'rgba(62, 65, 77, 0.6)';

export const encycolorpediaColor = '#ffe9e9';
export const azureColor = '#00bcbc';
export const bananamaniaColor = '#fcebb9';
export const peachСolor = '#ffe5b4';
export const orangeColor = '#ffbc66';
export const linenСolor = '#fbe9e9';
export const сadetBlueCrayolaСolor = '#bdbec7';
export const greenColor = '#00bcbc'
