import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

import { Box, Popper, Fade, Paper, ClickAwayListener, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { InfoNotificationModal } from 'shared/modals/InfoNotificationModal/InfoNotificationModal';
import { paramsPriority } from 'utils/constants';
import { themeColor, grayDarkColor, grayLightDarkOpacity } from 'utils/uiConstants';
import {
  notificationsTooltipBoxFlex,
  notificationsTooltipBoxWrapper,
  notificationsTooltipPaper,
  notificationsTooltipPaperBox,
  notificationsTooltipText,
} from 'ui/tooltips/tooltipNotification';
import { useViewedNotifications } from 'hooks/notifications/useViewedNotifications';
import { Notification, TooltipNotificationProps } from 'interfaces/notifications/notificationsInterfaces';
import { useViewedAllNotifications } from 'hooks/notifications/useViewedAllNotifications';

export const TooltipNotification: React.FC<TooltipNotificationProps> = ({
  open,
  anchorEl,
  handleTooltipClose,
  children,
  notificationsData,
}) => {
  const queryClient = useQueryClient();

  const [isInfoNotificationModal, setInfoNotificationModal] = useState<boolean>(false);
  const [notificationDataModal, setNotificationDataModal] = useState<Notification | null>(null);

  const notificationUuids: string[] = notificationsData
    .filter((item) => item.meta.priority !== 'urgent')
    .map((notification: Notification) => notification.uuid);

  const { handleViewedNotifications } = useViewedNotifications({
    onSuccess: () => queryClient.invalidateQueries('dataUnviewedNotifications'),
  });

  const { handleViewedAllNotifications } = useViewedAllNotifications({
    notificationUuids,
    onSuccess: () => queryClient.invalidateQueries('dataUnviewedNotifications'),
  });

  const notifications = notificationsData.map((notification: Notification) => {
    const priority: string | null = notification.meta.priority ? notification.meta.priority : null;
    const color: string = priority ? paramsPriority[priority].bgcolor : paramsPriority.regular.bgcolor;

    const dateNotification = format(new Date(notification.created_at), 'dd.MM.yyyy HH:mm');

    const deleteButtonColor =  themeColor;

    return (
      <Paper
        key={notification.uuid}
        sx={() => notificationsTooltipPaper(color, true)}
        elevation={2}
        onClick={() => {
          setInfoNotificationModal(true);
          setNotificationDataModal(notification);
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Typography variant="subtitle1" sx={{ color: grayDarkColor, paddingTop: '6px' }}>
              {notification.meta.notification_type === 'status_change' ? 'Cмена статуса ДС' : 'Операции с пациентами'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Typography variant="subtitle2" sx={{ color: grayLightDarkOpacity, marginRight: '8px' }}>
                {dateNotification}
              </Typography>
              <IconButton
                aria-label="Удалить"
                onClick={(evt) => {
                  evt.stopPropagation();
                  handleViewedNotifications(notification.uuid);
                }}
              >
                <CloseIcon sx={{ width: '16px', height: '16px', color: deleteButtonColor }} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2" sx={{ color: grayLightDarkOpacity }}>
              {notification.meta.company_title}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle2" sx={() => notificationsTooltipText(grayLightDarkOpacity)}>
              {`${notification.text}`}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  });

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box>
          <Popper
            sx={{ zIndex: 1300, width: '412px', top: '20px' }}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            transition
          >
            {({ TransitionProps }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ padding: '16px' }}>
                  <Box sx={notificationsTooltipPaperBox}>
                    <Box sx={notificationsTooltipBoxFlex}>
                      <MainButton
                        textButton="Все как прочитано"
                        disabled={notificationsData.length === 0}
                        border
                        handleClick={handleViewedAllNotifications}
                      />
                      <Link to="/notifications" style={{ textDecoration: 'none' }}>
                        <MainButton textButton="Все уведомления" mainBtn handleClick={handleTooltipClose} />
                      </Link>
                    </Box>
                    <Box sx={notificationsTooltipBoxWrapper}>
                      {!!notificationsData && notificationsData.length > 0 && notifications}
                    </Box>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
          {children}
        </Box>
      </ClickAwayListener>

      {!!notificationDataModal && (
        <InfoNotificationModal
          notification={notificationDataModal}
          open={isInfoNotificationModal}
          handleClose={() => setInfoNotificationModal(false)}
        />
      )}
    </>
  );
};
