import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';
import {
  ListReportExpendituresLegalsResourcesResponsesByAccept,
  ListReportExpendituresPatientsResourcesResponsesByAccept,
} from '@bestdoctor/core-arch-api/api/company-service-periods/types';
import {
  ServicePeriodsReportExpendituresLegalsRq,
  ServicePeriodsReportExpendituresPatientsRq,
} from '@bestdoctor/core-arch-api/models';
import { FetchError } from 'utils/FetchError';
import { useGetListExpensesData, useGetListExpensesParams } from 'interfaces/hooks/financeHookInterfaces';

export const useGetListExpenses = ({
  listExpenses,
  company,
  servicePeriod,

  selectedFilterByTitle,
  isListAllowed,

  onSuccess,
  onError,
}: useGetListExpensesParams): useGetListExpensesData => {
  const paramsListExpenses: string = listExpenses ? 'legal' : 'patient';
  const companyUuid: string = company ? company?.uuid : '';
  const periodId: string = servicePeriod ? servicePeriod?.uuid : '';

  const { isFetching, isLoading, error, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['dataListEXpenses', paramsListExpenses, companyUuid, periodId, selectedFilterByTitle],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      let params = `?page=${pageParam}&per_page=20`;

      if (selectedFilterByTitle) {
        params += `&search=${selectedFilterByTitle}`;
      }

      const token = localStorage.getItem('token');

      const res = await fetch(
        `${API_URL}/service_periods/${periodId}/reports/expenditures/${paramsListExpenses}s/${params}`,
        {
          method: 'GET',
          headers: {
            Accept: `${HEADER_ACCEPT_HR}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!periodId && isListAllowed,
      getNextPageParam: (
        lastPage:
          | ListReportExpendituresLegalsResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
          | ListReportExpendituresPatientsResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
      ) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      onSuccess,
      onError,
      select: (data: { pageParams; pages }) => {
        const pages = data.pages.map(
          (
            page:
              | ListReportExpendituresLegalsResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
              | ListReportExpendituresPatientsResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
          ) => page.data
        );

        const slotsListExpenses = pages.flat();

        const dataListEXpenses = listExpenses
          ? slotsListExpenses.map((item: ServicePeriodsReportExpendituresLegalsRq) => ({
            uuid: item.legal.uuid ? item.legal.uuid : '',
            title: item.legal.title ? item.legal.title : '',
            money: item.money_total ? Number(item.money_total) : 0,
            visits: item.num_patients ? item.num_patients : 0,
          }))
          : slotsListExpenses.map((item: ServicePeriodsReportExpendituresPatientsRq) => ({
            uuid: item.patient.uuid ? item.patient.uuid : '',
            title: item.patient.full_name ? item.patient.full_name : '',
            money: item.money_total ? Number(item.money_total) : 0,
            visits: item.num_visits ? item.num_visits : 0,
          }));

        return dataListEXpenses;
      },
    }
  );

  return {
    isLoadingListExpenses: isLoading,
    isErrorListExpenses: error,

    fetchNextPageListEXpenses: fetchNextPage,

    hasNextPageListEXpenses: hasNextPage,
    isFetchingNextPageListEXpenses: isFetchingNextPage,
    isFetchingListExpenses: isFetching,

    refetchListEXpenses: refetch,
  };
};
