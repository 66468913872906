import React, { useEffect, useState } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DescriptionIcon from '@mui/icons-material/Description';

import { tableCellStyle, tableDocsIconStyle, tableDocsStatusCellStyle, tableRowStyle } from 'ui/tables/tables';

import { stableSort, getComparator, throttle, nextSortState } from 'utils/tablesMethods';

import {
  IListAgentReportsAndActs,
  IListAgentReportsAndActsTableHeading,
  IListAgentReportsAndActsEnhancedTableHead,
  ActsAndReportsData,
} from 'interfaces/documents/documentsInterfaces';
import { Loader } from 'shared/loaders/Loader';
import { reportDateViewCurrent, reportTitleView } from 'utils/dateHelpers';
import { grayDarkColor } from 'utils/uiConstants';
import { useSnackbar } from 'notistack';
import { downloadDoc } from 'utils/downloadDoc';
import { Sorting } from 'interfaces/other/otherInterfaces';

function createData(uuid: string, document: string, report_date: string, appendix: string): ActsAndReportsData {
  return {
    uuid,
    report_date,
    document,
    appendix,
  };
}

const heading: readonly IListAgentReportsAndActsTableHeading[] = [
  {
    id: 'report_date',
    numeric: false,
    disablePadding: true,
    label: 'Период формирования',
  },
];

const EnhancedTableHead: React.FC<IListAgentReportsAndActsEnhancedTableHead> = (
  props: IListAgentReportsAndActsEnhancedTableHead
) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IListAgentReportsAndActs) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === head.id ? order : false}
            sx={tableCellStyle}
          >
            {head.id === 'report_date' && (
              <TableSortLabel
                active={!order || orderBy === head.id}
                direction={orderBy === head.id ? order : 'asc'}
                onClick={createSortHandler(head.id)}
                IconComponent={ExpandLessIcon}
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                {head.label}
                {orderBy === head.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const AgentReportsAndActs: React.FC<any> = ({
  actsAndReports,
  fetchNextPage,
  isFetchingNextPageReportFiles,
  hasNextPagePageReportFiles,
}) => {
  const [order, setOrder] = useState<Sorting>(undefined);
  const [orderBy, setOrderBy] = useState<keyof IListAgentReportsAndActs>('uuid');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [isBottom, setIsBottom] = useState<boolean>(false);

  const listExpenses: readonly IListAgentReportsAndActs[] = actsAndReports || [];

  const rows = listExpenses.map((item: IListAgentReportsAndActs) =>
    createData(item.uuid, item.document, item.report_date, item.appendix)
  );

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof IListAgentReportsAndActs): void => {
    setOrder(nextSortState(order));
    setOrderBy(property);
  };

  const handleSelectAllClick = (_event: React.ChangeEvent<HTMLInputElement>): void => {
    if (_event.target.checked) {
      const newSelecteds = rows.map((n) => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleScroll = (evt: Event): void => {
    const { scrollHeight, scrollTop } = (evt.target as Document).documentElement;
    const windowHeight = window.innerHeight;

    if (scrollHeight - (windowHeight + scrollTop) < 50) {
      setIsBottom(true);
    }
  };

  useEffect(() => {
    if (isBottom) {
      fetchNextPage();
      setIsBottom(false);
    }
  }, [fetchNextPage, isBottom]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (hasNextPagePageReportFiles && !isFetchingNextPageReportFiles) {
      document.addEventListener('scroll', throttle(handleScroll, 1000));
    }

    return () => document.removeEventListener('scroll', throttle(handleScroll, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPagePageReportFiles, isFetchingNextPageReportFiles]);
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            sx={{
              minWidth: 750,
              borderCollapse: 'inherit',
              borderSpacing: '0 4px',
            }}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid} sx={tableRowStyle}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        width: '250px',
                        padding: '0 8px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: grayDarkColor,
                        border: 'none',
                      }}
                    >
                      {reportDateViewCurrent(row.report_date)}
                    </TableCell>
                    <TableCell sx={tableDocsStatusCellStyle}>
                      <Box
                         onClick={() => {
                          if (row?.document === null) {
                            enqueueSnackbar('Файл отсутствует', { variant: 'error' });
                          } else {
                            downloadDoc(row.document.toString());
                          }
                        }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': { cursor: 'pointer' },
                        }}
                      >
                        <DescriptionIcon sx={tableDocsIconStyle} />
                        {reportTitleView(row.report_date)}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {isFetchingNextPageReportFiles && <Loader />}
        </TableContainer>
      </Paper>
    </Box>
  );
};
