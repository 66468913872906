import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { DetachFromFileHookData, DetachFromFileHookParams } from 'interfaces/hooks/patientsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useDetachFromFile = ({ onSuccess, onError }: DetachFromFileHookParams): DetachFromFileHookData => {
  const { mutate, isLoading } = useMutation(
    async (dataForm: FormData) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/bulk_detach_from_file/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `${HEADER_ACCEPT_HR}`,
        },
        body: dataForm,
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onSuccess,
      onError,
    }
  );

  return {
    handleDetachFromFile: mutate,
    isLoadingDetachFromFile: isLoading,
  };
};
