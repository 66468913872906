import { Company } from 'interfaces/global/globalInterfaces';
import { format, getMonth, getYear, isValid, parse, parseISO } from 'date-fns';

// регулярка даты по формату DD.MM.YYYY
export const regExpDate = /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/g;

export const regExpDateResponse = /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/;

export function disabledNonattachableDays(date: Date, companyData: Company | null): boolean {
  if (companyData?.can_attach_since) {
    const canAttachDate = new Date(companyData?.can_attach_since);
    const secondsBetween = date.getTime() - canAttachDate.getTime();
    const daysBetween = Math.ceil(secondsBetween / (1000 * 3600 * 24));
    return daysBetween < 0;
  }
  return true;
}

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const reportDateViewCurrent = (value: string): string => {
  const dateFromValue = parse(value, 'yyyy-MM-dd', new Date())
  const month = getMonth(dateFromValue)

  const year = getYear(dateFromValue);

  return `${months[month]}, ${year} г.`;
};


export const reportTitleView = (value: string | undefined): string => {
  if(value){
    const dateFromValue = parse(value, 'yyyy-MM-dd', new Date())

    const month = getMonth(dateFromValue)
  
    return `Отчёт за ${months[month]} от ${format(dateFromValue, 'dd.MM.yyyy')}`
  }
  return '';
};


export const responseDateView = (value: string | null | undefined): string => {
  let responseDate = ''
  if (value) {
    responseDate =  regExpDateResponse.test(value) ? format(new Date(value), 'dd.MM.yyyy') : value
  }
  return responseDate
}

export const isDateActual = (date: Date): boolean => date.getFullYear() > 1900

export const getFormattedDate = (date: string | undefined | null, mask: string): string => {
  if (date) {
    const parsedDate = parseISO(date)
    if (isValid(parsedDate)) {
      return format(parsedDate, mask)
    }
  }
  return ''
}

export const getTimeStamp = (date: string): number => {
  const parsedDate = parseISO(date)
  if (isValid(parsedDate)) {
    return parsedDate.getTime()
  }
  return 0
}

export const disabledDates = [new Date("01.01.2024").getTime(), new Date("01.02.2024").getTime(),new Date("01.03.2024").getTime(),new Date("01.04.2024").getTime(),new Date("01.05.2024").getTime(), new Date("01.06.2024").getTime(), new Date("01.07.2024").getTime(), new Date("01.08.2024").getTime(), new Date("01.09.2024").getTime(), new Date("01.10.2024").getTime(), new Date("01.11.2024").getTime()]

export const disabledDatesFilesAction = [new Date("01.01.2024").getTime(), new Date("01.02.2024").getTime(),new Date("01.03.2024").getTime(),new Date("01.04.2024").getTime(),new Date("01.05.2024").getTime(), new Date("01.06.2024").getTime(), new Date("01.07.2024").getTime(), new Date("01.08.2024").getTime()]
