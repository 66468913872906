/* eslint-disable react/no-array-index-key */
import { Box } from '@mui/system';
import React from 'react';

export interface InfoNotificationModalTextProps {
  linkText: string;
}

export const InfoNotificationModalText: React.FC<InfoNotificationModalTextProps> = ({ linkText }) => {
  const text = linkText;
  const splitText = text.split(' ');
  const replacedText = splitText.map((word, index) => {
    const trimmedWord = word.replace(/[.,]+$/, ''); // Удаляем точку или запятую в конце слова

    if (/^(http:\/\/|https:\/\/|www\.|ftp:\/\/)/.test(trimmedWord)) {
      let currentLink = word;
      if (word.endsWith(',') || word.endsWith('.')) {
        currentLink = word.slice(0, -1);
      }
      return (
        <a key={index} href={currentLink} target="_blank" rel="noopener noreferrer">
          {word}{' '}
        </a>
      );
    }

    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(trimmedWord)) {
      return (
        <a key={index} href={`mailto:${trimmedWord}`} target="_blank" rel="noopener noreferrer">
          {word}{' '}
        </a>
      );
    }

    return <span key={index}>{word} </span>;
  });

  return (
    <Box
      component="p"
      sx={{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.02em',
        whiteSpace: 'pre-wrapd',
      }}
    >
      {replacedText}
    </Box>
  );
};
