import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { FetchError } from 'utils/FetchError';
import { CancelDetachmentData, CancelDetachmentParams } from 'interfaces/hooks/patientsHooksInterfaces';

export const useCancelDetachment = ({ onError }: CancelDetachmentParams): CancelDetachmentData => {
  const { mutate, isLoading } = useMutation(
    async (uuid: string) => {
      const data = {
        proposal_action_uuid: uuid,
      };

      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/cancel_patient_detachment_proposal_action/`, {
        method: 'PUT',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      onError,
    }
  );

  return {
    handleCancelDetachment: mutate,
    isLoadingCancelDetachment: isLoading,
  };
};
