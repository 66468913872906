import React, { useState } from 'react';

import { Box } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { CustomTab, CustomTabs } from 'ui/tabs/tabs';

import { IFinanceCharts } from 'interfaces/finance/financeInterfaces';

import { ExpensesChart } from './ExpensesChart/ExpensesChart';
import { TreatmentChart } from './TreatmentChart/TreatmentChart';

const FinanceCharts: React.FC<IFinanceCharts> = ({ dataFinancePeriodsStats }) => {
  const [secondaryMenuValue, setSecondaryMenuValue] = useState('1');

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => setSecondaryMenuValue(newValue);

  return (
    <Box
      component="section"
      sx={{
        marginTop: '32px',
        padding: '32px',
        backgroundColor: '#fff',
        boxShadow: ' 0px 0.4px 0.8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.06)',
        borderRadius: '4px',
      }}
    >
      <TabContext value={secondaryMenuValue}>
        <CustomTabs value={secondaryMenuValue} onChange={handleChange} aria-label="Табы">
          <CustomTab value="1" label="Расходы по месяцам" />
          <CustomTab value="2" label="Обращаемость по месяцам" />
        </CustomTabs>

        <Box
          sx={{
            overflowX: `${dataFinancePeriodsStats.length > 14 ? 'scroll' : null}`,
            '@media(max-width:1600px)': {
              overflowX: `${dataFinancePeriodsStats.length > 10 ? 'scroll' : null}`,
            },
            '@media(max-width:1400px)': {
              overflowX: `${dataFinancePeriodsStats.length > 8 ? 'scroll' : null}`,
            },
            '@media(max-width:1200px)': {
              overflowX: `${dataFinancePeriodsStats.length > 6 ? 'scroll' : null}`,
            },
          }}
        >
          <TabPanel value="1">
            <Box
              sx={{
                width: `${dataFinancePeriodsStats.length > 1 ? `${dataFinancePeriodsStats.length * 100}px` : '100%'}`,
              }}
            >
              <ExpensesChart dataFinancePeriodsStats={dataFinancePeriodsStats} />
            </Box>
          </TabPanel>

          <TabPanel value="2">
            <Box
              sx={{
                width: `${dataFinancePeriodsStats.length > 1 ? `${dataFinancePeriodsStats.length * 100}px` : '100%'}`,
              }}
            >
              <TreatmentChart dataFinancePeriodsStats={dataFinancePeriodsStats} />
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export { FinanceCharts };
