import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { AddFromFileHook, AddFromFileHookData } from 'interfaces/hooks/patientsHooksInterfaces';
import { isActive } from 'itson/ItsOn';

export const useAddPatientFromFile = ({ companyId, onSuccess, onError }: AddFromFileHook): AddFromFileHookData => {
  const { mutate, isLoading } = useMutation(
    async (dataForm: FormData) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const mainFilterPeriodStaff =
        'mainFilterPeriodStaff' in sessionStorage
          ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '')
          : '';

      const res = await fetch(`${API_URL}/slots/create_slots_from_file/?${isActive('hr-411.add_period_uuid_in_creating_from_file') ? `service_period_uuid=${mainFilterPeriodStaff.uuid}` : `company_uuid=${companyId}`}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'attachment; filename=file.xlsx',
        },
        body: dataForm,
      });

      if (res.ok) return res.json();
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      onSuccess,
      onError
    }
  );

  return {
    handleAddFromFile: mutate,
    isLoadingAddFromFile: isLoading,
  };
};
