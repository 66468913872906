import { TabContext } from '@mui/lab';
import { IconButton } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { boxNavStyle } from 'ui/navigation/navigation';
import { CustomTabs, CustomTab } from 'ui/tabs/tabs';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { themeColor } from 'utils/uiConstants';

interface PermissionsNavigationProps {
  menuValue: string;
  handleMenuValue: (value: string) => void;
}

export const PermissionsNavigation: React.FC<PermissionsNavigationProps> = ({ menuValue, handleMenuValue }) => {
  const location = useLocation();
  return (
    <TabContext value={menuValue}>
      <Box component="section" sx={{ ...boxNavStyle }}>
        <Link to={location?.pathname === '/permissions' ? '/staff' : '/permissions'} style={{ textDecoration: 'none' }}>
          <IconButton>
            <KeyboardBackspaceIcon sx={{ width: '16px' }} />
          </IconButton>
        </Link>
        <CustomTabs
          sx={{ marginRight: 'auto' }}
          value={menuValue}
          onChange={(_event: React.SyntheticEvent, newValue: string): void => handleMenuValue(newValue)}
        >
          <CustomTab
            value="user_rights"
            label={
              <Stack direction="row" alignItems="center" gap="4px">
                Настройка прав доступа
                <TooltipActionsInfo
                  placement="right"
                  title="Этот раздел поможет настроить доступ к разделам и действиям HR кабинета исходя из функциональных обязанностей пользователя"
                >
                  <InfoOutlinedIcon fontSize="small" sx={{ color: themeColor, height: '20px', width: '20px' }} />
                </TooltipActionsInfo>
              </Stack>
            }
          />
        </CustomTabs>
      </Box>
    </TabContext>
  );
};
