import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { InputForm } from 'shared/inputs/InputForm/InputForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { SmsResetPasswordText, SmsResetPasswordTitle } from 'ui/login/ResetPasswordStyles';
import { SmsModalData, smsModalValidation } from './smsModalValidation';

interface PassModalProps {
  open: boolean;
  handleClose: () => void;
  handleNextStep: () => void;
  timer: number;
  sendSms: (code: string) => Promise<void>;
  smsIsLoading: boolean;
  smsIsError: boolean;
  sendEmail: (email?: string) => Promise<void>;
}

export const SMSModal: React.FC<PassModalProps> = ({
  open,
  handleClose,
  handleNextStep,
  timer,
  sendSms,
  smsIsError,
  smsIsLoading,
  sendEmail,
}) => {
  const [codeResended, setCodeResended] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SmsModalData>({
    resolver: zodResolver(smsModalValidation),
    defaultValues: {
      passowrd: '',
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue('passowrd', e.target.value);
  };
  const handleClean = (): void => {
    reset({ passowrd: '' });
  };
  const onSubmit = (data: SmsModalData): void => {
    sendSms(data.passowrd).then(() => {
      handleClose();
      handleNextStep();
      handleClean();
    });
  };

  const onClose = (): void => {
    handleClean();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '560px',
          padding: '20px 24px 24px',
        },
      }}
    >
      <Box
        className="modal-sms__header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography sx={SmsResetPasswordTitle}>Смена пароля</Typography>
          <Box component="p" sx={SmsResetPasswordText}>
            Введите ваш код подтверждения из E-mail.
          </Box>
          {codeResended && (
            <Box component="p" sx={SmsResetPasswordText}>
              Если вы не получили письмо или возникли трудности обратитесь к вашему менеджеру по договору или свяжитесь
              с нами по телефону <br /> 8-800-777-0503
            </Box>
          )}
        </Box>
      </Box>

      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            marginTop: '16px',
            marginBottom: '28px',
          }}
        >
          <Box
            sx={{
              marginBottom: '12px',
            }}
          >
            <InputForm
              idInput="modal-pass_password"
              textLabel="Код подтверждения:"
              error={!!errors.passowrd || smsIsError}
              helperText={errors.passowrd?.message || smsIsError ? errors.passowrd?.message || 'Неверный пароль' : ''}
              value={watch('passowrd')}
              type="text"
              handleChange={handleChange}
              handleCleanInput={handleClean}
            />
          </Box>
          <Box
            component="p"
            sx={{
              ...SmsResetPasswordText,
              padding: '18px 0 0',
              color: '#3e414d',
            }}
          >
            Повторно запросить код можно через: {timer} сек.
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            alignSelf: 'flex-end',
          }}
        >
          <MainButton
            disabled={!!timer}
            textButton="Выслать код еще раз"
            handleClick={() => {
              sendEmail();
              setCodeResended(true);
            }}
          />
          <MainButton typeBtn="submit" textButton="Далее" mainBtn disabled={smsIsLoading} />
        </Box>
      </Box>
    </Dialog>
  );
};
