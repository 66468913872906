import { SxProps } from "@mui/system";

export const SmsResetPasswordTitle: SxProps = {
    margin: '0',
    flex: '0 0 auto',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#3e414d',
  }

export const SmsResetPasswordText: SxProps = {
    margin: '12px 0 0',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: 'rgba(62, 65, 77, 0.6)',
  }