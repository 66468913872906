import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import { format } from 'date-fns';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { getPagesQuantity } from 'utils/hooksHelpers';

import { HistoryAction } from 'interfaces/patients/patientInterfaces';
import {
  CompanyActionsHook,
  CompanyActionsHookData,
  CompanyActionsHookResponse,
  CompanyActionsQueryParams,
  CompanyActionsResData,
} from 'interfaces/hooks/companiesHooksInterfaces';
import { getFormattedDate, responseDateView } from 'utils/dateHelpers';
import { CompanyActionLogsCompanyActionLogCompactRq } from '@bestdoctor/core-arch-api/models';
import { FetchError } from 'utils/FetchError';

export const useCompanyActions = ({
  companies,
  startDate,
  endDate,
  eventType,
  patient,
  ordering,

  onSuccess,
  onError,
  isSlotsAviable
}: CompanyActionsHook): CompanyActionsHookData => {
  const company__in = companies && !!companies.length ? companies.map((company) => company.uuid).join() : '';
  const date_from = startDate ? format(startDate, 'yyyy-MM-dd') : null;
  const date_to = endDate ? format(endDate, 'yyyy-MM-dd') : null;
  const category__in = eventType && !!eventType.length ? eventType.map((type) => type.id).join() : '';

  const {
    data,
    isFetching,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,

    refetch,
    fetchNextPage,
  } = useInfiniteQuery(
    [
      'dataCompanyActions',
      company__in,
      date_from,
      date_to,
      category__in,
      patient,
      ordering,
      endDate,
      startDate,
      eventType,
    ],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');
      const queryParams: CompanyActionsQueryParams = {
        company__in,
        date_to,
        date_from,
        category__in,
        patient,
        ordering,
        page: pageParam,
        per_page: 20,
      };

      const res = await fetch(`${API_URL}/company_actions/?${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: isSlotsAviable && (!!patient || !!companies),
      getNextPageParam: (lastPage: CompanyActionsHookResponse) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      onSuccess,
      onError,
      select: (res: CompanyActionsResData) => {
        const pages: (CompanyActionLogsCompanyActionLogCompactRq | undefined)[] = res.pages
          ?.map((page) => page.data)
          .flat();
        const slotsCompanyActions: HistoryAction[] = pages.map((item) => {
          const date = getFormattedDate(item?.date, 'dd.MM.yyyy');
          const date_action = getFormattedDate(item?.take_effect_at, 'dd.MM.yyyy');
          return {
            uuid: item?.uuid || '',
            date,
            user: item?.email || '-',
            full_name: item?.patient?.full_name || '-',
            action: item?.category?.text || '',
            action_category: item?.category?.value || '-',
            company: item?.company?.title || '-',
            date_action,
            verbose_changed_field_name: item?.verbose_changed_field_name || '-',
            new_value: responseDateView(item?.new_value) || '-',
            old_value: responseDateView(item?.old_value) || '-',
          };
        });

        return slotsCompanyActions;
      },
    }
  );

  return {
    CompanyActionsData: data,

    isLoadingCompanyActions: isLoading,
    isErrorCompanyActions: isError,
    hasNextPageCompanyActions: hasNextPage,
    isFetchingNextPageCompanyActions: isFetchingNextPage,
    isFetchingCompanyActions: isFetching,

    fetchNextPageCompanyActions: fetchNextPage,
    refetchCompanyActions: refetch,
  };
};
