import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { visuallyHidden } from '@mui/utils';

import { InfoProgramModal } from 'shared/modals/InfoProgramModal/InfoProgramModal';
import { useGetOneProgram } from 'hooks/programs/useGetOneProgram';

import {
  IProgram,
  ITableProgramProps,
  TableProgramData,
  TableProgramHeading,
  TableProgramsEnhancedTableProps,
} from 'interfaces/program/programInterfaces';
import { tableCellCheckboxStyle, tableCellRowContentStyle, tableCellStyle, tableRowStyle } from 'ui/tables/tables';

import { stableSort, getComparator, nextSortState } from 'utils/tablesMethods';

import { Sorting } from 'interfaces/other/otherInterfaces';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';

const checkedIcon = <CheckBoxOutlinedIcon sx={{ color: '#ff6d6d' }} />;

function createData(
  title: string,
  service_types: string,
  city: string,
  slot_budget: number,
  service_period: string,
  slots_count: number,
  files: string,
  programUuid: string,
  medicalProgram: string
): TableProgramData {
  return {
    title,
    service_types,
    city,
    slot_budget,
    service_period,
    slots_count,
    files,
    programUuid,
    medicalProgram,
  };
}

const heading: readonly TableProgramHeading[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Программа',
  },
  {
    id: 'service_types',
    numeric: false,
    disablePadding: false,
    label: 'Объем услуг',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'Город обсл.',
  },
  {
    id: 'slot_budget',
    numeric: false,
    disablePadding: false,
    label: 'Стоимость, ₽',
  },
  {
    id: 'service_period',
    numeric: false,
    disablePadding: false,
    label: 'Кол-во, чел.',
  },
  {
    id: 'files',
    numeric: false,
    disablePadding: false,
    label: 'Файлы',
  },
];

const EnhancedTableHead: React.FC<TableProgramsEnhancedTableProps> = (props: TableProgramsEnhancedTableProps) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property: keyof TableProgramData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            fontFamily: 'inherit',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '14px',
            color: 'rgba(62, 65, 77, 0.6)',
            border: 'none',
            padding: '8px 4px 20px',
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            checkedIcon={checkedIcon}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            sx={{
              '&.Mui-checked': {
                color: '#FF6D6D',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#FF6D6D',
              },
            }}
          />
        </TableCell>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'left' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === head.id ? order : false}
            sx={{
              padding: '8px 8px 20px',
              fontFamily: 'inherit',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '14px',
              color: 'rgba(62, 65, 77, 0.6)',
              border: 'none',
            }}
          >
            <TableSortLabel
              active={!order || orderBy === head.id}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {head.label}
              {orderBy === head.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const TableProgram: React.FC<ITableProgramProps> = ({
  programsData,
  selectedPrograms,
  handleSelectedPrograms,
  handleActionsWithProgramsTooltip,
}) => {
  const [order, setOrder] = useState<Sorting>(undefined);
  const [orderBy, setOrderBy] = useState<keyof TableProgramData>('programUuid');
  const [isInfoProgramModal, setInfoProgramModal] = useState(false);
  const [programId, setProgramId] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar()

  const {
    isLoadingGetOneProgram,
    isErrorGetOneProgram,

    programData,
  } = useGetOneProgram({
    programId,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const programArr: readonly IProgram[] = programsData || [];

  const rows = programArr?.map((item: IProgram) =>
    createData(
      item.programTitle,
      item.serviceTypes.length > 0 ? `${item.serviceTypes[0]?.title} (+${item.serviceTypes.length - 1})` : '0',
      item.city,
      item.budget,
      `${item.paidSlots}`,
      item.slotsCount,
      'Мед. программа',
      item.programUuid,
      item.medicalProgram
    )
  );

  const handleInfoProgramModalOpen = (): void => {
    setInfoProgramModal(true);
  };

  const handleInfoProgramModalClose = (): void => {
    setProgramId(null);

    setInfoProgramModal(false);
  };

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof TableProgramData): void => {
    setOrder(nextSortState(order));
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.programUuid);
      handleSelectedPrograms(newSelecteds);
      return;
    }
    handleSelectedPrograms([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, name: string): void => {
    const selectedIndex = selectedPrograms.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPrograms, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPrograms.slice(1));
    } else if (selectedIndex === selectedPrograms.length - 1) {
      newSelected = newSelected.concat(selectedPrograms.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPrograms.slice(0, selectedIndex),
        selectedPrograms.slice(selectedIndex + 1)
      );
    }

    handleSelectedPrograms(newSelected);
  };

  const isSelected = (name: string): boolean => selectedPrograms.indexOf(name) !== -1;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper
          sx={{
            width: '100%',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                borderCollapse: 'inherit',
                borderSpacing: '0 4px',
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selectedPrograms.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row?.programUuid);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      onClick={(event: React.MouseEvent<unknown>) => handleClick(event, row?.programUuid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.programUuid}
                      selected={isItemSelected}
                      sx={tableRowStyle}
                    >
                      <TableCell padding="checkbox" sx={tableCellCheckboxStyle}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          checkedIcon={checkedIcon}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: '#FF6D6D',
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" sx={tableCellStyle}>
                        {row?.title}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{row?.service_types}</TableCell>
                      <TableCell align="left" sx={tableCellStyle}>
                        {row?.city}
                      </TableCell>
                      <TableCell align="left" sx={tableCellStyle}>
                        {`${row?.slot_budget
                          .toFixed(2)
                          .split('')
                          .reverse()
                          .join('')
                          .replace(/\d\d\d/g, '$& ')
                          .split('')
                          .reverse()
                          .join('')}`}
                      </TableCell>
                      <TableCell align="left" sx={tableCellStyle}>
                        {row?.slots_count}
                      </TableCell>
                      <TableCell sx={tableCellRowContentStyle}>
                        <Box
                          component="div"
                          onClick={(evt) => {
                            evt.stopPropagation();

                            window.open(row?.medicalProgram);
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            <DescriptionIcon
                              sx={{
                                marginleft: '6px',
                                width: '14px',
                                height: '14px',
                                color: '#BCBEC8',
                              }}
                            />
                            {row?.files}
                          </Box>
                        </Box>

                        <IconButton
                          aria-label="add to shopping cart"
                          onClick={(evt) => {
                            evt.stopPropagation();

                            setProgramId(row?.programUuid);

                            handleActionsWithProgramsTooltip(false);

                            handleInfoProgramModalOpen();
                          }}
                        >
                          <InfoOutlinedIcon fontSize="small" sx={{ color: '#BCBEC8' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {!isLoadingGetOneProgram && !isErrorGetOneProgram && (
        <InfoProgramModal
          open={isInfoProgramModal}
          programData={programData}
          handleClose={handleInfoProgramModalClose}
        />
      )}
    </>
  );
};
