import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL } from 'utils/apiConstants';

import { getFormattedDate } from 'utils/dateHelpers';
import { FetchError } from 'utils/FetchError';
import { GetOnePatientParams, GetOnePatientData } from 'interfaces/hooks/patientsHooksInterfaces';
import { OnePatientData } from 'interfaces/patients/patientInterfaces';

export const useGetOnePatient = ({ patient, onSuccess, onError }: GetOnePatientParams): GetOnePatientData => {
  const patientId = patient;

  const {
    isLoading,
    error,
    data: patientData,
    refetch,
  } = useQuery(
    ['patientData', patientId],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/${patientId}/`, {
        method: 'GET',
        headers: {
          Accept: 'application/vnd.bestdoctor.v1-hr.hr:1',
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: false,
      onSuccess,
      onError,
      select: ({ data }: { data: OnePatientData }) => {
        const passportGivenDate = getFormattedDate(data.patient.passport_date, 'dd.MM.yyyy');
        const birthDate = getFormattedDate(data.patient.birth_date, 'dd.MM.yyyy');
        const startDate = getFormattedDate(data.start_date, 'dd.MM.yyyy');
        const endDate = getFormattedDate(data.end_date, 'dd.MM.yyyy');
        const startDateValid = getFormattedDate(data.start_date, 'yyyy-MM-dd');
        const birthDateValid = getFormattedDate(data.patient.birth_date, 'yyyy-MM-dd');
        const passportGivenDateValid = getFormattedDate(data.patient.passport_date, 'yyyy-MM-dd');

        return {
          uuid: data.uuid,
          id: data.patient.policy_number,
          patientUuid: data.patient.uuid,
          type: data.status?.text,
          welcome_newsletter_status: data.patient.welcome_newsletter_status,
          start_date: startDate,
          start_date_valid: startDateValid,
          end_date: endDate,
          full_name: data.patient.full_name,
          first_name: data.patient.first_name,
          middle_name: data.patient?.middle_name,
          last_name: data.patient.last_name,
          company: data.company_title,
          companyUuid: data.program.company.uuid,
          birth_date: birthDate,
          birth_date_valid: birthDateValid,
          nationality: data.patient.nationality,
          address: data.patient.address,
          gender: data.patient.gender.text,
          passport_number: data.patient.passport_number,
          passport_date: passportGivenDate,
          passport_date_valid: passportGivenDateValid,
          passport_given: data.patient.passport_given,
          phone: data.patient.phone,
          email: data.greetings_email,
          position: data.position,
          number: data.number,
          program_title: data.program.title,
          program_uuid: data.program.uuid,
          program_period: `${startDate} - ${endDate}`,
          policy_number: data.policy_number,
          medPolicy: data.patient.med_policy,
          vzrPolicy: data.patient.vzr_policy_url,
          medProgram: data.program.medical_program_file,
          service_types: data.program.service_types,
          scheduled_program_changes: data.scheduled_program_changes,
          patientType: data.patient_type,
          additional_info: data.additional_info,
          content_type: data.slot_type
        };
      },
    }
  );

  return {
    isLoadingPatient: isLoading,
    isErrorPatient: error,
    patientData,
    refetchPatient: refetch,
  };
};
