import { useQuery } from 'react-query';
import { NotificationsExtendedUserNotificationRq } from '@bestdoctor/core-arch-api/models';
import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { OneNotificationHook, OneNotificationHookData } from 'interfaces/notifications/notificationsInterfaces';
import { FetchError } from 'utils/FetchError';

export const useNotification = ({ notificationUuid, onSuccess, onError }: OneNotificationHook): OneNotificationHookData => {
  const { isLoading, error } = useQuery(
    ['dataOneNotification', notificationUuid],
    async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      await handleRefreshToken();

      const res = await fetch(`${API_URL}/notifications/${notificationUuid}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!notificationUuid,
      onSuccess,
      onError,
      select: ({ data }): NotificationsExtendedUserNotificationRq => data,
    }
  );

  return {
    isLoadingOneNotificationHook: isLoading,
    isErrorOneNotificationHook: !!error,
  };
};
