/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  modalBlockBorderStyle,
  modalBlockFooterStyle,
  modalBlockRowStyle,
  modalTypographyColor,
} from 'ui/modals/modalGlobal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { getFormattedDate } from 'utils/dateHelpers';
import { moneyRowCurrentView } from 'utils/tablesMethods';
import { ContractInfoModalProps } from 'interfaces/patients/patientInterfaces';
import { useSearchProgramsForPeriodDetails } from 'hooks/programs/useSearchProgramsForPeriodDetails';
import { accordionSummaryStyle } from 'ui/accordions/accordionStyles';
import { franchiseTypeMainText, franchiseTypeText } from 'utils/contractInfoTextUtils';
import { franchizeTypeToShow } from 'utils/constants';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { RelativesInsuranceTypeText } from './RelativesInsuranceTypeText';
import { EarlyTerminationOfferTermText } from './EarlyTerminationOfferTermText';
import { BusinessExpensesWithholdingFromContractModificationText } from './BusinessExpensesWithholdingFromContractModificationText';
import { BusinessExpensesRulesText } from './BusinessExpensesRulesText';

export const ContractInfoModal: React.FC<ContractInfoModalProps> = ({
  open,
  handleClose,
  data,
  period,
  managersHR,
}) => {
  const [getPeriodUrl, setGetPeriodUrl] = useState<string>('');
  const [programs, setPrograms] = useState<any>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { programsForPeriodDetails } = useSearchProgramsForPeriodDetails({
    url: open ? getPeriodUrl : null,
    onSuccess: (res) => {
      setPrograms((...prevPrograms) => [...prevPrograms, res?.data].flat());
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  useEffect(() => {
    if (data?.programs) {
      setGetPeriodUrl(data?.programs);
    }
  }, [data?.programs, getPeriodUrl]);

  if (programsForPeriodDetails?.meta?.next_url) {
    setGetPeriodUrl(programsForPeriodDetails?.meta?.next_url);
  }

  const modalClose = (): void => {
    setPrograms([]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={modalClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          minWidth: '800px',
          overflowX: 'hidden',
          padding: '20px 24px 24px',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Typography variant="h3" sx={() => modalTypographyColor('#3e414d')}>
              {`Договор №${data?.contractNum}`}{' '}
              {data?.contractDate ? `от ${getFormattedDate(data?.contractDate, 'dd.MM.yyyy')}` : ''}
            </Typography>

            <Typography variant="subtitle1" sx={() => modalTypographyColor('rgba(62, 65, 77, 0.6)')}>
              {`Действие с ${getFormattedDate(data?.startDate, 'dd.MM.yyyy')} по ${getFormattedDate(
                data?.endDate,
                'dd.MM.yyyy'
              )}`}
            </Typography>
          </Box>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={modalClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ maxHeight: '600px', overflowX: 'hidden', overflowY: 'scroll' }}>
        <Box sx={{ marginTop: '40px' }}>
          <Typography variant="h4" sx={{ margin: '0 0 12px', color: '#3e414d' }}>
            Данные договора
          </Typography>

          <Box sx={modalBlockRowStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Текущая численность, человек
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.amount ? `${data?.amount} человек(а)` : 'Нет данных'}
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Текущая премия, ₽
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {moneyRowCurrentView(data?.premium) || 'Нет данных'}
              </Typography>
            </Box>
          </Box>

          <Box sx={modalBlockBorderStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Количество плановых платежей по договору
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.paymentSchedule || 'Нет данных'}
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Периодичность подписания доп.соглашений
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.agreementSchedule || 'Нет данных'}
              </Typography>
            </Box>
          </Box>

          <Box sx={modalBlockRowStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Порядок внесения численных изменений
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.earlyTerminationOfferTerm ? (
                  <EarlyTerminationOfferTermText data={data?.earlyTerminationOfferTerm} />
                ) : (
                  'Нет данных'
                )}
              </Typography>
            </Box>
          </Box>

          <Box sx={modalBlockRowStyle}>
            {period?.contractType === 'fronting_risk' && (
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Порядок расчетов по численным изменениям
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {data?.businessExpensesWithholdingFromContractModification ? (
                    <BusinessExpensesRulesText
                      data={{
                        businessExpensesWithholdingFromContractModification:
                          data.businessExpensesWithholdingFromContractModification,
                        businessExpensesPercent: data.businessExpensesPercent,
                      }}
                    />
                  ) : (
                    'Нет данных'
                  )}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={modalBlockBorderStyle}>
            <Box>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Особенности расчётов по программам
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.programs ? (
                  <BusinessExpensesWithholdingFromContractModificationText
                    data={{
                      programs,
                    }}
                  />
                ) : (
                  'Нет данных'
                )}
              </Typography>
            </Box>
          </Box>

          <Box sx={modalBlockRowStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Наличие франшизы
              </Typography>
              {data?.franchiseType ? (
                <>
                  <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                    {franchiseTypeMainText(data?.franchiseType?.value)}
                  </Typography>
                  {franchizeTypeToShow?.includes(data?.franchiseType?.value) && (
                    <Accordion
                      sx={{
                        '&.MuiAccordion-root': {
                          boxShadow: 'none',
                        },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyle}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: '#3e414d', marginRight: '8px', marginBottom: '2px', fontWeight: '700' }}
                        >
                          Посмотреть подробнее
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: '0',
                        }}
                      >
                        <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                          {franchiseTypeText(data?.franchiseType?.value)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              ) : (
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  Нет данных
                </Typography>
              )}
            </Box>
          </Box>

          <Box sx={modalBlockRowStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Способ активации
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.activationMethod || 'Нет данных'}
              </Typography>
            </Box>
          </Box>

          <Box sx={modalBlockRowStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Аккаунт-менеджер
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {managersHR?.accountFullName}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {managersHR?.accountEmail}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {managersHR?.accountPhone}
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                Врач-куратор
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {managersHR?.doctorFullName}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {managersHR?.curatorEmail}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {managersHR?.curatorPhone}
              </Typography>
            </Box>
          </Box>

          <Box sx={modalBlockRowStyle}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                ВЗР включен в программу
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                {data?.isVzr || 'Нет данных'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={modalBlockRowStyle}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
              Условия страхования родственников
            </Typography>
            {data?.relativesInsurance ? (
              <>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {data?.relativesInsurance?.relativeType?.value === 'corporate_agreement' ||
                  data?.relativesInsurance?.relativeType?.value === 'individual_agreement'
                    ? `Допускается страхование родственников ${data?.relativesInsurance?.relativeType?.text?.toLowerCase()}`
                    : 'Не допускается страхование родственников по данному договору'}
                </Typography>
                {(data?.relativesInsurance?.relativeType?.value === 'corporate_agreement' ||
                  data?.relativesInsurance?.relativeType?.value === 'individual_agreement') && (
                  <Accordion
                    sx={{
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyle}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: '#3e414d', marginRight: '8px', marginBottom: '2px', fontWeight: '700' }}
                      >
                        Посмотреть подробнее
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: '0',
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                        {data?.relativesInsurance ? (
                          <RelativesInsuranceTypeText data={data?.relativesInsurance} />
                        ) : (
                          'Нет данных'
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            ) : (
              <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                Нет данных
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={modalBlockFooterStyle}>
        <MainButton textButton="Закрыть" mainBtn handleClick={handleClose} />
      </Box>
    </Dialog>
  );
};
