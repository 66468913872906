import { z } from 'zod';

export const validationPatient = (reqPass: boolean): z.ZodObject<{
  last_name: z.ZodString;
  first_name: z.ZodString;
  middle_name: z.ZodString;
  gender: z.ZodString;
  birth_date: z.ZodString;
  nationality: z.ZodString;
  address: z.ZodString;
  phone: z.ZodString | z.ZodNullable<z.ZodString>;
  greetings_email: z.ZodString | z.ZodNullable<z.ZodString>;
  position: z.ZodString;
  number: z.ZodString;
  passport_number: z.ZodString | z.ZodNullable<z.ZodString>;
  passport_date: z.ZodString | z.ZodNullable<z.ZodString>;
  passport_given: z.ZodString | z.ZodNullable<z.ZodString>;
  additional_info: z.ZodString | z.ZodNullable<z.ZodString>;
}> => z.object({
  last_name: z.string().min(1, { message: 'Обязательное поле' }).max(40, { message: 'Недопустимая длина строки'}),
  first_name: z.string().min(1, { message: 'Обязательное поле' }).max(40, { message: 'Недопустимая длина строки'}),
  middle_name: z.string().max(40, { message: 'Недопустимая длина строки'}),
  gender: z.string().min(1, { message: 'Обязательное поле' }),
  birth_date: z.string().min(1, { message: 'Обязательное поле' }),
  nationality: z.string().min(1, { message: 'Обязательное поле' }),
  address: z.string().min(1, { message: 'Обязательное поле' }),
  phone: z.string().nullable(),
  greetings_email: z.string().nullable(),
  position: z.string(),
  number: z.string(),
  passport_number: reqPass ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  passport_date: reqPass ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  passport_given: reqPass ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  additional_info: z.string().nullable()
});