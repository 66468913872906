/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, ReactElement, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box } from '@mui/system';
import { Dialog, IconButton, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { format, isSameDay, isValid, parse } from 'date-fns';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import { inputFormStyle } from 'ui/inputs/inputsForm';

import { useSearchPrograms } from 'hooks/programs/useSearchProgram';
import { IDataHooksProgram, IProgram } from 'interfaces/program/programInterfaces';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';

import { EditPatientPassModalProps, ProgramsParams } from 'interfaces/patients/patientInterfaces';

import {
  modalHeaderStyle,
  modalTitleStyle,
  modalSubtitleStyle,
  modalFormStyle,
  modalFormScrollStyle,
  modalFormWrapStyle,
  modalFormWrapInputsStyle,
  modalFormInputStyle,
} from 'ui/modals/modalGlobal';

import { themeColor, grayLightDarkOpacity } from 'utils/uiConstants';
import { optionsGender } from 'utils/constants';
import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { useSnackbar } from 'notistack';
import { useEditPatient } from 'hooks/patients/useEditPatient';
import { Company, DataPeriod, ErrorData } from 'interfaces/global/globalInterfaces';
import { requiredPassByCompany } from 'utils/requiredCheck';
import { moneyRowCurrentView } from 'utils/tablesMethods';
import { ChangeProgramModal } from './ChangeProgramModal';

import { validationPatient } from './validationEditPatient';

export const EditPatientModal: React.FC<EditPatientPassModalProps> = ({
  patientData,
  selectedCompanies,
  periodByAttachOrDetach,
  open,
  handleClose,
  refetchPatient,
}) => {
  const [dateBirth, setDateBirth] = useState<Date | null>(null);
  const [datePassport, setDatePassport] = useState<Date | null>(null);
  const [programs, setPrograms] = useState<ProgramsParams[]>([]);
  const [patientCompany, setPatientCompany] = useState<Company | null>(null);
  const [patientProgram, setPatientProgram] = useState<ProgramsParams | null>(null);
  const [programsNextPageUrl, setProgramsNextPageUrl] = useState<string | null>(null);

  const [isChangeProgramModal, setIsChangeProgramModal] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const reqPass: boolean = requiredPassByCompany(patientCompany);

  const validPatient = validationPatient(reqPass);
  type PatientParams = z.infer<typeof validPatient>;

  const rendersPickerDateBirth = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateBirth !== null ? isSameDay(date, dateBirth) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDatePassport = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = datePassport !== null ? isSameDay(date, datePassport) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const {
    setValue,
    register,
    reset,
    formState: { errors, isDirty, dirtyFields },
    setError,
    handleSubmit,
    control,
  } = useForm<PatientParams>({
    mode: 'onTouched',
    resolver: zodResolver(validationPatient(reqPass)),
  });

  const dirtyFullname = dirtyFields.first_name && dirtyFields.last_name && dirtyFields.middle_name;

  const mainFilterPeriodStaff: DataPeriod | null =
    'mainFilterPeriodStaff' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '') : '';

  const { fetchNextPage } = useSearchPrograms({
    company: patientCompany || null,
    period: mainFilterPeriodStaff,
    selectedProgram: null,
    selectedServices: [],
    selectedСity: null,

    onSuccess: (data: IDataHooksProgram) => {
      const loadProgrammArr: ProgramsParams[] | [] = data.programs
        ? data.programs.map((item: IProgram) => ({
            id: item.programUuid,
            title: `${item.programTitle}\n(страховая премия - ${moneyRowCurrentView(item.budget)})`,
            uuid: item.programUuid,
            allowedSlotPatientTypes: item.allowedSlotPatientTypes,
          }))
        : programs;

      const filterLoadProgrammArr = loadProgrammArr
        .filter((a, i) => loadProgrammArr.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

      filterLoadProgrammArr.forEach((el) => {
        if (el.uuid === patientData.program_uuid) {
          setPatientProgram(el);
        }
      });

      setPrograms(filterLoadProgrammArr);
      setProgramsNextPageUrl(data?.pageParams?.next_url);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingEditPatient, handleEditPatient } = useEditPatient({
    patientUuid: patientData ? patientData.uuid : null,
    dirtyFields,

    onSuccess: async (res: Response) => {
      if (res.ok) {
        enqueueSnackbar('Успешно отредактировано!', { variant: 'success' });
        refetchPatient();

        setTimeout(() => {
          handleClose();
          reset();
        }, 5000);
      } else {
        await res.json().then(
          (data: {
            message: string;
            errors: {
              message: string;
              code: string;
              field:
                | 'last_name'
                | 'first_name'
                | 'middle_name'
                | 'gender'
                | 'birth_date'
                | 'nationality'
                | 'address'
                | 'phone'
                | 'greetings_email'
                | 'position'
                | 'number'
                | 'passport_number'
                | 'passport_date'
                | 'passport_given';
            }[];
          }) => {
            if (data.message !== 'Invalid input.') {
              enqueueSnackbar(data.message, { variant: 'error' });
            }

            data.errors.forEach((item) => {
              const { field } = item;

              enqueueSnackbar(item.message, { variant: 'error' });

              setError(field, {
                type: 'custom',
                message: item.message,
              });
            });
          }
        );
      }
    },
    onError: () => {
      enqueueSnackbar('Произошла ошибка при редактировании', { variant: 'error' });
    },
  });

  useEffect(() => {
    if (programsNextPageUrl) {
      fetchNextPage();
    }
  }, [fetchNextPage, programsNextPageUrl, programs]);

  useEffect(() => {
    setDateBirth(isValid(patientData?.birth_date_valid) ? new Date(patientData.birth_date_valid) : null);
    setDatePassport(isValid(patientData?.passport_date_valid) ? new Date(patientData.passport_date_valid) : null);

    reset({
      last_name: patientData?.last_name || '',
      first_name: patientData?.first_name || '',
      middle_name: patientData?.middle_name || '',
      gender: patientData?.gender === 'Мужской' ? 'M' : 'F',

      birth_date: patientData?.birth_date
        ? format(parse(patientData.birth_date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
        : '',
      nationality: patientData?.nationality || '',
      address: patientData?.address || '',
      phone: patientData?.phone || '',
      greetings_email: patientData?.email || '',
      position: patientData?.position || '',
      number: patientData?.number || '',
      passport_number: patientData?.passport_number || '',
      passport_date: patientData?.passport_date
        ? format(parse(patientData?.passport_date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
        : null,
      passport_given: patientData?.passport_given || '',
    });
  }, [patientData, reset]);

  useEffect(() => {
    if (selectedCompanies.length) {
      selectedCompanies.forEach((company) => {
        if (company.uuid === patientData.companyUuid) {
          setPatientCompany(company);
        }
      });
    }
  }, [patientData, selectedCompanies]);

  return (
    <Dialog open={open} onClose={handleClose} sx={dialogStyleAddModal}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            Редактирование пациента
          </Typography>
          <Typography variant="subtitle1" sx={modalSubtitleStyle}>
            Изменения будут применены в ближайшее время.
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box component="form" sx={modalFormStyle} onSubmit={handleSubmit(handleEditPatient)}>
        <Box sx={() => modalFormScrollStyle(600)}>
          <Box sx={() => modalFormWrapStyle(false)}>
            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Фамилия"
                  variant="standard"
                  error={!!errors.last_name?.message}
                  helperText={errors.last_name?.message}
                  sx={() => inputFormStyle(false)}
                  required={!!dirtyFields.last_name || !!errors.last_name}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('last_name', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('last_name')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Имя"
                  variant="standard"
                  error={!!errors.first_name?.message}
                  helperText={errors.first_name?.message}
                  sx={() => inputFormStyle(false)}
                  required={!!dirtyFields.first_name || !!errors.first_name}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('first_name', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('first_name')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Отчество"
                  variant="standard"
                  error={!!errors.middle_name?.message}
                  helperText={errors.middle_name?.message}
                  sx={() => inputFormStyle(false)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('middle_name', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('middle_name')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <AutocompleteInput
                  defaultValue={patientData.gender}
                  label="Пол"
                  data={optionsGender || []}
                  required={!!dirtyFields.gender || !!errors.gender}
                  handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; value: string }) => {
                    setValue('gender', newValue.value, { shouldDirty: true });
                    register('gender', { value: newValue.value });
                  }}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                  <Controller
                    control={control}
                    name="birth_date"
                    render={({ field: { value } }) => (
                      <DesktopDatePicker
                        label="Дата рождения"
                        value={value}
                        renderDay={rendersPickerDateBirth}
                        PopperProps={{
                          sx: datePickerPopperStyled,
                        }}
                        onChange={(newValue: Date | null) => {
                          if (isValid(newValue) && newValue) {
                            setValue('birth_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });

                            setDateBirth(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Дата рождения"
                            variant="standard"
                            value={dateBirth}
                            error={!!errors.birth_date?.message}
                            helperText={errors.birth_date?.message}
                            sx={() => inputFormStyle(false)}
                            required={!!dirtyFields.birth_date || !!errors.birth_date}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            InputProps={{
                              endAdornment: (
                                <>
                                  <IconButton
                                    sx={{ width: '28px', height: '28px' }}
                                    onClick={() => {
                                      setDateBirth(null);
                                      setValue('birth_date', '', { shouldDirty: true });
                                    }}
                                  >
                                    <CloseOutlinedIcon fontSize="small" />
                                  </IconButton>

                                  {params.InputProps?.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Гражданство"
                  variant="standard"
                  error={!!errors.nationality?.message}
                  helperText={errors.nationality?.message}
                  sx={() => inputFormStyle(false)}
                  required={!!dirtyFields.nationality || !!errors.nationality}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('nationality', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('nationality')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Адрес фактического проживания"
                  variant="standard"
                  error={!!errors.address?.message}
                  helperText={errors.address?.message}
                  sx={() => inputFormStyle(false)}
                  required={!!dirtyFields.address || !!errors.address}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('address', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('address')}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={() => modalFormWrapStyle(false)}>
            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Телефон"
                  variant="standard"
                  defaultValue={patientData?.phone}
                  error={!!errors.phone?.message}
                  helperText={errors.phone?.message}
                  sx={() => inputFormStyle(false)}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('phone')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="E-mail"
                  variant="standard"
                  defaultValue={patientData?.email}
                  error={!!errors.greetings_email?.message}
                  helperText={errors.greetings_email?.message}
                  sx={() => inputFormStyle(false)}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('greetings_email')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Должность"
                  variant="standard"
                  defaultValue={patientData?.position}
                  error={!!errors.position?.message}
                  helperText={errors.position?.message}
                  sx={() => inputFormStyle(false)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('position', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('position')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Табельный номер"
                  variant="standard"
                  error={!!errors.number?.message}
                  helperText={errors.number?.message}
                  sx={() => inputFormStyle(false)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('number', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('number')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Номер паспорта"
                  variant="standard"
                  error={!!errors.passport_number?.message}
                  helperText={errors.passport_number?.message}
                  sx={() => inputFormStyle(false)}
                  required={reqPass}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('passport_number', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('passport_number')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <Controller
                  control={control}
                  name="passport_date"
                  defaultValue={null}
                  render={({ field: { value, onChange } }) => (
                    <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Дата выдачи"
                        value={value}
                        renderDay={rendersPickerDatePassport}
                        PopperProps={{
                          sx: datePickerPopperStyled,
                        }}
                        onChange={(newValue) => {
                          if (newValue && isValid(newValue)) {
                            const formattedDate = format(newValue, 'yyyy-MM-dd');
                            onChange(formattedDate);
                            setDatePassport(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Дата выдачи"
                            variant="standard"
                            error={!!errors.passport_date?.message}
                            helperText={errors.passport_date?.message}
                            sx={() => inputFormStyle(false)}
                            required={reqPass}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            InputProps={{
                              endAdornment: (
                                <>
                                  <IconButton
                                    sx={{ width: '28px', height: '28px' }}
                                    onClick={() => {
                                      setDatePassport(null);
                                      onChange(null);
                                    }}
                                  >
                                    <CloseOutlinedIcon fontSize="small" />
                                  </IconButton>

                                  {params.InputProps?.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Кем выдан"
                  variant="standard"
                  defaultValue={patientData?.passport_given}
                  error={!!errors.passport_given?.message}
                  helperText={errors.passport_given?.message}
                  sx={() => inputFormStyle(false)}
                  required={reqPass}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        onClick={() => {
                          setValue('passport_given', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('passport_given')}
                />
              </Box>
            </Box>
            <Box sx={() => modalFormInputStyle(false)}>
              <TextField
                label="Доп информация"
                variant="standard"
                defaultValue={patientData?.additional_info}
                error={!!errors.additional_info?.message}
                helperText={errors.additional_info?.message}
                sx={() => inputFormStyle(false)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ width: '28px', height: '28px' }}
                      onClick={() => {
                        setValue('additional_info', '', { shouldDirty: true });
                      }}
                    >
                      <CloseOutlinedIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('additional_info')}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={() => modalFormWrapStyle(true)}>
          <Typography variant="caption" sx={{ color: grayLightDarkOpacity }}>
            <Typography variant="caption" sx={{ color: themeColor }}>
              *{' '}
            </Typography>
            - обязательные поля.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
            }}
          >
            <TooltipActionsInfo placement="left" title={dirtyFullname ? 'Нельзя редактировать ФИО полностью' : ''}>
              <Box component="span">
                <MainButton
                  textButton="Сохранить"
                  typeBtn="submit"
                  mainBtn
                  disabled={isLoadingEditPatient || !isDirty || dirtyFullname}
                />
              </Box>
            </TooltipActionsInfo>
          </Box>
        </Box>
      </Box>

      {!!patientProgram && (
        <ChangeProgramModal
          open={isChangeProgramModal}
          handleClose={() => setIsChangeProgramModal(false)}
          periodByAttachOrDetach={periodByAttachOrDetach}
          parentClose={handleClose}
          patientData={patientData}
          programs={programs}
          patientProgram={patientProgram}
          refetchPatient={refetchPatient}
        />
      )}
    </Dialog>
  );
};
