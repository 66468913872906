/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useLayoutEffect, useState } from 'react';
import { useSession } from 'hooks/auth/useSession';
import { Box } from '@mui/system';
import { loginContainerStyle, loginTitleStyle } from 'ui/login/login';
import { themeColor } from 'utils/uiConstants';
import { ResetPassword } from 'components/ResetPassword/ResetPassword';
import { PasswordResetForm } from 'components/PasswordResetForm/PasswordResetForm';
import { useLocation } from 'react-router-dom';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import { getCompanyName } from 'utils/companyName';
import { FormLogin } from '../../components/FormLogin/FormLogin';

interface ILogin {
  handleLoggedIn: (value: boolean) => void;
  isSettingPassword?: boolean;
}

export const Login: React.FC<ILogin> = ({ handleLoggedIn, isSettingPassword }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoadingSession, isErrorSession } = useSession({
    onError: () => {
      enqueueSnackbar('Произошла ошибка сессии', { variant: 'error' });
    },
  });
  const [sessionKeyUrl, setSessionKeyUrl] = useState<string | null | undefined>(null);
  const [tokenKeyUrl, setTokenKey] = useState<string | null | undefined>(null);
  const [hashTokenUrl, setHashTokenUrl] = useState<string | null | undefined>(null);
  const [isModalPass, setModalPass] = useState<boolean>(false);
  const [isResetPossible, setResetPossible] = useState<boolean>(false);

  const handleModalPass = (): void => {
    setModalPass((prev) => !prev);
  };

  const { search } = useLocation();
  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(search);
    const urlArr = queryParams.get('token')?.split('-');

    if (urlArr) {
      const [sessionKey, tokenKey, hashToken]: string[] = urlArr;
      setSessionKeyUrl(sessionKey);
      setTokenKey(tokenKey);
      setHashTokenUrl(hashToken);
    }
    if (tokenKeyUrl && hashTokenUrl && sessionKeyUrl) {
      if (md5(sessionKeyUrl + tokenKeyUrl) === hashTokenUrl) {
        setResetPossible(true);
      }
    }
  }, [hashTokenUrl, search, sessionKeyUrl, tokenKeyUrl]);

  return (
    <Box component="section">
      <Box sx={loginContainerStyle}>
        <Box sx={loginTitleStyle}>
          {isSettingPassword ? 'Получение доступа к' : 'Добро пожаловать в'}
          <Box
            sx={{
              color: themeColor,
            }}
          >
            {getCompanyName()}
          </Box>
        </Box>
        {isSettingPassword ? (
          <PasswordResetForm
            tokenKeyUrl={tokenKeyUrl}
            sessionKeyUrl={sessionKeyUrl}
            isResetPossible={isResetPossible}
          />
        ) : (
          <FormLogin
            isLoadingSession={isLoadingSession}
            isErrorSession={isErrorSession}
            handleLoggedIn={handleLoggedIn}
            handleModalPass={handleModalPass}
          />
        )}
      </Box>
      <ResetPassword handleModalPass={handleModalPass} isModalPass={isModalPass} />
    </Box>
  );
};
