import React from 'react';

import Box from '@mui/material/Box';
import { Autocomplete, Checkbox, IconButton, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import { inputFormStyle } from 'ui/inputs/inputsForm';
import { AutocompletePopper, optionAutocompleteStyle } from 'ui/inputs/inputsAutocomplete';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { grayDarkColor } from 'utils/uiConstants';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxOutlinedIcon sx={{ color: '#ff6d6d' }} />;

interface IAutocompleteProps {
  label: string;
  placeholder?: string;
  value?: any;
  defaultValue?: any;
  inputValue?: string;
  data: any;
  limitTags?: number;
  disableCloseOnSelect?: boolean;
  check?: boolean;
  disabled?: boolean;
  required?: boolean;
  handleChange?: (_event: React.SyntheticEvent, newValue: string | null) => void;
  handleInputChange?: (_event: React.SyntheticEvent, newInputValue: string) => void;
  handleChangeObj?: (_event: any, newValue: any) => void;
  handleClear?: () => void;
  disableDefaultValue?: boolean
}

export const AutocompleteInput: React.FC<IAutocompleteProps> = ({
  label,
  placeholder,
  value,
  defaultValue,
  inputValue,
  data,
  limitTags,
  disableCloseOnSelect,
  check,
  disabled,
  required,
  handleChange,
  handleInputChange,
  handleChangeObj,
  handleClear,
  disableDefaultValue
}) => (
  <Autocomplete
    sx={{ width: '100%' }}
    PopperComponent={AutocompletePopper}
    id="size-small-standard"
    size="small"
    value={value}
    onChange={handleChange || handleChangeObj}
    inputValue={inputValue}
    onInputChange={handleInputChange}
    noOptionsText="Данные отсутствуют"
    options={data}
    limitTags={limitTags || 1}
    getOptionLabel={(option) => (option.title ? option.title : option)}
    defaultValue={defaultValue}
    disabled={disabled}
    disableCloseOnSelect={disableCloseOnSelect}
    getOptionDisabled={(option) => !!((option?.uuid === defaultValue?.uuid) && disableDefaultValue)}
    clearIcon={
      <IconButton
        sx={{ width: '28px', height: '28px' }}
        onClick={handleClear}
      >
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    }
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="standard"
        label={label}
        placeholder={placeholder}
        sx={() => inputFormStyle(true)}
        required={required}
      />
    )}
    renderOption={(props, option, { selected }) => (
      <Box
        id={value}
        key={option.title ? option.title : option}
        component="li"
        sx={() => optionAutocompleteStyle(value, option)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {check && (
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={value ? value?.title === option.title : false || selected}
          />
        )}

        <Box component="span" sx={{ color: option.color || grayDarkColor, padding: '8px' }}>
          {option.title ? option.title.split(/\n/).map((line) => <Box key={line}>{line}</Box>) : option}
        </Box>

        {option.bgcolor && <BookmarkIcon sx={{ width: '20px', height: '20px', color: option.bgcolor }} />}
      </Box>
    )}
  />
);
