import React from 'react';

import { Box } from '@mui/system';
import { Dialog, IconButton, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { modalHeaderStyle, modalTitleStyle, dialogModalStyle } from 'ui/modals/modalGlobal';

import { IChartPaymentsDocsModalProps } from 'interfaces/shared/modals/modalsInterfaces';

import { TableChartPaymentsDocs } from './TableChartPaymentsDocs/TableChartPaymentsDocs';

export const ChartPaymentsDocsModal: React.FC<IChartPaymentsDocsModalProps> = ({ open, handleClose }) => (
  <Dialog open={open} onClose={handleClose} sx={() => dialogModalStyle('1356px')}>
    <Box sx={modalHeaderStyle}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" sx={modalTitleStyle}>
          График платежей
        </Typography>
      </Box>
      <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>

    <Box sx={{ margin: '0 24px 20px' }}>
      <TableChartPaymentsDocs />
    </Box>
  </Dialog>
);
