import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { PatientActionsData } from 'interfaces/patients/patientInterfaces';
import { AddPatientHook, AddPatientHookData } from 'interfaces/hooks/patientsHooksInterfaces';

export const useAddPatient = ({ onSuccess, onError }: AddPatientHook): AddPatientHookData => {
  const { mutate, isLoading } = useMutation(
    async (values: PatientActionsData) => {

      const url = values.patient_type === 'relative' ? 'employees/attach_relative_employee' : 'employees' 

      const data = {
        last_name: values.last_name,
        first_name: values.first_name,
        middle_name: values.middle_name,
        gender: values.gender,
        birth_date: values.birth_date,
        nationality: values.nationality,
        address: values.address,
        email: values.email,
        position: values.position,
        number: values.number,  
        program: values.program, 
        start_date: values.start_date,
        additional_info: values.additional_info,
        ...(values.is_forced_attachment && {
          is_forced_attachment: values.is_forced_attachment,
        }),
        ...(values.passport_number && {
          passport_number: values.passport_number,
        }),
        ...(values.passport_date && {
          passport_date: values.passport_date,
        }),
        ...(values.passport_given && {
          passport_given: values.passport_given,
        }),
        ...(values.phone && {
          phone: values.phone,
        }),
        ...(values.patient_type === 'relative' && {
          relative_patients: [{
            patient_uuid: values.patient_relative,
            relative_type: values.relative_type,
          }
          ]
        })
      }

      if(values.patient_relative_additional && values.relative_type_additional && values.relative_type === 'child'){
        data.relative_patients?.push({
          patient_uuid: values.patient_relative_additional,
          relative_type: values.relative_type_additional,
        })
      }

      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/${url}/`, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      return res;
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    handleAddPatient: mutate,
    isLoadingAddPatient: isLoading,
  };
};
