import { SystemStyleObject, Theme } from '@mui/system';
import { themeColor, grayDarkColor, grayLightDarkOpacity } from 'utils/uiConstants';

export const titleFormStyle = {
  marginBottom: '12px',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
};

export const inputFormStyle = (boolean: boolean): SystemStyleObject<Theme> => ({
  width: '100%',

  'MuiFormControl-root': {
    position: 'relative',
  },
  '& .MuiInput-root': {
    marginTop: boolean ? '19px' : '16px',

    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: grayDarkColor,

    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: `1px solid ${grayDarkColor}`,
    },

    '& > div > button': {
      width: '28px',
      height: '28px',
      marginRight: '0px',

      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },

    '&.Mui-error::after': {
      borderColor: themeColor,
    },
  },
  '& label': {
    fontSize: '12px',
    lineHeight: '14px',
    color: grayLightDarkOpacity,
  },
  '& label.Mui-focused': {
    color: grayDarkColor,
  },
  '& .MuiInput-underline:after': {
    borderBottom: `1px solid ${grayDarkColor}`,
  },
  '.MuiFormLabel-asterisk': {
    color: themeColor,
  },
  '& :hover:not(.Mui-disabled)::before': {
    borderBottom: `1px solid ${grayDarkColor}`,
  },

  '& input:invalid + fieldset': {
    borderColor: themeColor,
  },
  '& .Mui-disabled::before': {
    borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
  },

  '&.MuiTextField-root': {
    '& .Mui-error': {
      color: themeColor,
    },

    '& > p': {
      width: '100%',
      position: 'absolute',
      top: '45px',
      color: themeColor,
    },
  },

  '&.Mui-error': {
    color: themeColor,
  },
});

export const textAreaFormStyle: SystemStyleObject<Theme> = {
  width: '100%',
  border: '1px solid #999999',
  borderRadius: '4px',
  padding: '8px',

  '& > label': {
    padding: '8px',
  },
  '& > label.Mui-focused': {
    padding: '8px',
  },

  '& .MuiInput-root': {
    marginTop: '19px',

    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: grayDarkColor,

    '&::before': {
      border: 'none',
    },

    '&:hover:not(.Mui-disabled)::before': {
      border: 'none',
    },

    '& > div > button': {
      width: '28px',
      height: '28px',
      marginRight: '0px',

      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },

    '&.Mui-focused::after': {
      border: 'none',
    },
    '&.Mui-error::after': {
      border: 'none',
    },
  },
  '& label': {
    fontSize: '12px',
    lineHeight: '14px',
    color: grayLightDarkOpacity,
  },
  '& label.Mui-focused': {
    color: grayDarkColor,
  },
  '& .MuiInput-underline:after': {
    border: 'none',
  },
  '.MuiFormLabel-asterisk': {
    color: themeColor,
  },
  '& :hover:not(.Mui-disabled)::before': {
    border: '1px solid #999999',
    borderRadius: '4px',
  },

  '& input:invalid + fieldset': {
    border: '1px solid #999999',
    borderRadius: '4px',
  },
  '& .Mui-disabled::before': {
    border: '1px solid #999999',
    borderRadius: '4px',
  },

  '&.MuiTextField-root': {
    '& .Mui-error': {
      color: themeColor,
    },

    '& > p': {
      width: '100%',
      position: 'absolute',
      top: '45px',
      color: themeColor,
    },
  },

  '&.Mui-error': {
    color: themeColor,
  },
};
