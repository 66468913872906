import React from 'react';

import { ActionWithFinanceBtn } from 'components/ActionWithFinanceBtn/ActionWithFinanceBtn';
import { AccessList, Company, DataPeriod, DefaultPermissions } from 'interfaces/global/globalInterfaces';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { BasicNavigation } from './BasicNavigation';

interface FinanceNavigationProps {
  isFronting: boolean;
  selectedPeriodByFinance: DataPeriod | null;
  accessList: AccessList[];
  defaultPermissions: DefaultPermissions;
}
export const FinanceNavigation: React.FC<FinanceNavigationProps> = ({
  isFronting,
  selectedPeriodByFinance,
  accessList,
  defaultPermissions,
}) => {
  const mainFilterCompanyFinance: Company | '' = sessionStorageGetItem<Company>('mainFilterCompanyFinance');
  return (
    <BasicNavigation>
      {isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance', accessList, defaultPermissions) && (
        <ActionWithFinanceBtn isFronting={isFronting} selectedPeriodByFinance={selectedPeriodByFinance} />
      )}
    </BasicNavigation>
  );
};
