/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ReportIcon from '@mui/icons-material/Report';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';

import { Loader } from 'shared/loaders/Loader';
import {
  ActionsWithSlotsEnhancedTableProps,
  ActionsWithSlotsTableProps,
  TableHistoryHeading,
} from 'interfaces/patients/patientInterfaces';

import { tableHistoryCellStyle, tableSlotsActionsStyle } from 'ui/tables/tables';
import { azureColor, themeColor } from 'utils/uiConstants';
import { Stack } from '@mui/system';
import { ContinueActionModal } from 'shared/modals/ContinueActionModal/ContinueActionModal';
import { Sorting } from 'interfaces/other/otherInterfaces';
import { nextSortState } from 'utils/tablesMethods';
import { operationTypes } from 'utils/constants';

function createData(
  uuid: string,
  task_name: string,
  status_display: string,
  start: string,
  end: string,
  result: string,
  task_id: string
): any {
  return {
    uuid,
    task_name,
    status_display,
    start,
    end,
    result,
    task_id,
  };
}

const heading: readonly TableHistoryHeading[] = [
  {
    id: 'task_name',
    numeric: false,
    disablePadding: false,
    label: 'Наименование операции',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'enqueued_at',
    numeric: false,
    disablePadding: false,
    label: 'Начало операции',
  },
  {
    id: 'done_at',
    numeric: false,
    disablePadding: false,
    label: 'Завершение операции',
  },
];

const EnhancedTableHead: React.FC<ActionsWithSlotsEnhancedTableProps> = (props: ActionsWithSlotsEnhancedTableProps) => {
  const { orderingActionsWithSlots, handleOrderingActionsWithSlots } = props;
  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if (!sortDirection) {
      handleOrderingActionsWithSlots('');
    }
  }, [handleOrderingActionsWithSlots, orderingActionsWithSlots, sortDirection]);

  return (
    <TableHead
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
      }}
    >
      <TableRow sx={{ width: '100%', display: 'flex' }}>
        {heading?.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sx={{ ...tableSlotsActionsStyle, fontSize: '12px', lineHeight: '12px', opacity: 0.6 }}
          >
            <TableSortLabel
              active={!sortDirection || orderingActionsWithSlots?.replace('-', '') === `${head.id}`}
              direction={sortDirection}
              onClick={() => {
                setSortDirection(nextSortState(sortDirection));
                handleOrderingActionsWithSlots(sortDirection === 'asc' ? `${head.id}` : `-${head.id}`);
              }}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {head.label}
              <Box component="span" sx={visuallyHidden}>
                {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ActionsWithSlotsTable: React.FC<ActionsWithSlotsTableProps> = ({
  actionsWithSlots,
  fetchNextPageSlotsOperations,
  orderingActionsWithSlots,
  handleOrderingActionsWithSlots,
  isFetchingNextPageSlotsOperations,
}) => {
  const rows = actionsWithSlots?.map((item: any) =>
    createData(item.uuid, item.task_name, item.status_display, item.start, item.end, item.result, item.task_id)
  );

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [continueModal, setContinueModal] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);

  const getOperationRes = (operationType: string, data: any): string | undefined => {
    if (!data?.total_count_records) {
      return `(Некорректный шаблон)`;
    }
    if (operationType === 'detach_slots_from_file') {
      return `(${data?.total_successful_detaching}/${data?.total_count_records})`;
    }
    if (operationType === 'create_slots_from_file') {
      return `(${data?.total_successful_creating}/${data?.total_count_records})`;
    }
    return undefined;
  };

  const getOperationStatus = (operationType: string, data: any): ReactNode | undefined => {
    if (operationType === 'detach_slots_from_file') {
      if (data?.total_successful_detaching === data?.total_count_records && data?.total_count_records) {
        return (
          <Stack
            onClick={() => setContinueModal(true)}
            direction="row"
            sx={{
              color: azureColor,
              cursor: 'pointer',
              alignItems: 'center',
            }}
          >
            Выполнена
          </Stack>
        );
      }
      return (
        <Stack
          onClick={() => setContinueModal(true)}
          direction="row"
          sx={{
            color: themeColor,
            alignItems: 'center',
            gap: '4px',
            cursor: 'pointer',
          }}
        >
          Выполнена
          <ReportIcon
            sx={{
              fontSize: '20px',
            }}
          />
        </Stack>
      );
    }
    if (operationType === 'create_slots_from_file') {
      if (data?.total_successful_creating === data?.total_count_records && data?.total_count_records) {
        return (
          <Stack
            onClick={() => setContinueModal(true)}
            direction="row"
            sx={{
              color: azureColor,
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            Выполнена
          </Stack>
        );
      }
      return (
        <Stack
          onClick={() => setContinueModal(true)}
          direction="row"
          sx={{
            color: themeColor,
            alignItems: 'center',
            gap: '4px',
            cursor: 'pointer',
          }}
        >
          Выполнена
          <ReportIcon
            sx={{
              fontSize: '20px',
            }}
          />
        </Stack>
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (isBottom) {
      fetchNextPageSlotsOperations();
      setIsBottom(false);
    }
  }, [fetchNextPageSlotsOperations, isBottom]);

  return (
    <Box>
      <Paper sx={{ width: '100%', boxShadow: 'none' }}>
        <TableContainer>
          <Table
            onScroll={(e) => {
              if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
                setIsBottom(true);
              }
            }}
            sx={{
              minWidth: 750,
              display: 'block',
              emptyCells: 'show',
              overflowY: 'scroll',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              orderingActionsWithSlots={orderingActionsWithSlots}
              handleOrderingActionsWithSlots={handleOrderingActionsWithSlots}
            />
            <TableBody
              sx={{
                maxHeight: '256px',
                position: 'relative',
                display: 'block',
                width: '100%',
              }}
            >
              {rows?.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.uuid}
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setSelectedRow(row);
                  }}
                >
                  <TableCell sx={tableHistoryCellStyle()}>
                    {operationTypes[row.task_name]} {getOperationRes(row.task_name, row.result?.data)}
                  </TableCell>
                  <TableCell sx={tableHistoryCellStyle()}>
                    {getOperationStatus(row.task_name, row.result?.data)}
                  </TableCell>
                  <TableCell sx={tableHistoryCellStyle()}>{row.start}</TableCell>
                  <TableCell sx={tableHistoryCellStyle()}>{row.end}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ContinueActionModal open={continueModal} handleClose={() => setContinueModal(false)} data={selectedRow} />
      {isFetchingNextPageSlotsOperations && <Loader />}
    </Box>
  );
};
