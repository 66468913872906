import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { EditProgramHookData, EditProgramHookParams } from 'interfaces/hooks/patientsHooksInterfaces';

export const useEditProgram = ({
  patientUuid,
  onSuccess,
  onError,
}: EditProgramHookParams): EditProgramHookData => {
  const { mutate, isLoading } = useMutation(
    async (values: { new_program_uuid: string, change_at: string }) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/${patientUuid}/change_program/`, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });

      return res;

    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!patientUuid,
      onSuccess,
      onError,
    }
  );

  return {
    handleEditProgram: mutate,
    isLoadingEditProgram: isLoading,
  };
};
