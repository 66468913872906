import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { DataPeriod } from 'interfaces/global/globalInterfaces';
import { ExportProgramsToExcelParams, ExportProgramsToExcelData } from 'interfaces/hooks/programsHooksInterfaces';

export const useExportProgramsToExcel = ({ onSuccess, onError }: ExportProgramsToExcelParams): ExportProgramsToExcelData => {
  const {
    mutate
  } = useMutation(
    ['dataExportSelectedProgramsToExcel'],
    async (programs: readonly string[]) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const formData: FormData = new FormData();

      const mainFilterPeriodPrograms: DataPeriod =
        'mainFilterPeriodPrograms' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodPrograms') || '') : '';

      for (let i = 0; i < programs.length; i++) {
        formData.append('program_uuids', programs[i]);
      }

      formData.append('service_period_uuid ', mainFilterPeriodPrograms.uuid)


      const res = await fetch(`${API_URL}/programs/export_programs_to_excel/`, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
        body: formData
      }
      );

      if (res.ok) {
        return res.json();
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    { onSuccess, onError, refetchOnWindowFocus: false, enabled: false }
  );

  return {
    exportProgramsToExcel: mutate,
  };
};
