import React from 'react';

import { Box, styled } from '@mui/system';

import { TooltipActions } from 'ui/tooltips/tooltipAction';

import { downloadDoc } from 'utils/downloadDoc';

const TooltipAppendixListItem = styled(Box)(() => ({
  width: '100%',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '14px',
  color: '#3E414D',
  paddingTop: '17.5px',
  paddingBottom: '17.5px',
  paddingLeft: '17.5px',
  paddingRight: '17.5px',
  '&:hover': {
    backgroundColor: 'rgba(255, 233, 233, 1)',
  },
}));

interface TooltipAppendixDocsProps {
  open: boolean;
  uuid: string;
  data: any;
  children: React.ReactElement;
  handleClose: () => void;
  isChangeHistoryTable?: boolean;
  onScrollOpen: boolean;
}
export const TooltipAppendixDocs: React.FC<TooltipAppendixDocsProps> = ({
  open,
  uuid,
  data,
  children,
  isChangeHistoryTable,
  onScrollOpen,
}) => (
  <Box>
    <TooltipActions
      open={`appendix-key-${data?.uuid}` === uuid && open && onScrollOpen}
      placement="bottom-start"
      title={
        <Box>
          {data?.appendix.map((item: string, index: number) => (
            <Box
              sx={{
                display: item ? 'flex' : 'none',
              }}
            >
              <TooltipAppendixListItem
                onClick={() => {
                  downloadDoc(item);
                }}
              >
                {(!isChangeHistoryTable && index === 4) || (isChangeHistoryTable && index === 5)
                  ? 'Файл калькулятор'
                  : `Приложение ${Number(index) + 1}`}
              </TooltipAppendixListItem>
            </Box>
          ))}
        </Box>
      }
    >
      {children}
    </TooltipActions>
  </Box>
);
