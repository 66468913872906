import React from 'react';
import { Box, ClickAwayListener, Fade, IconButton, Paper, Popper, Stack, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ActionsWithSlotsTable } from './ActionsWithSlotsTable';

export interface TooltipActionsWithSlotsProps {
  open: boolean,
  anchorEl:HTMLElement | null
  handleTooltipClose: () => void,
  children: React.ReactNode,
  handleOrderingActionsWithSlots: (value: string) => void,
  orderingActionsWithSlots: string,
  notificationsData: Notification[] | null,
  fetchNextPageSlotsOperations: () => void,
  isFetchingNextPageSlotsOperations: boolean
}
export const TooltipActionsWithSlots: React.FC<TooltipActionsWithSlotsProps> = ({
  open,
  anchorEl,
  handleTooltipClose,
  children,
  handleOrderingActionsWithSlots,
  orderingActionsWithSlots,
  notificationsData,
  fetchNextPageSlotsOperations,
  isFetchingNextPageSlotsOperations
}) => (
  <ClickAwayListener onClickAway={handleTooltipClose}>
    <Box>
      <Popper
        sx={{ zIndex: 1300, width: '812px', top: '20px' }}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ padding: '16px' }}>
              <Stack
                sx={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3" sx={{
                  paddingLeft: '10px',
                  fontWeight: '400'
                }}>Операции с пациентами</Typography>
                <IconButton
                  aria-label="close modal"
                  sx={{ width: '40px', height: '40px' }}
                  onClick={handleTooltipClose}
                >
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              </Stack>
              <ActionsWithSlotsTable
                actionsWithSlots={notificationsData}
                fetchNextPageSlotsOperations={fetchNextPageSlotsOperations}
                isFetchingNextPageSlotsOperations={isFetchingNextPageSlotsOperations}
                handleOrderingActionsWithSlots={handleOrderingActionsWithSlots}
                orderingActionsWithSlots={orderingActionsWithSlots}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
      {children}
    </Box>
  </ClickAwayListener>
);
