export const loginContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minWidth: '560px',
  width: 'calc(50% - 78px)',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'white',
  zIndex: '-1',
  paddingRight: '62px',
  paddingLeft: 'calc(50% - 720px)',

  '@media(max-width:1500px)': {
    paddingLeft: 'calc(50% - 656px)',
  },
  '@media(max-width:1440px)': {
    paddingLeft: '64px',
  },
}

export const loginTitleStyle = {
  lineHeight: '48px',
  fontSize: '40px',
  fontWeight: 500,
}

export const loginInputStyle = {
  marginTop: '24px',
  '& .MuiInput-root': {
    fontSize: '18px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: '#3e414d',
  },
  '& label': {
    fontSize: '14px',
    lineHeight: '14px',
    color: 'rgba(62, 65, 77, 0.6)',
  },
  '& label.Mui-focused': {
    color: 'rgba(62, 65, 77, 1)',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid rgba(62, 65, 77, 1)',
  },
  '.MuiFormLabel-asterisk': {
    color: '#ff6d6d'
  },
  '& :hover:not(.Mui-disabled)::before': {
    borderBottom: '1px solid rgba(62, 65, 77, 1)'
  }
}
