import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';

import { Loader } from 'shared/loaders/Loader';
import {
  TableHistoryHeading,
  TableHistoryPropsActions,
  HistoryAction,
  ICompanyActionTable,
} from 'interfaces/patients/patientInterfaces';

import { tableHistoryCellStyle } from 'ui/tables/tables';

import { getRowChangeString, nextSortState, throttle } from 'utils/tablesMethods';
import { lineBreaker } from 'utils/lineBreaker';
import { Sorting } from 'interfaces/other/otherInterfaces';

function createData(
  company: string,
  date: string,
  date_action: string,
  user: string,
  action_category: string,
  old_value: string,
  new_value: string,
  full_name: string,
  take_effect_at: string,
  verbose_changed_field_name: string,
  uuid: string
): ICompanyActionTable {
  return {
    company,
    date,
    date_action,
    user,
    action_category,
    old_value,
    new_value,
    full_name,
    take_effect_at,
    verbose_changed_field_name,
    uuid,
  };
}

const heading: readonly TableHistoryHeading[] = [
  {
    id: 'date_action',
    numeric: false,
    disablePadding: false,
    label: 'Дата действия',
  },
  {
    id: 'user__email',
    numeric: false,
    disablePadding: false,
    label: 'Изменения внёс',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Действие',
  },
  {
    id: 'slot__patient__last_name',
    numeric: false,
    disablePadding: false,
    label: 'ФИО',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Дата изменения',
  },
  {
    id: 'old_value',
    numeric: false,
    disablePadding: false,
    label: 'Старые данные',
  },
  {
    id: 'new_value',
    numeric: false,
    disablePadding: false,
    label: 'Новые данные',
  },
];

interface EnhancedTableProps {
  orderingTableHistory: string;
  handleOrderingTableHistory: (value: string) => void;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
  const { orderingTableHistory, handleOrderingTableHistory } = props;

  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if(!sortDirection){
      handleOrderingTableHistory('')
    }
  }, [handleOrderingTableHistory, orderingTableHistory, sortDirection]);

  return (
    <TableHead
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
      }}
    >
      <TableRow sx={{ width: '100%', display: 'flex' }}>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sx={() => tableHistoryCellStyle()}
          >
            <TableSortLabel
              active={!sortDirection || (orderingTableHistory.replace('-', '') === `${head.id}`)}
              direction={sortDirection}
              onClick={() => {
                setSortDirection(nextSortState(sortDirection))
                handleOrderingTableHistory(sortDirection === 'asc' ? `${head.id}` : `-${head.id}`);
              }}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {head.label}
              {orderingTableHistory.replace('-', '') === `${head.id}` ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableHistoryActions: React.FC<TableHistoryPropsActions> = ({
  historyActions,

  isLoadingCompanyActions,
  hasNextPageCompanyActions,
  isFetchingNextPageCompanyActions,

  orderingTableHistory,
  handleOrderingTableHistory,

  fetchNextPageCompanyActions,
}) => {
  const rows = historyActions.map((item: HistoryAction) =>
    createData(
      item.company,
      item.date,
      item.date_action,
      item.user,
      item.action_category,
      item.old_value,
      item.new_value,
      item.full_name,
      item.take_effect_at,
      item.verbose_changed_field_name,
      item.uuid
    )
  );

  const handleScroll = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const node = event.target;

    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;

    if (bottom) await fetchNextPageCompanyActions();
  };

  const paneDidMount = (node: HTMLTableSectionElement): any => {
    if (node && hasNextPageCompanyActions && !isFetchingNextPageCompanyActions) {
      // eslint-disable-next-line no-restricted-globals
      node.addEventListener('scroll', throttle(handleScroll, 1000));
    }

    // eslint-disable-next-line no-restricted-globals
    return () => node.removeEventListener('scroll', throttle(handleScroll.bind(event), 1000));
  };

  return (
    <Box sx={{ width: `${(100 / (heading.length - 2)) * heading.length}%` }}>
      <Paper sx={{ width: '100%', boxShadow: 'none' }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              display: 'block',
              emptyCells: 'show',
              overflowY: 'scroll',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              orderingTableHistory={orderingTableHistory}
              handleOrderingTableHistory={handleOrderingTableHistory}
            />
            <TableBody
              ref={paneDidMount}
              sx={{
                maxHeight: '256px',
                position: 'relative',
                display: 'block',
                width: '100%',
              }}
            >
              {rows.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.uuid}
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <TableCell sx={() => tableHistoryCellStyle()}>{row.date}</TableCell>
                  <TableCell sx={() => tableHistoryCellStyle()}>{lineBreaker(row.user)}</TableCell>
                  <TableCell sx={() => tableHistoryCellStyle()}>
                  {getRowChangeString(row.action_category, row.verbose_changed_field_name)}
                  </TableCell>
                  <TableCell sx={() => tableHistoryCellStyle()}>{lineBreaker(row.full_name)}</TableCell>
                  <TableCell sx={() => tableHistoryCellStyle()}>{row.date_action}</TableCell>
                  <TableCell sx={() => tableHistoryCellStyle()}>{row.action_category !== 'D' ? lineBreaker(row.old_value) : '-'}</TableCell>
                  <TableCell sx={() => tableHistoryCellStyle()}>{row.action_category !== 'D' ? lineBreaker(row.new_value) : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {(isFetchingNextPageCompanyActions || isLoadingCompanyActions) && <Loader />}
    </Box>
  );
};

export { TableHistoryActions };
