import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PermissionsNavigation } from 'components/Navigation/PermissionsNavigation';
import { useGetOneUserPermissions } from 'hooks/permissions/useGetOneUserPermissions';
import { useGetUserPermissionCollection } from 'hooks/permissions/useGetPermissionsCollection';
import { useSendPermissionSetting } from 'hooks/permissions/useSendPermissionSetting';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { listpatientTitleStyle } from 'ui/patients/patients';
import { StandartSwitch } from 'ui/switch/switch';
import { tableCellStyle, tableRowStyle } from 'ui/tables/tables';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { themeColor } from 'utils/uiConstants';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { useSnackbar } from 'notistack';
import { CommonTextChoiceWrq } from '@bestdoctor/core-arch-api/models';
import { currentPermissionGroupsName } from 'utils/constants';
import { PermissionFilterCompany } from 'interfaces/permissions/permissionsInterfaces';

export const UserRightsList: React.FC = () => {
  const [menuValue, setMenuValue] = useState<string>('user_rights');
  const [permissionData, setPermissionData] = useState<CommonTextChoiceWrq[] | undefined>(undefined);

  const permissionsFilterCompany = sessionStorageGetItem<PermissionFilterCompany>('permissionsFilterCompany');

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { permissionsCollection } = useGetUserPermissionCollection({
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { userRoleData } = useGetOneUserPermissions({
    companyUuid: permissionsFilterCompany?.uuid,
    userUuid: id,
    onSuccess: (data) => {
      setPermissionData(data[0]?.permissions);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { handleSendPermission } = useSendPermissionSetting({
    onSuccess: (res) => {
      setPermissionData(res.data?.permissions);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const handleSwitchChange = (event): void => {
    const { name, checked } = event.target;
    if (userRoleData && userRoleData.length > 0) {
      handleSendPermission({ company_permission: userRoleData[0]?.uuid, permission: name, set_on: checked });
    }
  };

  return (
    <Box>
      <PermissionsNavigation menuValue={menuValue} handleMenuValue={setMenuValue} />
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '40px',
            marginBottom: '37px',
          }}
        >
          <Typography variant="h4" sx={listpatientTitleStyle(false)}>
            Права доступа{' '}
            <Box
              component="span"
              sx={{
                color: themeColor,
              }}
            >
              {userRoleData ? userRoleData[0]?.user?.email : ''}
            </Box>
          </Typography>
        </Box>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            sx={{
              minWith: 750,
              borderCollapse: 'inherit',
              borderSpacing: '0 4px',
            }}
          >
            <TableBody>
              {permissionsCollection?.map(
                (permission) =>
                  permission && (
                    <TableRow sx={tableRowStyle}>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          color: '#3E414D',
                          fontWeight: currentPermissionGroupsName.some((item) => item === permission[0])
                            ? 'bold'
                            : null,
                          fontSize: currentPermissionGroupsName.some((item) => item === permission[0])
                            ? '14px'
                            : '12px',
                          lineHeight: currentPermissionGroupsName.some((item) => item === permission[0])
                            ? '18px'
                            : '14px',
                          borderTop: currentPermissionGroupsName.some((item) => item === permission[0])
                            ? '1px #FFE9E9 solid'
                            : null,
                        }}
                      >
                        {permission[1]}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          borderTop: currentPermissionGroupsName.some((item) => item === permission[0])
                            ? '1px #FFE9E9 solid'
                            : null,
                        }}
                      >
                        <StandartSwitch
                          name={permission[0]}
                          checked={
                            permissionData ? permissionData?.some((item) => item?.value === permission[0]) : false
                          }
                          onChange={handleSwitchChange}
                        />
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Box>
  );
};
