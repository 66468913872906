import { z } from 'zod';

export const validationAdditionalServices = z.object({
  patients: z.array(
    z.object({
      title: z.string().nullable(),
      uuid: z.string().nullable(),
    })
  ),
  isAllPatients: z.boolean(),
  scopeServices: z.string().nullable(),
  clinics: z.string().nullable(),
  otherDetails: z.string().nullable(),
  isLimitOrganizations: z.boolean(),
  isContactPatient: z.boolean(),
  phoneNumber: z.string().nullable(),
  fileList: z.array(z.any()),
});
