import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Box, Stack } from '@mui/material';
import { mainBtnStyle, tooltipBtnStyle } from 'ui/buttons/buttonsMain';

import { useSnackbar } from 'notistack';
import { IProgram } from 'interfaces/program/programInterfaces';
import { useExportProgramsToExcel } from 'hooks/programs/useExportProgramsToExcel';

const TooltipInfo = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '320px',
    width: '100%',
    padding: '8px 0',
    color: 'rgba(0, 0, 0, 1)',
    backgroundColor: '#fff',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    fontFamily: 'inherit',
  },
}));

const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '4px 12px',
    backgroundColor: 'rgba(62, 65, 77, 0.6)',
    borderRadius: '2px',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',

    textAlign: 'center',
    letterSpacing: '0.02em',

    color: '#fff',
  },
}));

interface IActionWithProgramBtnProps {
  selectedPrograms: readonly string[];
  programsData: IProgram[] | [];
  isActionsWithProgramTooltip: boolean;
  handleActionsWithProgramsTooltip: (value: boolean) => void;
}

export const ActionWithProgramBtn: React.FC<IActionWithProgramBtnProps> = ({
  selectedPrograms,
  programsData,
  isActionsWithProgramTooltip,
  handleActionsWithProgramsTooltip,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleTooltipClose = (): void => {
    handleActionsWithProgramsTooltip(false);
  };

  const handleTooltipOpen = (): void => {
    handleActionsWithProgramsTooltip(true);
  };

  const allPrograms = programsData.map((item) => item.programUuid);

  const { exportProgramsToExcel } = useExportProgramsToExcel({
    onSuccess: () => {
      enqueueSnackbar('Файл был сформирован и отправлен на Email адрес вашего аккаунта', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('При формировании отчёта произошла ошибка', { variant: 'error' });
    },
  });

  const exportSelectedProgramsToExcel = (): void => {
    exportProgramsToExcel(selectedPrograms);
  };

  const exportAllProgramsToExcel = (): void => {
    exportProgramsToExcel(allPrograms);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <TooltipInfo
          open={isActionsWithProgramTooltip}
          placement="bottom-end"
          title={
            <Stack>
              <InfoTooltip
                placement="left"
                title={selectedPrograms.length === 0 ? 'Для выгрузки необходимо выбрать минимум одну программу' : ''}
              >
                <Box component="span">
                  <Button
                    sx={tooltipBtnStyle}
                    onClick={exportSelectedProgramsToExcel}
                    style={selectedPrograms.length === 0 ? { pointerEvents: 'none' } : {}}
                    disabled={selectedPrograms.length === 0}
                  >
                    Выбранные программы обслуживания
                  </Button>
                </Box>
              </InfoTooltip>

              <Button sx={tooltipBtnStyle} onClick={exportAllProgramsToExcel}>
                Все программы обслуживания
              </Button>
            </Stack>
          }
        >
          <Button
            sx={() => mainBtnStyle(true, true)}
            variant="text"
            onClick={handleTooltipOpen}
            startIcon={
              <DoneOutlinedIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '2px',
                  backgroundColor: '#fff',
                  color: 'rgba(255, 109, 109, 1)',
                }}
              />
            }
          >
            Выгрузить данные
          </Button>
        </TooltipInfo>
      </Box>
    </ClickAwayListener>
  );
};
