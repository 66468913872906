import React from 'react';

import { styled } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { theme } from 'ui/theme/theme';
import { Box } from '@mui/system';

interface ButtonProps {
  textButton: string;
  loading: boolean;
  disabled: boolean;
  fullWidth?: boolean;
}

export const SignInButton: React.FC<ButtonProps> = ({ textButton, loading, disabled, fullWidth }) => {
  const Button = styled(LoadingButton)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: !fullWidth ? '272px' : 'unset',
    padding: '14px 20px',
    backgroundColor: 'rgba(255, 109, 109, 1)',
    boxShadow: '0px 0.4px 0.8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '32px',
    color: '#fff',
    fontFamily: theme.typography.button.fontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: 'rgba(255, 109, 109, 1)',
    },
    '&:active': {},
    '&:focus': {},
  });

  return (
    <Button
      endIcon={<ArrowForwardOutlinedIcon />}
      loading={loading}
      loadingPosition="end"
      variant="contained"
      disabled={disabled}
      type="submit"
    >
      <Box sx={theme.typography.subtitle1}>{textButton}</Box>
    </Button>
  );
};
