import { useRef, useEffect } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDebounce<Func extends SomeFunction>(func: Func, delay = 1000) {
  const timer = useRef<Timer>();

  useEffect(
    () => () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    },
    []
  );

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      func(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as Func;

  return debouncedFunction;
}
