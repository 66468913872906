import { isActive } from 'itson/ItsOn';
import { AccessList, DefaultPermissions } from 'interfaces/global/globalInterfaces';

export const isAccessAllowed = (
  companyUuid: string | undefined,
  token: string,
  accessList: AccessList[],
  defaultPermissions: DefaultPermissions
): boolean => {
  const userPermissions = accessList?.filter((company) => company?.company_uuid === companyUuid).shift();

  const userRole = userPermissions?.access_control?.role;

  const rolePermissions = userRole && defaultPermissions ? defaultPermissions[userRole] : [];

  if (isActive('hr-474.role_model')) {
    if (userRole === 'hr_user' && userPermissions?.access_control?.permissions?.some((item) => item === token)) {
      return true;
    }
    if (
      (userRole === 'hr_admin' || userRole === 'hr_account' || userRole === 'hr_watcher') &&
      rolePermissions?.some((item) => item === token)
    ) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};
