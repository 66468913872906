import { Company } from 'interfaces/global/globalInterfaces';

export const requiredPhoneByCompanies = (companies: Company[]): boolean =>
  (companies.length === 1 && companies[0]?.activation_method === 'passport_and_phone') ||
  companies[0]?.activation_method === 'phone';

export const requiredPassByCompanies = (companies: Company[]): boolean =>
  (companies.length === 1 && companies[0]?.activation_method === 'passport_and_phone') ||
  companies[0]?.activation_method === 'passport';

export const requiredPhoneByCompany = (company: Company | null): boolean =>
  company ? company.activation_method === 'passport_and_phone' || company.activation_method === 'phone' : false;

export const requiredPassByCompany = (company: Company | null): boolean =>
  company ? company.activation_method === 'passport_and_phone' || company.activation_method === 'passport' : false;
