import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { Box, SystemStyleObject, Theme } from '@mui/system';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TableHead,
  TableSortLabel,
  TableBody,
  IconButton,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { tableCellStyle, tableCheckboxStyle, tableRowStyle } from 'ui/tables/tables';
import {
  INotificationTableProps,
  NotificationForTable,
  TableNotificationHeading,
  Notification,
} from 'interfaces/notifications/notificationsInterfaces';

import { graylightColor, themeColor } from 'utils/uiConstants';
import { useNotification } from 'hooks/notifications/useNotification';
import { InfoNotificationModal } from 'shared/modals/InfoNotificationModal/InfoNotificationModal';
import { paramsPriority } from 'utils/constants';
import { Loader } from 'shared/loaders/Loader';
import { useRemoveNotifications } from 'hooks/notifications/useRemoveNotifications';
import { getNotificationType, nextSortState } from 'utils/tablesMethods';
import { Sorting } from 'interfaces/other/otherInterfaces';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';

const checkedIcon = <CheckBoxOutlinedIcon sx={{ color: themeColor }} />;
const iconsStyle = { color: themeColor, width: '16px', height: '16px' };

function createData(
  type: string,
  date: string,
  company: string,
  status: string,
  message: string,
  id: string,
  read: boolean,
  color: string,
  uuid: string
): NotificationForTable {
  return {
    type,
    date,
    company,
    status,
    message,
    id,
    read,
    color,
    uuid,
  };
}

const cellStyles = {
  padding: '8px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(62, 65, 77, 0.6)',
  borderTop: '2px solid #fff',
  borderBottom: '2px solid #fff',
  borderRadius: '4px',

  '&:first-child': {
    borderLeft: '2px solid #fff',
  },
  '&:last-child': {
    borderRight: '2px solid #fff',
  },
};

const unreadRowStyle = {
  ...tableRowStyle,
  backgroundColor: 'white',
  cursor: 'pointer',
};

const readRowStyle = {
  ...tableRowStyle,
  backgroundColor: 'transpanent',
  cursor: 'pointer',
};

const unreadMessageStyle = {
  color: 'rgba(1, 1, 1, 0.87)',
  fontWeight: 'bold',
};

const messageBaseStyles = {
  fontSize: '14px',
  lineHeight: '18px',
  maxWidth: '550px',
};

const messageStyle = (read: boolean): SystemStyleObject<Theme> =>
  read ? { ...cellStyles, ...messageBaseStyles } : { ...cellStyles, ...messageBaseStyles, ...unreadMessageStyle };

const heading: readonly TableNotificationHeading[] = [
  {
    id: 'meta__priority',
    numeric: false,
    label: '',
  },
  {
    id: 'created_at',
    numeric: false,
    label: 'Дата',
  },
  {
    id: 'meta_company_title',
    numeric: false,
    label: 'Компания',
  },
  {
    id: 'text',
    numeric: false,
    label: 'Тема уведомления',
  },
  {
    id: 'meta__notification_type',
    numeric: false,
    label: 'Тип',
  },
];

interface NotificationsEnhancedTableHead {
  orderingTableNotifications: string;
  setOrderingTableNotifications: (value: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnhancedTableHead: React.FunctionComponent<NotificationsEnhancedTableHead> = (
  props: NotificationsEnhancedTableHead
) => {
  const { orderingTableNotifications, setOrderingTableNotifications, onSelectAllClick } = props;
  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if(!sortDirection){
      setOrderingTableNotifications('')
    }
  }, [orderingTableNotifications, setOrderingTableNotifications, sortDirection]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={tableCellStyle}>
          <Checkbox
            color="primary"
            checkedIcon={checkedIcon}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            sx={tableCheckboxStyle}
          />
        </TableCell>
        {heading.map((head) => (
          <TableCell key={head.id} sx={tableCellStyle}>
            <TableSortLabel
              active={!sortDirection || (orderingTableNotifications.replace('-', '') === `${head.id}`)}
              direction={sortDirection}
              onClick={() => {
                setSortDirection(nextSortState(sortDirection))
                setOrderingTableNotifications(sortDirection === 'asc' ? `${head.id}` : `-${head.id}`);
              }}
              IconComponent={ExpandLessIcon}
              sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}
            >
              {head.label || <BookmarkIcon sx={{ width: '16px', height: '16px', color: 'rgba(62, 65, 77, 0.6);' }} />}
              {orderingTableNotifications.replace('-', '') === `${head.id}` ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const NotificationTable: React.FC<INotificationTableProps> = ({
  notificationsData,
  isFetchingNextPageNotifications,
  orderingTableNotifications,
  selected,
  selectedForViewed,
  setOrderingTableNotifications,
  setSelected,
  setSelectedForViewed,
}) => {
  const queryClient = useQueryClient();

  const [notification, setNotification] = useState<Notification | null>(null);
  const [notificationUuid, setNotificationUuid] = useState<string | null>(null);
  const [isInfoNotificationModal, setInfoNotificationModal] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar()

  const isSelected = (uuid: string): boolean => selected.indexOf(uuid) !== -1;

  const rows = notificationsData
    ? notificationsData?.map((item) =>
        createData(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          item.meta.priority ? paramsPriority[item.meta.priority].text : '',
          format(new Date(item.created_at), 'dd.MM.yyyy HH:mm'),
          item.meta.company_title,
          getNotificationType(item.meta.notification_type),
          item.text,
          item.uuid,
          item.status.value !== 'unviewed',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          item.meta.priority ? paramsPriority[item.meta.priority].color : graylightColor,
          item.uuid
        )
      )
    : [];

  const handleClick = (_event: React.MouseEvent<unknown>, obj: NotificationForTable): void => {
    const selectedIndex = selected.indexOf(obj.uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, obj.uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectedForViewed = (_event: React.MouseEvent<unknown>, obj: NotificationForTable): void => {
    const selectedIndex = selectedForViewed.indexOf(obj.uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedForViewed, obj.uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedForViewed.slice(1));
    } else if (selectedIndex === selectedForViewed.length - 1) {
      newSelected = newSelected.concat(selectedForViewed.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedForViewed.slice(0, selectedIndex),
        selectedForViewed.slice(selectedIndex + 1)
      );
    }

    setSelectedForViewed(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.uuid);
      setSelected(newSelecteds);

      const newSelectedsForViewed = rows
        .filter((item: NotificationForTable) => item.type !== 'Неотложные')
        .map((n: NotificationForTable) => n.uuid);

      setSelectedForViewed(newSelectedsForViewed);

      return;
    }
    setSelected([]);
    setSelectedForViewed([]);
  };

  useNotification({
    notificationUuid,
    onSuccess: (data: Notification) => setNotification(data),
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { handleRemoveNotifications } = useRemoveNotifications({
    onSuccess: () => queryClient.invalidateQueries('dataUnviewedNotifications'),
  });

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                borderCollapse: 'inherit',
                borderSpacing: '0 12px',
                marginTop: '16px',
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                orderingTableNotifications={orderingTableNotifications}
                setOrderingTableNotifications={setOrderingTableNotifications}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isItemSelected = isSelected(row.uuid);

                  return (
                    <TableRow
                      onClick={(event) => {
                        event.stopPropagation();
                        setNotificationUuid(row.uuid);
                        setInfoNotificationModal(true);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={row.read ? readRowStyle : unreadRowStyle}
                    >
                      <TableCell padding="checkbox" sx={cellStyles}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          checkedIcon={checkedIcon}
                          inputProps={{ 'aria-labelledby': labelId }}
                          sx={{ '&.Mui-checked': { color: '#FF6D6D' } }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClick(event, row);
                            if (row.type !== 'Неотложные') handleSelectedForViewed(event, row);
                          }}
                        />
                      </TableCell>
                      <TableCell scope="row" padding="none" sx={cellStyles}>
                        <BookmarkIcon sx={{ color: row.color }} />
                      </TableCell>
                      <TableCell scope="row" padding="none" sx={cellStyles}>
                        {row.date}
                      </TableCell>
                      <TableCell sx={{ ...cellStyles, fontSize: '14px', color: 'rgb(1,1,1,0.87)' }}>
                        {row.company}
                      </TableCell>
                      <TableCell sx={() => messageStyle(row.read)}>{row.message}</TableCell>
                      <TableCell sx={cellStyles}>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: '10px' }}
                        >
                          {row.status}
                          <IconButton
                            aria-label="Удаление"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRemoveNotifications([row.uuid]);
                            }}
                            disabled={row.type === 'Неотложные'}
                          >
                            <DeleteOutlineIcon sx={{ ...iconsStyle, color: themeColor }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {isFetchingNextPageNotifications && <Loader />}

      {!!notification && (
        <InfoNotificationModal
          notification={notification}
          open={isInfoNotificationModal}
          handleClose={() => {
            setInfoNotificationModal(false);
          }}
        />
      )}
    </>
  );
};
