import React from 'react';

import './InfoProgramModal.scss';

import Dialog from '@mui/material/Dialog';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { MainButton } from 'shared/buttons/MainButton/MainButton';

import { IProgram } from 'interfaces/program/programInterfaces';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { modalSubtitleInfo } from 'ui/programs/programs';
import { useLoadClinicsList } from 'hooks/programs/useLoadClinicsList';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';

interface ModalProps {
  open: boolean;
  programData: IProgram;
  handleClose: () => void;
}

const ModalBlock = styled(Box)({
  marginTop: '40px',
});

const InfoProgramModal: React.FC<ModalProps> = ({ open, programData, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  
  const { handleLoadClinicsList } = useLoadClinicsList({
    programUuid: programData.programUuid,
    programName: programData.programTitle,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
          overflow: 'auto',
          padding: '20px 24px 24px',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box className="modal-info-program__header">
        <Typography variant="h3">{programData.programTitle}</Typography>

        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <ModalBlock>
        <Typography variant="h4">Компания</Typography>
        <Box>{programData.companyTitle}</Box>
      </ModalBlock>

      <ModalBlock>
        <Typography variant="h4">Объём</Typography>
        <Box>{programData.stringServiceTypes}</Box>
      </ModalBlock>

      <ModalBlock>
        <Typography variant="h4">Информация</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h5" sx={modalSubtitleInfo}>
              Период обслуживания
            </Typography>
            <Box>{programData.period}</Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={modalSubtitleInfo}>
              Город
            </Typography>
            <Box>{programData.city}</Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={modalSubtitleInfo}>
              Количество человек
            </Typography>
            <Box>{programData.slotsCount}</Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={modalSubtitleInfo}>
              Полис ВЗР
            </Typography>
            <Box>{programData.isVzr ? 'Входит в программу' : 'Отсутствует'}</Box>
          </Box>
        </Box>
      </ModalBlock>

      <ModalBlock>
        <Typography variant="h4">Документы</Typography>

        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            gap: '18px',
          }}
        >
          <MainButton
            textButton="Мед. программа"
            icon={<FileCopyIcon sx={{ width: '16px' }} />}
            border
            handleClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              window.open(programData.medicalProgram);
            }}
          />
          <MainButton
            textButton="Список клиник"
            icon={<FileCopyIcon sx={{ width: '16px' }} />}
            border
            handleClick={() => {
              handleLoadClinicsList();
            }}
          />
        </Box>
      </ModalBlock>

      <ModalBlock
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <MainButton textButton="Закрыть" mainBtn handleClick={handleClose} />
      </ModalBlock>
    </Dialog>
  );
};

export { InfoProgramModal };
