import React from 'react';

import { Box, Typography } from '@mui/material';

import { IListExpensesProps } from 'interfaces/finance/financeInterfaces';
import { checkLabelStyle, titleStyle } from 'ui/finance/finance';
import { OrangeSwitch } from 'ui/switch/switch';

import { ListExpensesTableStaff } from './ListExpensesTableStaff/ListExpensesTableStaff';
import { ListExpensesTableClinic } from './ListExpensesTableClinic/ListExpensesTableClinic';

const ListExpenses: React.FC<IListExpensesProps> = ({
  isCheckedListExpenses,
  isLoadingListExpenses,
  dataListExpenses,
  handleCheckedListExpenses,

  optionsFilterByTitle,
  selectedFilterByTitle,
  handleSelectedFilterByTitle,

  isFetchingListExpenses,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    sessionStorage.setItem('tableFilterFinance', JSON.stringify(''));
    sessionStorage.setItem('tableCheckFinance', JSON.stringify(event.target.checked));

    handleCheckedListExpenses(event.target.checked);
  };

  return (
    <Box component="section" sx={{ marginTop: '32px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={titleStyle}>
          Список расходов
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component="p" sx={!isCheckedListExpenses ? () => checkLabelStyle(true) : () => checkLabelStyle(false)}>
            Пациенты
          </Box>
          <OrangeSwitch
            checked={isCheckedListExpenses}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'ant design' }}
            disabled={isLoadingListExpenses}
            onClick={() => {
              sessionStorage.setItem('tableFilterFinance', JSON.stringify(''));
              handleSelectedFilterByTitle(null);
            }}
          />
          <Box component="p" sx={isCheckedListExpenses ? () => checkLabelStyle(true) : () => checkLabelStyle(false)}>
            Клиники
          </Box>
        </Box>
      </Box>

      {!isCheckedListExpenses && (
        <ListExpensesTableStaff
          dataListExpenses={dataListExpenses}
          optionsFilterByTitle={optionsFilterByTitle}
          selectedFilterByTitle={selectedFilterByTitle}
          handleSelectedFilterByTitle={handleSelectedFilterByTitle}
          isFetchingListExpenses={isFetchingListExpenses}
          isLoadingListExpenses={isLoadingListExpenses}
        />
      )}

      {isCheckedListExpenses && (
        <ListExpensesTableClinic
          dataListExpenses={dataListExpenses}
          optionsFilterByTitle={optionsFilterByTitle}
          selectedFilterByTitle={selectedFilterByTitle}
          handleSelectedFilterByTitle={handleSelectedFilterByTitle}
          isFetchingListExpenses={isFetchingListExpenses}
          isLoadingListExpenses={isLoadingListExpenses}
        />
      )}
    </Box>
  );
};

export { ListExpenses };
