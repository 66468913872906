import { SystemStyleObject, Theme } from '@mui/system';

import { grayDarkColor, graylightColor } from 'utils/uiConstants';

export const tableCellStyle: SystemStyleObject<Theme> = {
  padding: '8px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(62, 65, 77, 0.6)',
  border: 'none',
};

export const tableCellRowContentStyle: SystemStyleObject<Theme> = {
  padding: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '14px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',
  cursor: 'pointer',
};

export const tableCheckboxStyle: SystemStyleObject<Theme> = {
  '&.Mui-checked': {
    color: '#FF6D6D',
  },
  '&.MuiCheckbox-indeterminate': {
    color: '#FF6D6D',
  },
};

export const tableRowStyle: SystemStyleObject<Theme> = {
  border: '4px solid #f8f8f8',
  '&:hover': {
    backgroundColor: 'rgba(255, 233, 233, 1)',
  },
  '&.MuiTableRow-hover:hover': {
    backgroundColor: 'rgba(255, 233, 233, 1)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 233, 233, 1)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(255, 233, 233, 1)',
  },
};

export const tableRowHoverStyle: SystemStyleObject<Theme> = {
  '& .MuiTableRow-hover:hover': {
    backgroundColor: '#ffe9e9',
  },
};

export const tableCellCheckboxStyle: SystemStyleObject<Theme> = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',

  tableRowHoverStyle,
};

export const tableCellTypeStyle = (row: string): SystemStyleObject<Theme> => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',

  color: () => {
    let color;

    if (row === 'Активный') {
      color = '#00bcbc';
    } else if (row === 'Планируемый') {
      color = '#4079ad';
    } else {
      color = '#81838a';
    }

    return color;
  },
  border: 'none',
});

export const slotsTableStatusCellStyle = (row?: string): SystemStyleObject<Theme> => ({
  ...tableCellStyle,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',

  color: () => {
    let color;

    if (row === 'Активный') {
      color = '#00bcbc';
    } else if (row === 'Планируемый') {
      color = '#4079ad';
    } else {
      color = '#81838a';
    }

    return color;
  },
});

export const tableCellDocsStatusStyle = (row: any): SystemStyleObject<Theme> => ({
  paddingTop: '11px',
  paddingBottom: '11px',
  verticalAlign: 'center',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: () => {
    let color;

    if (row.hr_status === 'signed' || row.hr_status === 'agreed') {
      color = '#00bcbc';
    } else if (row.hr_status === 'Планируемый') {
      color = '#4079ad';
    } else if (row.hr_status === 'need_approval') {
      color = '#FF6D6D';
    } else {
      color = '#81838a';
    }

    return color;
  },
  border: 'none',
});

export const tableCellStatusStyle = (row): SystemStyleObject<Theme> => ({
  flexBasis: '20%',
  flexGrow: '1',
  display: 'block',
  padding: '8px',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  border: 'none',
  color: () => {
    let color;

    if (row.statusPay === 'Просрочен') {
      color = '#FF6D6D';
    } else if (row.statusPay === 'Оплачен') {
      color = '#00BCBC';
    } else if (row.statusPay === 'Осталось более 10 дней') {
      color = '#4079AD';
    } else if (row.statusPay === 'Осталось менее 10 дней') {
      color = '#FF7629';
    } else {
      color = grayDarkColor;
    }

    return color;
  },
});

export const tableHistoryCellStyle = (userCursor?: string): SystemStyleObject<Theme> => ({
  flexBasis: '20%',
  flexGrow: '1',
  display: 'block',
  padding: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',
  cursor: userCursor,
});

export const tableHistoryBodyStyle: SystemStyleObject<Theme> = {
  maxHeight: '200px',
  position: 'relative',
  display: 'block',
  width: '100%',
  overflowY: 'scroll',
};

export const tablePaymentsBodyStyle: SystemStyleObject<Theme> = {
  maxHeight: '225px',
  position: 'relative',
  display: 'block',
  width: '100%',
  overflowY: 'scroll',
};

export const tableDocsCellStyle: SystemStyleObject<Theme> = {
  maxWidth: '250px',
  paddingTop: '11px',
  paddingBottom: '11px',
  paddingLeft: '6px',
  paddingRight: '35px',
  verticalAlign: 'center',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',
  '&:last-child': {
    paddingRight: '0',
  },
};

export const tableSortLabelStyle: SystemStyleObject<Theme> = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
};

export const tableDocsCellIconStyle: SystemStyleObject<Theme> = {
  padding: '8px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',
};

export const tableDocsIconStyle: SystemStyleObject<Theme> = {
  marginRight: '6px',
  width: '14px',
  height: '14px',
  color: graylightColor,
};

export const tableDocsStatusCellStyle = (): SystemStyleObject<Theme> => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '16px',
  paddingLeft: '8px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  border: 'none',
});

export const tableSlotsActionsStyle: SystemStyleObject<Theme> = {
  flexBasis: '20%',
  flexGrow: '1',
  display: 'block',
  padding: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',
};
