import React from 'react';

import { Box } from '@mui/system';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';

import { Company, DataPeriod, IFiltersProps } from 'interfaces/global/globalInterfaces';
import { mainFiltersContainerStyle, mainFiltersSectionStyle, mainFiltersWrapperStyle } from 'ui/patients/mainFilters';

export const DocumentsFilters: React.FC<IFiltersProps> = ({
  dataPeriods,
  allCompanies,
  selectedCompanyByDocuments,
  handleSelectedCompanyByDocuments,
  selectedPeriodByDocuments,
  handleSelectedPeriodByDocuments,
}) => (
  <Box component="section" sx={mainFiltersSectionStyle}>
    <Box sx={mainFiltersWrapperStyle}>
      <Box sx={mainFiltersContainerStyle}>
        <AutocompleteInput
          label="Компания"
          placeholder="Компания"
          data={allCompanies || []}
          value={selectedCompanyByDocuments}
          handleChangeObj={(_event: React.SyntheticEvent, newValue: Company) => {
            sessionStorage.setItem('mainFilterCompanyDocuments', JSON.stringify(newValue || ''));
            sessionStorage.setItem('mainFilterPeriodDocuments', JSON.stringify(''));

            handleSelectedCompanyByDocuments(newValue);
          }}
        />
      </Box>

      <Box sx={{ width: '100%' }}>
        <AutocompleteInput
          label="Период данных"
          placeholder="Период данных"
          data={dataPeriods || []}
          value={
            selectedPeriodByDocuments && selectedPeriodByDocuments ? selectedPeriodByDocuments : '(Активный период)'
          }
          handleChangeObj={(_event: React.SyntheticEvent, newValue: DataPeriod) => {
            sessionStorage.setItem('mainFilterPeriodDocuments', JSON.stringify(newValue || ''));

            handleSelectedPeriodByDocuments(newValue);
          }}
        />
      </Box>
    </Box>
  </Box>
);
