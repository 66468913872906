import { styled } from '@mui/material/styles';
import { Tabs, Tab } from '@mui/material';

import { themeColor, grayDarkColor } from 'utils/uiConstants';

export const CustomTabs = styled(Tabs)({
  borderBottom: `border: 2px solid ${themeColor}`,
  '& .MuiTabs-indicator': {
    backgroundColor: themeColor,
  },
});

export const CustomTab = styled(Tab)({
  color: grayDarkColor,
  fontWeight: '500',
  fontSize: '14px',
  maxWidth: '500px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  opacity: 1,
  '@media(max-width:800px)': {
    fontSize: '10px',
    lineHeight: '12px'
  },
  '&:hover': {
    color: themeColor,
    backgroundColor: 'rgba(255, 109, 109, 0.1)',
  },
  '&.Mui-selected': {
    color: themeColor,
  },
});
