import { DownloadDocumentParams, DownloadDocumentData } from 'interfaces/hooks/documentsHooksInterfaces';
import { useMutation } from 'react-query';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { handleRefreshToken } from 'utils/refreshToken';

export const useDownloadDocument = ({ fileUrl, onError }: DownloadDocumentParams): DownloadDocumentData => {
  const { mutate, isLoading } = useMutation(
    ['downloadFile'],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/supplementary_agreements/${fileUrl}/export_documents/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'file';
        a.click();
        a.remove();
        return res;
      }

      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      onError,
    }
  );

  const handleDownloadDocument: () => Promise<void> = mutate;
  const isLoadingDocument: boolean = isLoading;

  return {
    handleDownloadDocument,
    isLoadingDocument,
  };
};
