import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuIcon from '@mui/icons-material/Menu';

import { ICompanyActionTableHeading, IEnhancedCompanyActionTableProps } from 'interfaces/patients/patientInterfaces';

import { tableDocsIconStyle, tableHistoryBodyStyle, tableHistoryCellStyle } from 'ui/tables/tables';

import { nextSortState, throttle } from 'utils/tablesMethods';
import { Loader } from 'shared/loaders/Loader';
import { TooltipAppendixDocs } from 'shared/tooltip/TooltipAppendixDocs/TooltipAppendixDocs';
import { ClickAwayListener } from '@mui/material';
import { agreementStatus } from 'utils/agreementStatus';
import { DocumentViewModal } from 'shared/modals/DocumentViewModal/DocumentViewModal';
import { Sorting } from 'interfaces/other/otherInterfaces';
import { getFormattedDate } from 'utils/dateHelpers';
import { DocumetsChangeHistoryTableProps, SupplementaryAgreementsLog } from 'interfaces/documents/documentsInterfaces';

interface DocumentChangeHistoryTableData{
  uuid: string,
  created_at: string,
  user_email: string,
  hr_status: string,
  appendix: string[],
  description: string
}

function createData(
  uuid: string,
  created_at: string,
  user_email: string,
  hr_status: string,
  appendix: string[],
  description: string
): DocumentChangeHistoryTableData {
  return {
    uuid,
    created_at,
    user_email,
    hr_status,
    appendix,
    description,
  };
}

const heading: readonly ICompanyActionTableHeading[] = [
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Дата',
  },
  {
    id: 'user__email',
    numeric: false,
    disablePadding: false,
    label: 'Изменил',
  },
  {
    id: 'hr_status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'docs',
    numeric: false,
    disablePadding: false,
    label: 'Набор документов',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Комментарий',
  },
];

const EnhancedTableHead: React.FC<IEnhancedCompanyActionTableProps> = ({
  orderingTableHistory,
  handleOrderingTableHistory,
}) => {
  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if (!sortDirection) {
      handleOrderingTableHistory('');
    }
  }, [handleOrderingTableHistory, orderingTableHistory, sortDirection]);

  return (
    <TableHead
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
      }}
    >
      <TableRow sx={{ width: '100%', display: 'flex' }}>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sx={() => tableHistoryCellStyle()}
          >
            <TableSortLabel
              active={
                (!sortDirection || orderingTableHistory.replace('-', '') === `${head.id}`) &&
                (head.id === 'created_at' || head.id === 'user__email' || head.id === 'hr_status')
              }
              direction={sortDirection}
              onClick={() => {
                if (head.id === 'created_at' || head.id === 'user__email' || head.id === 'hr_status') {
                  setSortDirection(nextSortState(sortDirection));
                  handleOrderingTableHistory(sortDirection === 'asc' ? `${head.id}` : `-${head.id}`);
                }
              }}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {head.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const DocumetsChangeHistoryTable: React.FC<DocumetsChangeHistoryTableProps> = ({
  hasNextPageSupplementaryAgreementsLogs,
  isFetchingNextPageSupplementaryAgreementsLogs,

  fetchNextPage,

  handleOrderingChangeHistory,
  orderingChangeHistory,
  changeHistory,
}) => {
  const [tooltipSelected, setTooltipSelected] = useState<any | null>(null);

  const [isAppendixList, setIsAppendixList] = useState<boolean>(false);

  const [isDocumentViewModal, setDocumentViewModal] = useState<boolean>(false);

  const [isBottom, setIsBottom] = useState<boolean>(false);

  const rows = changeHistory.map((item: SupplementaryAgreementsLog) =>
    createData(item.uuid, item.created_at, item.user_email, item.hr_status, item.appendix, item.description)
  );

  const handleScroll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const node = event.target;
    setIsAppendixList(false);
    if (node.scrollHeight - node.scrollTop === node.clientHeight) {
      setIsBottom(true);
    }
  };

  const padeDidMount = (node: HTMLTableSectionElement): void => {
    if (node && hasNextPageSupplementaryAgreementsLogs && !isFetchingNextPageSupplementaryAgreementsLogs) {
      node.addEventListener('scroll', throttle(handleScroll, 0));
    }
  };

  useEffect(() => {
    if (isBottom) {
      fetchNextPage();
      setIsBottom(false);
    }
  }, [fetchNextPage, isBottom]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsAppendixList(false)}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', boxShadow: 'none' }}>
            <TableContainer>
              <Table
                sx={{
                  minWidth: 1300,
                  display: 'block',
                  emptyCells: 'show',
                  paddingLeft: '24px',
                  paddingRight: '24px',
                }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  handleOrderingTableHistory={handleOrderingChangeHistory}
                  orderingTableHistory={orderingChangeHistory}
                />
                <TableBody sx={tableHistoryBodyStyle} ref={padeDidMount}>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={labelId}
                        sx={{
                          width: '100%',
                          display: 'flex',
                        }}
                      >
                        <TableCell sx={() => tableHistoryCellStyle()}>
                            {getFormattedDate(row?.created_at, 'dd.MM.yyyy HH:mm:ss')}
                        </TableCell>
                        <TableCell sx={() => tableHistoryCellStyle()}>{row.user_email}</TableCell>
                        <TableCell sx={() => tableHistoryCellStyle()}>{agreementStatus[row.hr_status]}</TableCell>
                        <TableCell
                          sx={() => tableHistoryCellStyle()}
                          onClick={() => {
                            if (row.appendix.length > 0) {
                              setTooltipSelected(row);
                              setIsAppendixList(!isAppendixList);
                            }
                          }}
                        >
                          <TooltipAppendixDocs
                            onScrollOpen
                            data={tooltipSelected}
                            uuid={`appendix-key-${row.uuid}`}
                            open={isAppendixList}
                            handleClose={() => setIsAppendixList(false)}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                direction: 'row',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <MenuIcon sx={tableDocsIconStyle} />
                              {row.appendix ? `Документы (${row.appendix.length})` : 'Документы отсутствуют'}
                            </Box>
                          </TooltipAppendixDocs>
                        </TableCell>
                        <TableCell sx={() => tableHistoryCellStyle()}>{row.description}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {isFetchingNextPageSupplementaryAgreementsLogs && <Loader />}
        </Box>
      </ClickAwayListener>

      <DocumentViewModal link="dd" open={isDocumentViewModal} handleClose={() => setDocumentViewModal(false)} />
    </>
  );
};
