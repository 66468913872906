import React, { useState, ReactElement } from 'react';
import { format, isSameDay, isValid } from 'date-fns';
import { Box } from '@mui/system';
import { Dialog, Typography, IconButton, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { modalBlockFooterStyle, modalTypographyColor } from 'ui/modals/modalGlobal';
import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import { inputFormStyle } from 'ui/inputs/inputsForm';

import { NotificationFilterByPeriod } from 'interfaces/notifications/notificationsInterfaces';
import { isDateActual } from 'utils/dateHelpers';

export interface PeriodFilterModalProps {
  open: boolean;
  handleClose: () => void;
  setNotificationFilterByPeriod: (value: NotificationFilterByPeriod | null) => void;
}

export const PeriodFilterModal: React.FC<PeriodFilterModalProps> = ({
  open,
  setNotificationFilterByPeriod,
  handleClose,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const rendersPickerDayStartDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = startDate !== null ? isSameDay(date, startDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDayEndDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = endDate !== null ? isSameDay(date, endDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
          overflow: 'auto',
          padding: '20px 24px 24px',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Typography variant="h3" sx={() => modalTypographyColor('#3e414d')}>
              Произвольный период
            </Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="close modal"
          sx={{ width: '40px', height: '40px' }}
          onClick={() => {
            handleClose();
          }}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box sx={{ marginTop: '40px', display: 'flex', gap: '32px' }}>
        <DesktopDatePicker
          label="Период данных с"
          value={startDate}
          renderDay={rendersPickerDayStartDate}
          PopperProps={{
            sx: datePickerPopperStyled,
          }}
          onChange={(newValue: Date | null) => {
            if (newValue && isValid(newValue) && isDateActual(newValue)) {
              setStartDate(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              label="Период данных с"
              variant="standard"
              sx={() => inputFormStyle(false)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton sx={{ width: '28px', height: '28px' }} onClick={() => setStartDate(null)}>
                      <CloseOutlinedIcon fontSize="small" />
                    </IconButton>
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <DesktopDatePicker
          label="Период данных по"
          value={endDate}
          renderDay={rendersPickerDayEndDate}
          PopperProps={{
            sx: datePickerPopperStyled,
          }}
          onChange={(newValue: Date | null) => {
            if (newValue && isValid(newValue) && isDateActual(newValue)) {
              setEndDate(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              label="Период данных до"
              variant="standard"
              sx={() => inputFormStyle(false)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton sx={{ width: '28px', height: '28px' }} onClick={() => setEndDate(null)}>
                      <CloseOutlinedIcon fontSize="small" />
                    </IconButton>

                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>

      <Box sx={modalBlockFooterStyle}>
        <MainButton
          textButton="Отмена"
          handleClick={() => {
            handleClose();
          }}
        />
        <MainButton
          textButton="Применить"
          mainBtn
          handleClick={() => {
            let title = '';

            if (startDate) title += `c ${format(startDate, 'dd.MM.yyyy')} `;
            if (endDate) title += `по ${format(endDate, 'dd.MM.yyyy')} `;

            setNotificationFilterByPeriod({
              title,
              id: '05',
              dateGte: startDate ? format(startDate, 'yyyy-MM-dd 00:00:00') : '',
              dateLte: endDate ? format(endDate, 'yyyy-MM-dd 23:59:59') : '',
            });

            handleClose();
          }}
          disabled={!startDate && !endDate}
        />
      </Box>
    </Dialog>
  );
};
