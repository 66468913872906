import { guyabanoColor } from 'utils/uiConstants';

export const boxNavStyle = {
  position: 'sticky',
  top: '104px',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: guyabanoColor,
  padding: '32px 0',
};
