import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import { ListSlotResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/slots/types';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';

import { PatientsPatientRelativeRelationRq } from '@bestdoctor/core-arch-api/models';
import { ListPatientRelativeRelationResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/relative-patients/types';
import {
  GetRelativePatientsData,
  GetRelativePatientsParams,
  GetRelativePatientsQueryParams,
} from 'interfaces/hooks/patientsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetRelativePatients = ({ patient, onError }: GetRelativePatientsParams): GetRelativePatientsData => {
  const patientId: string = patient?.patientUuid || '';

  const { data, isFetching, isLoading, isError, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['dataRelativePatients', patientId],
      async ({ pageParam = 1 }) => {
        await handleRefreshToken();

        const token = localStorage.getItem('token');

        const queryParams: GetRelativePatientsQueryParams = {
          page: pageParam,
          per_page: 100,
        };

        const res = await fetch(
          `${API_URL}/patients/${patientId}/relative_relations/?${queryString.stringify(queryParams)}`,
          {
            method: 'GET',
            headers: {
              Accept: `${HEADER_ACCEPT_HR}`,
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.ok) {
          return res.json();
        }
        throw new FetchError(res);
      },

      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        enabled: !!patientId,
        onError,
        getNextPageParam: (lastPage: ListSlotResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
          const allPage: number = getPagesQuantity(lastPage);
          const currentPage: number = lastPage.meta?.page || 0;
          const nextPage = currentPage + 1;

          return nextPage <= allPage ? nextPage : undefined;
        },
        select: (res: {
          pages: ListPatientRelativeRelationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'];
          pageParams: unknown[];
        }) => {
          const pageParams = res.pages[0].meta;

          const patients = res.pages[0].data?.map((item: PatientsPatientRelativeRelationRq) => {
            const currentData = item.to_patient.uuid === patientId ? item.from_patient : item.to_patient;
            const relativeType =
              item.to_patient.uuid === patientId ? item.from_patient_type.text : item.to_patient_type.text;

            return {
              id: currentData?.id,
              slots: currentData?.slots,
              fullName: currentData?.full_name,
              birthDate: currentData?.birth_date,
              relativeType,
            };
          });
          return { patients, pageParams };
        },
      }
    );

  return {
    searchRelativePatientsData: data,

    isLoadingSearchRelativePatients: isLoading,
    isErrorSearchRelativePatients: isError,

    hasNextPageSearchRelativePatients: hasNextPage,
    isFetchingNextPageSearchRelativePatients: isFetchingNextPage,
    isFetchingSearchRelativePatients: isFetching,

    fetchNextPageSearchRelativePatients: fetchNextPage,
    refetchSearchRelativePatients: refetch,
  };
};
