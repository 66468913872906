export const listProgramHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

export const listProgramFilters = {
  position: 'sticky',
  top: '276px',
  zIndex: '10',

  display: 'flex',
  marginTop: '28px',
  padding: ' 8px 32px',
  gap: '32px',
  backgroundColor: '#fff',
  borderRadius: '4px'
}

export const modalSubtitleInfo = {
  marginTop: '18px',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  color: 'rgba(62,65,77,0.6)'
}

export const listProgramTitleStyle = (isCurrent: boolean) => ({
  margin: '0',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.01em',
  color: isCurrent ? '#bcbec8' : '#3e414d'

})
