import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { PatientActionsData } from 'interfaces/patients/patientInterfaces';
import { EditPatientHookData, EditPatientHookParams } from 'interfaces/hooks/patientsHooksInterfaces';

export const useEditPatient = ({
  patientUuid,
  dirtyFields,
  onSuccess,
  onError,
}: EditPatientHookParams): EditPatientHookData => {
  const { mutate, isLoading } = useMutation(
    async (values: PatientActionsData) => {
      const obj = {};

      Object.keys(dirtyFields).forEach((key) => {
        obj[key] = values[key]
      });

      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/${patientUuid}/`, {
        method: 'PATCH',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(obj),
      });

      if (res.ok || res.status === 400 || res.status === 420) {
        return res;
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!patientUuid,
      onSuccess,
      onError,
    }
  );

  return {
    handleEditPatient: mutate,
    isLoadingEditPatient: isLoading,
  };
};
