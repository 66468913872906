import React from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MainButton } from 'shared/buttons/MainButton/MainButton';

interface CancelConfirmModalProps {
  open: boolean;
  handleClose: () => void;
}

export const CancelConfirmModal: React.FC<CancelConfirmModalProps> = ({ open, handleClose }) => (
  <Dialog open={open} onClose={handleClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
    <Box sx={modalHeaderStyle}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" sx={modalTitleStyle}>
          Подтвердите действие
        </Typography>
      </Box>
      <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>
    <Box
      component="p"
      sx={{
        margin: '0 0 0 24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.02em',
        color: 'rgba(62, 65, 77, 0.6)',
      }}
    >
      Вы точно хотите аннулировать данное доп. соглашение?
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '32px',
          marginRight: '24px',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <MainButton
          textButton="Отмена"
          handleClick={() => {
            handleClose();
          }}
        />
        <MainButton textButton="Аннулировать" mainBtn />
      </Box>
    </Box>
  </Dialog>
);
