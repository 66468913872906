import { Box, ClickAwayListener } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TooltipActions, TooltipActionsBtn, tooltipBoxStyle } from 'ui/tooltips/tooltipAction';
import { inputTypeData } from 'utils/constants';
import { TooltipSlotsSearchTypeProps } from 'interfaces/patients/patientInterfaces';
import { grayDarkColor, grayLightDarkOpacity } from 'utils/uiConstants';
import { Stack } from '@mui/system';

export const TooltipSlotsSearchType: React.FC<TooltipSlotsSearchTypeProps> = ({ handleSearchType, open, handleTypeTooltipOpen }) => (
    <ClickAwayListener onClickAway={() => handleTypeTooltipOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
        }}
      >
        <TooltipActions
          open={open}
          placement="bottom"
          title={
            <Box sx={{ ...tooltipBoxStyle, maxHeight: '150px', overflowY: 'scroll' }}>
              {inputTypeData.map((type) => (
                <TooltipActionsBtn
                sx={{
                  padding: '8px 16px'
                }}
                  onClick={() => {
                    handleSearchType(type.value);
                    sessionStorage.setItem('tableFilterPatientCustomSearchType', type.value);
                    handleTypeTooltipOpen(false);
                  }}
                >
                  {type.title}
                </TooltipActionsBtn>
              ))}
            </Box>
          }
        >
          <Box
            sx={{
              padding: '6px 12px 10px 12px',
              whiteSpace: 'nowrap',
              borderBottom: `1px solid ${grayLightDarkOpacity}`,
              display: 'flex',
              gap: '8px',
              fontSize: '14px',
              lineHeight: '16px',
              fontWeight: 500,
              color: '#3E414D',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                borderBottom: `1px solid ${grayDarkColor}`,
              },
            }}
            onClick={() => handleTypeTooltipOpen(true)}
          >
            <Stack>
              <SearchIcon
                sx={{
                  width: '16px',
                  color: '#3E414D',
                  height: '16px',
                }}
              />
            </Stack>
            <Stack>ПОИСК ПО</Stack>
            <Stack>
              <ExpandMoreIcon
                sx={{
                  width: '16px',
                  height: '16px',
                  color: '#3E414D',
                }}
              />
            </Stack>
          </Box>
        </TooltipActions>
      </Box>
    </ClickAwayListener>
  );
