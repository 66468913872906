import React from 'react';

import { ActionWithProgramBtn } from 'components/ActionWithProgramBtn/ActionWithProgramBtn';
import { ProgramNavigationProps } from 'interfaces/program/programInterfaces';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { Company } from 'interfaces/global/globalInterfaces';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { BasicNavigation } from './BasicNavigation';

export const ProgramsNavigation: React.FC<ProgramNavigationProps> = ({
  selectedPrograms,
  programsData,
  isActionsWithProgramTooltip,
  handleActionsWithProgramsTooltip,
  accessList,
  defaultPermissions
}) => {
  const mainFilterCompanyPrograms: Company | '' = sessionStorageGetItem<Company>('mainFilterCompanyPrograms');
  return (
    <BasicNavigation>
      {isAccessAllowed(mainFilterCompanyPrograms?.uuid, 'HR_programs', accessList, defaultPermissions) && (
        <ActionWithProgramBtn
          selectedPrograms={selectedPrograms}
          programsData={programsData}
          isActionsWithProgramTooltip={isActionsWithProgramTooltip}
          handleActionsWithProgramsTooltip={handleActionsWithProgramsTooltip}
        />
      )}
    </BasicNavigation>
  );
};
