import React, { useCallback, ReactElement, useState, useEffect } from 'react';
import { format, isSameDay, isValid } from 'date-fns';
import { ru } from 'date-fns/locale';

import { Box } from '@mui/system';
import { Typography, TextField, Stack, IconButton } from '@mui/material';

import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { AutocompleteInputMultiple } from 'shared/inputs/AutocompleteInputMultiple/AutocompleteInputMultiple';

import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import { listPatientFilters, listpatientTitleStyle } from 'ui/patients/patients';

import { SearchParams, DNA } from 'interfaces/global/globalInterfaces';
import { IListStaffProps } from 'interfaces/patients/patientInterfaces';

import { inputFormStyle } from 'ui/inputs/inputsForm';

import { useDebounce } from 'utils/useDebounce';
import { isDateActual } from 'utils/dateHelpers';
import { patientTypes } from 'utils/constants';
import { customInputGetLabelText } from 'utils/customInputGetLabelText';
import { TooltipSlotsSearchType } from 'shared/tooltip/TooltipSlotsSearchType/TooltipSlotsSearchType';
import { TooltipCustomTableFields } from 'shared/tooltip/TooltipCustomTableFields/TooltipCustomTableFields';
import { TableStaff } from './TableStaff/TableStaff';

export const ListStaff: React.FC<IListStaffProps> = ({
  selectedCompanies,
  staffQuantity,

  isAddingForbiddenByDates,
  isDetachingForbiddenByDates,

  orderingTableStaff,

  isPatients,
  currentPatient,
  setPatient,

  searchStaffCustomField,

  startDate,
  endDate,
  handleStartDate,
  handleEndDate,

  selectedTypeStaff,
  handleSelectedTypeStaff,

  selectedPatientType,
  handleSelectedPatientType,

  selectedStaffFilterPrograms,
  setSelectedStaffFilterPrograms,

  tableUserFields,
  handleSearchStaffCustomField,

  handleSearchType,
  searchType,

  handleTableFields,

  programFilterOptions,

  isLoadingServicePeriods,
  isLoadingSearchPatients,
  isFetchingSearchPatients,

  isFetchingNextPageSearchPatients,

  handleStaffCheck,
  setSelectedStaff,
  handleAvailabilityCompletedPatients,

  handleOrderingTableStaff,
  handleActionWithStaffTooltip,
  accessList,
  rolesData,
  defaultPermissions,
  isSlotsAviable,
  refetchSearchPatients
}) => {
  const typeFilterOptions: SearchParams[] = [
    { id: 'active', value: 'active', title: 'Активные', color: '#00bcbc' },
    { id: 'planned', value: 'planned', title: 'Планируемые', color: '#4079ad' },
    { id: 'ended', value: 'ended', title: 'Завершенные', color: '#81838a' },
  ];

  const rendersPickerDayStartDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = startDate !== null ? isSameDay(date, startDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDayEndDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = endDate !== null ? isSameDay(date, endDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const [searchText, setSearchText] = useState<string>('');

  const [isTypeTooltipOpen, setTypeTooltipOpen] = useState<boolean>(false);

  const [isTableTooltipOpen, setTableTooltipOpen] = useState<boolean>(false);

  const handleDebounceFn = useCallback(
    (value: string): void => {
      handleSearchStaffCustomField(value);
      sessionStorage.setItem('tableFilterPatientCustomSearchValue', JSON.stringify(value));
    },
    [handleSearchStaffCustomField]
  );

  const debounceFn = useDebounce(handleDebounceFn, 1000);

  useEffect(() => {
    setSearchText(searchStaffCustomField);
  }, [searchStaffCustomField]);

  return (
    <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
      <Box component="section" sx={{ marginTop: '32px', marginBottom: '122px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" sx={listpatientTitleStyle(false)}>
            Список пациентов
            {!isLoadingServicePeriods &&
              !isLoadingSearchPatients &&
              !isFetchingSearchPatients &&
              staffQuantity !== 0 && (
                <Box component="span" sx={listpatientTitleStyle(true)}>{` (всего: ${staffQuantity})`}</Box>
              )}
          </Typography>
          <TooltipCustomTableFields
            handleTableFields={handleTableFields}
            tableUserFields={tableUserFields}
            open={isTableTooltipOpen}
            handleTableTooltip={setTableTooltipOpen}
          />
        </Box>

        <Box sx={listPatientFilters}>
          <TooltipSlotsSearchType
            handleSearchType={handleSearchType}
            handleTypeTooltipOpen={setTypeTooltipOpen}
            open={isTypeTooltipOpen}
          />
          <Box
            sx={{
              width: '100%',
              marginLeft: '-32px',
            }}
          >
            <TextField
              label={customInputGetLabelText(searchType) || 'Не указан тип поиска'}
              variant="standard"
              sx={() => inputFormStyle(false)}
              value={searchText}
              onInput={(_event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(_event.target.value);
                debounceFn(_event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ width: '28px', height: '28px' }}
                    onClick={() => {
                      setSearchText('');
                      debounceFn('');
                      sessionStorage.setItem('tableFilterPatientCustomSearchValue', JSON.stringify(''));
                    }}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Box>

          <AutocompleteInputMultiple
            truncateTag
            label="Программа"
            placeholder=""
            limitTags={1}
            value={selectedStaffFilterPrograms}
            data={programFilterOptions || []}
            handleChange={(_event: React.SyntheticEvent, newValue: SearchParams[]) => {
              const array = newValue;
              const counter = array.reduce(
                // eslint-disable-next-line no-return-assign, no-sequences
                (acc: DNA, current) => ((acc[current.title] = (acc[current.title] || 0) + 1), acc),
                {}
              );
              const result = array.filter((obj: SearchParams) => counter[obj.title] === 1);

              const selectedOptions = result.reduce((arr: SearchParams[], obj: SearchParams) => {
                const exist: boolean = arr.some(({ title }) => obj.title === title);
                if (!exist) {
                  arr.push(obj);
                }
                return arr;
              }, []);

              setSelectedStaffFilterPrograms(selectedOptions);
              sessionStorage.setItem('tableFilterPatientByPrograms', JSON.stringify(selectedOptions));
            }}
            check
            disableCloseOnSelect
          />

          <Stack spacing={3} sx={{ width: '100% ' }}>
            <DesktopDatePicker
              label="Период данных с"
              value={startDate}
              renderDay={rendersPickerDayStartDate}
              PopperProps={{
                sx: datePickerPopperStyled,
              }}
              disabled={isLoadingSearchPatients}
              onChange={(newValue: Date | null) => {
                if (newValue && isValid(newValue) && isDateActual(newValue)) {
                  handleStartDate(newValue);
                  setPatient({
                    ...currentPatient,
                    start_date: newValue ? format(newValue, 'yyyy-MM-dd 00:00:00') : null,
                  });
                  sessionStorage.setItem(
                    'tableFilterPatientByStartDate',
                    JSON.stringify(format(newValue, 'yyyy-MM-dd 00:00:00'))
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  label="Период данных с"
                  variant="standard"
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingSearchPatients}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          sx={{ width: '28px', height: '28px', left: '14px' }}
                          disabled={isLoadingSearchPatients}
                          onClick={() => {
                            setPatient({
                              ...currentPatient,
                              start_date: null,
                            });

                            handleStartDate(null);
                            sessionStorage.setItem('tableFilterPatientByStartDate', JSON.stringify(''));
                          }}
                        >
                          <CloseOutlinedIcon fontSize="small" />
                        </IconButton>
                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>

          <Stack spacing={3} sx={{ width: '100% ' }}>
            <DesktopDatePicker
              label="Период данных по"
              value={endDate}
              renderDay={rendersPickerDayEndDate}
              PopperProps={{
                sx: datePickerPopperStyled,
              }}
              disabled={isLoadingSearchPatients}
              onChange={(newValue: Date | null) => {
                if (newValue && isValid(newValue) && isDateActual(newValue)) {
                  handleEndDate(newValue);
                  setPatient({
                    ...currentPatient,
                    end_date: newValue ? format(newValue, 'yyyy-MM-dd 23:59:59') : null,
                  });

                  sessionStorage.setItem(
                    'tableFilterPatientByEndDate',
                    JSON.stringify(format(newValue, 'yyyy-MM-dd 23:59:59'))
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  label="Период данных по"
                  variant="standard"
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingSearchPatients}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          sx={{ width: '28px', height: '28px', left: '14px' }}
                          disabled={isLoadingSearchPatients}
                          onClick={() => {
                            setPatient({
                              ...currentPatient,
                              end_date: null,
                            });
                            handleEndDate(null);
                            sessionStorage.setItem('tableFilterPatientByEndDate', JSON.stringify(''));
                          }}
                        >
                          <CloseOutlinedIcon fontSize="small" />
                        </IconButton>

                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>

          <AutocompleteInputMultiple
            truncateTag
            label="Категория"
            placeholder=""
            value={selectedPatientType}
            data={patientTypes}
            handleChange={(_event: React.SyntheticEvent, newValue: SearchParams[]) => {
              const array = newValue;
              const counter = array.reduce(
                // eslint-disable-next-line no-return-assign, no-sequences
                (acc: DNA, current) => ((acc[current.title] = (acc[current.title] || 0) + 1), acc),
                {}
              );
              const result = array.filter((obj: SearchParams) => counter[obj.title] === 1);

              const selectedOptions = result.reduce((arr: SearchParams[], obj: SearchParams) => {
                const exist: boolean = arr.some(({ title }) => obj.title === title);
                if (!exist) {
                  arr.push(obj);
                }
                return arr;
              }, []);

              handleSelectedPatientType(selectedOptions);
              sessionStorage.setItem('tableFilterPatientRelativeType', JSON.stringify(selectedOptions));
            }}
            disableCloseOnSelect
            check
          />

          <AutocompleteInputMultiple
            truncateTag
            label="Тип пациентов"
            placeholder=""
            value={selectedTypeStaff}
            data={typeFilterOptions}
            handleChange={(_event: React.SyntheticEvent, newValue: SearchParams[]) => {
              const array = newValue;
              const counter = array.reduce(
                // eslint-disable-next-line no-return-assign, no-sequences
                (acc: DNA, current) => ((acc[current.title] = (acc[current.title] || 0) + 1), acc),
                {}
              );
              const result = array.filter((obj: SearchParams) => counter[obj.title] === 1);

              const selectedOptions = result.reduce((arr: SearchParams[], obj: SearchParams) => {
                const exist: boolean = arr.some(({ title }) => obj.title === title);
                if (!exist) {
                  arr.push(obj);
                }
                return arr;
              }, []);

              handleSelectedTypeStaff(selectedOptions);
              sessionStorage.setItem('tableFilterPatientByType', JSON.stringify(selectedOptions));
            }}
            disableCloseOnSelect
            check
          />
        </Box>

        {(!isLoadingSearchPatients || !isLoadingServicePeriods || !isFetchingSearchPatients) && (
          <TableStaff
            handleCloseTableTooltip={() => setTableTooltipOpen(false)}
            handleCloseTypeSearchTooltip={() => setTypeTooltipOpen(false)}
            tableUserFields={tableUserFields}
            handleActionWithStaffTooltip={handleActionWithStaffTooltip}
            isAddingForbiddenByDates={isAddingForbiddenByDates}
            isDetachingForbiddenByDates={isDetachingForbiddenByDates}
            handleAvailabilityCompletedPatients={handleAvailabilityCompletedPatients}
            selectedCompanies={selectedCompanies}
            isPatients={isPatients}
            isFetchingNextPageSearchPatients={isFetchingNextPageSearchPatients}
            orderingTableStaff={orderingTableStaff}
            handleStaffCheck={handleStaffCheck}
            setSelectedStaff={setSelectedStaff}
            handleOrderingTableStaff={handleOrderingTableStaff}
            accessList={accessList}
            rolesData={rolesData}
            defaultPermissions={defaultPermissions}
            isSlotsAviable={isSlotsAviable}
            refetchSearchPatients={refetchSearchPatients}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};
