import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { FetchError } from 'utils/FetchError';
import { GetUserPermissionCollectionParams, UseGetUserPermissionCollectionData } from 'interfaces/hooks/permissionsHooksInterfaces';

export const useGetUserPermissionCollection = ({ onError }: GetUserPermissionCollectionParams): UseGetUserPermissionCollectionData => {
  const { data: permissionsCollection } = useQuery(
    ['permission_collection'],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/company_permissions/company_permissions_collection/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onError,
      select: (res) => res.data,
    }
  );

  return { permissionsCollection };
};
