import { Box } from '@mui/material';
import React from 'react';

export const RelativesInsuranceTypeText: React.FC<any> = ({ data }) => (
  <Box>
    {data?.relativeType?.value === 'disabled' ? (
      'Не допускается страхование родственников по данному договору'
    ) : (
      <Box>
        <Box component="li"> доступные категории: {data?.relativeCategoties?.map((item) => item?.text).join(', ')}</Box>
        <Box component="li">
          срок принятия на страхования: в течение {data?.aceeptancePeriod} дней с момента прикрепления сотрудника
        </Box>
        {data?.admissionDeadline?.value === 'in_90_days' && (
          <Box component="li"> прикрепление родственников не осуществляется на срок менее 90 дней</Box>
        )}
        <Box>
          {data?.tariff?.map((item) => item.value).indexOf('special_program_for_family_members') !== -1 && (
            <Box component="li">
              {' '}
              для прикрепления используются специальные программы для страхования членов семьи сотрудника
            </Box>
          )}
           {data?.tariff?.map((item) => item.value).indexOf('fixed_rate') !== -1 && (
            <Box component="li">
              стоимость прикрепления рассчитывается с применением дополнительного коэф.в размере {data?.tariffRate} к
              программам доступным по договору
            </Box>
          )}
          {data?.tariff?.map((item) => item.value).indexOf('questioning') !== -1 && (
            <Box component="li"> стоимость прикрепления осуществляется по итогам медицинского анкетирования</Box>
          )}
          {data?.tariff?.map((item) => item.value).indexOf('increased_rate') !== -1 && (
            <Box component="li"> дополнительно при расчете прикрепления применяются возрастные коэффициенты</Box>
          )}
          {data?.tariff?.map((item) => item.value).indexOf('without_planned_hospitalization') !== -1 && (
            <Box component="li"> программы для страхования родственников не включают плановую госпитализацию</Box>
          )}

          {data?.paymentMaking?.value === 'like_in_contract' && (
            <Box component="li"> расчет прикрепления осуществляется по условиям договора</Box>
          )}
          {data?.paymentMaking?.value === 'in_full' && (
            <Box component="li"> оплата осуществляется в полном объеме независимо от срока страхования</Box>
          )}

          {data?.relativeCategoties?.map((item) => item.value).indexOf('children') !== -1 && (
            <Box component="li"> для детей применяются специальные варианты программ</Box>
          )}
          {data?.autoRelativeDetach?.value === 'enabled' && (
            <Box component="li"> в случае открепления сотрудника родственник также снимается со страхования</Box>
          )}
        </Box>
      </Box>
    )}
  </Box>
);
