import { Box } from '@mui/system';
import { RelativesInsuranceTypeTextProps } from 'interfaces/patients/patientInterfaces';
import React from 'react';
import { themeColor } from 'utils/uiConstants';

export const EarlyTerminationOfferTermText: React.FC<RelativesInsuranceTypeTextProps> = ({ data }) => (
  <Box>
    {data?.earlyTerminationOfferTerm?.value === 'other' && (
      <Box component="li">
        Численные изменения (включение/ исключение/смена программы/расширение программ) осуществляются с{' '}
        <span
          style={{
            color: themeColor,
          }}
        >
          {data?.earlyTerminationOfferTermOther}{' '}
        </span>
        числа каждого месяца. При самостоятельном вводе изменения допускается вносить с любых дат.
      </Box>
    )}
    {data?.earlyTerminationOfferTerm?.value === 'any' && (
      <Box component="li">
        Численные изменения (включение/ исключение/смена программы/расширение программ) осуществляются с{' '}
        <span
          style={{
            color: themeColor,
          }}
        >
          любого числа
        </span>
        .
      </Box>
    )}
    {data?.canAttachSinceDays === data?.canDetachSinceDays && (
      <Box component="li">
        Изменения должны быть внесены не позднее чем за{' '}
        <span
          style={{
            color: themeColor,
          }}
        >
          {data?.canDetachSinceDays} р. дн.
        </span>{' '}
        до планируемой даты изменения.
      </Box>
    )}
    {data?.canAttachSinceDays !== data?.canDetachSinceDays && (
      <Box component="li">
        Изменения должны быть внесены не позднее чем за{' '}
        <span
          style={{
            color: themeColor,
          }}
        >
          {data?.canAttachSinceDays} р. дн.
        </span>{' '}
        до планируемой даты прикрепления/расширения программы и за{' '}
        <span
          style={{
            color: themeColor,
          }}
        >
          {data?.canDetachSinceDays} р. дн
        </span>{' '}
        до планируемой даты открепления/смены программы.
      </Box>
    )}
    {data.insurantsAmountIncreaseDeadline?.value === 'ten_days' && (
      <Box component="li">
        Возможность внесения изменений прекращается за{' '}
        <span
          style={{
            color: themeColor,
          }}
        >
          10 дней
        </span>{' '}
        до конца действия договора
      </Box>
    )}
    {(data.insurantsAmountIncreaseDeadline?.value === 'two_months' ||
      data.insurantsAmountIncreaseDeadline?.value === 'one_month') && (
      <Box component="li">
        Включение пациентов за {data.insurantsAmountIncreaseDeadline?.value === 'two_months' && 2}{' '}
        {data.insurantsAmountIncreaseDeadline?.value === 'one_month' && 1} и менее месяцев до окончания срока действия
        Договора допускается, однако страховой взнос будет рассчитан, как за{' '}
        {data.insurantsAmountIncreaseDeadline?.value === 'two_months' && 2}{' '}
        {data.insurantsAmountIncreaseDeadline?.value === 'one_month' && 1} месяц(а).
      </Box>
    )}
    {data.insurantsAmountIncreaseDeadline?.value === 'contract_expiration_date' && (
      <Box component="li">Изменения допускается вносить до конца действия договора.</Box>
    )}
  </Box>
);
