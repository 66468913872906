import React, { useState } from 'react';

import { IDataHooksProgram, IProgram } from 'interfaces/program/programInterfaces';

import { ListProgram } from 'components/ListProgram/ListProgram';

import { useSearchPrograms } from 'hooks/programs/useSearchProgram';
import { useGetCompanies } from 'hooks/companies/useGetCompanies';
import { useGetServicePeriods } from 'hooks/periods/useGetServicePeriods';
import { Company, DataPeriod, ErrorData, IManagersHR } from 'interfaces/global/globalInterfaces';
import { BorderLinearProgress } from 'components/Loader/Loader';
import { ProgramsFilters } from 'components/MainFilters/ProgramsFilters';
import { ProgramsNavigation } from 'components/Navigation/ProgramsNavigation';
import { Box } from '@mui/system';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { InfoText } from 'ui/other/Typography';
import { useGetCompanyAdmins } from 'hooks/permissions/useGetCompanyAdmins';
import { useGetAccessList } from 'hooks/permissions/useGetAccessList';
import { useGetDefaultPermissions } from 'hooks/permissions/useGetDefaultPermissions';
import { useSnackbar } from 'notistack';

interface SearchParams {
  id: string;
  title: string;
  value?: string;
  uuid?: string;
}

interface IProgramsProps {
  handleManagersHR: (value: IManagersHR | null) => void;
}

export const Programs: React.FC<IProgramsProps> = ({ handleManagersHR }) => {
  // sessionStorage для главных фильтров
  const mainFilterCompanyPrograms: Company | '' = sessionStorageGetItem<Company>('mainFilterCompanyPrograms');
  const mainFilterPeriodPrograms: DataPeriod | '' = sessionStorageGetItem('mainFilterPeriodPrograms');
  // sessionStorage для фильтров таблицы
  const tableProgramsFilterProgram: SearchParams | '' = sessionStorageGetItem('tableProgramsFilterProgram');
  const tableProgramsFilterServices: SearchParams[] | '' = sessionStorageGetItem('tableProgramsFilterServices');
  const tableProgramsFilterCity: SearchParams | '' = sessionStorageGetItem('tableProgramsFilterCity');

  const [allCompanies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanyByProgram, setSelectedCompanyByProgram] = useState<Company | null>(
    mainFilterCompanyPrograms || null
  );
  const [dataPeriods, setDataPeriods] = useState<DataPeriod[]>([]);
  const [selectedPeriodByProgram, setSelectedPeriodByProgram] = useState<DataPeriod | null>(
    mainFilterPeriodPrograms || null
  );

  const [selectedPrograms, setSelectedPrograms] = useState<any>([]);

  const [programsQuantity, setAmountProgram] = useState<number>(0);
  const [programsData, setDataPrograms] = useState<IProgram[] | []>([]);

  const [searchByProgrammArr, setSearchByProgrammArr] = useState<SearchParams[] | []>([]);
  const [searchByCityArr, setSearchByCityArr] = useState<SearchParams[] | []>([]);

  const [isActionsWithProgramTooltip, setActionsWithProgramsTooltip] = useState(false);

  const [selectedProgramFilter, setSelectedProgramFilter] = useState<SearchParams | null>(
    tableProgramsFilterProgram || null
  );
  const [selectedServicesForProgramsFilter, setSelectedServicesForProgramsFilter] = useState<SearchParams[]>(
    tableProgramsFilterServices || []
  );
  const [selectedСityForProgram, setSelectedСityForProgram] = useState<SearchParams | null>(
    tableProgramsFilterCity || null
  );

  const token = localStorage.getItem('token');

  const { enqueueSnackbar } = useSnackbar();

  const { accessList } = useGetAccessList({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { defaultPermissions } = useGetDefaultPermissions({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingCompanies, fetchNextPageCompanies } = useGetCompanies({
    onSuccess: ({ pageParams, companiesSearch }) => {
      const allPage: number = pageParams?.pages[0]?.meta
        ? Math.ceil(pageParams.pages[0].meta.total / pageParams.pages[0].meta.per_page)
        : 0;

      if (!selectedCompanyByProgram && !mainFilterCompanyPrograms) {
        setSelectedCompanyByProgram(companiesSearch[0]);
        sessionStorage.setItem('mainFilterCompanyPrograms', JSON.stringify(companiesSearch[0]));
      } else if (mainFilterCompanyPrograms) {
        setSelectedCompanyByProgram(
          companiesSearch.filter((company) => company.uuid === mainFilterCompanyPrograms.uuid)[0]
        );
        sessionStorage.setItem(
          'mainFilterCompanyPrograms',
          JSON.stringify(companiesSearch.filter((company) => company.uuid === mainFilterCompanyPrograms.uuid)[0])
        );
      }

      if (companiesSearch) setCompanies(companiesSearch);

      if (pageParams.pages.length < allPage) fetchNextPageCompanies();
    },
  });

  const { isLoadingServicePeriods } = useGetServicePeriods({
    companies: selectedCompanyByProgram ? [selectedCompanyByProgram] : [],
    onSuccess: (data: DataPeriod[]) => {
      const res = data.reverse();

      setDataPeriods(res);

      if (selectedCompanyByProgram) {
        const activePeriodExist = res.some((item) => item.activePeriod);
        res.forEach((period: DataPeriod) => {
          if (mainFilterPeriodPrograms) {
            if (mainFilterPeriodPrograms.uuid === period.uuid) {
              setSelectedPeriodByProgram(period);
              sessionStorage.setItem('mainFilterPeriodPrograms', JSON.stringify(period));
              handleManagersHR({
                accountEmail: period.accountEmail,
                accountPhone: period.accountPhone,
                curatorEmail: period.curatorEmail,
                curatorPhone: period.curatorPhone,
                accountFullName: period.accountFullName,
                doctorFullName: period.doctorFullName,
                doctorEmail: period.doctorEmail,
              });
            }
          } else if (period.activePeriod) {
            setSelectedPeriodByProgram(period);
            sessionStorage.setItem('mainFilterPeriodPrograms', JSON.stringify(period));

            handleManagersHR({
              accountEmail: period.accountEmail,
              accountPhone: period.accountPhone,
              curatorEmail: period.curatorEmail,
              curatorPhone: period.curatorPhone,
              accountFullName: period.accountFullName,
              doctorFullName: period.doctorFullName,
            });
          } else if (!activePeriodExist) {
            setSelectedPeriodByProgram(res[0]);
            sessionStorage.setItem('mainFilterPeriodPrograms', JSON.stringify(res[0]));

            handleManagersHR({
              accountEmail: res[0].accountEmail,
              accountPhone: res[0].accountPhone,
              curatorEmail: res[0].curatorEmail,
              curatorPhone: res[0].curatorPhone,
              accountFullName: res[0].accountFullName,
              doctorFullName: res[0].doctorFullName,
            });
          }
        });
      } else {
        setSelectedPeriodByProgram(null);
        sessionStorage.setItem('mainFilterPeriodPrograms', JSON.stringify(''));

        setDataPeriods([]);
      }
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { isLoadingSearchPrograms } = useSearchPrograms({
    isProgramsAllowed: isAccessAllowed(mainFilterCompanyPrograms?.uuid, 'HR_programs', accessList, defaultPermissions),
    company: selectedCompanyByProgram,
    period: selectedPeriodByProgram,
    selectedProgram: selectedProgramFilter,
    selectedServices: selectedServicesForProgramsFilter,
    selectedСity: selectedСityForProgram,

    onSuccess: (data: IDataHooksProgram) => {
      setAmountProgram(Number(data.pageParams?.total));
      setDataPrograms(data.programs);

      const loadProgrammArr: SearchParams[] | [] = data.programs
        ? data.programs.map((item: IProgram) => ({
            id: item.programUuid,
            title: item.programTitle,
            uuid: item.programUuid,
          }))
        : searchByProgrammArr;

      const filterLoadProgrammArr = loadProgrammArr
        .filter((a, i) => loadProgrammArr.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

      setSearchByProgrammArr(filterLoadProgrammArr);

      const loadCityArr: SearchParams[] | [] = data.programs
        ? data.programs.map((item: IProgram) => ({
            id: item.cityUuid ? item.cityUuid : '',
            title: item.city ? item.city : '',
            uuid: item.cityUuid ? item.cityUuid : '',
          }))
        : searchByCityArr;

      const filterLoadCityArr = loadCityArr
        .filter((a, i) => loadCityArr.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

      setSearchByCityArr(filterLoadCityArr);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { companyAdminsData } = useGetCompanyAdmins({
    companyUuid: selectedCompanyByProgram?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  return (
    <>
      {isLoadingCompanies || isLoadingSearchPrograms || (isLoadingServicePeriods && <BorderLinearProgress />)}

      <ProgramsNavigation
        selectedPrograms={selectedPrograms}
        programsData={programsData}
        isActionsWithProgramTooltip={isActionsWithProgramTooltip}
        handleActionsWithProgramsTooltip={setActionsWithProgramsTooltip}
        accessList={accessList}
        defaultPermissions={defaultPermissions}
      />

      <ProgramsFilters
        allCompanies={allCompanies}
        selectedCompanyByProgram={selectedCompanyByProgram}
        handleSelectedCompanyByProgram={setSelectedCompanyByProgram}
        dataPeriods={dataPeriods}
        selectedPeriodByProgram={selectedPeriodByProgram}
        handleSelectedPeriodByProgram={setSelectedPeriodByProgram}
      />
      {isAccessAllowed(mainFilterCompanyPrograms?.uuid, 'HR_programs', accessList, defaultPermissions) ? (
        <Box component="section" sx={{ marginTop: '32px' }}>
          <ListProgram
            programsQuantity={programsQuantity}
            programsData={programsData}
            searchByProgrammArr={searchByProgrammArr}
            searchByCityArr={searchByCityArr}
            selectedProgramFilter={selectedProgramFilter}
            handleSelectedProgramFilter={setSelectedProgramFilter}
            selectedServicesForProgramsFilter={selectedServicesForProgramsFilter}
            handleSelectedServices={setSelectedServicesForProgramsFilter}
            selectedСityForProgram={selectedСityForProgram}
            handleSelectedСity={setSelectedСityForProgram}
            selectedPrograms={selectedPrograms}
            handleSelectedPrograms={setSelectedPrograms}
            handleActionsWithProgramsTooltip={setActionsWithProgramsTooltip}
          />
        </Box>
      ) : (
        <InfoText variant="h5">
          Для просмотра данного раздела у вас недостаточно прав, обратитесь к администратору{' '}
          {companyAdminsData
            ?.slice(0, 3)
            ?.map((item) => item?.user?.email)
            ?.join(',')}
          , если вам необходимы данные для работы
        </InfoText>
      )}
    </>
  );
};
