import React, { useState } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DescriptionIcon from '@mui/icons-material/Description';

import {
  tableCellStyle,
  tableCellStatusStyle,
  tableDocsCellStyle,
  tableSortLabelStyle,
  tableRowStyle,
} from 'ui/tables/tables';

import { stableSort, getComparator } from 'utils/tablesMethods';

import {
  ITablePaymentDataChart,
  ITablePaymentHeadingChart,
  ITablePaymentChartEnhancedTableProps,
} from 'interfaces/finance/financeInterfaces';

import { TOrder } from 'interfaces/documents/documentsInterfaces';

const dataTable: ITablePaymentDataChart[] = [
  {
    periodPay: '01.2021 - 31.2021',
    beforeDatePay: '01.01.2021',
    actualDatePay: '31.12.2021',
    amountPay: 10000,
    statusPay: 'Оплачен',
    basisPay: 'Доп. соглашение 3546',
    billDate: 'Счет 11.11.2022 2312321',
  },
  {
    periodPay: '03.2022 - 07.2022',
    beforeDatePay: '11.11.2021',
    actualDatePay: '16.11.2021',
    amountPay: 32000,
    statusPay: 'Просрочен',
    basisPay: 'Доп. соглашение 55787',
    billDate: 'Счет 11.11.2022 14577',
  },
  {
    periodPay: '06.2022 - 07.2022',
    beforeDatePay: '16.11.2021',
    actualDatePay: '22.11.2021',
    amountPay: 11133,
    statusPay: 'Осталось более 10 д.',
    basisPay: 'Доп. соглашение 44587',
    billDate: 'Счет 30.12.2022 44777',
  },
  {
    periodPay: '06.2022 - 07.2022',
    beforeDatePay: '01.11.2021',
    actualDatePay: '02.11.2021',
    amountPay: 55500,
    statusPay: 'Осталось менее 10 д.',
    basisPay: 'Доп. соглашение 23321',
    billDate: 'Счет 30.12.2022 333',
  },
];

function createData(
  periodPay: string,
  beforeDatePay: string,
  actualDatePay: string,
  amountPay: number,
  statusPay: string,
  basisPay: string,
  billDate: string
): ITablePaymentDataChart {
  return {
    periodPay,
    beforeDatePay,
    actualDatePay,
    amountPay,
    statusPay,
    basisPay,
    billDate,
  };
}

const heading: readonly ITablePaymentHeadingChart[] = [
  {
    id: 'periodPay',
    numeric: false,
    disablePadding: true,
    label: 'Период платежа',
  },
  {
    id: 'beforeDatePay',
    numeric: false,
    disablePadding: false,
    label: 'План. дата платежа',
  },
  {
    id: 'actualDatePay',
    numeric: false,
    disablePadding: false,
    label: 'Факт. дата платежа',
  },
  {
    id: 'amountPay',
    numeric: false,
    disablePadding: false,
    label: 'Сумма платежа, ₽',
  },
  {
    id: 'statusPay',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'basisPay',
    numeric: false,
    disablePadding: false,
    label: 'Основание',
  },
  {
    id: 'billDate',
    numeric: false,
    disablePadding: false,
    label: 'Счет',
  },
];

const EnhancedTableHead: React.FC<ITablePaymentChartEnhancedTableProps> = (
  props: ITablePaymentChartEnhancedTableProps
) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof ITablePaymentDataChart) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === head.id ? order : false}
            sx={tableCellStyle}
          >
            <TableSortLabel
              active={orderBy === head.id}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              IconComponent={ExpandLessIcon}
              sx={tableSortLabelStyle}
            >
              {head.label}
              {orderBy === head.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const TableChartPaymentsDocs: React.FC = () => {
  const [order, setOrder] = useState<TOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITablePaymentDataChart>('periodPay');
  const [selected, setSelected] = useState<readonly string[]>([]);

  const rows = dataTable.map((item: ITablePaymentDataChart) =>
    createData(
      item.periodPay,
      item.beforeDatePay,
      item.actualDatePay,
      item.amountPay,
      item.statusPay,
      item.basisPay,
      item.billDate
    )
  );

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof ITablePaymentDataChart): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (_event: React.ChangeEvent<HTMLInputElement>): void => {
    if (_event.target.checked) {
      const newSelecteds = rows.map((n) => n.periodPay);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              borderCollapse: 'inherit',
              borderSpacing: '0 4px',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.periodPay} sx={tableRowStyle}>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={tableDocsCellStyle}>
                      {row.periodPay}
                    </TableCell>
                    <TableCell sx={tableDocsCellStyle}>{row.beforeDatePay ? `${row.beforeDatePay}` : '-'}</TableCell>
                    <TableCell sx={tableDocsCellStyle}>{row.actualDatePay ? `${row.actualDatePay}` : '-'}</TableCell>
                    <TableCell sx={tableDocsCellStyle}>
                      {`${row.amountPay
                        .toFixed(2)
                        .split('')
                        .reverse()
                        .join('')
                        .replace(/\d\d\d/g, '$& ')
                        .split('')
                        .reverse()
                        .join('')} ₽`}
                    </TableCell>
                    <TableCell sx={() => tableCellStatusStyle(row)}>{row.statusPay}</TableCell>
                    <TableCell sx={tableDocsCellStyle}>{row.basisPay ? `${row.basisPay}` : '-'}</TableCell>
                    <TableCell sx={tableDocsCellStyle}>
                      <DescriptionIcon
                        sx={{
                          marginRight: '6px',
                          width: '14px',
                          height: '14px',
                          color: '#BCBEC8',
                        }}
                      />
                      <span
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        {row.billDate ? `Счет ${row.billDate}` : '-'}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
