import { franchizeTypeToShow } from "./constants"

export const paymentScheduleText = (value: string | undefined | null): string => {
    if (value === 'one_time') {
        return 'оплата единовременная без рассрочки (один платеж)'
    }
    if (value === 'monthly') {
        return 'помесячная оплата'
    }
    if (value === 'quarterly') {
        return 'оплата в рассрочку четырьмя платежами'
    }
    if (value === 'by_half_year') {
        return 'оплата в рассрочку двумя платежами'
    }
    return ''
}

export const agreementScheduleText = (value: string | undefined | null): string => {
    if (value === 'monthly') {
        return 'ежемесячно'
    }
    if (value === 'quarterly') {
        return 'раз в квартал'
    }
    if (value === 'by_half_year') {
        return 'раз в полугодие'
    }
    return ''
}

export const franchiseTypeText = (value: string | undefined | null): string => {
    if (value === 'empty') {
        return 'Франшиза не применяется'
    }
    if (value === 'electronic' || value === 'white') {
        return 'Франшиза применяется по некоторым клиникам/услугам в программах. Т.е. за часть услуг – будет взиматься плата с застрахованных лиц путем списания через личный кабинет пациента'
    }
    if (value === 'black') {
        return 'Франшиза применяется по некоторым клиникам/услугам программах. Т.е. предполагается удержание части стоимости работодателем самостоятельно'
    }
    return ''
}

export const franchiseTypeMainText = (value: string): string => {
    if (value === 'empty') {
        return 'Франшиза не применяется'
    }
    if (franchizeTypeToShow?.includes(value)) {
        return 'Франшиза включена'
    }
    return ''
}

export const isVzrText = (value: boolean): string => {
    let text: string
    if (value) {
        text = 'Полис ВЗР включен во все или некоторые программы (см. подробнее в условиях программы)'
    } else {
        text = 'Полис ВЗР не предусмотрен программой страхования, но пациент сможет докупить за свой счет через личный кабинет.'
    }

    return text

}

