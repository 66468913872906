import React, { useState } from 'react';

import { TablePayment } from 'components/AccordionPayment/TablePayment/TablePayment';

import { MainAccordion } from 'shared/accordions/MainAccordion/MainAccordion';

import { IAccordionPaymentProps } from 'interfaces/finance/financeInterfaces';
import { Box } from '@mui/material';

const AccordionPayment: React.FC<IAccordionPaymentProps> = ({
  isLoadingPayments,
  isErrorPayments,
  hasNextPagePayments,
  isFetchingNextPagePayments,
  dataPayments,

  fetchNextPage,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      <MainAccordion
        title="График платежей"
        expanded={isLoadingPayments || isErrorPayments || dataPayments.length === 0 ? false : expanded}
        handleChange={handleChange('panel1')}
        disabled={isLoadingPayments || isErrorPayments || dataPayments.length === 0}
      >
        <TablePayment
          dataPayments={dataPayments}
          hasNextPagePayments={hasNextPagePayments}
          isFetchingNextPagePayments={isFetchingNextPagePayments}
          fetchNextPage={fetchNextPage}
        />
      </MainAccordion>
    </Box>
  );
};

export { AccordionPayment };
