/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';

import { Loader } from 'shared/loaders/Loader';

import {
  ICompanyActionTable,
  ICompanyActionTableHeading,
  TableHistoryProps,
  IEnhancedCompanyActionTableProps,
} from 'interfaces/patients/patientInterfaces';

import { tableHistoryBodyStyle, tableHistoryCellStyle } from 'ui/tables/tables';

import { getRowChangeString, nextSortState, throttle } from 'utils/tablesMethods';
import { lineBreaker } from 'utils/lineBreaker';
import { Sorting } from 'interfaces/other/otherInterfaces';

function createData(
  company: string,
  date: string,
  date_action: string,
  user: string,
  action_category: string,
  old_value: string,
  new_value: string,
  full_name: string,
  take_effect_at: string,
  verbose_changed_field_name: string,
  uuid: string
): ICompanyActionTable {
  return {
    company,
    date,
    date_action,
    user,
    action_category,
    old_value,
    new_value,
    full_name,
    take_effect_at,
    verbose_changed_field_name,
    uuid,
  };
}

const heading: readonly ICompanyActionTableHeading[] = [
  {
    id: 'slot__program__service_period__company__title',
    numeric: false,
    disablePadding: false,
    label: 'Юр. лицо',
  },
  {
    id: 'date_action',
    numeric: false,
    disablePadding: false,
    label: 'Дата действия',
  },
  {
    id: 'user__email',
    numeric: false,
    disablePadding: false,
    label: 'Изменения внёс',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Действие',
  },
  {
    id: 'slot__patient__last_name',
    numeric: false,
    disablePadding: false,
    label: 'ФИО',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Дата изменения',
  },
  {
    id: 'old_value',
    numeric: false,
    disablePadding: false,
    label: 'Старые данные',
  },
  {
    id: 'new_value',
    numeric: false,
    disablePadding: false,
    label: 'Новые данные',
  },
];

const EnhancedTableHead: React.FC<IEnhancedCompanyActionTableProps> = (props: IEnhancedCompanyActionTableProps) => {
  const { orderingTableHistory, handleOrderingTableHistory } = props;
  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if(!sortDirection){
      handleOrderingTableHistory('')
    }
  }, [handleOrderingTableHistory, orderingTableHistory, sortDirection]);

  return (
    <TableHead
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
      }}
    >
      <TableRow sx={{ width: '100%', display: 'flex' }}>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sx={() => tableHistoryCellStyle()}
          >
            <TableSortLabel
              active={orderingTableHistory.replace('-', '') === `${head.id}`}
              direction={sortDirection}
              onClick={() => {
                setSortDirection(nextSortState(sortDirection))
                handleOrderingTableHistory(sortDirection === 'asc' ? `${head.id}` : `-${head.id}`);
              }}
              IconComponent={ExpandLessIcon}
              sx={{
                '.MuiTableSortLabel-icon': {
                  opacity: orderingTableHistory ? 0 : 1,
                },
              }}
            >
              {head.label}
              {orderingTableHistory.replace('-', '') === `${head.id}` ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const TableHistory: React.FC<TableHistoryProps> = ({
  historyActions,
  hasNextPageCompanyActions,
  isFetchingNextPageCompanyActions,
  isLoadingCompanyActions,
  fetchNextPageCompanyActions,

  orderingTableHistory,
  handleOrderingTableHistory,
}) => {
  const [isBottom, setIsBottom] = useState<boolean>(false);

  const rows = historyActions.map((item) =>
    createData(
      item.company,
      item.date,
      item.date_action,
      item.user,
      item.action_category,
      item.old_value,
      item.new_value,
      item.full_name,
      item.take_effect_at,
      item.verbose_changed_field_name,
      item.uuid
    )
  );

  const handleScroll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const node = event.target;

    if (node.scrollHeight - node.scrollTop === node.clientHeight) {
      setIsBottom(true);
    }
  };

  const padeDidMount = (node: HTMLTableSectionElement): void => {
    if (node && hasNextPageCompanyActions && !isFetchingNextPageCompanyActions) {
      node.addEventListener('scroll', throttle(handleScroll, 1000));
    }
  };

  useEffect(() => {
    if (isBottom) {
      fetchNextPageCompanyActions();
      setIsBottom(false);
    }
  }, [fetchNextPageCompanyActions, isBottom]);

  return (
    <Box sx={{ width: `${(100 / (heading.length - 2)) * heading.length}%` }}>
      <Paper sx={{ width: '100%', boxShadow: 'none' }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              display: 'block',
              emptyCells: 'show',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              orderingTableHistory={orderingTableHistory}
              handleOrderingTableHistory={handleOrderingTableHistory}
            />
            <TableBody sx={tableHistoryBodyStyle} ref={padeDidMount}>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.uuid}
                    sx={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <TableCell sx={() => tableHistoryCellStyle()}>{row.company}</TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>{row.date}</TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>{lineBreaker(row.user)}</TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>
                      {getRowChangeString(row.action_category, row.verbose_changed_field_name)}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>{lineBreaker(row.full_name)}</TableCell>
                    <TableCell id={labelId} sx={() => tableHistoryCellStyle()}>
                      {row.date_action}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>
                      {row.action_category !== 'D' ? lineBreaker(row.old_value) : '-'}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>
                      {row.action_category !== 'D' ? lineBreaker(row.new_value) : '-'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {(isFetchingNextPageCompanyActions || isLoadingCompanyActions) && <Loader />}
    </Box>
  );
};
