/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { GetUserInfoParams, GetUserInfoRedData } from 'interfaces/hooks/userInfoHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetUserInfo = ({ isLoggedIn, onError }: GetUserInfoParams): void => {
  useQuery(
    ['user_info'],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/users/me/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: isLoggedIn,
      onSuccess: (res: GetUserInfoRedData) => {
        const login = res?.data?.email.endsWith('@bestdoctor.ru') ? res?.data?.email.split('@')[0] : res?.data?.email;

        const tmr = (window as any)._tmr || ((window as any)._tmr = []);
        tmr.push({ type: 'setUserID', userid: res?.data?.id });
        sessionStorage.setItem('userInfo', login);
      },
      onError,
    }
  );
};
