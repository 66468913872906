import React, { useState } from 'react';
import './NewPassModal.scss';
import Dialog from '@mui/material/Dialog';
import { InputForm } from 'shared/inputs/InputForm/InputForm';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ResetPasswordData, validationPasswordData } from './validationReset';

interface NewPassModalProps {
  open: boolean;
  handleClose: () => void;
  sendPassword: (password: string) => Promise<void>;
  passwordIsLoading: boolean;
  passwordIsError: boolean;
}

interface FormModalState {
  isShowPassword: boolean;
  showPasswordConfirm: boolean;
}

export const NewPassModal: React.FC<NewPassModalProps> = ({
  open,
  handleClose,
  sendPassword,
  passwordIsError,
  passwordIsLoading,
}) => {
  const [values, setValues] = useState<FormModalState>({
    isShowPassword: false,
    showPasswordConfirm: false,
  });
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationPasswordData),
    defaultValues: { password: '', confirmation: '' },
  });

  const handleChange = (prop: keyof ResetPasswordData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(prop, event.target.value);
  };

  const handleCleanInput = (prop: keyof ResetPasswordData) => () => {
    reset({ [prop]: '' });
  };

  const handleClickShowPassword = (prop: keyof FormModalState) => () => {
    setValues((prev) => ({
      ...prev,
      [prop]: !prev[prop],
    }));
  };

  const onClose = (): void => {
    handleClose();
    handleCleanInput('confirmation');
    handleCleanInput('password');
  };

  const onSubmit = (data: ResetPasswordData): void => {
    sendPassword(data.password).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '560px',
          padding: '20px 24px 24px',
        },
      }}
    >
      <div className="modal-new-pass__header">
        <div className="modal-new-pass__head">
          <h4 className="modal-new-pass__title">Смена пароля</h4>
          <p className="modal-new-pass__text">Укажите новый пароль.</p>
        </div>
      </div>

      <form className="modal-new-pass__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-new-pass__form-wrapper">
          <div className="login-form__input">
            <InputForm
              idInput="modal-new-pass_password"
              textLabel="Новый пароль"
              error={!!errors.password || passwordIsError}
              helperText={errors.password?.message && (passwordIsError ? 'Что-то пошло не так' : '')}
              value={watch('password')}
              type={values.isShowPassword ? 'text' : 'password'}
              isPass
              showPass={values.isShowPassword}
              handleChange={handleChange('password')}
              handleCleanInput={handleCleanInput('password')}
              handleShowPass={handleClickShowPassword('isShowPassword')}
            />
          </div>
          <div className="login-form__input">
            <InputForm
              idInput="modal-new-pass_password-confirm"
              error={!!errors.confirmation}
              helperText={errors.confirmation?.message}
              textLabel="Подтверждение пароля"
              value={watch('confirmation')}
              type={values.showPasswordConfirm ? 'text' : 'password'}
              isPass
              showPass={values.showPasswordConfirm}
              handleChange={handleChange('confirmation')}
              handleCleanInput={handleCleanInput('confirmation')}
              handleShowPass={handleClickShowPassword('showPasswordConfirm')}
            />
          </div>
        </div>
        <div className="modal-new-pass__btn-wrapper">
          <MainButton disabled={passwordIsLoading} typeBtn="submit" textButton="Далее" mainBtn />
        </div>
      </form>
    </Dialog>
  );
};
