import { SystemStyleObject, Theme } from '@mui/system';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import { grayDarkColor, encycolorpediaColor } from 'utils/uiConstants';

export const AutocompletePopper = styled(Popper)(() => ({
  [`& .${autocompleteClasses.paper}`]: {},
  [`& .${autocompleteClasses.listbox}`]: {
    maxHeight: '195px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
  },
  [`& .${autocompleteClasses.option}`]: {
    '&[aria-selected="true"]': {},
    [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {},
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const optionAutocompleteStyle = (value?: any, option?: any, exist?: boolean) => ({
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  paddingTop: '318px',
  minHeight: '48px',

  [`&.${autocompleteClasses.option}`]: {
    display: 'flex',
    justifyContent: option.bgcolor ? 'space-between' : 'flex-start',
    backgroundColor:
      // eslint-disable-next-line no-nested-ternary
      value || exist ? (value?.title === option.title || exist ? encycolorpediaColor : 'transparent') : 'transparent',

    [`&.${autocompleteClasses.option}[aria-selected="true"]`]: {
      backgroundColor: encycolorpediaColor,
    },
    [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
      backgroundColor: encycolorpediaColor,
    },
  },
});

export const chipStyle: SystemStyleObject<Theme> = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: grayDarkColor,
  border: 'none',
};
