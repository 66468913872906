import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/system';
import { ClickAwayListener } from '@mui/material';

import {
  TooltipActions,
  TooltipActionsInfo,
  TooltipActionsMainBtn,
  TooltipActionsBtn,
  tooltipBoxStyle,
  actionsIcon,
} from 'ui/tooltips/tooltipAction';

import { AddPatientModal } from 'shared/modals/AddPatientModal/AddPatientModal';
import { ActionStaffModal } from 'shared/modals/ActionStaffModal/ActionStaffModal';
import { DetachPatientModal } from 'shared/modals/DetachPatientModal/DetachPatientModal';

import { useDetachFromFile } from 'hooks/patients/useDetachFromFile';

import { IActionWithStaffBtnProps } from 'interfaces/shared/tooltips/tooltipsInterfaces';
import { useGetTaskActionStaff } from 'hooks/other/useGetTaskActionStaff';

import { useSnackbar } from 'notistack';
import { modalDetachContent } from 'utils/constants';
import { ActionWithPatientFromFileModal } from 'shared/modals/ActionWithPatientFromFileModal/ActionWithPatientFromFileModal';
import { isActive } from 'itson/ItsOn';
import { AddPatientModalNew } from 'shared/modals/AddPatientModal/AddPatientModalNew';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { ErrorData } from 'interfaces/global/globalInterfaces';

export const ActionWithStaffBtn: React.FC<IActionWithStaffBtnProps> = ({
  isCompanyCheck,
  isStaffCheck,
  selectedStaff,
  companiesHR,

  hasSelectedCompletedPatients,

  selectedCompanies,
  periodInMainFilter,

  isPeriodActive,
  handleExportToExcel,
  isDetachingForbiddenByDates,
  isAddingForbiddenByDates,
  isActionWithStaffTooltip,
  handleActionWithStaffTooltip,
  isReportAviable,
  isSlotActionsAviable,
  accessList,
  defaultPermissions,
}) => {
  const [isAddStaffModal, setAddStaffModal] = useState<boolean>(false);
  const [ActionWithPatientModalOpen, setActionWithPatientModalOpen] = useState<boolean>(false);

  const [isActionStaffModal, setActionStaffModal] = useState<boolean>(false);
  const [isDetachPatientModal, setDetachPatientModal] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const [massDetachTitleTooltip, setMassDetachTitleToolTip] = useState<string>('');

  const [attachTooltipText, setAttachTooltipText] = useState<string>('');

  const [taskIdAttach, setTaskIdAttach] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();

  const { dataTaskActionStaff, refetchTaskActionStaff } = useGetTaskActionStaff({
    taskId: taskIdAttach,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { handleDetachFromFile } = useDetachFromFile({
    onSuccess: (res: {
      data: {
        task_id: string;
      };
    }) => {
      const taskId = res.data.task_id;
      setTaskIdAttach(taskId);

      setActionStaffModal(true);

      handleActionWithStaffTooltip(false);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
      setTaskIdAttach('');
      handleActionWithStaffTooltip(false);
    },
  });

  const mainFilterCompaniesStaff =
    'mainFilterCompaniesStaff' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('mainFilterCompaniesStaff') || '')
      : '';

  const companiesUuid: string = mainFilterCompaniesStaff ? mainFilterCompaniesStaff.map((company) => company.uuid) : '';

  const handleChangeDetachFromFile = async (evt: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    evt.preventDefault();

    const fileList: FileList | null = evt.target.files ? evt.target.files : null;
    const file: File | null = fileList !== null ? fileList[0] : null;

    const formData: FormData = new FormData();

    if (file) {
      formData.append('detach_file', file);

      if (isActive('hr-100500.detach_files_company_uuid')) {
        for (let i = 0; i < companiesUuid.length; i++) {
          formData.append('company_uuids', companiesUuid[i]);
        }
      }

      await handleDetachFromFile(formData);
    } else {
      enqueueSnackbar('Ошибка при оформлении заявки на открепление', { variant: 'error' });
    }
  };

  const isPatientActionsForbiddenByPeriod = isActive('hr-495.adding_slots_in_future')
    ? !(isPeriodActive || periodInMainFilter?.status?.value === 'planned')
    : !isPeriodActive;

  const detachFromFile = useMemo(() => {
    if (selectedCompanies.length !== 1) {
      return 'Откреплять пациентов можно только в рамках одной компании';
    }
    if (!isPeriodActive) {
      return 'Откреплять пациентов можно только в рамках активного периода';
    }
    return ''
  }, [selectedCompanies, isPeriodActive]);

  useEffect(() => {
    if (selectedCompanies.length !== 1) {
      setDisabled(true);
      setMassDetachTitleToolTip('Для открепления выбранных пациентов необходимо выбрать одну компанию');
    } else if (!isStaffCheck) {
      setDisabled(true);
      setMassDetachTitleToolTip('Для открепления выбранных пациентов необходимо выбрать одного или более пациентов');
    } else if (hasSelectedCompletedPatients) {
      setDisabled(true);
      setMassDetachTitleToolTip('Нельзя откреплять пациентов с завершенным периодом');
    } else if (isDetachingForbiddenByDates) {
      setMassDetachTitleToolTip('Нет доступных дат для множественного открепления сотрудников');
    } else {
      setDisabled(false);
      setMassDetachTitleToolTip('');
    }
  }, [hasSelectedCompletedPatients, isDetachingForbiddenByDates, isPeriodActive, isStaffCheck, selectedCompanies]);

  useEffect(() => {
    if (companiesHR.length !== 1) {
      setAttachTooltipText('Для прикрепления пациента необходимо выбрать одну компанию');
    } else if (isPatientActionsForbiddenByPeriod) {
      setAttachTooltipText('Нельзя прикреплять пациентов в течение завершённого периода');
    } else {
      setAttachTooltipText('');
    }
  }, [companiesHR.length, isAddingForbiddenByDates, isPatientActionsForbiddenByPeriod, isPeriodActive]);

  useEffect(() => {
    if (isActionStaffModal && dataTaskActionStaff?.status !== 'success') {
      const interval = setInterval(() => {
        refetchTaskActionStaff();
      }, 15000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dataTaskActionStaff?.status, isActionStaffModal, refetchTaskActionStaff]);

  return (
    <>
      <ClickAwayListener onClickAway={() => handleActionWithStaffTooltip(false)}>
        <Box>
          <TooltipActions
            open={isActionWithStaffTooltip && !isDetachPatientModal && !isAddStaffModal && !ActionWithPatientModalOpen}
            placement="bottom-end"
            title={
              <Box sx={tooltipBoxStyle}>
                {isSlotActionsAviable && (
                  <TooltipActionsInfo placement="left" title={attachTooltipText}>
                    <Box component="span">
                      <TooltipActionsBtn
                        disabled={companiesHR.length !== 1 || isPatientActionsForbiddenByPeriod}
                        style={companiesHR.length !== 1 ? { pointerEvents: 'none' } : {}}
                        onClick={() => {
                          handleActionWithStaffTooltip(!isActionWithStaffTooltip);
                          setAddStaffModal(true);
                        }}
                      >
                        Прикрепить
                      </TooltipActionsBtn>
                    </Box>
                  </TooltipActionsInfo>
                )}

                {isSlotActionsAviable && (
                  <TooltipActionsInfo placement="left" title={massDetachTitleTooltip}>
                    <Box component="span">
                      <TooltipActionsBtn
                        disabled={isDisabled || isDetachingForbiddenByDates}
                        style={isDisabled || isDetachingForbiddenByDates ? { pointerEvents: 'none' } : {}}
                        onClick={() => setDetachPatientModal(true)}
                      >
                        Открепить выбранные
                      </TooltipActionsBtn>
                    </Box>
                  </TooltipActionsInfo>
                )}

                {isAccessAllowed(companiesUuid[0], 'HR_slots_action_attach', accessList, defaultPermissions) &&
                  isAccessAllowed(companiesUuid[0], 'HR_slots', accessList, defaultPermissions) && (
                    <TooltipActionsInfo placement="left" title={detachFromFile}>
                      <Box component="span">
                        <TooltipActionsBtn
                          disabled={companiesHR.length !== 1 || !isPeriodActive}
                          style={companiesHR.length !== 1 ? { pointerEvents: 'none' } : {}}
                          onClick={() => {
                            setActionWithPatientModalOpen(true);
                            handleActionWithStaffTooltip(false);
                          }}
                        >
                          Открепить из файла
                        </TooltipActionsBtn>
                      </Box>
                    </TooltipActionsInfo>
                  )}

                {isReportAviable && (
                  <TooltipActionsInfo
                    placement="left"
                    title={!isCompanyCheck && 'Для выгрузки данных необходимо выбрать минимум одну компанию'}
                  >
                    <Box component="span">
                      <TooltipActionsBtn
                        style={!isCompanyCheck ? { pointerEvents: 'none' } : {}}
                        onClick={handleExportToExcel}
                        disabled={!isCompanyCheck}
                      >
                        Выгрузить данные в Excel
                      </TooltipActionsBtn>
                    </Box>
                  </TooltipActionsInfo>
                )}
              </Box>
            }
          >
            <TooltipActionsMainBtn
              variant="text"
              onClick={() => handleActionWithStaffTooltip(true)}
              startIcon={actionsIcon}
            >
              Действия с пациентами
            </TooltipActionsMainBtn>
          </TooltipActions>
        </Box>
      </ClickAwayListener>

      {isActive('hr-416.new_adding_modal') ? (
        <AddPatientModalNew
          isAddingForbiddenByDates={isAddingForbiddenByDates}
          celeryType="slots"
          open={isAddStaffModal}
          companiesHR={companiesHR}
          selectedCompanies={selectedCompanies}
          periodInMainFilter={periodInMainFilter}
          handleClose={() => setAddStaffModal(false)}
          handleTaskIdAttach={setTaskIdAttach}
          accessList={accessList}
          defaultPermissions={defaultPermissions}
        />
      ) : (
        <AddPatientModal
          isAddingForbiddenByDates={isAddingForbiddenByDates}
          celeryType="slots"
          open={isAddStaffModal}
          companiesHR={companiesHR}
          selectedCompanies={selectedCompanies}
          periodInMainFilter={periodInMainFilter}
          handleClose={() => setAddStaffModal(false)}
          handleTaskIdAttach={setTaskIdAttach}
          accessList={accessList}
          defaultPermissions={defaultPermissions}
        />
      )}

      <ActionStaffModal
        celeryType="employees"
        textContent={modalDetachContent}
        open={isActionStaffModal}
        dataTaskActionStaff={dataTaskActionStaff?.data}
        displayErrors
        handleClose={() => {
          handleActionWithStaffTooltip(false);
          setActionStaffModal(false);
        }}
      />

      <DetachPatientModal
        open={isDetachPatientModal}
        selectedStaff={selectedStaff}
        massDetach
        handleClose={() => setDetachPatientModal(false)}
      />

      <ActionWithPatientFromFileModal
        status={dataTaskActionStaff?.data?.status.toLowerCase()}
        isActionMuted={isDetachingForbiddenByDates}
        type="detach"
        open={ActionWithPatientModalOpen}
        handleClose={() => setActionWithPatientModalOpen(false)}
        handleChangeDetachFromFile={handleChangeDetachFromFile}
        periodInMainFilter={periodInMainFilter}
      />
    </>
  );
};
