/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { ProgramsProgramHrWithCountSlotsRq } from '@bestdoctor/core-arch-api/models';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Stack } from '@mui/system';
import { BusinessExpensesWithholdingFromContractModificationTextProps } from 'interfaces/hooks/patientsHooksInterfaces';
import React from 'react';
import { Typography } from '@mui/material';
import { accordionSummaryStyle } from 'ui/accordions/accordionStyles';

export const BusinessExpensesWithholdingFromContractModificationText: React.FC<
  BusinessExpensesWithholdingFromContractModificationTextProps
> = ({ data }) => {
  const groupsOfPrograms = data?.programs
    ?.filter(
      (program) =>
        program?.period_rounding_rule_attachment?.value === 'full_month' ||
        program?.period_rounding_rule_attachment?.value === 'full_quarter' ||
        program?.period_rounding_rule_attachment?.value === 'one_time'
    )
    .reduce((acc, val) => {
      const key = val.period_rounding_rule_attachment?.text;
      if (!acc[key]) {
        acc[key] = [val];
      } else {
        acc[key].push(val);
      }
      return acc;
    }, {});

  const groupsOfProgramsByRoundingPrepayment = data?.programs
    ?.filter(
      (program) =>
        program?.prepayment_rounding_rule_attachment?.value === 'quarterly' ||
        program?.prepayment_rounding_rule_attachment?.value === 'fully_one_time'
    )
    ?.reduce((acc, val) => {
      const key = val.prepayment_rounding_rule_attachment?.text;
      if (!acc[key]) {
        acc[key] = [val];
      } else {
        acc[key].push(val);
      }
      return acc;
    }, {});

  const isDataEmpty =
    data?.programs?.filter(
      (program) =>
        program?.prepayment_rounding_rule_attachment?.value === 'quarterly' ||
        program?.prepayment_rounding_rule_attachment?.value === 'fully_one_time'
    ).length === 0 &&
    data?.programs?.filter(
      (program) =>
        program?.period_rounding_rule_attachment?.value === 'full_month' ||
        program?.period_rounding_rule_attachment?.value === 'full_quarter' ||
        program?.period_rounding_rule_attachment?.value === 'one_time'
    ).length === 0;

  const getPeriodTitle = (periodRoundingRuleAttachmentValue: string): string => {
    if (periodRoundingRuleAttachmentValue === 'full_month') {
      return 'Для программ(ы) премия удерживается с округлением до полного месяца в рамках которого действовало обслуживание:';
    }
    if (periodRoundingRuleAttachmentValue === 'full_quarter') {
      return 'Для программ(ы) вся премия удерживается с округлением до квартала в рамках которого действовало обслуживание:';
    }
    if (periodRoundingRuleAttachmentValue === 'one_time') {
      return 'Для программ(ы) вся премия удерживается в полном размере независимо от срока страхования:';
    }
    return '';
  };

  return (
    <Box>
      {isDataEmpty ? (
        <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
          Нет данных
        </Typography>
      ) : (
        <Accordion
          sx={{
            '&.MuiAccordion-root': {
              boxShadow: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={accordionSummaryStyle}
          >
            <Typography variant="subtitle1" sx={{ color: '#3e414d', marginRight: '8px', marginBottom: '2px', fontWeight: '700' }}>
              Посмотреть подробнее
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{
            padding: '0'
          }}>
            <>
              {data?.programs?.filter(
                (program) =>
                  program?.period_rounding_rule_attachment?.value === 'full_month' ||
                  program?.period_rounding_rule_attachment?.value === 'full_quarter' ||
                  program?.period_rounding_rule_attachment?.value === 'one_time'
              ).length > 0 && (
                  <Box>
                    {(Object.values(groupsOfPrograms) as ProgramsProgramHrWithCountSlotsRq[][])?.map(
                      (item: ProgramsProgramHrWithCountSlotsRq[]) => (
                        <Box>
                          {getPeriodTitle(item[0]?.period_rounding_rule_attachment?.value)}
                          {item.map((program) => (
                            <Box component="li">{program.title}</Box>
                          ))}
                        </Box>
                      )
                    )}
                  </Box>
                )}
              {data?.programs?.filter(
                (program) =>
                  program?.prepayment_rounding_rule_attachment?.value === 'quarterly' ||
                  program?.prepayment_rounding_rule_attachment?.value === 'fully_one_time'
              ).length > 0 && (
                  <Box>
                    {(Object.values(groupsOfProgramsByRoundingPrepayment) as ProgramsProgramHrWithCountSlotsRq[][])?.map(
                      (item: ProgramsProgramHrWithCountSlotsRq[]) => {
                        const groupsOfAmount = item.reduce((acc, val) => {
                          const key = val.non_refundable_amount || '';
                          if (!acc[key]) {
                            acc[key] = [val];
                          } else {
                            acc[key].push(val);
                          }
                          return acc;
                        }, {});

                        return (
                          <Box sx={{
                            marginTop: '12px'
                          }}>
                            {(Object.values(groupsOfAmount) as ProgramsProgramHrWithCountSlotsRq[][])?.map(
                              (amountGroup: ProgramsProgramHrWithCountSlotsRq[], groupIndex) => (
                                <Box>
                                  <Stack direction="column">
                                    {amountGroup[0]?.prepayment_rounding_rule_attachment?.value === 'quarterly' && (
                                      <Stack>
                                        Часть премии в размере {Object.keys(groupsOfAmount)[groupIndex]} удерживается с
                                        округлением до полного квартала для программ(ы):
                                        {amountGroup?.map((groupElement) => (
                                          <Box component="li">{groupElement?.title}</Box>
                                        ))}
                                      </Stack>
                                    )}
                                    {amountGroup[0]?.prepayment_rounding_rule_attachment?.value === 'fully_one_time' && (
                                      <Box>
                                        Часть премии в размере {Object.keys(groupsOfAmount)[groupIndex]} удерживается в
                                        полном размере независимо от срока страхования для программ(ы):
                                        {amountGroup?.map((groupElement) => (
                                          <Box component="li">{groupElement?.title}</Box>
                                        ))}
                                      </Box>
                                    )}
                                  </Stack>
                                </Box>
                              )
                            )}
                          </Box>
                        );
                      }
                    )}
                  </Box>
                )}
            </>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
