import { Dialog } from '@mui/material';
import React, { FC } from 'react';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import './SuccessModal.scss';

interface SuccessModalProps {
  open: boolean;
  handleClose(): void;
}

export const SuccessModal: FC<SuccessModalProps> = ({ open, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '560px',
        padding: '20px 24px 24px',
      },
    }}
  >
    <div className="success__header">
      <div className="success__head">
        <h4 className="success__title">Смена пароля</h4>
        <p className="success__text">
          Ваш пароль успешно изменен!
          <br />
          Авторизуйтесь с новыми данными для входа.
        </p>
      </div>
    </div>
    <div className="success__btn-wrapper">
      <MainButton handleClick={handleClose} typeBtn="button" textButton="Ок" mainBtn />
    </div>
  </Dialog>
);
