import { PasswordResetFormProps } from 'components/PasswordResetForm/PasswordResetForm';
import { GetResetTokenParams, useGetResetTokenData } from 'interfaces/hooks/passwordResetHooksInterfaces';
import { useMutation } from 'react-query';
import { FetchError } from 'utils/FetchError';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

export const useGetResetToken = ({ onSuccess, onError }: GetResetTokenParams): useGetResetTokenData => {
  const { mutate } = useMutation(
    async (values: PasswordResetFormProps) => {
      const objBody = {
        code: values.tokenKeyUrl,
      };
      const res = await fetch(`${API_URL}/auth/sessions/${values.sessionKeyUrl}/token/`, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(objBody),
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    handleGetResetToken: mutate,
  };
};
