import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { SendToRevisionData, SendToRevisionParams } from 'interfaces/hooks/documentsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useSendToRevision = ({
  uuid,
  onError,
  onSuccess
}: SendToRevisionParams): SendToRevisionData => {
  const { mutate, isLoading } = useMutation(
    async (dataForm: FormData) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token')

      const res = await fetch(`${API_URL}/supplementary_agreements/${uuid}/status/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: HEADER_ACCEPT_HR,
        },
        body: dataForm,
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      enabled: !!uuid,
      onSuccess,
      onError,
    }
  );
  
  const handleSendToRevision = mutate;
  const isLoadingSendToRevision: boolean = isLoading;

  return {
    handleSendToRevision,
    isLoadingSendToRevision,
  };
};
