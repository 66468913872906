import { useInfiniteQuery } from 'react-query';
import { ListCompanyResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/companies/types';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { Company } from 'interfaces/global/globalInterfaces';
import { GetCompaniesHook, GetCompaniesHookData } from 'interfaces/hooks/companiesHooksInterfaces';

export const useGetCompanies = ({ onSuccess }: GetCompaniesHook): GetCompaniesHookData => {
  const { isFetching, isLoading, isError, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['dataCompanies'],
    async ({ pageParam = 1 }) => {
      const token = localStorage.getItem('token');
      await handleRefreshToken();

      const res = await fetch(`${API_URL}/companies/?page=${pageParam}&per_page=200`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      getNextPageParam: (lastPage: ListCompanyResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
        const allPage: number = lastPage?.meta ? Math.ceil(lastPage.meta.total / lastPage.meta.per_page) : 0;
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onSuccess,
      select: (data: {
        pageParams: unknown[];
        pages: ListCompanyResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'][];
      }) => {
        const pageParams = data;

        const pages: ListCompanyResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data'][] =
          data.pages.map(
            (page: ListCompanyResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => page.data
          );

        const slotsCompanies: Company[] = [];

        pages.forEach((arr: ListCompanyResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data']) => {
          arr?.forEach((item) =>
            slotsCompanies.push({
              id: item?.id || 0,
              title: item?.title || '',
              uuid: item?.uuid || '',
              can_attach_since: item?.can_attach_since || '',
              can_detach_since: item?.can_detach_since || '',
              link_to_slots_create_file_template: item?.link_to_slots_create_file_template || '',
              activation_method: item?.activation_method || '',
              holding: item.holding?.uuid,
            })
          );
        });

        const companiesSearch: Company[] = slotsCompanies
          .filter((a, i) => slotsCompanies.findIndex((s) => a.title === s.title && a.title !== '') === i)
          .sort((a, b) => {
            if (a.title > b.title) {
              return 1;
            }
            if (a.title < b.title) {
              return -1;
            }
            return 0;
          });

        return { pageParams, companiesSearch };
      },
    }
  );

  return {
    isLoadingCompanies: isLoading,
    isErrorCompanies: isError,

    fetchNextPageCompanies: fetchNextPage,

    hasNextPageCompanies: hasNextPage,
    isFetchingNextPageCompanies: isFetchingNextPage,
    isFetchingCompanies: isFetching,

    refetchCompanies: refetch,
  };
};
