/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SettingsIcon from '@mui/icons-material/Settings';
import { tableCellStyle, tableDocsCellStyle, tableRowStyle } from 'ui/tables/tables';
import { nextSortState } from 'utils/tablesMethods';
import { Stack } from '@mui/system';
import { Sorting } from 'interfaces/other/otherInterfaces';
import { themeColor } from 'utils/uiConstants';
import { useNavigate } from 'react-router-dom';

interface UsersListPermissionHeading {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

const heading: readonly UsersListPermissionHeading[] = [
  {
    id: 'login',
    numeric: false,
    disablePadding: true,
    label: 'Логин',
  },
];

interface UserListTableHeadProps {
  ordering: string;
  handleOrdering: (value: string) => void;
}

const EnhancedTableHead: React.FC<UserListTableHeadProps> = (props: UserListTableHeadProps) => {
  const { ordering, handleOrdering } = props;

  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if (!sortDirection) {
      handleOrdering('');
    }
  }, [handleOrdering, ordering, sortDirection]);

  return (
    <TableHead>
      <TableRow>
        {heading.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={tableCellStyle}
          >
            <TableSortLabel
              active={!sortDirection || ordering.replace('-', '') === `${headCell.id}`}
              direction={sortDirection}
              onClick={() => {
                setSortDirection(nextSortState(sortDirection));
                handleOrdering(sortDirection === 'asc' ? `${headCell.id}` : `-${headCell.id}`);
              }}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}
            >
              {headCell.label}
              {ordering.replace('-', '') === `${headCell.id}` ? (
                <Box component="span" sx={visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface UsersListProps {
  usersRolesData: any;
}

export const UsersList: React.FC<UsersListProps> = ({ usersRolesData }) => {
  const [usersOrdering, setUsersOrdering] = useState<string>('');

  const navigate = useNavigate();

  function createData(uuid: string, login: string, role: string): any {
    return {
      uuid,
      login,
      role,
    };
  }

  const rows = usersRolesData?.map((item) => createData(item.user.uuid, item.user.email, item.role.text));

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            sx={{
              minWidth: 750,
              borderCollapse: 'inherit',
              borderSpacing: '0 4px',
            }}
          >
            <EnhancedTableHead ordering={usersOrdering} handleOrdering={setUsersOrdering} />
            <TableBody>
              {rows?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.uuid} sx={tableRowStyle}>
                    <TableCell sx={tableDocsCellStyle} id={labelId}>
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Stack>{row?.login}</Stack>
                        <Stack>
                          <SettingsIcon
                            onClick={() => navigate(row?.uuid)}
                            sx={{
                              color: themeColor,
                              cursor: 'pointer',
                              marginRight: '16px'
                            }}
                          />
                        </Stack>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
