import { Box } from '@mui/system';
import { GetTooltipTitleRelativeTypeProps } from 'interfaces/patients/patientInterfaces';
import { useSnackbar } from 'notistack';
import React from 'react';
import { writeToClipboard } from 'utils/writeToClipboard';

export const GetTooltipTitleRelativeType: React.FC<GetTooltipTitleRelativeTypeProps> = ({
  relativeType,
  isTariffError,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      {relativeType && relativeType === 'individual_agreement' && (
        <Box>
          По условиям договора прикрепление родственников возможно по договору индивидуального страхования, пожалуйста,
          направьте заявку по адресу{' '}
          <Box
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              writeToClipboard('best.family@bestdoctor.ru');
              enqueueSnackbar('Скопировано в буфер обмена!', { variant: 'info' });
            }}
          >
            best.family@bestdoctor.ru
          </Box>
        </Box>
      )}
      {relativeType && relativeType === 'disabled' && (
        <Box>По условиям договора прикрепление родственников не предусмотрено</Box>
      )}
      {isTariffError && (
        <Box>
          По условиям договора прикрепление осуществляется по итогам мед. анкетирования. Пожалуйста, заполните анкету и
          направьте запрос через менеджера или форму запроса в верхней панели
        </Box>
      )}
    </>
  );
};
