import { PrintDocumentData } from 'interfaces/hooks/documentsHooksInterfaces';
import { useQuery } from 'react-query';
import { FetchError } from 'utils/FetchError';
import { HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { handleRefreshToken } from 'utils/refreshToken';

export const usePrintDocument = (
  fileUrl: string
): PrintDocumentData => {
  const { refetch, isLoading } = useQuery(['printFile'], async () => {
    await handleRefreshToken();

    const token = localStorage.getItem('token');

    const res = await fetch(`${fileUrl}`, {
      method: 'GET',
      headers: {
        Accept: `${HEADER_ACCEPT_HR}`,
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.ok) {
      const blob = await res.blob();
      const iframe = document.createElement('iframe');
      iframe.src = window.URL.createObjectURL(blob);
      iframe.classList.add('d-none')
      document.body.appendChild(iframe)
      iframe.contentWindow?.print()

      return res;
    }
    throw new FetchError(res);
  },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    });

  const handlePrintDocument: () => Promise<void> = refetch;
  const isPrintingDocument: boolean = isLoading;

  return {
    handlePrintDocument,
    isPrintingDocument,
  };
};
