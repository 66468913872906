import React, { InputHTMLAttributes } from 'react';

import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { inputFormStyle } from 'ui/inputs/inputsForm';

interface IInputProps extends Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> {
  textLabel: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  idInput?: string;
  isPass?: boolean;
  showPass?: boolean;
  handleCleanInput?: () => void;
  handleShowPass?: () => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputForm: React.FC<IInputProps> = ({
  textLabel,
  error,
  helperText,
  required,
  disabled,
  value,
  isPass,
  showPass,
  type,
  idInput,
  handleCleanInput,
  handleShowPass,
  handleChange
}) => (
  <TextField
    id={idInput}
    label={textLabel}
    variant="standard"
    onChange={handleChange}
    error={error}
    value={value}
    helperText={helperText}
    sx={() => inputFormStyle(false)}
    required={required}
    disabled={disabled}
    type={type}
    InputProps={{
      endAdornment: (
        <>
          {isPass && (
            <IconButton sx={{ width: '28px', height: '28px' }} disabled={disabled} onClick={handleShowPass}>
              {showPass ? <VisibilityOffOutlinedIcon fontSize="small" /> : <VisibilityOutlinedIcon fontSize="small" />}
            </IconButton>
          )}

          <IconButton sx={{ width: '28px', height: '28px' }} disabled={disabled} onClick={handleCleanInput}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </>
      ),
    }}
  />
);
