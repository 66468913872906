import React from 'react';

import { IActionWithStaffBtnProps } from 'interfaces/shared/tooltips/tooltipsInterfaces';
import { ActionWithStaffBtn } from 'components/ActionWithStaffBtn/ActionWithStaffBtn';

import { BasicNavigation } from './BasicNavigation';

export const StaffNavigation: React.FC<IActionWithStaffBtnProps> = ({
  isCompanyCheck,
  isStaffCheck,
  selectedStaff,
  companiesHR,
  periodHR,
  hasSelectedCompletedPatients,
  selectedCompanies,
  periodInMainFilter,
  isPeriodActive,
  handleExportToExcel,
  isDetachingForbiddenByDates,
  isAddingForbiddenByDates,
  isActionWithStaffTooltip,
  handleActionWithStaffTooltip,
  isReportAviable,
  isSlotActionsAviable,
  accessList,
  rolesData,
  defaultPermissions
}) => (
  <BasicNavigation>
    {(isReportAviable || isSlotActionsAviable) && (
      <ActionWithStaffBtn
        isDetachingForbiddenByDates={isDetachingForbiddenByDates}
        isAddingForbiddenByDates={isAddingForbiddenByDates}
        isCompanyCheck={isCompanyCheck}
        isStaffCheck={isStaffCheck}
        selectedStaff={selectedStaff}
        companiesHR={companiesHR}
        periodHR={periodHR}
        hasSelectedCompletedPatients={hasSelectedCompletedPatients}
        selectedCompanies={selectedCompanies}
        periodInMainFilter={periodInMainFilter}
        isPeriodActive={isPeriodActive}
        handleExportToExcel={handleExportToExcel}
        isActionWithStaffTooltip={isActionWithStaffTooltip}
        handleActionWithStaffTooltip={handleActionWithStaffTooltip}
        isReportAviable={isReportAviable}
        isSlotActionsAviable={isSlotActionsAviable}
        accessList={accessList}
        rolesData={rolesData}
        defaultPermissions={defaultPermissions}
      />
    )}
  </BasicNavigation>
);
