import { graylightColor } from 'utils/uiConstants';

export const footerStyle = (isLoginType?: boolean) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  width: '100%',
  marginBottom: '52px',
  maxWidth: isLoginType ? '1440px' : '1312px',

  '@media(max-width:1500px)': {
    padding: '0 64px 0',
  },
  '@media(max-width:1200px)': {
    marginBottom: '16px',
  },
});

export const footerLinkStyle = {
  marginBottom: '8px',
  color: `${graylightColor}`,
  textDecorationLine: 'underline',
};

export const footerDescriptionStyle = {
  margin: '0',
  color: `${graylightColor}`,
};

export const footerContainerStyle = {
  justifyContent: ' space-between',
  alignItems: 'center',
  borderTop: `1px solid ${graylightColor}`,
  backgroundColor: 'rgb(248 248 248)',
  height: '116px',
};

export const footerSubtitleStyle = {
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '400',
  color: `${graylightColor}`,
};

export const footerTitleStyle = {
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: '700',
  color: '#FF6D6D',
};
