import React, { ReactElement, useCallback, useState, useEffect, useMemo } from 'react';
import { Box, Dialog, IconButton, TextField, Typography } from '@mui/material';
import { ChangeProgramModalProps } from 'interfaces/patients/patientInterfaces';
import { DesktopDatePicker, LocalizationProvider, PickersDayProps } from '@mui/x-date-pickers';
import { endOfDay, format, isSameDay, isValid, parse, startOfDay } from 'date-fns';
import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import {
  modalFormInputStyle,
  modalFormScrollStyle,
  modalFormStyle,
  modalFormWrapInputsStyle,
  modalFormWrapStyle,
  modalHeaderStyle,
  modalSubtitleStyle,
  modalTitleStyle,
} from 'ui/modals/modalGlobal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { inputFormStyle } from 'ui/inputs/inputsForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { themeColor, grayLightDarkOpacity } from 'utils/uiConstants';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import { useSnackbar } from 'notistack';
import { useEditProgram } from 'hooks/programs/useEditProgram';

import { disabledDates } from 'utils/dateHelpers';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { swapElements } from 'utils/swapElements';
import { validationProgram, ProgramParams } from './validationChangeProgram';

export const ChangeProgramModal: React.FC<ChangeProgramModalProps> = ({
  open,
  parentClose,
  handleClose,
  periodByAttachOrDetach,
  patientData,
  programs,
  refetchPatient,
}) => {
  const [dateStartProgram, setDateStartProgram] = useState<Date | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const {
    setValue,
    register,
    reset,
    formState: { errors, isDirty },
    setError,
    handleSubmit,
    watch,
  } = useForm<ProgramParams>({
    mode: 'onTouched',
    resolver: zodResolver(validationProgram),
  });

  const rendersPickerDateStartProgram = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateStartProgram !== null ? isSameDay(date, dateStartProgram) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const disabledDays = (date: Date): boolean => {
    const actualDayTimeStamp = startOfDay(date).getTime();

    const canDetachSinceTimestamp = periodByAttachOrDetach
      ? startOfDay(parse(periodByAttachOrDetach.canDetachSince, 'yyyy-MM-dd', new Date())).getTime()
      : null;
    const canDetachSinceTimestampMax = periodByAttachOrDetach
      ? endOfDay(parse(periodByAttachOrDetach.canAttachOrDetachMaxDate, 'yyyy-MM-dd', new Date())).getTime()
      : null;
    if (disabledDates.some((item) => item === date.getTime())) {
      return disabledDates.some((item) => item === date.getTime());
    }

    if (canDetachSinceTimestamp && canDetachSinceTimestampMax) {
      return actualDayTimeStamp < canDetachSinceTimestamp || actualDayTimeStamp > canDetachSinceTimestampMax;
    }
    if (canDetachSinceTimestamp) {
      return actualDayTimeStamp < canDetachSinceTimestamp;
    }
    if (canDetachSinceTimestampMax) {
      return actualDayTimeStamp > canDetachSinceTimestampMax;
    }
    return false;
  };

  const modalClose: () => void = () => {
    setDateStartProgram(null);
    setValue('change_at', '');
    handleClose();
  };

  const defaultProgram = useMemo(
    () =>
      patientData?.program_uuid
        ? programs[programs.map((program) => program.uuid).indexOf(patientData?.program_uuid)]
        : null,
    [patientData, programs]
  );

  useEffect(() => {
    if(patientData?.program_uuid){
      setValue('new_program_uuid', patientData?.program_uuid)
    }
  }, [open, patientData, setValue])

  const { isLoadingEditProgram, handleEditProgram } = useEditProgram({
    patientUuid: patientData ? patientData.uuid : null,
    onSuccess: (res) => {
      if (res.ok) {
        enqueueSnackbar('Программа успешно изменена!', { variant: 'success' });
        modalClose();
        refetchPatient();
        parentClose();
      } else {
        res.json().then(
          (data: {
            message: string;
            errors: {
              message: string;
              code: string;
              field: 'new_program_uuid' | 'change_at';
            }[];
          }) => {
            data.errors.forEach((item) => {
              const { field } = item;
              enqueueSnackbar(item.message, { variant: 'error' });
              setError(field, {
                type: 'custom',
                message: item.message,
              });
            });
          }
        );
      }
    },
    onError: (res) => {
      res.json().then(
        (data: {
          message: string;
          errors: {
            message: string;
            code: string;
            field: 'new_program_uuid' | 'change_at';
          }[];
        }) => {
          data.errors.forEach((item) => {
            const { field } = item;

            setError(field, {
              type: 'custom',
              message: item.message,
            });

            enqueueSnackbar(item.message, { variant: 'error' });
          });
        }
      );
    },
  });

  useEffect(() => {
    reset({
      new_program_uuid: patientData?.program_uuid,
    });
  }, [patientData, reset]);

  return (
    <Dialog open={open} onClose={modalClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            Редактирование программы пациента
          </Typography>
          <Typography variant="subtitle1" sx={modalSubtitleStyle}>
            Изменения будут применены в ближайшее время.
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={modalClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box component="form" sx={{ ...modalFormStyle, overflow: 'visible' }} onSubmit={handleSubmit(handleEditProgram)}>
        <Box sx={() => modalFormScrollStyle(600)}>
          <Box sx={() => modalFormWrapStyle(false)}>
            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <AutocompleteInput
                  defaultValue={defaultProgram}
                  disableDefaultValue
                  label="Выберите программу"
                  placeholder="Выберите программу"
                  data={
                    (patientData  && !!programs && defaultProgram &&
                      swapElements(programs, 0, programs.indexOf(defaultProgram))?.filter(
                        (type) =>
                          (type?.allowedSlotPatientTypes && type?.allowedSlotPatientTypes?.includes('undefined')) ||
                          type?.allowedSlotPatientTypes?.includes(patientData?.patientType?.value)
                      )) ||
                    []
                  }
                  required
                  disabled={isLoadingEditProgram}
                  handleChangeObj={useCallback(
                    (_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                      // eslint-disable-next-line react/jsx-props-no-spreading

                      setValue('new_program_uuid', newValue.uuid);
                      register('new_program_uuid', { value: newValue.uuid });
                    },
                    [setValue, register]
                  )}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Дата начала обслуживания"
                    value={dateStartProgram}
                    shouldDisableDate={(date) => disabledDays(date)}
                    renderDay={rendersPickerDateStartProgram}
                    PopperProps={{
                      sx: datePickerPopperStyled,
                    }}
                    disabled={isLoadingEditProgram}
                    onChange={(newValue: Date | null) => {
                      if (newValue && isValid(newValue)) {
                        register('change_at', {
                          value: format(newValue, 'yyyy-MM-dd'),
                        });
                        setValue('change_at', format(newValue, 'yyyy-MM-dd'));

                        setDateStartProgram(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Дата начала обслуживания"
                        variant="standard"
                        error={!!errors.change_at?.message}
                        helperText={errors.change_at?.message}
                        sx={() => inputFormStyle(false)}
                        required
                        disabled={isLoadingEditProgram}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        InputProps={{
                          endAdornment: (
                            <>
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingEditProgram}
                                onClick={() => {
                                  reset(
                                    {
                                      change_at: '',
                                    },
                                    {
                                      keepErrors: true,
                                      keepDirty: true,
                                    }
                                  );

                                  setDateStartProgram(null);
                                  setValue('change_at', '');
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>

                              {params.InputProps?.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={() => modalFormWrapStyle(true)}>
          <Typography variant="caption" sx={{ color: grayLightDarkOpacity }}>
            <Typography variant="caption" sx={{ color: themeColor }}>
              *{' '}
            </Typography>
            - обязательные поля.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
            }}
          >
            <MainButton textButton="Отмена" handleClick={handleClose} />
            <TooltipActionsInfo
              placement="top"
              title={
                defaultProgram?.uuid === watch('new_program_uuid')
                  ? 'Невозможно изменить программу пациента на существующую'
                  : ''
              }
            >
              <Box component="span">
                <MainButton
                  textButton="Сохранить"
                  typeBtn="submit"
                  mainBtn
                  disabled={isLoadingEditProgram || isDirty || defaultProgram?.uuid === watch('new_program_uuid')}
                />
              </Box>
            </TooltipActionsInfo>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
