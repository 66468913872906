import React, { useState } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { checkLabelStyle } from 'ui/finance/finance';
import { mainFiltersSectionStyle, mainFiltersWrapperStyle } from 'ui/patients/mainFilters';
import { StandartSwitch } from 'ui/switch/switch';

import { periodsNotificationsData, paramsPriorityFilter } from 'utils/constants';

import { Company } from 'interfaces/global/globalInterfaces';
import {
  INotificationFilters,
  ParamsPriorityFilter,
  PeriodsNotificationsParams,
} from 'interfaces/notifications/notificationsInterfaces';
import { PeriodFilterModal } from 'shared/modals/PeriodFilterModal/PeriodFilterModal';

export const NotificationFilters: React.FC<INotificationFilters> = ({
  allCompanies,
  selectedCompaniesInNotifications,
  notificationFilterByPeriod,
  priority,
  unviewed,
  setSelectedCompaniesInNotifications,
  setNotificationFilterByPeriod,
  setPriority,
  setUnviewed,
}) => {
  const [isPeriodFilterModal, setPeriodFilterModal] = useState<boolean>(false);

  return (
    <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
      <Box component="section" sx={mainFiltersSectionStyle}>
        <Box sx={mainFiltersWrapperStyle}>
          <AutocompleteInput
            label="Компания"
            placeholder="Компания"
            data={allCompanies}
            value={selectedCompaniesInNotifications || ''}
            handleChangeObj={(_event: React.SyntheticEvent, newValue: Company | null) => setSelectedCompaniesInNotifications(newValue)}
          />

          <AutocompleteInput
            label="Период данных"
            placeholder="Период данных"
            data={periodsNotificationsData}
            value={notificationFilterByPeriod || ''}
            handleChangeObj={(_event: React.SyntheticEvent, newValue: PeriodsNotificationsParams | null) => {
              if (newValue?.id === '01') {
                setNotificationFilterByPeriod({
                  ...newValue,
                  dateGte: format(new Date(), 'yyyy-MM-dd 00:00:00'),
                  dateLte: format(new Date(), 'yyyy-MM-dd 23:59:59'),
                });
              } else if (newValue?.id === '02') {
                setNotificationFilterByPeriod({
                  ...newValue,
                  dateGte: format(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd 00:00:00'),
                  dateLte: format(new Date(), 'yyyy-MM-dd 23:59:59'),
                });
              } else if (newValue?.id === '03') {
                setNotificationFilterByPeriod({
                  ...newValue,
                  dateGte: format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd 00:00:00'),
                  dateLte: format(new Date(), 'yyyy-MM-dd 23:59:59'),
                });
              } else if (newValue?.id === '04') {
                setNotificationFilterByPeriod({
                  ...newValue,
                  dateGte: format(new Date().setFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd 00:00:00'),
                  dateLte: format(new Date(), 'yyyy-MM-dd 23:59:59'),
                });
              } else if (newValue?.id === '05') {
                setPeriodFilterModal(true);
              } else {
                setNotificationFilterByPeriod(newValue);
              }
            }}
          />

          <AutocompleteInput
            label="Приоритет"
            placeholder="Приоритет"
            data={paramsPriorityFilter}
            value={priority || ''}
            handleChangeObj={(_event: React.SyntheticEvent, newValue: ParamsPriorityFilter | null) => setPriority(newValue)}
            disableCloseOnSelect
          />

          <Box sx={{ display: 'flex', width: '100%', minWidth: '200px' }}>
            <StandartSwitch
              checked={unviewed}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUnviewed(event.target.checked)}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Box component="p" sx={() => checkLabelStyle(true)}>
              Только непрочитанные
            </Box>
          </Box>
        </Box>
      </Box>

      <PeriodFilterModal
        open={isPeriodFilterModal}
        setNotificationFilterByPeriod={setNotificationFilterByPeriod}
        handleClose={() => setPeriodFilterModal(false)}
      />
    </LocalizationProvider>
  );
};
