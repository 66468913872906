import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { FC, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { loginInputStyle } from 'ui/login/login';
import { SignInButton } from 'shared/buttons/SignInButton/SignInButton';
import { useGetResetToken } from 'hooks/resetPassword/useGetResetToken';
import { useSnackbar } from 'notistack';
import { useSetPassword } from 'hooks/resetPassword/useSetPassword';
import { useNavigate } from 'react-router-dom';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { ResetPasswordData, validationPasswordData } from './validationReset';

export interface PasswordResetFormProps {
  tokenKeyUrl: string | null | undefined;
  sessionKeyUrl: string | null | undefined;
  isResetPossible?: boolean | undefined;
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({ tokenKeyUrl, sessionKeyUrl, isResetPossible }) => {
  const [isActionAvaible, setActionAviable] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [newPasswordUrl, setNewPasswordUrl] = useState<RequestInfo | URL>('');
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isShowConfirm, setShowConfirm] = useState<boolean>(false);
  const handleShowPassword = (): void => setShowPassword((prev) => !prev);
  const handleShowConfirm = (): void => setShowConfirm((prev) => !prev);

  type Step = {
    url: string;
    access_token: string;
    type: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordData>({ resolver: zodResolver(validationPasswordData) });

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { handleGetResetToken } = useGetResetToken({
    onSuccess: (res: {
      data: {
        allowed_steps: Step[];
      };
    }) => {
      if (res?.data?.allowed_steps.map((item) => item.type).includes('reset_password')) {
        setActionAviable(true);
        const currentIndex: number = res?.data?.allowed_steps.map((item) => item.type).indexOf('reset_password');
        setToken(res.data.allowed_steps[currentIndex].access_token);
        setNewPasswordUrl(res.data.allowed_steps[currentIndex].url);
      }
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { handleSetPassword } = useSetPassword({
    token,
    newPasswordUrl,
    onSuccess: () => {
      enqueueSnackbar('Пароль успешно установлен!', { variant: 'success' });
      setButtonDisabled(true);
      setTimeout(() => navigate('/'), 5000);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const onSubmit = (data: ResetPasswordData): void => {
    handleSetPassword(data.password);
  };

  useLayoutEffect(() => {
    if (sessionKeyUrl && tokenKeyUrl) {
      handleGetResetToken({ sessionKeyUrl, tokenKeyUrl });
    }
  }, [handleGetResetToken, sessionKeyUrl, tokenKeyUrl]);

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isActionAvaible && isResetPossible ? (
        <>
          <Stack
            direction="column"
            sx={{
              marginBottom: '32px',
            }}
          >
            <TextField
              sx={loginInputStyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword}>
                      {isShowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={errors.password?.message}
              label="Задайте пароль для входа"
              error={!!errors.password}
              type={isShowPassword ? 'text' : 'password'}
              variant="standard"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('password')}
            />
            <TextField
              sx={loginInputStyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirm}>
                      {isShowConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={errors.confirmation?.message}
              label="Подтвердите пароль"
              error={!!errors.confirmation}
              type={isShowConfirm ? 'text' : 'password'}
              variant="standard"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('confirmation')}
            />
          </Stack>
          <SignInButton fullWidth disabled={isButtonDisabled} loading={false} textButton="Подтвердить" />
        </>
      ) : (
        <Box
          component="p"
          sx={{
            marginTop: '8px',
            fontSize: '20px',
            lineHeight: '28px',
            opacity: '0.6',
          }}
        >
          Срок действия ссылки истек. Запросите повторное приглашение у аккаунт-менеджера
        </Box>
      )}
    </Box>
  );
};
