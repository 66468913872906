import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { blackColor, whiteColor, graylightColor, themeColor } from 'utils/uiConstants';

export const TooltipInfo = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '16px',
    color: blackColor,
    backgroundColor: whiteColor,
    borderRadius: '16px',
    boxShadow: '0px 0.4px 0.8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.06)',
  },
}));

export const tooltipContainerStyle = {
  marginBottom: '16px',

  '&:last-child': {
    marginBottom: 0,
  },
};

export const tooltipTitleStyle = {
  margin: '0 0 4px 0',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: graylightColor,
};

export const tooltipLinkStyle = {
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: themeColor,
};
