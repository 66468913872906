import React from 'react';

import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';

import { mainBtnStyle } from 'ui/buttons/buttonsMain';

interface AdditionalServicesBtnProps {
  handleClick: () => void;
}
export const AdditionalServicesBtn: React.FC<AdditionalServicesBtnProps> = ({ handleClick }) => (
  <Button
    sx={() => mainBtnStyle(true, false)}
    variant="text"
    onClick={handleClick}
    startIcon={
      <DescriptionIcon
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '2px',
          color: 'white',
        }}
      />
    }
  >
    Запросить доп. услугу
  </Button>
);
