import { useQuery } from 'react-query';
import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { agreementScheduleText, isVzrText, paymentScheduleText } from 'utils/contractInfoTextUtils';
import { RetrieveCompanyServicePeriodResourceResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-service-periods/types';
import { FetchError } from 'utils/FetchError';
import { GetPeriodDetachEditData, GetPeriodStaffActionParams } from 'interfaces/hooks/periodsHooksInterfaces';

export const useGetPeriodStaffAction = ({ period, onError }: GetPeriodStaffActionParams): GetPeriodDetachEditData => {
  const periodUuid = period?.uuid;
  const { data } = useQuery(
    ['periodDetachOrAttach', periodUuid],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/service_periods/${periodUuid}/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!period,
      onError,
      select: (res: RetrieveCompanyServicePeriodResourceResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => ({
        contractDate: res?.data?.contract_date,
        contractNum: res?.data?.contract_num,
        startDate: res?.data?.start_date,
        endDate: res?.data?.end_date,
        amount: res?.data?.total_employee_count ? res?.data?.total_employee_count : res?.data?.contract_slots_amount, // будет доп условие + утилита для окончания
        premium: res?.data?.insurance_premium_total ? res?.data?.insurance_premium_total : res?.data?.service_cost, // Валюты нет в админке
        paymentSchedule: paymentScheduleText(res?.data?.payment_schedule?.value),
        agreementSchedule: agreementScheduleText(res?.data?.agreement_schedule?.value),
        earlyTerminationOfferTerm: {
          earlyTerminationOfferTerm: res.data?.early_termination_offer_term,
          earlyTerminationOfferTermOther: res?.data?.early_termination_offer_term_other,
          canAttachSinceDays: res?.data?.can_attach_since_days,
          canDetachSinceDays: res?.data?.can_detach_since_days,
          insurantsAmountIncreaseDeadline: res?.data?.insurants_amount_increase_deadline,
        },
        businessExpensesWithholdingFromContractModification:
          res?.data?.business_expenses_withholding_from_contract_modification,
        businessExpensesPercent: res?.data?.business_expenses_percent,
        franchiseType: res?.data?.franchise_type,
        programs: res?.data?.programs_url,
        activationMethod: res?.data?.activation_method?.text,
        isVzr: res?.data?.is_vzr_cost_included_in_program ? isVzrText(res?.data?.is_vzr_cost_included_in_program) : '',
        relativesInsurance: {
          relativeType: res?.data?.relatives_insurance_type,
          relativeCategoties: res?.data?.relatives_categories,
          paymentMaking: res?.data?.payment_making,
          aceeptancePeriod: res?.data?.acceptance_period,
          tariff: res?.data?.tariff,
          admissionDeadline: res?.data?.admission_deadline,
          autoRelativeDetach: res?.data?.auto_relatives_detach,
          tariffRate: res?.data?.tariff_rate,
        },
        canDetachSince: res?.data?.can_detach_since,
        canDetachSinceDays: res?.data?.can_detach_since_days,
        canAttachOrDetachMaxDate: res?.data?.can_attach_or_detach_max_date,
        canAttachSince: res?.data?.can_attach_since,
        canAttachSinceDays: res?.data?.can_attach_since_days,
      }),
    }
  );
  return {
    periodStaffAction: data,
  };
};
