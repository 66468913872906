/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { getNotificationModalActionStaffText } from 'utils/tablesMethods';
import { useGetTaskActionStaff } from 'hooks/other/useGetTaskActionStaff';
import { modalAddFromFileContent, modalDetachContent } from 'utils/constants';
import { ContinueActionModalProps } from 'interfaces/hooks/patientsHooksInterfaces';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { ActionStaffModal } from '../ActionStaffModal/ActionStaffModal';

export const ContinueActionModal: React.FC<ContinueActionModalProps> = ({ open, handleClose, data }) => {
  const [isActionStaffModal, setActionStaffModal] = useState<boolean>(false);
  const [isTaskAvailable, setTaskAvailable] = useState<boolean>(false);

  const taskId = data?.task_id;

  const { enqueueSnackbar } = useSnackbar();

  const { dataTaskActionStaff, refetchTaskActionStaff } = useGetTaskActionStaff({
    taskId,
    onSuccess: () => setTaskAvailable(true),
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const isOperationSuccess =
    (dataTaskActionStaff?.data?.total_successful_creating || dataTaskActionStaff?.data?.total_successful_detaching) &&
    dataTaskActionStaff?.data?.errors_records?.length === 0;

  const refetch = (): void => {
    refetchTaskActionStaff();
  };

  useEffect((): any => {
    if (open && dataTaskActionStaff?.data.status !== 'success') {
      const interval = setInterval(() => {
        refetch();
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dataTaskActionStaff]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
        <Box sx={modalHeaderStyle}>
          <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3" sx={modalTitleStyle}>
              Детали по статусу операций
            </Typography>
            <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Box>

        <Box
          component="p"
          sx={{
            margin: '0 0 0 24px',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.02em',
          }}
        >
          <Box
            sx={{
              opacity: '0.6',
              marginBottom: '40px',
            }}
          >
            Прикрепление/открепление
          </Box>
          {getNotificationModalActionStaffText(
            data?.task_name,
            data?.result?.data?.total_count_records,
            data?.task_name === 'create_slots_from_file'
              ? data?.result?.data?.total_successful_creating
              : data?.result?.data?.total_successful_detaching
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
              marginRight: '24px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <MainButton textButton="Отмена" handleClick={handleClose} />
            {isTaskAvailable && (
              <MainButton
                textButton={isOperationSuccess ? 'Посмотреть детали' : 'Продолжить операцию'}
                mainBtn
                handleClick={() => setActionStaffModal(true)}
              />
            )}
          </Box>
        </Box>
      </Dialog>
      <ActionStaffModal
        celeryType={data?.task_name === 'create_slots_from_file' ? 'slots' : 'employees'}
        open={isActionStaffModal}
        displayErrors
        dataTaskActionStaff={dataTaskActionStaff?.data}
        handleClose={() => {
          setActionStaffModal(false);
          handleClose();
        }}
        textContent={data?.task_name === 'create_slots_from_file' ? modalAddFromFileContent : modalDetachContent}
      />
    </>
  );
};
