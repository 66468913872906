import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ITreatmentChart } from 'interfaces/finance/financeInterfaces';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const TreatmentChart: React.FC<ITreatmentChart> = ({ dataFinancePeriodsStats }) => {
  const labels = dataFinancePeriodsStats.map((el) => el.month);
  const dataValues = dataFinancePeriodsStats.map((el) => el.visits);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataValues,
        backgroundColor: '#ffe2bc',
      },
    ],
  };

  const options: object = {
    layout: {
      padding: {
        top: 30,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        title: {
          display: false,
          text: 'Количество обращений',
          font: {
            family: 'inherit',
            style: 'normal',
            weight: 400,
            size: 12,
            lineHeight: '14px',
          },
          color: '#bcbec8',
        },
        labels: {
          boxWidth: 0,
          font: {
            family: 'inherit',
            style: 'normal',
            weight: 400,
            size: 12,
            lineHeight: '14px',
          },
        },
      },
      datalabels: {
        font: {
          family: 'inherit',
          style: 'normal',
          weight: 400,
          size: 12,
          lineHeight: '14px',
        },
        color: '#FF6D6D',
        borderRadius: 4,
        backgroundColor: '#fff',
        align: 'end',
        anchor: 'end',
        borderWidth: 1,
        borderColor: '#FF6D6D',
        formatter: (value: number) => `${value.toLocaleString()}`,
      },
    },
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          font: {
            family: 'inherit',
            size: 12,
            style: 'normal',
            weight: 400,
            lineHeight: '14px',
          },
          color: 'rgba(62, 65, 77, 0.6)',
          callback: (context: number) => labels[context]?.toLocaleString(),
        },
      },
      y: {
        position: 'right',
        beginAtZero: true,
        ticks: {
          font: {
            family: 'inherit',
            size: 12,
            style: 'normal',
            weight: 400,
            lineHeight: '14px',
          },
          color: '#BCBEC8',
          maxTicksLimit: 4,
        },
      },
    },
  };

  return <Bar options={options} data={data} height="326px" />;
};

export { TreatmentChart };
