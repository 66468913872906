export const mainFiltersSectionStyle = {
  position: 'sticky',
  top: '216px',
  zIndex: '10',
  borderRadius: '4px',
  backgroundColor: 'white',
};

export const mainFiltersWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px 32px',
  gap: '32px',
  boxShadow: '0px 0.4px 0.8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.06)',
  borderRadius: ' 4px',
};

export const mainFiltersContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  width: '100%',
};
