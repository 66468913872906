/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';

import { Box, Dialog, IconButton, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';

import { MainButton } from 'shared/buttons/MainButton/MainButton';

import {
  actionModaListItemStyle,
  actionModalListCellStyle,
  actionModalListStyle,
  actionModalSubtitleStyle,
  actionModalTextStyle,
  actionModalTitleStyle,
} from 'ui/modals/actionModals';

import { IModalTextContent } from 'interfaces/patients/patientInterfaces';
import ReportIcon from '@mui/icons-material/Report';
import { useDetachErrorsToFile } from 'hooks/patients/useDetachErrorsToFile';
import { isActive } from 'itson/ItsOn';
import { themeColor } from 'utils/uiConstants';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { useSnackbar } from 'notistack';
import { GetTaskActionStaffData } from 'interfaces/hooks/otherHooksInterfaces';

interface ActionStaffModalProps {
  celeryType: string;
  textContent: IModalTextContent;
  open: boolean;
  dataTaskActionStaff: GetTaskActionStaffData['data'] | null;
  handleClose: () => void;
  displayErrors: boolean;
}

const ListCell = styled(Box)({
  actionModalListCellStyle,
});

export const ActionStaffModal: React.FC<ActionStaffModalProps> = ({
  open,
  textContent,
  dataTaskActionStaff,
  handleClose,
  celeryType,
  displayErrors,
}) => {
  const [errorFile, setErrorFile] = useState<Blob | MediaSource | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { handleDetachErrorsToFile } = useDetachErrorsToFile({
    celeryType,
    onSuccess: (res: Blob | MediaSource) => {
      setErrorFile(res);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500 && displayErrors) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const isActionSuccess =
    (dataTaskActionStaff?.total_successful_creating || dataTaskActionStaff?.total_successful_detaching) &&
    dataTaskActionStaff?.errors_records?.length === 0;

  const isListFull = isActive('hr-373.full_list_slot_operation');

  const successSlots =
    isListFull && celeryType === 'slots'
      ? dataTaskActionStaff?.successful_attached_slots
      : dataTaskActionStaff?.successful_detached_slots;

  useEffect(() => {
    if (dataTaskActionStaff) {
      if (dataTaskActionStaff.status === 'success' || dataTaskActionStaff.status === 'error') {
        handleDetachErrorsToFile(dataTaskActionStaff);
      }
    }
  }, [dataTaskActionStaff, handleDetachErrorsToFile]);

  return (
    <Dialog
      open={open && dataTaskActionStaff?.status === 'success'}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
          height: '600px',
          overflow: 'auto',
        },
      }}
    >
      <Box sx={{ margin: '20px 24px' }}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="h4" sx={actionModalTitleStyle}>
                {textContent.title}
              </Typography>
              <Box component="p" sx={actionModalTextStyle}>
                {!isActionSuccess ? textContent.subTitle : ''}
              </Box>
            </Box>
            <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box
            sx={{
              height: '382px',
            }}
          >
            {dataTaskActionStaff?.status === 'success' || dataTaskActionStaff?.status === 'error' ? (
              <>
                {dataTaskActionStaff?.status === 'success' && (
                  <>
                    <Typography variant="h4" sx={actionModalSubtitleStyle}>
                      {textContent.successTitle} {dataTaskActionStaff?.total_successful_detaching}
                      {dataTaskActionStaff?.total_successful_creating}/{dataTaskActionStaff?.total_count_records}
                    </Typography>
                    <Box component="p" sx={actionModalTextStyle}>
                      {!isActionSuccess && textContent.errorText}
                    </Box>
                  </>
                )}
                <Box sx={actionModalListStyle}>
                  {successSlots?.map((ele) => (
                    <Box key={ele[3]} sx={actionModaListItemStyle}>
                      <ListCell sx={actionModalListCellStyle}>{ele[0] ? ele[0] : '-'}</ListCell>
                      <ListCell sx={actionModalListCellStyle}>{ele[1] ? ele[1] : '-'}</ListCell>
                      <ListCell sx={actionModalListCellStyle}>{ele[2] ? ele[2] : '-'}</ListCell>
                      <ListCell sx={actionModalListCellStyle}>{ele[3] ? ele[3] : '-'}</ListCell>
                      <Box
                        sx={{
                          color: '#00BCBC',
                          textAlign: 'center',
                        }}
                      >
                        <CheckIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                  {dataTaskActionStaff?.errors_records?.map((ele) => (
                    <Box key={ele[3]} sx={actionModaListItemStyle}>
                      <ListCell sx={actionModalListCellStyle}>{ele[0] ? ele[0] : '-'}</ListCell>
                      <ListCell sx={actionModalListCellStyle}>{ele[1] ? ele[1] : '-'}</ListCell>
                      <ListCell sx={actionModalListCellStyle}>{ele[2] ? ele[2] : '-'}</ListCell>
                      <ListCell sx={actionModalListCellStyle}>{ele[4] ? ele[4] : '-'}</ListCell>
                      <Box
                        sx={{
                          color: themeColor,
                          textAlign: 'center',
                        }}
                      >
                        <ReportIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h4" sx={actionModalSubtitleStyle}>
                  {textContent.loadingTitle}
                </Typography>
                <Box component="p" sx={actionModalTextStyle}>
                  {textContent.loadingSubtitle}
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: isActionSuccess ? 'none' : 'flex',
            justifyContent: 'flex-end',
            marginTop: '40px',
          }}
        >
          <MainButton
            mainBtn
            textButton={textContent.dowloadButtonText}
            handleClick={() => {
              if (errorFile) {
                const url = window.URL.createObjectURL(errorFile);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'errors.xlsx';
                a.click();
                handleClose();
              }
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
