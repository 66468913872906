import React, { useState } from 'react';

import { Box } from '@mui/system';
import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

import { textAreaFormStyle, titleFormStyle } from 'ui/inputs/inputsForm';
import {
  modalHeaderStyle,
  modalTitleStyle,
  modalFormStyle,
  modalFormWrapStyle,
  modalAlarmIconStyle,
  modalListItemTextStyle,
  modalListItemBtnStyle,
} from 'ui/modals/modalGlobal';

import { MainButton } from 'shared/buttons/MainButton/MainButton';

import { mainBtnStyle } from 'ui/buttons/buttonsMain';
import { useSendToRevision } from 'hooks/documents/useSendToRevision';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';

export const SendRequestModal: React.FC<any> = ({ open, data, handleClose }) => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [comment, setComment] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();

  const formData: FormData = new FormData();

  const { handleSendToRevision } = useSendToRevision({
    uuid: data?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} sx={{
        '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '560px',
          },
    }}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            Создать запрос
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box component="form" sx={modalFormStyle}>
        <Box sx={() => modalFormWrapStyle(false)}>
          <Box sx={{ width: '100%' }}>
          <AutocompleteInput
                label="Тема запроса"
                placeholder="Тема запроса"
                data={[]}
                value={[] || ''}
            />
            <Box sx={{ width: '100%' }}>
              <TextField
                label="Укажите ваш комментарий"
                variant="standard"
                sx={{...textAreaFormStyle, borderLeft: 'none', borderRight: 'none', borderTop: 'none'}}
                multiline
                value={comment}
                rows={7}
                onChange={(evt) => {
                  setComment(evt.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ width: '28px', height: '28px', alignSelf: 'flex-start' }}
                      onClick={() => {
                        setComment('');
                      }}
                    >
                      <CloseOutlinedIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>

        <Typography variant="h4" sx={{...titleFormStyle, marginBottom: '6px' }}>
          Прикреплённые файлы
        </Typography>

        <Box>
          <List>
            {fileList.map((item, index) => {
              formData.append('files', item);
              const key = index;
              return (
                <ListItem sx={{ padding: '0', gap: '4px' }} key={key}>
                  <ListItemIcon sx={{ display: 'flex', alignSelf: 'center', minWidth: 0 }}>
                    <DescriptionIcon sx={modalAlarmIconStyle} />
                  </ListItemIcon>
                  <ListItemText sx={modalListItemTextStyle}>{item.name}</ListItemText>
                  <ListItemButton
                    sx={modalListItemBtnStyle}
                    onClick={() => {
                      const toDeleteItem = item.name;
                      fileList.indexOf(item) > -1 &&
                        setFileList(Array.from(fileList).filter((file) => !toDeleteItem.includes(file.name)));
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <CloseOutlinedIcon sx={modalAlarmIconStyle} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box>
          <Box
            sx={{
              paddingTop: '29px',
              display: 'flex',
              gap: '32px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Button component="label" sx={() => mainBtnStyle(false, true)}>
                <input
                  hidden
                  type="file"
                  onChange={(evt) => {
                    if (evt.target.files) {
                      setFileList([...fileList, evt.target.files[0]]);
                    }
                  }}
                />
                Прикрепить файл
              </Button>
            </Box>
           
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'end'
          }}>
          <MainButton
                textButton="Отмена"
                handleClick={() => {
                  formData.append('hr_status', 'sent_for_revision');
                  formData.append('comment', comment);
                  handleSendToRevision(formData);
                  handleClose();
                }}
              />
              <MainButton
                textButton="Отправить"
                mainBtn
                handleClick={() => {
                  formData.append('hr_status', 'sent_for_revision');
                  formData.append('comment', comment);
                  handleSendToRevision(formData);
                  handleClose();
                }}
              />
            </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
