/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Dialog, IconButton, TextField, Typography } from '@mui/material';
import {
  modalFormScrollStyle,
  modalFormStyle,
  modalFormWrapInputsStyle,
  modalHeaderStyle,
  modalTitleStyle,
} from 'ui/modals/modalGlobal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { inputFormStyle } from 'ui/inputs/inputsForm';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSendToRevision } from 'hooks/documents/useSendToRevision';
import { SendDocumentsModalProps } from 'interfaces/shared/modals/modalsInterfaces';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { useSnackbar } from 'notistack';
import { sendDocsParams, validationSendDocumentsModal } from './validationSendDocumentsModal';

export const SendDocumentsModal: React.FC<SendDocumentsModalProps> = ({ open, handleClose, data }) => {
  const { handleSubmit, setValue, register, reset } = useForm<sendDocsParams>({
    mode: 'onTouched',
    resolver: zodResolver(validationSendDocumentsModal),
  });

  const { enqueueSnackbar } = useSnackbar();

  const { handleSendToRevision } = useSendToRevision({
    uuid: data?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const onSubmit: SubmitHandler<sendDocsParams> = (values): void => {
    const formData: FormData = new FormData();
    formData.append('signed_with', 'hand');
    formData.append('hr_status', 'agreed');
    formData.append('contact_person', values.contact_person);
    formData.append('contact_phone', values.contact_phone);
    formData.append('address', values.address);
    handleSendToRevision(formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            Данные для отправки документов
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        component="p"
        sx={{
          margin: '0 0 0 24px',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
          color: 'rgba(62, 65, 77, 0.6)',
        }}
      >
        Выбран формат “Бумажный носитель”, требуются дополнительные данные.
      </Box>

      <Box component="form" sx={modalFormStyle} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={() => modalFormScrollStyle(600)}>
          <Box sx={modalFormWrapInputsStyle}>
            <TextField
              sx={() => inputFormStyle(false)}
              label="Адрес отправки документов"
              variant="standard"
              defaultValue={data?.contact_address}
              {...register('address')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ width: '28px', height: '28px' }}
                    onClick={() => {
                      setValue('address', '', { shouldDirty: true });
                    }}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '16px',
              marginTop: '12px',
            }}
          >
            <TextField
              sx={() => inputFormStyle(true)}
              label="Контактное лицо"
              variant="standard"
              defaultValue={data?.contact_person}
              {...register('contact_person')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ width: '28px', height: '28px' }}
                    onClick={() => {
                      setValue('contact_person', '', { shouldDirty: true });
                    }}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
            <TextField
              sx={() => inputFormStyle(true)}
              label="Контактный телефон"
              variant="standard"
              defaultValue={data?.contact_phone}
              {...register('contact_phone')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ width: '28px', height: '28px' }}
                    onClick={() => {
                      setValue('contact_phone', '', { shouldDirty: true });
                    }}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
              marginRight: '24px',
              marginTop: '20px',
            }}
          >
            <MainButton
              textButton="Отмена"
              handleClick={() => {
                reset();
                handleClose();
              }}
            />
            <MainButton
              textButton="Сохранить"
              mainBtn
              typeBtn="submit"
              handleClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
