import { Box, Button, Checkbox, ClickAwayListener } from '@mui/material';
import React, { useEffect } from 'react';
import { TooltipActions, TooltipActionsBtn, actionsIconFilled, tooltipBoxStyle } from 'ui/tooltips/tooltipAction';
import { TooltipCustomTableFieldsProps } from 'interfaces/patients/patientInterfaces';
import { mainBtnStyle } from 'ui/buttons/buttonsMain';
import { requiredTableFields, tableFieldsCollection } from 'utils/constants';
import { themeColor } from 'utils/uiConstants';

export const TooltipCustomTableFields: React.FC<TooltipCustomTableFieldsProps> = ({
  tableUserFields,
  handleTableFields,
  open,
  handleTableTooltip
}) => {

  const handleFieldClick = (field: {
    value: string,
    title: string
  }): void => {
    const index = tableUserFields?.indexOf(field?.value);
    if (index !== -1) {
      handleTableFields((prevArray) => {
        const newArray = [...prevArray];
        newArray.splice(index, 1);
        return newArray;
      });
    } else if (tableUserFields?.length >= 3) {
      handleTableFields((prevArray) => prevArray.slice(1));
      handleTableFields((prevArray) => [...prevArray, field?.value]);
    } else {
      handleTableFields((prevArray) => [...prevArray, field?.value]);
      
    }
  }

  useEffect(() => {
    sessionStorage.setItem('tableUserFields', JSON.stringify(tableUserFields))
  },[tableUserFields])
  return (
    <ClickAwayListener onClickAway={() => handleTableTooltip(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <TooltipActions
          open={open}
          placement="bottom-end"
          title={
            <>
              <Box sx={{ ...tooltipBoxStyle, maxHeight: '200px', overflowY: 'scroll' }}>
                {tableFieldsCollection?.map((field) => (
                  <TooltipActionsBtn
                    disabled={requiredTableFields?.some((item) => item?.value === field?.value)}
                    onClick={() => handleFieldClick(field)}
                    sx={{
                      paddingTop: '0 !important',
                      paddingBottom: '0 !important',
                      paddingLeft: '16px !important',
                    }}
                  >
                    <Checkbox
                      checked={
                        tableUserFields?.some((item) => item === field?.value) ||
                        requiredTableFields?.some((item) => item?.value === field?.value)
                      }
                    />
                    {field?.title}
                  </TooltipActionsBtn>
                ))}
              </Box>
              {tableUserFields?.length === 3 && (
                <Box
                  sx={{
                    position: 'absolute',
                    marginTop: '16px',
                    padding: '12px 16px 12px 16px',
                    borderRadius: '8px',
                    backgroundColor: themeColor,
                    color: '#FFFFFF',
                  }}
                >
                  Одновременно вы можете выбрать не более 3х дополнительных столбцов.
                </Box>
              )}
            </>
          }
        >
          <Button
            startIcon={actionsIconFilled}
            sx={() => mainBtnStyle(false, true, false)}
            onClick={() => handleTableTooltip(true)}
          >
            Дополнительные данные таблицы
          </Button>
        </TooltipActions>
      </Box>
    </ClickAwayListener>
  )
};
