import React from 'react';

import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { IFinancialStats } from 'interfaces/finance/financeInterfaces';
import { dataCurrentStyle, dataItemStyle, dataStyle, titleStyle } from 'ui/finance/financialStats';
import { AdditionalServicesBtn } from 'components/AdditionalServicesBtn.tsx/AdditionalServicesBtn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { themeColor } from 'utils/uiConstants';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { isActive } from 'itson/ItsOn';

export const formatNumber = (number: string): string =>
  number
    .split('')
    .reverse()
    .join('')
    .replace(/\d\d\d/g, '$& ')
    .split('')
    .reverse()
    .join('');

const FinancialStats: React.FC<IFinancialStats> = ({
  dataFinanceStats,
  isLoadingMainFinanceStats,
  isErrorMainFinanceStats,
  handleAdditionalServicesOpen,
}) => (
  <Box
    sx={{
      marginTop: '32px',
    }}
  >
    <Typography variant="h4" sx={titleStyle}>
      Общие финансовые показатели
    </Typography>

    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Stack>
        <Box component="ul" sx={dataStyle}>
          <Box component="li" sx={() => dataItemStyle()}>
            <Box component="p" sx={() => dataCurrentStyle(1)}>
              {!isLoadingMainFinanceStats && !isErrorMainFinanceStats && dataFinanceStats?.moneyTotal
                ? `${formatNumber(dataFinanceStats?.moneyTotal)} ₽`
                : '0 ₽'}
            </Box>
            <Box component="p" sx={() => dataCurrentStyle(0.6)}>
              Текущие затраты
            </Box>
          </Box>

          <Box component="li" sx={() => dataItemStyle(true)}>
            <Box component="p" sx={() => dataCurrentStyle(1)}>
              {!isLoadingMainFinanceStats && !isErrorMainFinanceStats && dataFinanceStats?.moneyFranchise
                ? `${formatNumber(dataFinanceStats?.moneyFranchise)} ₽`
                : '0 ₽'}
            </Box>
            <Box component="p" sx={() => dataCurrentStyle(0.6)}>
              Остаток мед. бюджета
            </Box>
          </Box>

          <Box component="li" sx={() => dataItemStyle()}>
            <Box component="p" sx={() => dataCurrentStyle(1)}>
              {!isLoadingMainFinanceStats && !isErrorMainFinanceStats && dataFinanceStats?.deposite
                ? `${formatNumber(dataFinanceStats?.deposite)} ₽`
                : '0 ₽'}
            </Box>
            <Box component="p" sx={() => dataCurrentStyle(0.6)}>
              Размер текущего мед. бюджета
            </Box>
          </Box>

          <Box component="li" sx={() => dataItemStyle()}>
            <Box component="p" sx={() => dataCurrentStyle(1)}>
              {!isLoadingMainFinanceStats && !isErrorMainFinanceStats && dataFinanceStats?.budget
                ? `${formatNumber(dataFinanceStats?.budget)} ₽`
                : '0 ₽'}
            </Box>
            <Box component="p" sx={() => dataCurrentStyle(0.6)}>
              Запланированный годовой мед. бюджет
            </Box>
          </Box>
        </Box>
      </Stack>
      {isActive('hr-518.additional_services_modal') && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap="14px">
          <AdditionalServicesBtn handleClick={() => handleAdditionalServicesOpen(true)} />
          <TooltipActionsInfo placement="bottom" title="Из средств депозита">
            <InfoOutlinedIcon fontSize="small" sx={{ color: themeColor, height: '20px', width: '20px' }} />
          </TooltipActionsInfo>
        </Stack>
      )}
    </Stack>
  </Box>
);

export { FinancialStats };
