import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';

import { ListProgramResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/program/types';

import queryString from 'query-string';
import {
  SearchProgramsHook,
  SearchProgramsHookData,
  SlotsPrograms,
  UseSearchProgramsQueryParams,
} from 'interfaces/hooks/programsHooksInterfaces';
import { getFormattedDate } from 'utils/dateHelpers';
import { FetchError } from 'utils/FetchError';

export const useSearchPrograms = ({
  company,
  period,
  selectedProgram,
  selectedServices,
  selectedСity,
  isProgramsAllowed,
  onSuccess,
  onError
}: SearchProgramsHook): SearchProgramsHookData => {
  const companyUuid: string = company?.uuid || '';
  const periodId: string = period?.uuid || '';
  const programId: string = selectedProgram?.uuid || '';
  const valuesServices: string = selectedServices.length ? selectedServices.map((el) => el.value).join(',') : '';
  const cityId: string = selectedСity?.uuid || '';

  const { isFetching, isLoading, error, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['dataPrograms', companyUuid, periodId, programId, valuesServices, cityId],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      const queryParams: UseSearchProgramsQueryParams = {
        page: pageParam,
        per_page: 200,
      };

      if (companyUuid) {
        queryParams.company = companyUuid;
      }
      if (periodId) {
        queryParams.service_period = periodId;
      }

      if (programId) {
        queryParams.uuid = programId;
      }

      if (valuesServices) {
        queryParams.service_types = valuesServices;
      }

      if (cityId) {
        queryParams.city = cityId;
      }

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/programs/?${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!companyUuid && isProgramsAllowed,
      getNextPageParam: (lastPage: ListProgramResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      onError,
      onSuccess,
      select: (data: {
        pageParams: unknown[];
        pages: ListProgramResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'][];
      }) => {
        const currentIndex = data.pages.length - 1
        const pageParams = data.pages[currentIndex].meta;

        const pages: ListProgramResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data'][] =
          data.pages.map(
            (page: ListProgramResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => page.data
          );

        const slotsPrograms: SlotsPrograms[] = [];

        pages.forEach((arr: ListProgramResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data']) => {
          arr?.forEach((item) => slotsPrograms.push(item));
        });

        const programs = slotsPrograms.map((item: SlotsPrograms) => {
          const startDate = getFormattedDate(item.service_period?.start_date, 'dd.MM.yyyy');
          const endDate = getFormattedDate(item.service_period?.start_date, 'dd.MM.yyyy');

          const stringServiceTypes = item.service_types?.map((el) => el.title).join('.');

          return {
            id: item.id,
            programUuid: item.uuid,
            programTitle: item.title,
            companyTitle: item.company?.title,
            serviceTypes: item.service_types,
            stringServiceTypes,
            city: item.city?.title || '',
            cityUuid: item.city?.uuid || '',
            budget: Number(item.insurance_premium) || 0,
            paidSlots: item.service_period?.paid_slots ? item.service_period.paid_slots : 0,
            medicalProgram: item.medical_program_file,
            startDate,
            endDate,
            allowedSlotPatientTypes: item.allowed_slot_patient_types?.map(type => type.value) || null,
            period: `${startDate} - ${endDate}`,
            isVzr: item.is_vzr_cost_included_in_program_override,
            slotsCount: item.slots_count,
          };
        });

        return { programs, pageParams };
      },
    }
  );

  const isLoadingSearchPrograms = isLoading;
  const isErrorSearchPrograms = error;
  const hasNextPageSearchPrograms = hasNextPage;
  const isFetchingNextPageSearchPrograms = isFetchingNextPage;
  const isFetchingSearchPrograms = isFetching;
  const refetchSearchPrograms = refetch;

  return {
    isLoadingSearchPrograms,
    isErrorSearchPrograms,

    fetchNextPage,

    hasNextPageSearchPrograms,
    isFetchingNextPageSearchPrograms,
    isFetchingSearchPrograms,

    refetchSearchPrograms,
  };
};
