import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import {
  NotificationsUnviewedHook,
  NotificationsUnviewedHookData,
} from 'interfaces/notifications/notificationsInterfaces';

import { getPagesQuantity } from 'utils/hooksHelpers';
import { ListUserNotificationResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/notifications/types';
import { FetchError } from 'utils/FetchError';

export const useUnviewedNotifications = ({
  enabled,
  onSuccess,
  onError,
}: NotificationsUnviewedHook): NotificationsUnviewedHookData => {
  const { isFetching, isLoading, error, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['dataUnviewedNotifications'],
    async ({ pageParam = 1 }) => {
      const token = localStorage.getItem('token');

      if (!token) return;

      await handleRefreshToken();

      const res = await fetch(`${API_URL}/notifications/?page=${pageParam}&per_page=200`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      enabled,
      getNextPageParam: (
        lastPage: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
      ) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number | undefined = lastPage.meta?.page;
        let nextPage = 0;
        if (currentPage) {
          nextPage = currentPage + 1;
        }

        return nextPage <= allPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onError,
      onSuccess,
      select: (data: {
        pages: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'][];
        pageParams: unknown[];
      }) => {
        const pageParams = data;
        const pages: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data'][] =
          data.pages.map(
            (page: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => page.data
          );

        // Any из за короеда
        const slotsNotification = pages
          .flat()
          .filter((obj: any | undefined) => obj?.status?.value === 'unviewed' || obj?.meta?.priority === 'urgent');
        return { slotsNotification, pageParams };
      },
    }
  );

  return {
    isLoadingSearchUnviewedNotifications: isLoading,
    isErrorSearchUnviewedNotifications: !!error,

    fetchNextPageUnviewedNotifications: fetchNextPage,

    hasNextPageSearchUnviewedNotifications: hasNextPage,
    isFetchingNextPageSearchUnviewedNotifications: isFetchingNextPage,
    isFetchingSearchUnviewedNotifications: isFetching,

    refetchSearchUnviewedNotifications: refetch,
  };
};
