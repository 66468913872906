import { useQuery } from 'react-query';
import queryString from 'query-string';
import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import {
  ExportPatientsToExcelData,
  ExportPatientsToExcelQueryParams,
  GetExportPatientsToExcelParams,
} from 'interfaces/hooks/patientsHooksInterfaces';
import { format } from 'date-fns';
import { DataPeriod } from 'interfaces/global/globalInterfaces';

export const useGetExportPatientsToExcel = ({
  userStartDate,
  userEndDate,
  companies,
  period,
  fullname,
  programs,
  patient,
  types,
  activePeriods,
  onSuccess,
  onError,
}: GetExportPatientsToExcelParams): ExportPatientsToExcelData => {
  const patientId: string = patient?.patientUuid || '';
  const programsUuid: string = programs && !!programs.length ? programs.map((item) => item.value).join(',') : '';
  const typesArr = types ? types.map((item) => item.value) : null;
  const activePeriodsUuids = activePeriods
    ?.reduce((acc: string[], item: DataPeriod) => {
      if (item.activePeriod) {
        acc.push(item.uuid);
      }
      return acc;
    }, [])
    .join(',');

  const mainFilterPeriodStaff =
    'mainFilterPeriodStaff' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '') : '';

  const getServicePeriodsId = (): string | null | undefined => {
    if (!companies) {
      return undefined;
    }

    if (companies.length !== 1) {
      return activePeriodsUuids;
    }

    return undefined;
  };

  const startDateGte = (): string | null => {
    let startDate;
    if (userStartDate) {
      startDate = format(userStartDate, 'yyyy-MM-dd');
    } else if (period) {
      startDate = null;
    }
    return startDate;
  };

  const startDateLte = (): string | null => {
    let endDate;
    if (userEndDate) {
      endDate = format(userEndDate, 'yyyy-MM-dd');
    } else if (period) {
      endDate = null;
    }
    return endDate;
  };

  const {
    isLoading,
    error,

    refetch,
  } = useQuery(
    [
      'dataExportPatientsToExcel',
      userStartDate,
      userEndDate,
      companies,
      period,
      fullname,
      programs,
      patient,
      types,
      activePeriods,
    ],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const queryParams: ExportPatientsToExcelQueryParams = {
        start_date__gte: startDateGte(),
        start_date__lte: startDateLte(),
        patient: patientId,
        service_period__in: companies.length === 1 ? mainFilterPeriodStaff.uuid : getServicePeriodsId(),
        program__in: programsUuid,
        search: fullname,
        status: typesArr,
      };

      const res = await fetch(`${API_URL}/slots/export_slots_to_excel/?${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      WindowFocus: false,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  return {
    isLoadingExportPatientsToExcel: isLoading,
    isErrorExportPatientsToExcel: !!error,

    refetchExportPatientsToExcel: refetch,
  };
};
