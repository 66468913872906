import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const CustomBtn = styled(IconButton)({
  border: '1px solid #ff6d6d',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'rgba(255, 109, 109, 0.1)',
  },
  '&:active': {},
  '&:focus': {},
  '& > svg': {
    color: '#ff6d6d',
  },
  '&.Mui-disabled': {
    border: '1px solid rgba(62, 65, 77, 0.6)',
  },
  '&.Mui-disabled > svg': {
    color: 'rgba(62, 65, 77, 0.6)',
  },
});
