import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ff6d6d',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Relative';
          src: url('../../assets/fonts/Relative-BookItalic.eot');
          src: local('Relative Book Italic'), local('Relative-BookItalic'),
            url('../../assets/fonts/Relative-BookItalic.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/Relative-BookItalic.woff2') format('woff2'),
            url('../../assets/fonts/Relative-BookItalic.woff') format('woff'),
            url('../../assets/fonts/Relative-BookItalic.ttf') format('truetype');
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Relative';
          src: url('../../assets/fonts/Relative-BoldItalic.eot');
          src: local('Relative Bold Italic'), local('Relative-BoldItalic'),
            url('../../assets/fonts/Relative-BoldItalic.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/Relative-BoldItalic.woff2') format('woff2'),
            url('../../assets/fonts/Relative-BoldItalic.woff') format('woff'),
            url('../../assets/fonts/Relative-BoldItalic.ttf') format('truetype');
          font-weight: bold;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'Relative';
          src: url('../../assets/fonts/Relative-Bold.eot');
          src: local('Relative Bold'), local('Relative-Bold'),
            url('../../assets/fonts/Relative-Bold.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/Relative-Bold.woff2') format('woff2'),
            url('../../assets/fonts/Relative-Bold.woff') format('woff'),
            url('../../assets/fonts/Relative-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Relative';
          src: url('../../assets/fonts/Relative-Book.eot');
          src: local('Relative Book'), local('Relative-Book'),
            url('../../assets/fonts/Relative-Book.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/Relative-Book.woff2') format('woff2'),
            url('../../assets/fonts/Relative-Book.woff') format('woff'),
            url('../../assets/fonts/Relative-Book.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'TRY Vesterbro';
          src: url('../../assets/fonts/TRYVesterbro-Bold.eot');
          src: local('TRY Vesterbro Bold'), local('TRYVesterbro-Bold'),
            url('../../assets/fonts/TRYVesterbro-Bold.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/TRYVesterbro-Bold.woff2') format('woff2'),
            url('../../assets/fonts/TRYVesterbro-Bold.woff') format('woff'),
            url('../../assets/fonts/TRYVesterbro-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'TRY Vesterbro';
          src: url('../../assets/fonts/TRYVesterbro-Light.eot');
          src: local('TRY Vesterbro Light'), local('TRYVesterbro-Light'),
            url('../../assets/fonts/TRYVesterbro-Light.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/TRYVesterbro-Light.woff2') format('woff2'),
            url('../../assets/fonts/TRYVesterbro-Light.woff') format('woff'),
            url('../../assets/fonts/TRYVesterbro-Light.ttf') format('truetype');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'TRY Vesterbro';
          src: url('../../assets/fonts/TRYVesterbro-Regular.eot');
          src: local('TRY Vesterbro'), local('TRYVesterbro-Regular'),
            url('../../assets/fonts/TRYVesterbro-Regular.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/TRYVesterbro-Regular.woff2') format('woff2'),
            url('../../assets/fonts/TRYVesterbro-Regular.woff') format('woff'),
            url('../../assets/fonts/TRYVesterbro-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'TRY Vesterbro';
          src: url('../../assets/fonts/TRYVesterbro-Medium.eot');
          src: local('TRY Vesterbro Medium'), local('TRYVesterbro-Medium'),
            url('../../assets/fonts/TRYVesterbro-Medium.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/TRYVesterbro-Medium.woff2') format('woff2'),
            url('../../assets/fonts/TRYVesterbro-Medium.woff') format('woff'),
            url('../../assets/fonts/TRYVesterbro-Medium.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Relative';
          src: url('../../assets/fonts/Relative-Medium.eot');
          src: local('Relative Medium'), local('Relative-Medium'),
            url('../../assets/fonts/Relative-Medium.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/Relative-Medium.woff2') format('woff2'),
            url('../../assets/fonts/Relative-Medium.woff') format('woff'),
            url('../../assets/fonts/Relative-Medium.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'TRY Vesterbro Poster';
          src: url('../../assets/fonts/TRYVesterbro-Poster.eot');
          src: local('TRY Vesterbro Poster'), local('TRYVesterbro-Poster'),
            url('../../assets/fonts/TRYVesterbro-Poster.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/TRYVesterbro-Poster.woff2') format('woff2'),
            url('../../assets/fonts/TRYVesterbro-Poster.woff') format('woff'),
            url('../../assets/fonts/TRYVesterbro-Poster.ttf') format('truetype');
          font-weight: 900;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'TRY Vesterbro';
          src: url('../../assets/fonts/TRYVesterbro-Extrabold.eot');
          src: local('TRY Vesterbro Extrabold'), local('TRYVesterbro-Extrabold'),
            url('../../assets/fonts/TRYVesterbro-Extrabold.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/TRYVesterbro-Extrabold.woff2') format('woff2'),
            url('../../assets/fonts/TRYVesterbro-Extrabold.woff') format('woff'),
            url('../../assets/fonts/TRYVesterbro-Extrabold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Relative';
          src: url('../../assets/fonts/Relative-MediumItalic.eot');
          src: local('Relative Medium Italic'), local('Relative-MediumItalic'),
            url('../../assets/fonts/Relative-MediumItalic.eot?#iefix') format('embedded-opentype'),
            url('../../assets/fonts/Relative-MediumItalic.woff2') format('woff2'),
            url('../../assets/fonts/Relative-MediumItalic.woff') format('woff'),
            url('../../assets/fonts/Relative-MediumItalic.ttf') format('truetype');
          font-weight: 500;
          font-style: italic;
          font-display: swap;
        }
        `,
    },
  },
  typography: {
    fontFamily: [
      'Relative',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'antarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),

    h1: {},
    h2: {},
    h3: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.01em',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.02em',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.02em',
    },
    h6: {},
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.02em',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.02em',
    },
    body1: {},
    body2: {},
    button: {},
    caption: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.02em',
    },
    overline: {},
  },
});
