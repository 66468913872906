export const dialogStyleAddModal = {
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '800px',
  },
};

export const dialogChildrenModal = {
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '600px',
  },
};
