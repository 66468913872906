import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import { ListUserNotificationResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/notifications/types';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import {
  NotificationsHook,
  NotificationsHookData,
  NotificationsHookPaginationResponseData,
} from 'interfaces/notifications/notificationsInterfaces';

import { getPagesQuantity } from 'utils/hooksHelpers';
import { NotificationsExtendedUserNotificationRq } from '@bestdoctor/core-arch-api/models';
import { NotificationsQueryParams } from 'interfaces/hooks/notificationsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useNotifications = ({
  selectedCompanies,
  period,
  priority,

  statusNotifications,
  status,
  ordering,
  onSuccess,
  onError,
}: NotificationsHook): NotificationsHookData => {
  const company_uuid = selectedCompanies?.uuid || '';
  const created_at__gte = period?.dateGte || '';
  const created_at__lte = period?.dateLte || '';

  const { isFetching, isLoading, isError, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [
      'dataNotifications',
      company_uuid,
      created_at__gte,
      created_at__lte,
      priority,
      status,
      statusNotifications,

      ordering,
    ],

    async ({ pageParam = 1 }) => {
      const token = localStorage.getItem('token');
      if (!token) return;

      await handleRefreshToken();

      const queryParams: NotificationsQueryParams = {
        company_uuid: selectedCompanies?.uuid || '',
        created_at__gte,
        created_at__lte,
        priority,
        status: status ? 'unviewed' : '',
        notification_type: statusNotifications,
        ordering,

        page: pageParam,
        per_page: 20,
      };

      const obj = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const key in queryParams) {
        if (queryParams[key]) obj[key] = queryParams[key];
      }

      const res = await fetch(`${API_URL}/notifications/?${queryString.stringify(obj)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      getNextPageParam: (lastPage: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor']) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onError,
      onSuccess,
      select: (data: NotificationsHookPaginationResponseData) => {
        const pageParams = data;
        const slotsNotification: (NotificationsExtendedUserNotificationRq | undefined)[] = data?.pages
          ?.map((page) => page?.data)
          .flat();

        return { slotsNotification, pageParams };
      },
    }
  );

  return {
    isLoadingNotifications: isLoading,
    isErrorNotifications: isError,

    fetchNextPageNotifications: fetchNextPage,

    hasNextPageNotifications: hasNextPage,
    isFetchingNextPageNotifications: isFetchingNextPage,
    isFetchingNotifications: isFetching,

    refetchNotifications: refetch,
  };
};
