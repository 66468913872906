import React, { useCallback, useState } from 'react';
import { Box } from '@mui/system';

import { BorderLinearProgress } from 'components/Loader/Loader';
import { InfoText } from 'ui/other/Typography';
import { MainAccordion } from 'shared/accordions/MainAccordion/MainAccordion';

import { useCompanyActions } from 'hooks/companies/useCompanyActions';

import { IAccordionHistory, IEventTypes } from 'interfaces/patients/patientInterfaces';

import { eventTypes } from 'utils/constants';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { FiltersHistory } from './FiltersHistory/FiltersHistory';

const AccordionHistory: React.FC<IAccordionHistory> = ({ selectedCompanies,isSlotsAviable }) => {
  // параметры в фильтре поиска
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [eventType, setEventType] = useState<IEventTypes[]>([]);
  const [orderingTableHistory, setOrderingTableHistory] = useState<string>('');

  const [expanded, setExpanded] = useState<string | false>(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    CompanyActionsData,
    isLoadingCompanyActions,
    isErrorCompanyActions,
    hasNextPageCompanyActions,
    isFetchingNextPageCompanyActions,

    fetchNextPageCompanyActions,
  } = useCompanyActions({
    isSlotsAviable,
    companies: selectedCompanies,

    startDate,
    endDate,
    eventType,
    ordering: orderingTableHistory,

    patient: '',
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? 'panel1' : false);
    },
    []
  )

  if (isErrorCompanyActions) {
    return (
      <InfoText variant="h3">
        Что-то пошло не так. Не удалось получить данные истории операций. Попробуйте позже
      </InfoText>
    );
  }
  return (
    <>
      {isLoadingCompanyActions && <BorderLinearProgress />}

      <Box component="section">
        <MainAccordion
          title="История операций"
          expanded={expanded}
          handleChange={handleChange}
        >
          <FiltersHistory
            historyActions={CompanyActionsData || []}
            hasNextPageCompanyActions={hasNextPageCompanyActions}
            isFetchingNextPageCompanyActions={isFetchingNextPageCompanyActions}
            eventTypes={eventTypes}
            startDate={startDate}
            endDate={endDate}
            handleStartDate={setStartDate}
            handleEndDate={setEndDate}
            eventType={eventType}
            handleEventType={setEventType}
            isLoadingCompanyActions={isLoadingCompanyActions}
            fetchNextPageCompanyActions={fetchNextPageCompanyActions}
            orderingTableHistory={orderingTableHistory}
            handleOrderingTableHistory={setOrderingTableHistory}
          />
        </MainAccordion>
      </Box>
    </>
  );
};

export { AccordionHistory };
