import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { ListCompanyServicePeriodResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-service-periods/types';
import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { AutoCompleteInputData } from 'interfaces/global/globalInterfaces';
import { getFormattedDate } from 'utils/dateHelpers';
import { relativeNamesMap, relativeTypesMap } from 'utils/constants';
import { ServicePeriodsCompanyServicePeriodHrDetailRq } from '@bestdoctor/core-arch-api/models';
import { FetchError } from 'utils/FetchError';
import { GetServicePeriodsData, GetServicePeriodsParams } from 'interfaces/hooks/periodsHooksInterfaces';

export const useGetServicePeriods = ({
  companies,
  onSuccess,
  onError,
}: GetServicePeriodsParams): GetServicePeriodsData => {
  let params = '';

  if (companies.length === 1) {
    params += `?company=${companies[0].uuid}`;
  } else if (companies.length > 1) {
    params += `?company__in=${companies.map((company) => company.uuid).join()}`;
  }

  const {
    isLoading,
    error,
    data: dataServicePeriods,
  } = useQuery(
    ['dataServicePeriods', companies],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/service_periods/${params}&per_page=200`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!params,
      onSuccess,
      onError,
      select: (
        servicePeriodsData
      ): ListCompanyServicePeriodResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'] =>
        servicePeriodsData?.data?.map((item: ServicePeriodsCompanyServicePeriodHrDetailRq) => {
          const startDate = getFormattedDate(item.start_date, 'dd.MM.yyyy');
          const endDate = getFormattedDate(item.end_date, 'dd.MM.yyyy');

          let relativeCategoriesArr: AutoCompleteInputData[] | [] = [];

          if (item.relatives_categories) {
            const relativeTypesCurrent = Object.keys(relativeTypesMap);

            const relativeTypesValuesCurrent = Object.values(relativeTypesMap);

            const relativeNamesValuesCurrent = Object.values(relativeNamesMap);

            const filteredCategories = item.relatives_categories.filter((category) => category.value !== 'other');

            const relativeCategories = filteredCategories?.map(
              (
                res: {
                  text: string;
                  value: string;
                },
                index: number
              ) => {
                const currentIndex = relativeTypesCurrent.indexOf(res.value);

                return {
                  uuid: index,
                  title: relativeNamesValuesCurrent[currentIndex],
                  value: relativeTypesValuesCurrent[currentIndex],
                };
              }
            );
            relativeCategoriesArr = Array.from(relativeCategories);
          }

          return {
            title: `${startDate} - ${endDate} ${item.contract_num ? `Договор №${item.contract_num}` : ''} ${
              item.is_active ? '(Активный период)' : ''
            }`,
            startDate: format(new Date(item.start_date), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(new Date(item.end_date), 'yyyy-MM-dd HH:mm:ss'),
            uuid: item.uuid,
            activePeriod: item.is_active,
            accountFullName: item.account_user_full_name,
            curatorPhone: item.holding_doctor_curator_phone,
            curatorEmail: item.holding_doctor_curator_email,
            accountPhone: item.account_user_phone,
            accountEmail: item.account_user_email,
            contractType: item.contract_type,
            contract: item.contract,
            doctorFullName: item.holding_doctor_curator_full_name,
            doctorEmail: item.holding_doctor_curator_email,
            canAttachOrDetachMaxDate: item.can_attach_or_detach_max_date,
            canAttachSince: item.can_attach_since_days,
            canDetachSince: item.can_detach_since_days,
            relativesInsuranceType: item.relatives_insurance_type?.value,
            tariff: item.tariff,
            relativeCategories: relativeCategoriesArr || [],
            status: item?.status,
          };
        }),
    }
  );

  return {
    isLoadingServicePeriods: isLoading,
    isErrorServicePeriods: error,
    dataServicePeriods,
  };
};
