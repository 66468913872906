import { SessionHookData } from 'interfaces/hooks/globalHooksInterfaces';
import { SessionHookParams, UseSessionResData } from 'interfaces/hooks/sessionHooksInterfaces';
import { useQuery } from 'react-query';
import { FetchError } from 'utils/FetchError';

import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

export const useSession = ({ onError }: SessionHookParams): SessionHookData => {
  const { isLoading, error } = useQuery(
    ['session'],
    async () => {
      const res = await fetch(`${API_URL}/auth/session/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      onError,
      onSuccess: ({ data }: UseSessionResData) => {
        const rExp = new RegExp(`${API_URL}/auth/|/login/`, 'gi');
        const keySession: string | undefined = data.login_url?.replace(rExp, '');

        if (keySession) {
          localStorage.setItem('keySession', keySession);
        }
      },
    }
  );

  return {
    isLoadingSession: isLoading,
    isErrorSession: !!error,
  };
};
