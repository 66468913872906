import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Box, Button } from '@mui/material';
import { TabContext } from '@mui/lab';
import { CustomTab, CustomTabs } from 'ui/tabs/tabs';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteIcon from '@mui/icons-material/Delete';

import { BorderLinearProgress } from 'components/Loader/Loader';

import { MainButton } from 'shared/buttons/MainButton/MainButton';

import { boxNavStyle } from 'ui/navigation/navigation';
import { mainBtnStyle } from 'ui/buttons/buttonsMain';
import { grayDarkColor } from 'utils/uiConstants';

import { useGetCompanies } from 'hooks/companies/useGetCompanies';
import { Company, ErrorData } from 'interfaces/global/globalInterfaces';

import { NotificationFilters } from 'components/NotificationsList/NotificationFilters';
import { NotificationTable } from 'components/NotificationsList/NotificationTable';
import {
  Notification,
  NotificationFilterByPeriod,
  ParamsPriorityFilter,
} from 'interfaces/notifications/notificationsInterfaces';
import { useNotifications } from 'hooks/notifications/useNotifications';
import { throttle } from 'utils/tablesMethods';
import { useViewedAllNotifications } from 'hooks/notifications/useViewedAllNotifications';
import { useRemoveNotifications } from 'hooks/notifications/useRemoveNotifications';
import { isActive } from 'itson/ItsOn';
import { useSnackbar } from 'notistack';

export const Notifications: React.FC = () => {
  const queryClient = useQueryClient();

  const [notificationsData, setNotificationsData] = useState<Notification[] | null>(null);
  const [allCompanies, setCompanies] = useState<Company[]>([]);
  const [menuValue, setMenuValue] = useState<'' | 'payments' | 'status_change'>('');

  // check уведомлений
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForViewed, setSelectedForViewed] = useState<string[]>([]);

  // стейты главных фильтров
  const [selectedCompaniesInNotifications, setSelectedCompaniesInNotifications] = useState<Company | null>(null);
  const [notificationFilterByPeriod, setNotificationFilterByPeriod] = useState<NotificationFilterByPeriod | null>(null);
  const [priority, setPriority] = useState<ParamsPriorityFilter | null>(null);
  const [unviewed, setUnviewed] = useState<boolean>(false);
  const [orderingTableNotifications, setOrderingTableNotifications] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar()

  const { isLoadingCompanies, fetchNextPageCompanies } = useGetCompanies({
    onSuccess: ({ pageParams, companiesSearch }) => {
      const allPage: number = pageParams?.pages[0]?.meta
        ? Math.ceil(pageParams.pages[0].meta.total / pageParams.pages[0].meta.per_page)
        : 0;

      if (companiesSearch) setCompanies(companiesSearch);

      if (pageParams.pages.length < allPage) fetchNextPageCompanies();
    },
  });

  const {
    isLoadingNotifications,
    fetchNextPageNotifications,
    hasNextPageNotifications,
    isFetchingNextPageNotifications,
  } = useNotifications({
    statusNotifications: menuValue,
    selectedCompanies: selectedCompaniesInNotifications,
    period: notificationFilterByPeriod,
    priority: priority?.value || '',
    status: unviewed,
    ordering: orderingTableNotifications,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
    onSuccess: (data: { slotsNotification: Notification[]; pageParams: unknown[] }) =>
      setNotificationsData(data.slotsNotification),
  });

  const { handleViewedAllNotifications } = useViewedAllNotifications({
    notificationUuids: selectedForViewed,
    onSuccess: () => {
      setSelected([]);
      setSelectedForViewed([]);
      queryClient.invalidateQueries('dataUnviewedNotifications');
    },
  });

  const { handleRemoveNotifications } = useRemoveNotifications({
    onSuccess: () => {
      setSelected([]);
      setSelectedForViewed([]);
      queryClient.invalidateQueries('dataUnviewedNotifications');
    },
  });

  const handleScroll = async (evt: Event): Promise<void> => {
    const { scrollHeight, scrollTop } = (evt.target as Document).documentElement;
    const windowHeight = window.innerHeight;

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    if (scrollHeight - (windowHeight + scrollTop) < 50) {
      await fetchNextPageNotifications();
    }
  };

  useEffect(() => {
    if (hasNextPageNotifications && !isFetchingNextPageNotifications) {
      document.addEventListener('scroll', throttle(handleScroll, 1000));
    }

    return () => document.removeEventListener('scroll', throttle(handleScroll, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPageNotifications, isFetchingNextPageNotifications]);

  return (
    <>
      {(isLoadingCompanies || isLoadingNotifications) && <BorderLinearProgress />}
      <TabContext value={menuValue}>
        <Box component="section" sx={{ ...boxNavStyle, gap: '17px' }}>
          <Link to="/staff" style={{ textDecoration: 'none' }}>
            <Button
              sx={{ ...mainBtnStyle(), color: grayDarkColor }}
              variant="text"
              startIcon={<KeyboardBackspaceIcon sx={{ width: '16px' }} />}
            >
              Назад
            </Button>
          </Link>
          <CustomTabs
            sx={{ marginRight: 'auto' }}
            value={menuValue}
            onChange={(_event: React.SyntheticEvent, newValue: '' | 'payments' | 'status_change'): void =>
              setMenuValue(newValue)
            }
            aria-label="Табы"
          >
            <CustomTab value="" label="Все уведомления" />
            {isActive('hr-351.notification_features') && (
              <CustomTab value="slot_action" label="Операции с пациентами" />
            )}
            <CustomTab value="payments" label="Платежи" />

            <CustomTab value="status_change" label="Смена статуса ДС" />
          </CustomTabs>

          <MainButton
            border
            icon={<DoneAllIcon sx={{ width: '16px' }} />}
            textButton="Прочитать выбранные"
            handleClick={handleViewedAllNotifications}
            disabled={selectedForViewed.length === 0}
          />
          <MainButton
            border
            icon={<DeleteIcon sx={{ width: '16px' }} />}
            textButton="Удалить выбранные"
            handleClick={() => handleRemoveNotifications(selected)}
            disabled={selectedForViewed.length === 0}
          />
        </Box>
        <NotificationFilters
          allCompanies={allCompanies}
          selectedCompaniesInNotifications={selectedCompaniesInNotifications}
          notificationFilterByPeriod={notificationFilterByPeriod}
          priority={priority}
          unviewed={unviewed}
          setSelectedCompaniesInNotifications={setSelectedCompaniesInNotifications}
          setNotificationFilterByPeriod={setNotificationFilterByPeriod}
          setPriority={setPriority}
          setUnviewed={setUnviewed}
        />

        {notificationsData && !!notificationsData?.length && (
          <NotificationTable
            notificationsData={notificationsData}
            isFetchingNextPageNotifications={isFetchingNextPageNotifications}
            orderingTableNotifications={orderingTableNotifications}
            selected={selected}
            selectedForViewed={selectedForViewed}
            setOrderingTableNotifications={setOrderingTableNotifications}
            setSelected={setSelected}
            setSelectedForViewed={setSelectedForViewed}
          />
        )}
      </TabContext>
    </>
  );
};
