import { SystemStyleObject, Theme } from "@mui/system";

export const accordionSummaryStyle: SystemStyleObject<Theme> = {
    width: 'min-content',
    whiteSpace: 'nowrap',
    padding: '0',
    '&.MuiAccordionSummary-root': {
        minHeight: '0',
    },
    '&.MuiAccordionSummary-content': {
        margin: '0',
    },
    '& .MuiAccordionSummary-contentGutters': {
        margin: '0',
    },
    '&.css-o4b71y-MuiAccordionSummary-content': {
        margin: '0',
    },
    '&.Mui-expanded': {
        margin: '0',
        '&.MuiAccordionSummary-content': {
            margin: '0',
        },
        '& .MuiAccordionSummary-contentGutters': {
            margin: '0',
        },
        '&.css-o4b71y-MuiAccordionSummary-content': {
            margin: '0',
        },
    },
}