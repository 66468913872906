import React, { useEffect, useState } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  ClickAwayListener,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuIcon from '@mui/icons-material/Menu';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { TooltipActionBtns } from 'shared/tooltip/TooltipActionBtns/TooltipActionBtns';
import { ActionDocModal } from 'shared/modals/ActionDocModal/ActionDocModal';
import { ChartPaymentsDocsModal } from 'shared/modals/ChartPaymentsDocsModal/ChartPaymentsDocsModal';

import { nextSortState, throttle } from 'utils/tablesMethods';

import {
  tableCellStyle,
  tableDocsCellStyle,
  tableDocsStatusCellStyle,
  tableDocsIconStyle,
  tableSortLabelStyle,
  tableRowStyle,
  tableCellDocsStatusStyle,
} from 'ui/tables/tables';

import {
  IListAddAgreementAndPayments,
  IListAddAgreementAndPaymentsTableHeading,
  IListAddAgreementAndPaymentsEnhancedTableHead,
  TOrder,
} from 'interfaces/documents/documentsInterfaces';
import { DocumetsChangeHistory } from 'shared/modals/DocumetsChangeHistory/DocumetsChangeHistory';
import { TooltipAppendixDocs } from 'shared/tooltip/TooltipAppendixDocs/TooltipAppendixDocs';
import { DocumentViewModal } from 'shared/modals/DocumentViewModal/DocumentViewModal';
import { ApproveDocumentModal } from 'shared/modals/ApproveDocumentModal/ApproveDocumentModal';
import { SendDocumentsModal } from 'shared/modals/SendDocumentsModal/SendDocumentsModal';
import { CancelConfirmModal } from 'shared/modals/ActionDocModal/CancelConfirmModal';
import { Loader } from 'shared/loaders/Loader';
import { agreementStatus } from 'utils/agreementStatus';
import { downloadDoc } from 'utils/downloadDoc';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { Sorting } from 'interfaces/other/otherInterfaces';
import { AccessList, DefaultPermissions } from 'interfaces/global/globalInterfaces';

interface IListAddAgreementAndPaymentsProps {
  handleOnScroll: (value: boolean) => void;
  onScrollOpen: boolean;
  supplementaryAgreements: IListAddAgreementAndPayments[];
  fetchNextPage: () => void;
  isFetchingNextPageSupplementaryAgreements: boolean;
  hasNextPageSupplementaryAgreements: boolean;
  handleOrderingAgreement: (value: string) => void;
  orderingAgreement: string;
  accessList: AccessList[];
  defaultPermissions: DefaultPermissions;
  refetchSupplementaryAgreements: () => void;
}

function createData(
  uuid: string,
  number: string,
  supplementary_agreement_file: string,
  appendix: string | number,
  payments: string,
  hr_status: string,
  signed_with: string,
  contact_address: string,
  contact_phone: string,
  contact_person: string,
  original_file: string,
  original_bill_file_url: string
): IListAddAgreementAndPayments {
  return {
    uuid,
    number,
    supplementary_agreement_file,
    appendix,
    payments,
    hr_status,
    signed_with,
    contact_address,
    contact_phone,
    contact_person,
    original_file,
    original_bill_file_url,
  };
}

const heading: readonly IListAddAgreementAndPaymentsTableHeading[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    label: 'Наименование документа',
  },
  {
    id: 'supplementary_agreement_file',
    numeric: false,
    disablePadding: false,
    label: 'Документ',
  },
  {
    id: 'appendix',
    numeric: false,
    disablePadding: false,
    label: 'Приложения',
  },
  {
    id: 'payments',
    numeric: false,
    disablePadding: false,
    label: 'Счёт',
  },
  {
    id: 'hr_status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
];

const EnhancedTableHead: React.FC<IListAddAgreementAndPaymentsEnhancedTableHead> = (
  props: IListAddAgreementAndPaymentsEnhancedTableHead
) => {
  const { handleOrderingAgreement, orderingAgreement } = props;

  const [sortDirection, setSortDirection] = useState<Sorting>(undefined);

  useEffect(() => {
    if (!sortDirection) {
      handleOrderingAgreement('');
    }
  }, [handleOrderingAgreement, orderingAgreement, sortDirection]);

  return (
    <TableHead>
      <TableRow>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sortDirection={sortDirection}
            sx={tableCellStyle}
          >
            <TableSortLabel
              active={
                (!sortDirection || orderingAgreement.replace('-', '') === `${head.id}`) &&
                (head.id === 'hr_status' || head.id === 'number')
              }
              direction={sortDirection}
              onClick={() => {
                if (head.id === 'hr_status' || head.id === 'number') {
                  setSortDirection(nextSortState(sortDirection));
                  handleOrderingAgreement(sortDirection === 'asc' ? `${head.id}` : `-${head.id}`);
                }
              }}
              IconComponent={ExpandLessIcon}
              sx={tableSortLabelStyle}
            >
              {head.label}
              <Box component="span" sx={visuallyHidden}>
                {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const AddAgreementAndPayments: React.FC<IListAddAgreementAndPaymentsProps> = ({
  supplementaryAgreements,
  fetchNextPage,
  isFetchingNextPageSupplementaryAgreements,
  hasNextPageSupplementaryAgreements,
  handleOrderingAgreement,
  orderingAgreement,
  onScrollOpen,
  handleOnScroll,
  accessList,
  defaultPermissions,
  refetchSupplementaryAgreements,
}) => {
  const [order, setOrder] = useState<TOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof IListAddAgreementAndPayments>('uuid');
  const [selected, setSelected] = useState<readonly string[]>([]);

  // стейты для модалок
  const [isActionDocModal, setActionDocModal] = useState<boolean>(false);
  const [isChartPaymentsDocsModal, setChartPaymentsDocsModal] = useState<boolean>(false);
  const [isAppendixList, setAppendixList] = useState<boolean>(false);
  const [isDocumentViewModal, setDocumentViewModal] = useState<boolean>(false);
  const [isApproveDocumentModal, setApproveDocumentModal] = useState<boolean>(false);
  const [isSendDocumentModal, setSendDocumentModal] = useState<boolean>(false);
  const [isCancelConfirmModal, setCancelConfirmModal] = useState<boolean>(false);
  const [documentModalTitle, setModalTitle] = useState<string>('Предпросмотр файла');
  const [documentModalLink, setDocumentModalLink] = useState<string>('');
  // ChartPaymentsDocsModal
  const [isBottom, setIsBottom] = useState<boolean>(false);
  // стейты кнопки действия
  const [isTooltipActions, setTooltipActions] = useState<boolean>(false);
  const [tooltipSelected, setTooltipSelected] = useState<IListAddAgreementAndPayments | null>(null);

  const agreements: readonly IListAddAgreementAndPayments[] = supplementaryAgreements || [];

  const [isDocumentsChangeHistory, setDocumentsChangeHistory] = useState<boolean>(false);

  const rows = agreements.map((item: IListAddAgreementAndPayments) =>
    createData(
      item.uuid,
      item.number,
      item.supplementary_agreement_file,
      item.appendix,
      item.payments,
      item.hr_status,
      item.signed_with,
      item.contact_address,
      item.contact_phone,
      item.contact_person,
      item.original_file,
      item.original_bill_file_url
    )
  );

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof IListAddAgreementAndPayments): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (_event: React.ChangeEvent<HTMLInputElement>): void => {
    if (_event.target.checked) {
      const newSelecteds = rows.map((n) => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleScroll = (evt: Event): void => {
    const { scrollHeight, scrollTop } = (evt.target as Document).documentElement;
    const windowHeight = window.innerHeight;

    if (scrollHeight - (windowHeight + scrollTop) < 50) {
      setIsBottom(true);
    }
  };

  useEffect(() => {
    if (hasNextPageSupplementaryAgreements && !isFetchingNextPageSupplementaryAgreements) {
      document.addEventListener('scroll', throttle(handleScroll, 1000));
    }

    return () => document.removeEventListener('scroll', throttle(handleScroll, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPageSupplementaryAgreements, isFetchingNextPageSupplementaryAgreements]);

  useEffect(() => {
    if (isBottom) {
      fetchNextPage();
      setIsBottom(false);
    }
  }, [fetchNextPage, isBottom]);

  useEffect(() => {
    if (!onScrollOpen && (isAppendixList || isTooltipActions)) {
      handleOnScroll(true);
    }
  }, [handleOnScroll, onScrollOpen, isAppendixList, tooltipSelected, isTooltipActions]);

  return (
    <ClickAwayListener onClickAway={() => setAppendixList(false)}>
      <Box sx={{ width: '100%' }}>
        <Paper
          sx={{
            width: '100%',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                borderCollapse: 'inherit',
                borderSpacing: '0 4px',
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                handleOrderingAgreement={handleOrderingAgreement}
                orderingAgreement={orderingAgreement}
              />
              <TableBody>
                {agreements.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={labelId}
                      sx={tableRowStyle}
                      onClick={() => handleOnScroll(true)}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none" sx={tableDocsCellStyle}>
                        {row.number}
                      </TableCell>
                      <TableCell
                        sx={tableDocsCellStyle}
                        onClick={() => {
                          downloadDoc(row.supplementary_agreement_file);
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': { cursor: 'pointer' },
                          }}
                        >
                          <DescriptionIcon sx={tableDocsIconStyle} />
                          Документ
                        </Box>
                      </TableCell>
                      <TableCell sx={tableDocsCellStyle}>
                        <TooltipAppendixDocs
                          onScrollOpen={onScrollOpen}
                          data={tooltipSelected}
                          uuid={`appendix-key-${row.uuid}`}
                          open={isAppendixList}
                          handleClose={() => setAppendixList(false)}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              '&:hover': { cursor: 'pointer' },
                            }}
                            onClick={() => {
                              setTooltipSelected(row);
                              setAppendixList(true);
                            }}
                          >
                            <MenuIcon sx={tableDocsIconStyle} />
                            Приложения
                          </Box>
                        </TooltipAppendixDocs>
                      </TableCell>
                      <TooltipActionsInfo
                        title={row.original_bill_file_url ? '' : 'Файл счёта отсутствует'}
                        placement="left"
                      >
                        <TableCell
                          sx={tableDocsCellStyle}
                          onClick={() => {
                            if (row.original_bill_file_url) {
                              setTooltipSelected(row);
                              window.open(row.original_bill_file_url);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              opacity: row.original_bill_file_url ? 1 : 0.5,
                              pointerEvents: row.original_bill_file_url ? 'auto' : 'none',
                              '&:hover': { cursor: row.original_bill_file_url ? 'pointer' : 'none' },
                            }}
                          >
                            <LinkIcon
                              sx={{
                                height: '14px',
                              }}
                            />
                            Ссылка на счёт
                          </Box>
                        </TableCell>
                      </TooltipActionsInfo>
                      <TableCell sx={() => tableDocsStatusCellStyle()}>
                        <Box
                          sx={() => tableCellDocsStatusStyle(row)}
                          onClick={() => {
                            setTooltipSelected(row);
                          }}
                        >
                          {agreementStatus[row.hr_status]}
                        </Box>
                        <TooltipActionBtns
                          handleDocumentViewModal={() => setDocumentViewModal(true)}
                          onScrollOpen={onScrollOpen}
                          handleApproveDocumentModal={() => setApproveDocumentModal(true)}
                          open={isTooltipActions}
                          status={tooltipSelected?.hr_status}
                          isAgreements
                          uuid={`tooltip-key-${row.uuid}`}
                          data={tooltipSelected}
                          handleDocumentsChangeHistoryModal={() => setDocumentsChangeHistory(true)}
                          handleActionDoc={() => setActionDocModal(true)}
                          handleTooltipClose={() => setTooltipActions(false)}
                          handleDocumentModalLink={setDocumentModalLink}
                          handleModalTitle={setModalTitle}
                          accessList={accessList}
                          defaultPermissions={defaultPermissions}
                        >
                          <IconButton
                            sx={{
                              color: '#FF6D6D',
                            }}
                            aria-label="add to shopping cart"
                            onClick={(evt) => {
                              evt.stopPropagation();
                              setTooltipSelected(row);
                              setTooltipActions(true);
                              setAppendixList(false);
                            }}
                          >
                            <MoreVertIcon fontSize="small" sx={{ color: '#FF6D6D' }} />
                          </IconButton>
                        </TooltipActionBtns>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {isFetchingNextPageSupplementaryAgreements && <Loader />}
          </TableContainer>
        </Paper>

        <DocumentViewModal
          title={documentModalTitle}
          link={documentModalLink}
          open={isDocumentViewModal}
          handleClose={() => {
            setDocumentViewModal(false);
          }}
        />

        <ActionDocModal
          open={isActionDocModal}
          data={tooltipSelected}
          handleClose={() => setActionDocModal(false)}
          handleCancelConfirmModal={() => setCancelConfirmModal(true)}
          refetchSupplementaryAgreements={refetchSupplementaryAgreements}
        />

        <ChartPaymentsDocsModal open={isChartPaymentsDocsModal} handleClose={() => setChartPaymentsDocsModal(false)} />

        <DocumetsChangeHistory
          data={tooltipSelected}
          open={isDocumentsChangeHistory}
          handleClose={() => setDocumentsChangeHistory(false)}
        />

        <ApproveDocumentModal
          data={tooltipSelected}
          open={isApproveDocumentModal}
          handleClose={() => setApproveDocumentModal(false)}
          handleSetDocumentModal={() => setSendDocumentModal(true)}
          refetchSupplementaryAgreements={refetchSupplementaryAgreements}
        />

        <SendDocumentsModal
          data={tooltipSelected}
          open={isSendDocumentModal}
          handleClose={() => setSendDocumentModal(false)}
        />

        <CancelConfirmModal open={isCancelConfirmModal} handleClose={() => setCancelConfirmModal(false)} />
      </Box>
    </ClickAwayListener>
  );
};
