import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { dialogChildrenModal } from 'ui/modals/modalAdd/modalAdd';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { SlotActionModalProps } from 'interfaces/shared/modals/modalsInterfaces';

export const EarlyAttachWarning: React.FC<SlotActionModalProps> = ({ open, handleClose, handleClickBtn }) => (
  <Dialog open={open} onClose={handleClose} sx={{ ...dialogChildrenModal, fullWidth: 'true' }}>
    <Box sx={modalHeaderStyle}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" sx={{ ...modalTitleStyle, marginBottom: '8px' }}>
          Раняя активация прикрепления
        </Typography>
      </Box>
    </Box>
    <Box
      component="p"
      sx={{
        margin: '0 0 0 24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.02em',
        color: 'rgba(62, 65, 77, 0.6)',
      }}
    >
      Функция ранней активации поможет сразу сформировать документы по прикреплению,
      однако вы не сможете отменить действие или скорректировать данные без отображения в документах.
    </Box>
    <Box
      component="p"
      sx={{
        margin: '16px 0 0 24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.02em',
        color: 'rgba(62, 65, 77, 0.6)',
      }}
    >
      Вы уверены, что хотите произвести действие сразу?
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginRight: '24px',
          marginTop: '20px',
          marginBottom: '20px',
          gap: '16px'
        }}
      >
        <MainButton textButton="Да, продолжить действие" handleClick={handleClickBtn} />
        <MainButton textButton="Закрыть" handleClick={handleClose} mainBtn/>
      </Box>
    </Box>
  </Dialog>
);
