import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';

import { AccordionPayment } from 'components/AccordionPayment/AccordionPayment';
import { FinancialStats } from 'components/FinancialStats/FinancialStats';
import { FinanceCharts } from 'components/FinanceCharts/FinanceCharts';
import { ListExpenses } from 'components/ListExpenses/ListExpenses';
import { BorderLinearProgress } from 'components/Loader/Loader';
import { FinanceFilters } from 'components/MainFilters/FinanceFilters';
import { FinanceNavigation } from 'components/Navigation/FinanceNavigation';

import { useGetCompanies } from 'hooks/companies/useGetCompanies';
import { useGetServicePeriods } from 'hooks/periods/useGetServicePeriods';
import { useGetMainFinanceStats } from 'hooks/finance/useGetMainFinanceStats';
import { useGetPayments } from 'hooks/finance/useGetPayments';
import { useGetListExpenses } from 'hooks/finance/useGetListExpenses';

import { monthsData } from 'utils/constants';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { throttle } from 'utils/tablesMethods';

import {
  IDataFinancePeriodsStats,
  IDataFinanceStats,
  IDataPayments,
  IFinanceProps,
  IListExpenses,
} from 'interfaces/finance/financeInterfaces';
import { Company, DataPeriod, ErrorData } from 'interfaces/global/globalInterfaces';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { InfoText } from 'ui/other/Typography';
import { useGetCompanyAdmins } from 'hooks/permissions/useGetCompanyAdmins';
import { useGetAccessList } from 'hooks/permissions/useGetAccessList';
import { useGetDefaultPermissions } from 'hooks/permissions/useGetDefaultPermissions';
import { useSnackbar } from 'notistack';
import { AdditionalServicesModal } from 'shared/modals/AdditionalServicesModa/AdditionalServicesModal';

interface ISelectedFilter {
  id: string;
  title: string;
}

const Finance: React.FC<IFinanceProps> = ({ handleManagersHR }) => {
  // sessionStorage для главных фильтров
  const mainFilterCompanyFinance: Company | '' = sessionStorageGetItem<Company>('mainFilterCompanyFinance');
  const mainFilterPeriodFinance: DataPeriod | '' = sessionStorageGetItem('mainFilterPeriodFinance');

  // sessionStorage для фильтров таблицы
  const tableCheckFinance: boolean = sessionStorageGetItem('tableCheckFinance');
  const tableFilterFinance: string = sessionStorageGetItem('tableFilterFinance');

  const [dataFinanceStats, setDataFinanceStats] = useState<IDataFinanceStats>({
    moneyTotal: null,
    deposite: null,
    budget: null,
    moneyFranchise: null,
  });

  const [allCompanies, setCompanies] = useState<Company[]>([]); // все компании эйчара
  const [dataPeriods, setDataPeriods] = useState<DataPeriod[]>([]);
  const [selectedCompanyByFinance, setSelectedCompanyByFinance] = useState<Company | null>(
    mainFilterCompanyFinance || null
  );
  const [selectedPeriodByFinance, setSelectedPeriodByFinance] = useState<DataPeriod | null>(
    mainFilterPeriodFinance || null
  );
  const [dataFinancePeriodsStats, setDataFinancePeriodsStats] = useState<IDataFinancePeriodsStats[]>([]);
  const [dataPayments, setDataPayments] = useState<IDataPayments[]>([]);
  const [isCheckedListExpenses, setCheckedListExpenses] = useState<boolean>(tableCheckFinance || false);
  const [dataListExpenses, setDataListExpenses] = useState<IListExpenses[] | []>([]);
  const [selectedFilterByTitle, setSelectedFilterByTitle] = useState<string | null>(tableFilterFinance || null);
  const [optionsFilterByTitle, setOptionsFilterByTitle] = useState<ISelectedFilter[] | []>([]);
  const [isAdditionalServicesModal, setAdditionalServicesModal] = useState<boolean>(false);

  const token = localStorage.getItem('token');

  const { enqueueSnackbar } = useSnackbar();

  const { accessList } = useGetAccessList({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { defaultPermissions } = useGetDefaultPermissions({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingCompanies, fetchNextPageCompanies } = useGetCompanies({
    onSuccess: ({ pageParams, companiesSearch }) => {
      const allPage: number = pageParams?.pages[0]?.meta
        ? Math.ceil(pageParams.pages[0].meta.total / pageParams.pages[0].meta.per_page)
        : 0;

      if (!selectedCompanyByFinance && !mainFilterCompanyFinance) {
        setSelectedCompanyByFinance(companiesSearch[0]);
        sessionStorage.setItem('mainFilterCompanyFinance', JSON.stringify(companiesSearch[0]));
      } else if (mainFilterCompanyFinance) {
        setSelectedCompanyByFinance(
          companiesSearch.filter((company) => company.uuid === mainFilterCompanyFinance.uuid)[0]
        );
        sessionStorage.setItem(
          'mainFilterCompanyFinance',
          JSON.stringify(companiesSearch.filter((company) => company.uuid === mainFilterCompanyFinance.uuid)[0])
        );
      }

      if (companiesSearch) setCompanies(companiesSearch);

      if (pageParams.pages.length < allPage) fetchNextPageCompanies();
    },
  });

  const { isLoadingServicePeriods } = useGetServicePeriods({
    companies: selectedCompanyByFinance ? [selectedCompanyByFinance] : [],
    onSuccess: (data: DataPeriod[]) => {
      const res = data.reverse();
      setDataPeriods(res);
      const activePeriodExist = res.some((item) => item.activePeriod);
      if (selectedCompanyByFinance) {
        res.forEach((period: DataPeriod) => {
          if (mainFilterPeriodFinance) {
            if (mainFilterPeriodFinance.uuid === period.uuid) {
              setSelectedPeriodByFinance(period);
              sessionStorage.setItem('mainFilterPeriodFinance', JSON.stringify(period));
              handleManagersHR({
                accountEmail: period.accountEmail,
                accountPhone: period.accountPhone,
                curatorEmail: period.curatorEmail,
                curatorPhone: period.curatorPhone,
                accountFullName: period.accountFullName,
                doctorFullName: period.doctorFullName,
                doctorEmail: period.doctorEmail,
              });
            }
          } else if (period.activePeriod) {
            setSelectedPeriodByFinance(period);
            sessionStorage.setItem('mainFilterPeriodFinance', JSON.stringify(period));

            handleManagersHR({
              accountEmail: period.accountEmail,
              accountPhone: period.accountPhone,
              curatorEmail: period.curatorEmail,
              curatorPhone: period.curatorPhone,
              accountFullName: period.accountFullName,
              doctorFullName: period.doctorFullName,
            });
          } else if (!activePeriodExist) {
            setSelectedPeriodByFinance(res[0]);
            sessionStorage.setItem('mainFilterPeriodFinance', JSON.stringify(res[0]));

            handleManagersHR({
              accountEmail: res[0].accountEmail,
              accountPhone: res[0].accountPhone,
              curatorEmail: res[0].curatorEmail,
              curatorPhone: res[0].curatorPhone,
              accountFullName: res[0].accountFullName,
              doctorFullName: res[0].doctorFullName,
            });
          }
        });
      } else {
        setSelectedPeriodByFinance(null);
        sessionStorage.setItem('mainFilterPeriodFinance', JSON.stringify(''));

        setDataPeriods([]);
      }
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { isLoadingMainFinanceStats, isErrorMainFinanceStats } = useGetMainFinanceStats({
    isStatsAllowed:
      isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance_stats', accessList, defaultPermissions) &&
      isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance', accessList, defaultPermissions) &&
      selectedPeriodByFinance?.contractType === 'fronting',
    servicePeriod: selectedPeriodByFinance,
    onSuccess: (data) => {
      const dataMoneyTotal = Number(data?.money_total);
      const dataDeposite = Number(data?.deposite);
      const moneyFranchise: number = dataDeposite - dataMoneyTotal;
      setDataFinanceStats({
        moneyTotal: data?.money_total || null,
        deposite: data?.deposite || null,
        budget: data?.budget || null,
        moneyFranchise: moneyFranchise.toFixed(2),
      });
      if (data) {
        const periodsMonthStats: IDataFinancePeriodsStats[] = data.per_month_stat.map(
          (el: { money_total: string; month: string; num_visits: number }) => {
            const monthString = el.month.substr(-2, 4);
            const yearString = el.month.substr(2, 2);
            return {
              money: Number(el.money_total),
              month: `${monthsData[monthString]} '${yearString}`,
              visits: el.num_visits,
            };
          }
        );
        periodsMonthStats.push({
          money: Number(data.expenditures_after_period.money_total),
          month: 'Доп.',
          visits: data.expenditures_after_period.num_visits,
        });
        setDataFinancePeriodsStats(periodsMonthStats);
      }
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { isLoadingPayments, isErrorPayments, hasNextPagePayments, isFetchingNextPagePayments, fetchNextPage } =
    useGetPayments({
      isPaymentsAllowed: isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance', accessList, defaultPermissions),
      company: selectedCompanyByFinance || null,
      servicePeriod: selectedPeriodByFinance || null,
      onSuccess: (data: IDataPayments[]) => setDataPayments(data),
      onError: (res) => {
        if (res.response.status >= 400 && res.response.status < 500) {
          res.response.json().then((result) => {
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            );
          });
        }
      },
    });

  const {
    isLoadingListExpenses,
    fetchNextPageListEXpenses,
    hasNextPageListEXpenses,
    isFetchingNextPageListEXpenses,
    isFetchingListExpenses,
  } = useGetListExpenses({
    isListAllowed:
      isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance_reports', accessList, defaultPermissions) &&
      isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance', accessList, defaultPermissions) &&
      selectedPeriodByFinance?.contractType === 'fronting',
    listExpenses: isCheckedListExpenses,
    company: selectedCompanyByFinance || null,
    servicePeriod: selectedPeriodByFinance || null,
    selectedFilterByTitle,

    onSuccess: (data: IListExpenses[]) => {
      setDataListExpenses(data);
      const loadFilterByTitle: ISelectedFilter[] | [] = data
        ? data.map((item: IListExpenses) => ({
            id: item.uuid,
            title: item.title,
          }))
        : optionsFilterByTitle;
      const filterLoadFilterByTitle = loadFilterByTitle
        .filter((a, i) => loadFilterByTitle.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });
      setOptionsFilterByTitle(filterLoadFilterByTitle);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { companyAdminsData } = useGetCompanyAdmins({
    companyUuid: selectedCompanyByFinance?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const handleScroll = async (evt: Event): Promise<void> => {
    const { scrollHeight, scrollTop } = (evt.target as Document).documentElement;
    const windowHeight = window.innerHeight;

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    if (scrollHeight - (windowHeight + scrollTop) < 50) {
      await fetchNextPageListEXpenses();
    }
  };

  useEffect(() => {
    if (hasNextPageListEXpenses && !isFetchingNextPageListEXpenses) {
      document.addEventListener('scroll', throttle(handleScroll, 1000));
    }

    return () => document.removeEventListener('scroll', throttle(handleScroll, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPageListEXpenses, isFetchingNextPageListEXpenses]);

  return (
    <Box>
      {(isLoadingCompanies || isLoadingServicePeriods) && <BorderLinearProgress />}

      <FinanceNavigation
        isFronting={selectedPeriodByFinance?.contractType === 'fronting'}
        selectedPeriodByFinance={selectedPeriodByFinance}
        accessList={accessList}
        defaultPermissions={defaultPermissions}
      />

      <FinanceFilters
        allCompanies={allCompanies}
        dataPeriods={dataPeriods}
        selectedCompanyByFinance={selectedCompanyByFinance}
        handleSelectedCompanyByFinance={setSelectedCompanyByFinance}
        selectedPeriodByFinance={selectedPeriodByFinance}
        handleSelectedPeriodByFinance={setSelectedPeriodByFinance}
      />
      {isAccessAllowed(mainFilterCompanyFinance?.uuid, 'HR_finance', accessList, defaultPermissions) ? (
        <>
          <AccordionPayment
            isLoadingPayments={isLoadingPayments}
            isErrorPayments={!!isErrorPayments}
            hasNextPagePayments={hasNextPagePayments}
            isFetchingNextPagePayments={isFetchingNextPagePayments}
            dataPayments={dataPayments}
            fetchNextPage={fetchNextPage}
          />
          <Box component="section" sx={{ marginTop: '32px' }}>
            {selectedPeriodByFinance?.contractType === 'fronting' && (
              <>
                {isAccessAllowed(
                  mainFilterCompanyFinance?.uuid,
                  'HR_finance_stats',
                  accessList,
                  defaultPermissions
                ) && (
                  <>
                    <FinancialStats
                      dataFinanceStats={dataFinanceStats}
                      isLoadingMainFinanceStats={isLoadingMainFinanceStats}
                      isErrorMainFinanceStats={isErrorMainFinanceStats}
                      handleAdditionalServicesOpen={setAdditionalServicesModal}
                    />
                    <AdditionalServicesModal
                      open={isAdditionalServicesModal}
                      handleClose={() => setAdditionalServicesModal(false)}
                    />
                  </>
                )}
                <FinanceCharts dataFinancePeriodsStats={dataFinancePeriodsStats} />
                {isAccessAllowed(
                  mainFilterCompanyFinance?.uuid,
                  'HR_finance_reports',
                  accessList,
                  defaultPermissions
                ) && (
                  <ListExpenses
                    isCheckedListExpenses={isCheckedListExpenses}
                    isLoadingListExpenses={isLoadingListExpenses}
                    dataListExpenses={dataListExpenses}
                    handleCheckedListExpenses={setCheckedListExpenses}
                    optionsFilterByTitle={optionsFilterByTitle}
                    selectedFilterByTitle={selectedFilterByTitle}
                    handleSelectedFilterByTitle={setSelectedFilterByTitle}
                    isFetchingListExpenses={isFetchingListExpenses}
                  />
                )}
              </>
            )}
          </Box>
        </>
      ) : (
        <InfoText variant="h5">
          Для просмотра данного раздела у вас недостаточно прав, обратитесь к администратору{' '}
          {companyAdminsData
            ?.slice(0, 3)
            ?.map((item) => item?.user?.email)
            ?.join(',')}
          , если вам необходимы данные для работы
        </InfoText>
      )}
    </Box>
  );
};
export { Finance };
