import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import { ListSlotResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/slots/types';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';
import { format } from 'date-fns';
import { GetSlotsOperationsData, GetSlotsOperationsParams, GetSlotsOperationsQueryParams, SlotsOperations } from 'interfaces/hooks/patientsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetSlotsOperations = ({ enabled, ordering, onError }: GetSlotsOperationsParams): GetSlotsOperationsData => {
  const { data, isFetching, isLoading, isError, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['dataSlotsOperations', ordering],
      async ({ pageParam = 1 }) => {
        await handleRefreshToken();

        const token = localStorage.getItem('token');

        if (!token) return;

        const queryParams: GetSlotsOperationsQueryParams = {
          page: pageParam,
          per_page: 20,
          ordering,
        };

        const res = await fetch(`${API_URL}/task_results/?${queryString.stringify(queryParams)}`, {
          method: 'GET',
          headers: {
            Accept: `${HEADER_ACCEPT_HR}`,
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          return res.json();
        }
        throw new FetchError(res);
      },

      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        enabled,
        onError,
        getNextPageParam: (lastPage: ListSlotResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
          const allPage: number = getPagesQuantity(lastPage);
          const currentPage: number = lastPage.meta?.page || 0;
          const nextPage = currentPage + 1;

          return nextPage <= allPage ? nextPage : undefined;
        },
        select: (res) => {
          const pages = res.pages.map((page) => page.data);
          const { total } = res.pages[0].meta;
          const slotsOperations = pages.flat();

          return slotsOperations.map((item: SlotsOperations) => ({
            total,
            uuid: item.uuid,
            result: item.result ? JSON.parse(item.result) : null,
            task_name: item.task_name,
            status: item.status,
            status_display: item.status_display,
            task_id: item.task_id,
            start: format(new Date(item.enqueued_at), 'dd.MM.yyyy H:mm:ss'),
            end: format(new Date(item.done_at), 'dd.MM.yyyy H:mm:ss'),
          }));
        },
      }
    );

  return {
    dataSlotsOperations: data,

    isLoadingSlotsOperations: isLoading,
    isErrorSlotsOperations: isError,

    hasNextPageSlotsOperations: hasNextPage,
    isFetchingNextPageSlotsOperations: isFetchingNextPage,
    isFetchingSlotsOperations: isFetching,

    fetchNextPageSlotsOperations: fetchNextPage,
    refetchSlotsOperations: refetch,
  };
};
