import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { FetchError } from 'utils/FetchError';
import { GetTaskActionStaffData } from 'interfaces/hooks/otherHooksInterfaces';
import { ErrorsToFileHook, ErrorsToFileHookData } from 'interfaces/hooks/patientsHooksInterfaces';

export const useDetachErrorsToFile = ({
  celeryType,
  onSuccess,
  onError,
}: ErrorsToFileHook): ErrorsToFileHookData => {
  const { mutate, isLoading } = useMutation(
    async (sendData: GetTaskActionStaffData['data']) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const methodType = celeryType === 'slots' ? 'create' : 'detach';

      const res = await fetch(`${API_URL}/${celeryType}/${methodType}_errors_to_file/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendData),
      });

      if (res.ok) {
        return res.blob();
      }
      throw new FetchError(res);
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    handleDetachErrorsToFile: mutate,
    isLoadingDetachErrorsToFile: isLoading,
  };
};
