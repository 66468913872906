export const titleStyle = {
  fontSize: '15vh',
  margin: 0,
  color: '#ff6d6d',
};

export const errorMainStyle = {
    width:'100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh'
}

export const errorLinkStyle = {
    color: '#ff6d6d',
    textDecoration: 'none',
    fontSize: '20px'
}