import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';

import { appStyle, appLoginStyle } from 'ui/main/app';
import { mainStyle, mainLoginStyle } from 'ui/main/main';

import { Header } from 'components/Header/Header';
import { RoutesMenu } from 'routes/RoutesMenu';
import { Footer } from 'components/Footer/Footer';
import { LoginFooter } from 'components/Footer/LoginFooter';
import { ErrorData, IManagersHR } from 'interfaces/global/globalInterfaces';
import { isLoginPath } from 'utils/isLoginPath';
import { useGetPermissions } from 'hooks/permissions/useGetPermissions';
import { useGetUserInfo } from 'hooks/other/useGetUserInfo';
import { useSnackbar } from 'notistack';
import { itsOnClient } from 'itson/ItsOn';

export const App: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);

  // стейт контаков аккаунт-менеджера и куратора
  const [managersHR, setManagersHR] = useState<IManagersHR | null>(null);
  const [areFlagsLoaded, setAreFlagsLoaded] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
    }
  }, [isLoggedIn, navigate, path]);

  useGetPermissions({
    isLoggedIn,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  useGetUserInfo({
    isLoggedIn,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  useEffect(() => {
    (async () => {
      await itsOnClient.fetchFlags();
      setAreFlagsLoaded(true);
    })();
  }, []);

  return areFlagsLoaded ? (
    <Box sx={() => (isLoginPath(path) ? appLoginStyle : appStyle)}>
      <Header managersHR={managersHR} handleLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />
      <Box component="main" sx={() => (isLoginPath(path) ? mainLoginStyle : mainStyle)}>
        <RoutesMenu
          isLoggedIn={isLoggedIn}
          handleLoggedIn={setLoggedIn}
          handleManagersHR={setManagersHR}
          managersHR={managersHR}
        />
      </Box>

      {isLoginPath(path) ? <LoginFooter /> : <Footer />}
    </Box>
  ) : null;
};
