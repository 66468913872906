import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Notifications } from 'pages/Notifications/Notifications';
import { IManagersHR } from 'interfaces/global/globalInterfaces';
import { Permissions } from 'pages/Permissions/Permissions';
import { UserRightsList } from 'components/UserRightsList/UserRightsList';
import { ErrorRoute } from 'pages/ErrorRoute/ErrorRoute';
import { Login } from '../pages/Login/Login';
import { Staff } from '../pages/Staff/Staff';
import { Finance } from '../pages/Finance/Finance';
import { Programs } from '../pages/Programs/Programs';
import { Documents } from '../pages/Documents/Documents';

interface IRoutesMenu {
  isLoggedIn: boolean;
  handleManagersHR: (value: IManagersHR | null) => void;
  handleLoggedIn: (value: boolean) => void;
  managersHR: IManagersHR | null;
}

export const RoutesMenu: React.FC<IRoutesMenu> = ({ isLoggedIn, handleLoggedIn, handleManagersHR, managersHR }) => (
  <Routes>
    <Route path="/" element={isLoggedIn ? <Navigate to="/staff" /> : <Login handleLoggedIn={handleLoggedIn} />} />
    <Route path="/staff" element={<Staff handleManagersHR={handleManagersHR} managersHR={managersHR} />} />
    <Route path="/finance" element={<Finance handleManagersHR={handleManagersHR} />} />
    <Route path="/programs" element={<Programs handleManagersHR={handleManagersHR} />} />
    <Route path="/documents" element={<Documents handleManagersHR={handleManagersHR} />} />
    <Route path="/notifications" element={<Notifications />} />
    <Route path="/permissions" element={<Permissions />} />
    <Route path="/permissions/:id" element={<UserRightsList />} />
    <Route path="/reset-password" element={<Login handleLoggedIn={handleLoggedIn} isSettingPassword />} />
    <Route path="*" element={<ErrorRoute />}/>
  </Routes>
);
