import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { reportTitleView } from 'utils/dateHelpers';

import queryString from 'query-string';
import { getPagesQuantity } from 'utils/hooksHelpers';
import { ListReportFilesResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-reports/types';
import { CompanyReportsReportFilesHrRq } from '@bestdoctor/core-arch-api/models';
import { GetReportFileData, GetReportFileParams, GetReportFilesQueryParams } from 'interfaces/hooks/documentsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetReportFile = ({
  onError,
  period,
  company,
  isReportFilesAllowed
}: GetReportFileParams): GetReportFileData => {
  const {
    isFetching,
    isLoading,
    error,
    fetchNextPage,

    hasNextPage,
    isFetchingNextPage,
    data: actsAndReports
  } = useInfiniteQuery(
    ['dataReportFiles', period, company],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      const queryParams: GetReportFilesQueryParams = {
        page: pageParam,
        per_page: 20,
        service_period: period?.uuid,
        company: company?.uuid,
      };

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/company_reports/report_files/?${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    {
      getNextPageParam: (lastPage: ListReportFilesResourcesResponsesByAccept["application/vnd.bestdoctor.v1-hr"]) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number | undefined = lastPage.meta?.page;
        let nextPage = 0;
        if (currentPage) {
          nextPage = currentPage + 1;
        }

        return nextPage <= allPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: isReportFilesAllowed,
      onError,
      select: (data: {
        pages: ListReportFilesResourcesResponsesByAccept["application/vnd.bestdoctor.v1-hr"][],
        meta: unknown[]
      }) => {
        const pages = data.pages.map((page: ListReportFilesResourcesResponsesByAccept["application/vnd.bestdoctor.v1-hr"]) => page.data);

        const slotsReportFiles: CompanyReportsReportFilesHrRq[] = [];

        pages.forEach((arr: CompanyReportsReportFilesHrRq[] | undefined) => {
          arr?.forEach((item: CompanyReportsReportFilesHrRq) => slotsReportFiles.push(item));
        });

        return slotsReportFiles.map((item: CompanyReportsReportFilesHrRq, index: number) => ({
          uuid: index,
          report_date: item.report_date,
          title: reportTitleView(item.report_date),
          document: item.report_file_instance,
          appendix: [
            item.report_file_instance,
            item.act_file_instance,
            item.franchise_file_instance,
            item.invoice_file_instance,
          ],
        }));
      },
    }
  );

  return {
    isLoadingReportFiles: isLoading,
    isErrorReportFiles: error,

    fetchNextPageActs: fetchNextPage,
    hasNextPageReportFiles: hasNextPage,
    isFetchingNextPageReportFiles: isFetchingNextPage,
    isFetchingReportFiles: isFetching,
    actsAndReports
  };
};
