export const titleStyle = {
    margin: '0',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#3e414d'
}

export const checkLabelStyle = (isActive: boolean) => ({
    margin: '0',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: isActive ? '#3e414d' : '#bcbec8'
})
