import { Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { errorLinkStyle, errorMainStyle, titleStyle } from 'ui/other/404'

export const ErrorRoute: React.FC = () => (
    <Stack sx={errorMainStyle}>
        <h1 style={titleStyle}>404</h1>
        <Link to="/staff" style={errorLinkStyle}>На главную</Link>
    </Stack>
  )

