import React, { SyntheticEvent, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { AddAgreementAndPayments } from 'components/TablesDocs/AddAgreementAndPayments';
import { AgentReportsAndActs } from 'components/TablesDocs/AgentReportsAndActs';

import { CustomTab, CustomTabs } from 'ui/tabs/tabs';

import { useGetSupplementaryAgreements } from 'hooks/documents/useGetSupplementaryAgreements';
import { useGetReportFile } from 'hooks/documents/useGetReportFiles';

import { BorderLinearProgress } from 'components/Loader/Loader';
import { DocumentsFilters } from 'components/MainFilters/DocumentsFilters';
import { DocumentsNavigation } from 'components/Navigation/DocumentsNavigation';
import { Company, DataPeriod, ErrorData, IManagersHR } from 'interfaces/global/globalInterfaces';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { useGetCompanies } from 'hooks/companies/useGetCompanies';
import { useGetServicePeriods } from 'hooks/periods/useGetServicePeriods';

import DownloadIcon from '@mui/icons-material/Download';
import { DocumentViewModal } from 'shared/modals/DocumentViewModal/DocumentViewModal';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { InfoText } from 'ui/other/Typography';
import { useGetCompanyAdmins } from 'hooks/permissions/useGetCompanyAdmins';
import { useGetAccessList } from 'hooks/permissions/useGetAccessList';
import { useGetDefaultPermissions } from 'hooks/permissions/useGetDefaultPermissions';
import { useSnackbar } from 'notistack';

interface DocumentsProps {
  handleManagersHR: (value: IManagersHR | null) => void;
}

export const Documents: React.FC<DocumentsProps> = ({ handleManagersHR }) => {
  // sessionStorage для главных фильтров
  const mainFilterCompanyDocuments: Company | null = sessionStorageGetItem<Company>('mainFilterCompanyDocuments');
  const mainFilterPeriodDocuments: DataPeriod | null = sessionStorageGetItem('mainFilterPeriodDocuments');

  const [selectedPeriodByDocuments, setSelectedPeriodByDocuments] = useState<DataPeriod | null>(
    mainFilterPeriodDocuments || null
  );

  const [selectedCompanyByDocuments, setSelectedCompanyByDocuments] = useState<Company | null>(
    mainFilterCompanyDocuments || null
  );

  const [allCompanies, setCompanies] = useState<Company[]>([]); // все компании эйчара

  const [secondaryMenuValue, setSecondaryMenuValue] = useState<string>('docs1');

  const [dataPeriods, setDataPeriods] = useState<DataPeriod[]>([]);

  const [orderingAgreement, setOrderingAgreement] = useState<string>('');

  const [onScrollOpen, setOnScrollOpen] = useState<boolean>(true);

  const [isDocumentViewModal, setDocumentViewModal] = useState<boolean>(false);

  const [modalLink, setModalLink] = useState<string | undefined>('');

  const token = localStorage.getItem('token');

  const { enqueueSnackbar } = useSnackbar();

  const { accessList } = useGetAccessList({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { defaultPermissions } = useGetDefaultPermissions({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingCompanies, fetchNextPageCompanies } = useGetCompanies({
    onSuccess: ({ pageParams, companiesSearch }) => {
      const allPage: number = pageParams?.pages[0]?.meta
        ? Math.ceil(pageParams.pages[0].meta.total / pageParams.pages[0].meta.per_page)
        : 0;

      if (!selectedCompanyByDocuments && !mainFilterCompanyDocuments) {
        setSelectedCompanyByDocuments(companiesSearch[0]);
        sessionStorage.setItem('mainFilterCompanyDocuments', JSON.stringify(companiesSearch[0]));
      } else if (mainFilterCompanyDocuments) {
        setSelectedCompanyByDocuments(
          companiesSearch.filter((company) => company.uuid === mainFilterCompanyDocuments.uuid)[0]
        );
        sessionStorage.setItem(
          'mainFilterCompanyDocuments',
          JSON.stringify(companiesSearch.filter((company) => company.uuid === mainFilterCompanyDocuments.uuid)[0])
        );
      }

      if (companiesSearch) setCompanies(companiesSearch);

      if (pageParams.pages.length < allPage) fetchNextPageCompanies();
    },
  });

  const {
    isLoadingSupplementaryAgreements,
    fetchNextPage,
    hasNextPageSupplementaryAgreements,
    isFetchingNextPageSupplementaryAgreements,
    dataSupplementaryAgreements,
    refetchSupplementaryAgreements
  } = useGetSupplementaryAgreements({
    isSupplementaryAgreementsAllowed: isAccessAllowed(
      mainFilterCompanyDocuments?.uuid,
      'HR_docs',
      accessList,
      defaultPermissions
    ),
    company: selectedCompanyByDocuments,
    period: selectedPeriodByDocuments,
    ordering: orderingAgreement,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const {
    isLoadingReportFiles,
    fetchNextPageActs,
    hasNextPageReportFiles,
    actsAndReports,
    isFetchingNextPageReportFiles,
  } = useGetReportFile({
    isReportFilesAllowed: isAccessAllowed(mainFilterCompanyDocuments?.uuid, 'HR_docs', accessList, defaultPermissions),
    company: selectedCompanyByDocuments,
    period: selectedPeriodByDocuments,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingServicePeriods } = useGetServicePeriods({
    companies: selectedCompanyByDocuments ? [selectedCompanyByDocuments] : [],
    onSuccess: (data: DataPeriod[]) => {
      const res = data.reverse();
      setDataPeriods(res);
      const activePeriodExist = res.some((item) => item.activePeriod);
      if (selectedCompanyByDocuments) {
        res.forEach((period: DataPeriod) => {
          if (mainFilterPeriodDocuments) {
            if (mainFilterPeriodDocuments.uuid === period.uuid) {
              setSelectedPeriodByDocuments(period);
              sessionStorage.setItem('mainFilterPeriodDocuments', JSON.stringify(period));
              handleManagersHR({
                accountEmail: period.accountEmail,
                accountPhone: period.accountPhone,
                curatorEmail: period.curatorEmail,
                curatorPhone: period.curatorPhone,
                accountFullName: period.accountFullName,
                doctorFullName: period.doctorFullName,
                doctorEmail: period.doctorEmail,
              });
            }
          } else if (period.activePeriod) {
            setSelectedPeriodByDocuments(period);
            sessionStorage.setItem('mainFilterPeriodDocuments', JSON.stringify(period));

            handleManagersHR({
              accountEmail: period.accountEmail,
              accountPhone: period.accountPhone,
              curatorEmail: period.curatorEmail,
              curatorPhone: period.curatorPhone,
              accountFullName: period.accountFullName,
              doctorFullName: period.doctorFullName,
            });
          } else if (!activePeriodExist) {
            setSelectedPeriodByDocuments(res[0]);
            sessionStorage.setItem('mainFilterPeriodDocuments', JSON.stringify(res[0]));

            handleManagersHR({
              accountEmail: res[0].accountEmail,
              accountPhone: res[0].accountPhone,
              curatorEmail: res[0].curatorEmail,
              curatorPhone: res[0].curatorPhone,
              accountFullName: res[0].accountFullName,
              doctorFullName: res[0].doctorFullName,
            });
          }
        });
      } else {
        setSelectedPeriodByDocuments(null);
        sessionStorage.setItem('mainFilterPeriodDocuments', JSON.stringify(''));

        setDataPeriods([]);
      }
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { companyAdminsData } = useGetCompanyAdmins({
    companyUuid: selectedCompanyByDocuments?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const handleChange = (_event: SyntheticEvent, newValue: string): void => setSecondaryMenuValue(newValue);

  useEffect(() => {
    const handleScroll = (): void => {
      setOnScrollOpen(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setModalLink(selectedPeriodByDocuments?.contract);
  }, [selectedPeriodByDocuments]);

  return (
    <>
      {isLoadingCompanies && isLoadingServicePeriods && isLoadingReportFiles && <BorderLinearProgress />}

      <DocumentsNavigation />

      <DocumentsFilters
        allCompanies={allCompanies}
        selectedCompanyByDocuments={selectedCompanyByDocuments}
        handleSelectedCompanyByDocuments={setSelectedCompanyByDocuments}
        dataPeriods={dataPeriods}
        selectedPeriodByDocuments={selectedPeriodByDocuments}
        handleSelectedPeriodByDocuments={setSelectedPeriodByDocuments}
      />

      {isAccessAllowed(mainFilterCompanyDocuments?.uuid, 'HR_docs', accessList, defaultPermissions) ? (
        <Box component="section" sx={{ marginTop: '32px' }}>
          <TabContext value={secondaryMenuValue}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <CustomTabs value={secondaryMenuValue} onChange={handleChange} aria-label="Навигация по документам">
                  <CustomTab value="docs1" label="Дополнительные соглашения и платежи" />
                  {selectedPeriodByDocuments?.contractType === 'fronting' &&
                    isAccessAllowed(
                      mainFilterCompanyDocuments?.uuid,
                      'HR_docs_reports',
                      accessList,
                      defaultPermissions
                    ) && <CustomTab value="docs2" label="Отчеты и прочие документы" />}
                </CustomTabs>
              </Box>
              <Box>
                <MainButton
                  textButton="Выгрузить договор"
                  mainBtn
                  handleClick={() => setDocumentViewModal(true)}
                  disabled={!modalLink}
                  icon={<DownloadIcon />}
                />
              </Box>
            </Box>

            <DocumentViewModal
              link={modalLink}
              title="Предпросмотр документа"
              open={isDocumentViewModal && !!modalLink}
              handleClose={() => setDocumentViewModal(false)}
            />

            {isLoadingSupplementaryAgreements && <BorderLinearProgress />}
            <TabPanel value="docs1">
              <AddAgreementAndPayments
                handleOnScroll={setOnScrollOpen}
                onScrollOpen={onScrollOpen}
                supplementaryAgreements={dataSupplementaryAgreements}
                fetchNextPage={fetchNextPage}
                isFetchingNextPageSupplementaryAgreements={isFetchingNextPageSupplementaryAgreements}
                hasNextPageSupplementaryAgreements={hasNextPageSupplementaryAgreements}
                handleOrderingAgreement={setOrderingAgreement}
                orderingAgreement={orderingAgreement}
                accessList={accessList}
                defaultPermissions={defaultPermissions}
                refetchSupplementaryAgreements={refetchSupplementaryAgreements}
              />
            </TabPanel>

            {selectedPeriodByDocuments?.contractType === 'fronting' &&
              isAccessAllowed(mainFilterCompanyDocuments?.uuid, 'HR_docs_reports', accessList, defaultPermissions) && (
                <TabPanel value="docs2">
                  <AgentReportsAndActs
                    actsAndReports={actsAndReports}
                    fetchNextPage={fetchNextPageActs}
                    isFetchingNextPageReportFiles={isFetchingNextPageReportFiles}
                    hasNextPagePageReportFiles={hasNextPageReportFiles}
                  />
                </TabPanel>
              )}
          </TabContext>
        </Box>
      ) : (
        <InfoText variant="h5">
          Для просмотра данного раздела у вас недостаточно прав, обратитесь к администратору{' '}
          {companyAdminsData
            ?.slice(0, 3)
            ?.map((item) => item?.user?.email)
            ?.join(',')}
          , если вам необходимы данные для работы
        </InfoText>
      )}
    </>
  );
};
