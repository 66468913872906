import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { ListProgramResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/program/types';
import { getPagesQuantity } from 'utils/hooksHelpers';
import queryString from 'query-string';
import { Company, DataPeriod, SearchParams } from 'interfaces/global/globalInterfaces';
import { FetchError } from 'utils/FetchError';

interface SearchProgramsForStaffFilterHookParams {
  periods: DataPeriod[] | null;
  periodInMainFilter: DataPeriod | null;
  companies: Company[];
  isSlotsAviable: boolean;
  onError: (res: { response: Response }) => void;
}

interface SearchProgramsForStaffFilterHookData {
  isLoadingGetProgramsForFilter: boolean;
  isErrorGetProgramsForFilter: boolean;

  fetchNextPage: Promise<void>;

  programsData: SearchParams[];
}

interface SearchProgramsForStaffFilterHookQueryParams {
  per_page: number;
  service_period__in?: string;
}

export const useSearchProgramsForStaffFilter = ({
  periods,
  periodInMainFilter,
  companies,
  isSlotsAviable,
  onError,
}: SearchProgramsForStaffFilterHookParams): SearchProgramsForStaffFilterHookData => {
  
  const activePeriodsUuids = periods
    ?.reduce((acc: string[], item: DataPeriod) => {
      if (item.activePeriod) {
        acc.push(item.uuid);
      }
      return acc;
    }, [])
    .join(',');

  const {
    isLoading,
    error,
    fetchNextPage,

    data: programsData,
  } = useQuery(
    ['dataProgram', activePeriodsUuids, periodInMainFilter],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const queryParams: SearchProgramsForStaffFilterHookQueryParams = {
        per_page: 200,
      };

      if (companies.length > 1) {
        queryParams.service_period__in = activePeriodsUuids;
      }

      if (companies.length === 1) {
        queryParams.service_period__in = periodInMainFilter?.uuid;
      }

      const res = await fetch(`${API_URL}/programs/?${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: companies.length > 1 ? !!activePeriodsUuids && isSlotsAviable : !!periodInMainFilter && isSlotsAviable,
      getNextPageParam: (lastPage: ListProgramResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number = lastPage.meta?.page || 0;
        const nextPage = currentPage + 1;

        return nextPage <= allPage ? nextPage : undefined;
      },
      onError,
      select: (res) => {
        const { data } = res;

        const groupedPrograms: ArrayLike<unknown> = data.reduce((acc, val) => {
          const key = val.title;
          if (!acc[key]) {
            acc[key] = [val.uuid];
          } else {
            acc[key].push(val.uuid);
          }
          return acc;
        }, {});

        const programs = Object.entries(groupedPrograms).map(([title, value]) => ({
          title,
          value: (value as string[]).join(','),
        }));

        return programs;
      },
    }
  );

  return {
    isLoadingGetProgramsForFilter: isLoading,
    isErrorGetProgramsForFilter: error,

    fetchNextPage,

    programsData,
  };
};
