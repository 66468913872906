import React from 'react';

import { Box } from '@mui/system';
import { ClickAwayListener, Typography, Link } from '@mui/material';

import { TooltipInfo, tooltipContainerStyle, tooltipTitleStyle, tooltipLinkStyle } from 'ui/tooltips/tooltipInfo';

import { ITooltipFeedbackInfoProps } from 'interfaces/shared/tooltips/tooltipsInterfaces';

export const TooltipFeedbackInfo: React.FC<ITooltipFeedbackInfoProps> = ({
  open,
  titleText,
  href,
  textContact,
  isLoggedIn,
  titleTextCurator,
  textContactCurator,
  hrefContactCurator,
  titleTextAccount,
  fullNameAccount,
  doctorFullName,
  textContactAccount,
  hrefContactAccount,

  children,

  handleTooltipClose,
}) => (
  <ClickAwayListener onClickAway={handleTooltipClose}>
    <Box>
      <TooltipInfo
        open={open}
        placement="bottom-end"
        title={
          <Box sx={{ width: '100%' }}>
            <Box sx={tooltipContainerStyle}>
              <Typography variant="h4" sx={tooltipTitleStyle}>
                {titleText}
              </Typography>

              <Link href={href} sx={tooltipLinkStyle} underline="none">
                {textContact}
              </Link>
            </Box>

            {titleTextCurator && textContactCurator && isLoggedIn && (
              <Box sx={tooltipContainerStyle}>
                <Typography variant="h4" sx={tooltipTitleStyle}>
                  {titleTextCurator}
                </Typography>

                <Link href={hrefContactCurator || ''} sx={tooltipLinkStyle} underline="none">
                  {textContactCurator}
                </Link>

                {doctorFullName && doctorFullName.length > 0 && isLoggedIn && (
                  <Typography variant="h4" sx={tooltipTitleStyle}>
                    {doctorFullName}
                  </Typography>
                )}
              </Box>
            )}

            {titleTextAccount && textContactAccount && isLoggedIn && (
              <Box sx={tooltipContainerStyle}>
                <Typography variant="h4" sx={tooltipTitleStyle}>
                  {titleTextAccount}
                </Typography>

                <Link href={hrefContactAccount || ''} sx={tooltipLinkStyle} underline="none">
                  {textContactAccount}
                </Link>

                {fullNameAccount && fullNameAccount.length > 0 && isLoggedIn && (
                  <Typography variant="h4" sx={tooltipTitleStyle}>
                    {fullNameAccount}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        }
      >
        {children}
      </TooltipInfo>
    </Box>
  </ClickAwayListener>
);
