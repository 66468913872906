import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';

interface TooltipFeedbackInfoProps {
  open: boolean;
  isStats: any;
  children: React.ReactElement;
  handleTooltipClose: () => void;
}

const TooltipInfo = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  // maxWidth: '640px',
  maxWidth: '704px',
  width: '100%',

  [`& .${tooltipClasses.tooltip}`]: {
    // maxWidth: '640px',
    maxWidth: '704px',
    width: '100%',
    // width: '640px',
    padding: '32px',
    color: 'rgba(0, 0, 0, 1)',
    backgroundColor: '#fff',
    boxShadow: '0px 1.5px 3px rgba(0, 0, 0, 0.1), 0px 12px 24px rgba(0, 0, 0, 0.15);',
    borderRadius: '4px',
    fontFamily: 'inherit',
    boxSizing: 'border-box',
  },
}));

const BorderLinearProgress = styled(LinearProgress)(() => ({
  width: '100%',
  maxWidth: '514px',
  height: '2px',
  borderRadius: 1,
  marginRight: '16px',
  backgroundColor: '#ffe9e9',

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#ff6d6d',
  },
}));

const TooltipTotalStats: React.FC<TooltipFeedbackInfoProps> = ({ open, isStats, children, handleTooltipClose }) => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <TooltipInfo
          open={open}
          placement="bottom-end"
          title={
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  margin: 0,
                  fontFamily: 'inherit',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '18px',
                  letterSpacing: '0.02em',
                  color: '#3e414d',
                }}
              >
                Общая информация
              </Typography>
  
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '32px',
                }}
              >
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: 'inherit',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '0.02em',
                    color: '#bcbec8',
                    marginBottom: '16px',
                  }}
                >
                  Человек в системе
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '150px',
                    }}
                  >
                    <Box
                      sx={{
                        margin: 0,
                        fontFamily: 'TRY Vesterbro',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '48px',
                        lineHeight: '36px',
                        color: '#3e414d',
                        width: 'max-content',
                      }}
                    >
                      {isStats.total_registered}
                    </Box>
                  </Box>
  
                  <Box
                    component="ul"
                    sx={{
                      width: '100%',
                      margin: 0,
                      padding: 0,
                      listStyleType: 'none',
                    }}
                  >
                    <Box
                      component="li"
                      sx={{
                        width: '100%',
                        marginBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        '&:last-child': {
                          marginBottom: 0,
                        },
                      }}
                    >
                      <BorderLinearProgress variant="determinate" value={isStats.men_percentage} />
                      <Box
                        component="p"
                        sx={{
                          maxWidth: '72px',
                          width: '100%',
                          margin: '0 8px 0 0',
                          fontFamily: 'inherit',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '14px',
                          lineHeight: '18px',
                          letterSpacing: '0.02em',
                          color: '#3e414d',
                        }}
                      >{`${isStats.men_percentage}%`}</Box>
                      <Box
                        component="p"
                        sx={{
                          margin: 0,
                          maxWidth: '72px',
                          width: '100%',
                          fontFamily: 'inherit',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '18px',
                          letterSpacing: '0.02em',
                          color: '#bcbec8',
                        }}
                      >
                        Мужчины
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      sx={{
                        width: '100%',
                        marginBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        '&:last-child': {
                          marginBottom: 0,
                        },
                      }}
                    >
                      <BorderLinearProgress variant="determinate" value={isStats.women_percentage} />
                      <Box
                        component="p"
                        sx={{
                          maxWidth: '72px',
                          width: '100%',
                          margin: '0 8px 0 0',
                          fontFamily: 'inherit',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '14px',
                          lineHeight: '18px',
                          letterSpacing: '0.02em',
                          color: '#3e414d',
                        }}
                      >{`${isStats.women_percentage}%`}</Box>
                      <Box
                        component="p"
                        sx={{
                          margin: 0,
                          maxWidth: '72px',
                          width: '100%',
                          fontFamily: 'inherit',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '18px',
                          letterSpacing: '0.02em',
                          color: '#bcbec8',
                        }}
                      >
                        Женщины
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
  
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '32px',
                }}
              >
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: 'inherit',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '0.02em',
                    marginBottom: '16px',
                    color: '#bcbec8',
                  }}
                >
                  Распределение по возрасту
                </Typography>
  
                <Box
                  component="ul"
                  sx={{
                    width: '100%',
                    margin: 0,
                    padding: 0,
                    listStyleType: 'none',
                  }}
                >
                  <Box
                    component="li"
                    sx={{
                      width: '100%',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      '&:last-child': {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <BorderLinearProgress variant="determinate" value={isStats.less_30} />
                    <Box
                      component="p"
                      sx={{
                        maxWidth: '72px',
                        width: '100%',
                        margin: '0 8px 0 0',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#3e414d',
                      }}
                    >{`${isStats.less_30}%`}</Box>
                    <Box
                      component="p"
                      sx={{
                        margin: 0,
                        maxWidth: '72px',
                        width: '100%',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#bcbec8',
                      }}
                    >
                      до 30
                    </Box>
                  </Box>
                  <Box
                    component="li"
                    sx={{
                      width: '100%',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      '&:last-child': {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <BorderLinearProgress variant="determinate" value={isStats.more_30_and_less_45} />
                    <Box
                      component="p"
                      sx={{
                        maxWidth: '72px',
                        width: '100%',
                        margin: '0 8px 0 0',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#3e414d',
                      }}
                    >{`${isStats.more_30_and_less_45}%`}</Box>
                    <Box
                      component="p"
                      sx={{
                        margin: 0,
                        maxWidth: '72px',
                        width: '100%',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#bcbec8',
                      }}
                    >
                      30-45
                    </Box>
                  </Box>
                  <Box
                    component="li"
                    sx={{
                      width: '100%',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      '&:last-child': {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <BorderLinearProgress variant="determinate" value={isStats.more_45_and_less_60} />
                    <Box
                      component="p"
                      sx={{
                        maxWidth: '72px',
                        width: '100%',
                        margin: '0 8px 0 0',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#3e414d',
                      }}
                    >
                      {`${isStats.more_45_and_less_60}%`}
                    </Box>
                    <Box
                      component="p"
                      sx={{
                        margin: 0,
                        maxWidth: '72px',
                        width: '100%',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#bcbec8',
                      }}
                    >45-60</Box>
                  </Box>
                  <Box
                    component="li"
                    sx={{
                      width: '100%',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      '&:last-child': {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <BorderLinearProgress variant="determinate" value={isStats.more_60} />
                    <Box
                      component="p"
                      sx={{
                        maxWidth: '72px',
                        width: '100%',
                        margin: '0 8px 0 0',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#3e414d',
                      }}
                    >
                      {`${isStats.more_60}%`}
                    </Box>
                    <Box
                      component="p"
                      sx={{
                        margin: 0,
                        maxWidth: '72px',
                        width: '100%',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#bcbec8',
                      }}
                    >60+</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        >
          {children}
        </TooltipInfo>
      </Box>
    </ClickAwayListener>
  )

export { TooltipTotalStats };
