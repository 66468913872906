export const mainStyle = {
  maxWidth: '1440px',
  width: '100%',
  flex: '1 1 auto',

  '@media(max-width:1500px)': {
    padding: '0 64px',
  },
};

export const mainLoginStyle = {
  width: 'calc(100% - 64px - 64px)',
};

export const filterStyle = {
  position: 'sticky',
  top: '276px',
  zIndex: '10',
  display: 'flex',
  gap: '32px',
  padding: '8px 32px',
  backgroundColor: '#fff',
  borderRadius: '4px',
};
