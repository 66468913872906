import {
  guyabanoColor,
  peachСolor,
  linenСolor,
  graylightColor,
  orangeColor,
  themeColor,
  greenColor,
} from 'utils/uiConstants';

import { ITab } from 'interfaces/other/otherInterfaces';
import {
  ParamsPriority,
  ParamsPriorityFilter,
  PeriodsNotificationsParams,
  StatusesHR,
} from 'interfaces/notifications/notificationsInterfaces';
import { IEventTypes } from 'interfaces/patients/patientInterfaces';

export const mainTabs: ITab[] = [
  {
    id: 1,
    name: 'Пациенты',
    href: '/staff',
  },
  {
    id: 2,
    name: 'Финансы',
    href: '/finance',
  },
  {
    id: 3,
    name: 'Программы',
    href: '/programs',
  },
  {
    id: 4,
    name: 'Документы',
    href: '/documents',
  },
];

export const allRoutes: ITab[] = [
  {
    id: 1,
    name: 'Пациенты',
    href: '/staff',
  },
  {
    id: 2,
    name: 'Финансы',
    href: '/finance',
  },
  {
    id: 3,
    name: 'Программы',
    href: '/programs',
  },
  {
    id: 4,
    name: 'Документы',
    href: '/documents',
  },
  {
    id: 5,
    name: 'Уведомления',
    href: '/notifications',
  },
  {
    id: 6,
    name: 'Права доступа',
    href: '/permissions',
  },
];

export const optionsGender: {
  title: string;
  value: string;
}[] = [
    {
      title: 'Мужской',
      value: 'M',
    },
    {
      title: 'Женский',
      value: 'F',
    },
  ];

export const monthsData = {
  '01': 'январь',
  '02': 'февраль',
  '03': 'март',
  '04': 'апрель',
  '05': 'май',
  '06': 'июнь',
  '07': 'июль',
  '08': 'август',
  '09': 'сентябрь',
  '10': 'октябрь',
  '11': 'ноябрь',
  '12': 'декабрь',
};

export const paramsPriority: ParamsPriority = {
  regular: { text: 'Регулярные', color: graylightColor, bgcolor: guyabanoColor },
  attention: { text: 'Требует внимания', color: orangeColor, bgcolor: peachСolor },
  urgent: { text: 'Неотложные', color: themeColor, bgcolor: linenСolor },
};

export const paramsPriorityFilter: ParamsPriorityFilter[] = [
  {
    title: 'Регулярные',
    id: '01',
    value: 'regular',
    bgcolor: graylightColor,
  },
  {
    title: 'Требуют внимания',
    id: '02',
    value: 'attention',
    bgcolor: orangeColor,
  },
  {
    title: 'Неотложные',
    id: '03',
    value: 'urgent',
    bgcolor: themeColor,
  },
];

export const periodsNotificationsData: PeriodsNotificationsParams[] = [
  {
    title: 'За сегодня',
    id: '01',
  },
  {
    title: 'За неделю',
    id: '02',
  },
  {
    title: 'За месяц',
    id: '03',
  },
  {
    title: 'За год',
    id: '04',
  },
  {
    title: 'Произвольный период',
    id: '05',
  },
];

export const eventTypes: IEventTypes[] = [
  { title: 'Добавлен пациент', id: 'A' },
  { title: 'Изменение данных', id: 'C' },
  { title: 'Откреплен пациент', id: 'D' },
  { title: ' Смена программы', id: 'E' },
];

export const modalAddFromFileContent = {
  title: 'Прикрепление пациентов из файла',
  subTitle: 'Пользователи будут подключены к программе в самое ближайшее время!',
  successTitle: 'Успешно добавлено',
  errorText:
    'Обратите внимание, что некоторые пациенты не были загружены в систему из-за некорректно указанных данных. Вы можете скачать список пациентов с ошибками, исправить их и повторить операцию заново в разделе “Пациенты"',
  loadingTitle: 'Идёт процесс прикрепления пациентов',
  loadingSubtitle: 'Пожалуйста подождите, это может занять несколько минут.',
  dowloadButtonText: 'Скачать файл с неприкрепленными пациентами',
};

export const modalDetachContent = {
  title: 'Открепление пациентов из файла',
  subTitle: 'Пользователи будут откреплены от программы в самое ближайшее время!',
  successTitle: 'Успешно откреплено',
  errorText:
    'Перечисленные пациенты не были загружены в систему из-за некорректно указанных данных. Вы можете скачать список пациентов с ошибками, исправить их и повторить операцию заново в разделе "Пациенты"',
  loadingTitle: 'Идёт процесс открепления пациентов',
  loadingSubtitle: 'Пожалуйста подождите, это может занять несколько минут.',
  dowloadButtonText: 'Скачать файл с неоткрепленными пациентами',
};

export const statusesHR: StatusesHR = {
  need_approval: {
    id: 1,
    title: 'Требует согласования',
    value: 'need_approval',
    color: themeColor,
  },
  sent_for_revision: {
    id: 2,
    title: 'Отправлено на доработку',
    value: 'sent_for_revision',
    color: themeColor,
  },
  agreed: {
    id: 3,
    title: 'Согласовано',
    value: 'agreed',
    color: greenColor,
  },
  sent_for_signing: {
    id: 4,
    title: 'Отправлен на подписание',
    value: 'sent_for_signing',
    color: greenColor,
  },
  signed: {
    id: 5,
    title: 'Подписан',
    value: 'signed',
    color: greenColor,
  },
};

export const operationTypes = {
  detach_slots_from_file: 'Открепление из файла',
  create_slots_from_file: 'Прикрепление из файла',
};

export const patientTypes = [
  {
    id: 0,
    title: 'Родственник',
    value: 'relative',
  },
  {
    id: 1,
    title: 'Сотрудник',
    value: 'employee',
  },
];
// временная подмена ключей
export const relativeTypesMap = {
  children: 'child',
  spouse: 'spouse',
  parents: 'parent',
  civil_spouse: 'civil_spouse',
};

export const relativeNamesMap = {
  children: 'Ребёнок',
  spouse: 'Супруг(а)',
  parents: 'Родитель/законный представитель',
  civil_spouse: 'Гражданский супргуг(а)',
};

export const currentPermissionGroupsName = ['HR_slots', 'HR_finance', 'HR_docs', 'HR_programs'];

export const inputTypeData = [
  {
    title: 'ФИО',
    value: 'search',
  },
  {
    title: 'ID',
    value: 'patient__id__icontains',
  },
  {
    title: 'Номер полиса',
    value: 'policy_number__icontains',
  },
  {
    title: 'Телефон',
    value: 'patient_phone',
  },
  {
    title: 'Паспорт',
    value: 'patient__passport_number__icontains',
  },
  {
    title: 'Табельный номер',
    value: 'number__icontains',
  },
  {
    title: 'Email',
    value: 'greetings_email__icontains',
  },
  {
    title: 'Доп. информация',
    value: 'additional_info__icontains',
  },
];

export const tableFieldsCollection = [
  {
    title: 'ФИО',
    value: 'patient__last_name',
  },
  {
    title: 'Юр.лицо',
    value: 'program__service_period__company__title',
  },
  {
    title: 'Программа',
    value: 'program__title',
  },
  {
    title: 'Статус',
    value: '_status',
  },
  {
    title: 'Дата рождения',
    value: 'patient__birth_date',
  },
  {
    title: 'Период обслуживания',
    value: 'start_date',
  },
  {
    title: 'Email',
    value: 'greetings_email__icontains',
  },
  {
    title: 'Номер полиса',
    value: 'policy_number__icontains',
  },
  {
    title: 'Табельный номер',
    value: 'number__icontains',
  },
  {
    title: 'Доп. информация',
    value: 'additional_info__icontains',
  },
];

export const requiredTableFields = [{
  title: 'ФИО',
  value: 'patient__last_name',
},
{
  title: 'Юр.лицо',
  value: 'program__service_period__company__title',
},
{
  title: 'Программа',
  value: 'program__title',
},
{
  title: 'Статус',
  value: '_status',
}]

export const defaultTableFields = ['patient__birth_date', 'start_date']

export const franchizeTypeToShow = ['black', 'electronic', 'white']

export const MAX_EMAIL_LENGTH = 30;

export const MAX_CONTENT_LENGTH = 15;