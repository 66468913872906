import backgroundImage from 'assets/images/login-bg.png';
import { guyabanoColor } from 'utils/uiConstants';

export const appStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  minHeight: '100%',
  backgroundColor: guyabanoColor,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const appLoginStyle = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: guyabanoColor,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  zIndex: -1,
};
