import React from 'react';

export const actionModalTitleStyle: React.CSSProperties = {
  margin: '0',
  flex: '0 0 auto',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.01em',
  color: '#3e414d',
};

export const actionModalSubtitleStyle: React.CSSProperties = {
  margin: '40px 0 0',
  flex: '0 0 auto',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: '#3e414d',
};

export const actionModalTextStyle: React.CSSProperties = {
  margin: '12px 0 0',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.02em',
  color: 'rgba(62, 65, 77, 0.6)',
};

export const actionModalListStyle: React.CSSProperties = {
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  marginTop: '40px',
  overflowY: 'auto',
  height: '270px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
};

export const actionModaListItemStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const actionModalListCellStyle: React.CSSProperties = {
  minWidth: '170px',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
};
