import { useMutation } from 'react-query';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { IValuesAuth } from 'interfaces/global/globalInterfaces';
import { AuthHookData, AuthHookParams } from 'interfaces/hooks/globalHooksInterfaces';
import { FetchError } from 'utils/FetchError';
import { useSnackbar } from 'notistack';

export const useAuth = ({ onSuccess, onError }: AuthHookParams): AuthHookData => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isSuccess, isLoading } = useMutation(
    async (values: IValuesAuth) => {
      const keySession = localStorage.getItem('keySession');
      if (!keySession) {
        enqueueSnackbar('Session error', { variant: 'error' });
        throw new Error('Session key error');
      }
      const res = await fetch(`${API_URL}/auth/${keySession}/login/`, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    handleUseAuth: mutate,
    isSuccessAuth: isSuccess,
    isLoadingAuth: isLoading,
  };
};
