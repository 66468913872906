import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';
import { ListSupplementaryAgreementResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/supplementary-agreement/types';
import { SupplementaryAgreementsSupplementaryAgreementHrRq } from '@bestdoctor/core-arch-api/models';
import {
  GetSupplementaryAgreementsData,
  GetSupplementaryAgreementsParams,
} from 'interfaces/hooks/documentsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetSupplementaryAgreements = ({
  company,
  period,
  ordering,
  isSupplementaryAgreementsAllowed,
  onError,
}: GetSupplementaryAgreementsParams): GetSupplementaryAgreementsData => {
  const {
    isFetching,
    isLoading,
    error,
    fetchNextPage,
    data: dataSupplementaryAgreements,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery(
    ['dataSupplementaryAgreements', ordering, company, period],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      let params = '';

      params += params.length === 0 ? `?page=${pageParam}&per_page=20` : `&page=${pageParam}&per_page=20`;

      if (company) {
        params += `&company=${company.uuid}`;
      }

      if (ordering) {
        params += `&ordering=${ordering}`;
      }

      if (period) {
        params += `&service_period=${period.uuid}`;
      }

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/supplementary_agreements/${params}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    {
      getNextPageParam: (
        lastPage: ListSupplementaryAgreementResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
      ) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number | undefined = lastPage.meta?.page;
        let nextPage = 0;
        if (currentPage) {
          nextPage = currentPage + 1;
        }
        return nextPage <= allPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onError,
      enabled: isSupplementaryAgreementsAllowed,
      select: (data: {
        pages: ListSupplementaryAgreementResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'][];
        pageParams: unknown[];
      }) => {
        const pages = data.pages.map(
          (page: ListSupplementaryAgreementResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => page.data
        );

        const slotsSupplementaryAgreements: SupplementaryAgreementsSupplementaryAgreementHrRq[] = [];

        pages.forEach((arr: SupplementaryAgreementsSupplementaryAgreementHrRq[] | undefined) => {
          arr?.forEach((item: SupplementaryAgreementsSupplementaryAgreementHrRq) =>
            slotsSupplementaryAgreements.push(item)
          );
        });

        return slotsSupplementaryAgreements.map((item: SupplementaryAgreementsSupplementaryAgreementHrRq) => ({
          uuid: item.uuid,
          number: item.file_title,
          supplementary_agreement_file: item.supplementary_agreement_file,
          appendix: [
            item.addendum_1_file,
            item.addendum_2_file,
            item.addendum_3_file,
            item.additional_addendums_file,
            item.calculator_file,
          ],
          hr_status: item.hr_status,
          signed_with: item.signed_with,
          contact_address: item.address,
          contact_phone: item.contact_phone,
          contact_person: item.contact_person,
          original_file: item.original_file,
          original_bill_file_url: item.original_bill_file_url,
        }));
      },
    }
  );

  return {
    dataSupplementaryAgreements,
    isLoadingSupplementaryAgreements: isLoading,
    isErrorSupplementaryAgreements: !!error,
    refetchSupplementaryAgreements: refetch,
    fetchNextPage,
    hasNextPageSupplementaryAgreements: hasNextPage,
    isFetchingNextPageSupplementaryAgreements: isFetchingNextPage,
    isFetchingSupplementaryAgreements: isFetching,
  };
};
