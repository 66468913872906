import { useInfiniteQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';
import { ListSupplementaryAgreementLogResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/supplementary-agreement-log/types';
import {
  GetSupplementaryAgreementsLogsParams,
  SupplementaryAgreementLogsData,
} from 'interfaces/hooks/documentsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetSupplementaryAgreementsLogs = ({
  uuid,
  ordering,
  onSuccess,
  onError,
}: GetSupplementaryAgreementsLogsParams): SupplementaryAgreementLogsData => {
  const {
    isFetching,
    isLoading,
    error,
    refetch,
    fetchNextPage,

    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['dataSupplementaryAgreements'],
    async ({ pageParam = 1 }) => {
      await handleRefreshToken();

      let params = '';

      params += params.length === 0 ? `?page=${pageParam}&per_page=20` : `&page=${pageParam}&per_page=20`;

      if (uuid) {
        params += `&supplementary_agreement=${uuid}`;
      }

      if (ordering) {
        params += `&ordering=${ordering}`;
      }

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/supplementary_agreement_logs/${params}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    {
      getNextPageParam: (
        lastPage: ListSupplementaryAgreementLogResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']
      ) => {
        const allPage: number = getPagesQuantity(lastPage);
        const currentPage: number | undefined = lastPage.meta?.page;
        let nextPage = 0;
        if (currentPage) {
          nextPage = currentPage + 1;
        }

        return nextPage <= allPage ? nextPage : undefined;
      },
      onSuccess,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onError,
      select: (data: {
        pages: ListSupplementaryAgreementLogResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr'][];
        meta: unknown[];
      }) => {
        const pages = data.pages.map(
          (page: ListSupplementaryAgreementLogResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) =>
            page.data
        );

        const slotsSupplementaryAgreementsLogs = pages.flat();

        return slotsSupplementaryAgreementsLogs.map((item) => ({
          uuid: item?.uuid,
          created_at: item?.created_at,
          user_email: item?.user_email,
          hr_status: item?.hr_status,
          appendix: item?.user_email !== 'Сотрудник БД' ? item?.supplementary_agreement_request?.supplementary_agreement_document.map((req) => req.document) : [item?.addendum_1_file, item?.addendum_2_file, item?.addendum_3_file, item?.additional_addendums_file, item?.calculator_file, item?.supplementary_agreement_file],
          supplementary_agreement_uuid: item?.supplementary_agreement.uuid,
          description: item?.supplementary_agreement_request?.comment
        }));
      },
    }
  );

  return {
    isLoadingSupplementaryAgreementsLogs: isLoading,
    isErrorSupplementaryAgreementsLogs: error,

    fetchNextPage,
    hasNextPageSupplementaryAgreementsLogs: hasNextPage,
    isFetchingNextPageSupplementaryAgreementsLogs: isFetchingNextPage,
    isFetchingSupplementaryAgreementsLogs: isFetching,

    refetchSupplementaryAgreementsLogs: refetch,
  };
};
