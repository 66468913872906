import { addMinutes } from 'date-fns';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

export const handleRefreshToken = async () => {

  const token = localStorage.getItem('token');
  const refresh = localStorage.getItem('refresh');

  const tokenDate = localStorage.getItem('tokenExpirationDate');
  const parseTokenDate: number = tokenDate ? JSON.parse(tokenDate) : null;


  const dateToday = new Date();
  const todayTimestamp: number = new Date().getTime();


  if (!parseTokenDate || !token || !refresh) {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('tokenExpirationDate');

    window.location.href = '/';
  }

  if (parseTokenDate <= todayTimestamp) {
    const objRefresh = { refresh };

    const res = await fetch(`${API_URL}/auth/token/`, {
      method: 'POST',
      headers: {
        Accept: `${HEADER_ACCEPT_HR}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objRefresh),
    });
    // если запрос успешен, то обновить данные auth в localStorage
    if (res.ok) {
      const result = res.json();

      result.then(({ data }) => {
        const dataAccess: string = data.access;
        const dataRefresh: string = data.refresh;
        const tokenEndTime = addMinutes(dateToday, 59).getTime();

        localStorage.setItem('token', dataAccess);
        localStorage.setItem('refresh', dataRefresh);

        localStorage.setItem('tokenExpirationDate', JSON.stringify(tokenEndTime));
      });
    } else {
      // иначе очистить localStorage
      // и перенаправить на страницу входа
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('tokenExpirationDate');

      window.location.href = '/';
    }
  }
};
