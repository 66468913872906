import React from 'react';
import { Box } from '@mui/system';

import logoSrc from 'assets/images/logo-main.svg';

interface Props {
  variant?: 'primary' | 'secondary';
}

export const Logo: React.FC<Props> = ({ variant = 'primary' }) => (
  <Box
    component="img"
    src={logoSrc}
    alt="Логотип"
    loading="lazy"
    style={{
      display: 'flex',
      width: '200px',
      ...(variant === 'secondary' ? { filter: 'invert(48%)' } : {}),
    }}
  />
);
