import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { DetachPatientsHook, DetachPatientsHookData, DetachDateData } from 'interfaces/hooks/patientsHooksInterfaces';
import { format, parse } from 'date-fns';

export const useDetachPatients = ({
  massDetach,
  patient,
  selectedPatients,
  onSuccess,
  onError,
}: DetachPatientsHook): DetachPatientsHookData => {
  const { mutate, isLoading } = useMutation(
    async (dataForDetach: DetachDateData) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const url = massDetach
        ? `${API_URL}/employees/bulk_detach_request/`
        : `${API_URL}/employees/${patient?.uuid}/detach_request/`;

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          massDetach
            ? {
              detach_date: dataForDetach.detach_date,
              slot_uuids: selectedPatients,
            }
            : dataForDetach
        ),
      });

      const date = format(parse(dataForDetach.detach_date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');

      return {
        res,
        message: massDetach
          ? `Пациенты будут откреплены ${date} г.`
          : `${patient?.full_name} будет откреплен(a) ${date} г.`,
      };

    },
    {
      enabled: massDetach ? !!selectedPatients : !!patient,
      onSuccess,
      onError,
    }
  );

  return {
    handleDetachPatients: mutate,
    isLoadDetachPatients: isLoading,
  };
};
