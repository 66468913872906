import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { ListCompanyRoleResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-roles/types';
import { FetchError } from 'utils/FetchError';
import { GetPermissionsParams } from 'interfaces/hooks/permissionsHooksInterfaces';

export const useGetUserRole = ({
  isLoggedIn, onError
}: GetPermissionsParams): {rolesData: ListCompanyRoleResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data']} => {
  const { data: rolesData } = useQuery(
    ['user_role'],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/company_roles/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onError,
      enabled: isLoggedIn,
      select: (res) => res?.data
    }
  );

  return { rolesData };
};
