import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import {
  ViewedNotificationsHook, ViewedNotificationsHookData
} from 'interfaces/notifications/notificationsInterfaces';

export const useViewedNotifications = ({
  onSuccess,
}: ViewedNotificationsHook): ViewedNotificationsHookData => {
  const { mutate, error, isLoading } = useMutation(
    async (notificationUuid: string) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/notifications/${notificationUuid}/status`, {
        method: 'PUT',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          status: 'viewed'
        }),
      });

      if (res.ok || res.status === 400 || res.status === 420) {
        return res;
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      onSuccess,
    }
  );

  return {
    handleViewedNotifications: mutate,
    isLoadingViewedNotifications: isLoading,
    isErrorViewedNotifications: !!error,
  };
};
