import { SystemStyleObject, Theme } from '@mui/system';

export const dialogTableModalStyle: SystemStyleObject<Theme> = {
  '& .MuiDialog-paper': {
    width: '100%',
    maxHeight: '500px',
    overflowY: 'scroll',
    maxWidth: '1356px',
    padding: '20px 24px 24px',
  },
};

export const modalTableHeaderStyle: SystemStyleObject<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
};
