import React, { useEffect, useState } from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { DocumetsChangeHistoryTable } from 'components/TablesDocs/DocumentsChangeHistoryTable';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { useGetSupplementaryAgreementsLogs } from 'hooks/documents/useGetSupplementaryAgreementsLogs';
import { DocumetsChangeHistoryProps, SupplementaryAgreementsLog } from 'interfaces/documents/documentsInterfaces';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { useSnackbar } from 'notistack';

export const DocumetsChangeHistory: React.FC<DocumetsChangeHistoryProps> = ({ data, open, handleClose }) => {
  const [orderingChangeHistory, setOrderingChangeHistory] = useState<string>('');
  const [changeHistory, setChangeHistory] = useState<SupplementaryAgreementsLog[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const {
    refetchSupplementaryAgreementsLogs,
    fetchNextPage,

    hasNextPageSupplementaryAgreementsLogs,
    isFetchingNextPageSupplementaryAgreementsLogs,
  } = useGetSupplementaryAgreementsLogs({
    uuid: data && data.uuid,
    ordering: orderingChangeHistory,

    onSuccess: (res: SupplementaryAgreementsLog[]) => {
      setChangeHistory(res);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    }
  });

  useEffect(() => {
    refetchSupplementaryAgreementsLogs();
  }, [orderingChangeHistory, refetchSupplementaryAgreementsLogs, data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '1312px',
        },
      }}
    >
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            История изменений
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <DocumetsChangeHistoryTable
        fetchNextPage={fetchNextPage}
        hasNextPageSupplementaryAgreementsLogs={hasNextPageSupplementaryAgreementsLogs}
        isFetchingNextPageSupplementaryAgreementsLogs={isFetchingNextPageSupplementaryAgreementsLogs}
        handleOrderingChangeHistory={setOrderingChangeHistory}
        orderingChangeHistory={orderingChangeHistory}
        changeHistory={changeHistory}
      />
      <Box
        sx={{
          marginLeft: '24px',
          marginRight: '24px',
          marginBottom: '24px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <MainButton textButton="Закрыть" mainBtn handleClick={handleClose} />
      </Box>
    </Dialog>
  );
};
