import { z } from 'zod';

export const validationPatient = (reqPhone: boolean, reqPass: boolean): z.ZodObject<{
  last_name: z.ZodString;
  first_name: z.ZodString;
  middle_name: z.ZodString;
  gender: z.ZodString;
  birth_date: z.ZodString;
  nationality: z.ZodString;
  address: z.ZodString;
  phone: z.ZodString | z.ZodNullable<z.ZodString>;
  email: z.ZodString;
  position: z.ZodString;
  number: z.ZodString;
  passport_number: z.ZodString | z.ZodNullable<z.ZodString>;
  passport_date: z.ZodString | z.ZodNullable<z.ZodString>;
  passport_given: z.ZodString | z.ZodNullable<z.ZodString>;
  program: z.ZodString;
  start_date: z.ZodString;
  patient_relative: z.ZodString | z.ZodOptional<z.ZodString>;
  relative_type: z.ZodString | z.ZodOptional<z.ZodString>;
  patient_relative_additional: z.ZodString | z.ZodOptional<z.ZodString>;
  relative_type_additional: z.ZodString | z.ZodOptional<z.ZodString>;
  patient_type: z.ZodString | z.ZodOptional<z.ZodString>;
  additional_info: z.ZodString | z.ZodNullable<z.ZodString>;
}> => z.object({
  last_name: z.string().min(1, { message: 'Обязательное поле' }).max(40, { message: 'Недопустимая длина строки'}),
  first_name: z.string().min(1, { message: 'Обязательное поле' }).max(40, { message: 'Недопустимая длина строки'}),
  middle_name: z.string().max(40, { message: 'Недопустимая длина строки'}),
  gender: z.string().min(1, { message: 'Обязательное поле' }),
  birth_date: z.string().min(1, { message: 'Обязательное поле' }),
  nationality: z.string().min(1, { message: 'Обязательное поле' }),
  address: z.string().min(1, { message: 'Обязательное поле' }),
  phone: reqPhone ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  email: z.string().min(1, { message: 'Обязательное поле' }).email({ message: 'Некорректный email' }),
  position: z.string(),
  number: z.string(),
  passport_number: reqPass ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  passport_date: reqPass ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  passport_given: reqPass ? z.string().min(1, { message: 'Обязательное поле' }) : z.string().nullable(),
  program: z.string().min(1, { message: 'Обязательное поле' }),
  start_date: z.string().min(1, { message: 'Обязательное поле' }),
  patient_relative: z.string().optional(),
  relative_type: z.string().optional(),
  patient_relative_additional: z.string().optional(),
  relative_type_additional: z.string().optional(),
  patient_type: z.string().optional(),
  additional_info: z.string().nullable()
});
