import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import { ListSlotResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/slots/types';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';
import {
  SearchPatientsHookPaginationResponseData,
  SearchRelativeSlotsData,
  SearchRelativeSlotsParams,
  SearchRelativeSlotsQueryParams,
} from 'interfaces/hooks/patientsHooksInterfaces';
import { SlotsSlotHrRq } from '@bestdoctor/core-arch-api/models';
import { getFormattedDate } from 'utils/dateHelpers';
import { FetchError } from 'utils/FetchError';

export const useSearchRelativeSlots = ({
  holding,
  fullname,
  isSlotsAviable,
  onError
}: SearchRelativeSlotsParams): SearchRelativeSlotsData => {
  const { data, isFetching, isLoading, isError, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['dataCompanyPatients', holding, fullname],
      async ({ pageParam = 1 }) => {
        await handleRefreshToken();

        const token = localStorage.getItem('token');

        const queryParams: SearchRelativeSlotsQueryParams = {
          holding: holding || '',
          page: pageParam,
          search: fullname || '',
          per_page: 200,
          status: ['active', 'planned'],
          patient_type: 'employee',
        };

        const res = await fetch(`${API_URL}/slots/?${queryString.stringify(queryParams)}`, {
          method: 'GET',
          headers: {
            Accept: `${HEADER_ACCEPT_HR}`,
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          return res.json();
        }
        throw new FetchError(res);
      },

      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        enabled: isSlotsAviable && (!!holding || !!fullname),
        onError,
        getNextPageParam: (lastPage: ListSlotResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
          const allPage: number = getPagesQuantity(lastPage);
          const currentPage: number = lastPage.meta?.page || 0;
          const nextPage = currentPage + 1;

          return nextPage <= allPage ? nextPage : undefined;
        },
        select: (res: SearchPatientsHookPaginationResponseData) => {
          const pageParams = res.pages[0].meta;

          const slotsPatients: (SlotsSlotHrRq | undefined)[] = res?.pages.map((page) => page.data).flat();

          const patients = slotsPatients.map((item: SlotsSlotHrRq | undefined) => {
            if (item === undefined) return;

            return {
              title: item.patient.full_name,
              uuid: item.patient.uuid,
              date_birth: getFormattedDate(item.patient.birth_date, 'dd.MM.yyyy'),
            };
          });
          return { patients, pageParams };
        },
      }
    );

  return {
    searchPatientsRelative: data,

    isLoadingSearchPatientsRelative: isLoading,
    isErrorSearchPatientsRelative: isError,

    hasNextPageSearchPatientsRelative: hasNextPage,
    isFetchingNextPageSearchPatientsRelative: isFetchingNextPage,
    isFetchingSearchPatientsRelative: isFetching,

    fetchNextPageSearchPatientsRelative: fetchNextPage,
    refetchSearchPatientsRelative: refetch,
  };
};
