import React from 'react';

import { Box } from '@mui/system';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';

import { Company, DataPeriod, FinanceFiltersProps } from 'interfaces/global/globalInterfaces';
import { mainFiltersContainerStyle, mainFiltersSectionStyle, mainFiltersWrapperStyle } from 'ui/patients/mainFilters';

export const FinanceFilters: React.FC<FinanceFiltersProps> = ({
  dataPeriods,
  allCompanies,

  selectedCompanyByFinance,
  handleSelectedCompanyByFinance,

  selectedPeriodByFinance,
  handleSelectedPeriodByFinance,
}) => (
  <Box component="section" sx={mainFiltersSectionStyle}>
    <Box sx={mainFiltersWrapperStyle}>
      <Box sx={mainFiltersContainerStyle}>
        <AutocompleteInput
          label="Компания"
          placeholder="Компания"
          data={allCompanies}
          value={selectedCompanyByFinance}
          handleChangeObj={(_event: React.SyntheticEvent, newValue: Company) => {
            sessionStorage.setItem('mainFilterCompanyFinance', JSON.stringify(newValue || ''));
            sessionStorage.setItem('mainFilterPeriodFinance', JSON.stringify(''));

            handleSelectedCompanyByFinance(newValue);
          }}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <AutocompleteInput
          label="Период данных"
          placeholder="Период данных"
          data={selectedCompanyByFinance ? dataPeriods || [] : []}
          value={selectedCompanyByFinance && selectedPeriodByFinance ? selectedPeriodByFinance : '(Активный период)'}
          handleChangeObj={(_event: React.SyntheticEvent, newValue: DataPeriod) => {
            sessionStorage.setItem('mainFilterPeriodFinance', JSON.stringify(newValue || ''));

            handleSelectedPeriodByFinance(newValue);
          }}
        />
      </Box>
    </Box>
  </Box>
);
