import React from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { AddPatientDialog } from 'interfaces/shared/modals/modalsInterfaces';

export const CrossingByCompanyPeriod: React.FC<AddPatientDialog> = ({
  open,
  handleClose,
  handleSubmit,
  handleCancel,
}) => (
  <Dialog open={open} onClose={handleClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
    <Box sx={modalHeaderStyle}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" sx={{ ...modalTitleStyle, marginBottom: '52px' }}>
          Подтверждение подкрепления
        </Typography>
      </Box>
      <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>
    <Box
      component="p"
      sx={{
        margin: '-24px 0 0 24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.02em',
        color: 'rgba(62, 65, 77, 0.6)',
      }}
    >
      Пациент прикреплен в другой компании в этот же период. Прикрепить?
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginRight: '24px',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <MainButton textButton="Нет" handleClick={handleCancel} />
        <MainButton textButton="Да" mainBtn handleClick={handleSubmit} />
      </Box>
    </Box>
  </Dialog>
);
