import React from 'react';

import Button from '@mui/material/Button';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { useGetExpendituresToExcel } from 'hooks/finance/useGetExpendituresToExcel';

import { mainBtnStyle } from 'ui/buttons/buttonsMain';

import { useSnackbar } from 'notistack';
import { ActionWithFinanceBtnProps } from 'interfaces/finance/financeInterfaces';
import { ErrorData } from 'interfaces/global/globalInterfaces';

export const ActionWithFinanceBtn: React.FC<ActionWithFinanceBtnProps> = ({ isFronting, selectedPeriodByFinance }) => {
  const { enqueueSnackbar } = useSnackbar();

  const periodId = selectedPeriodByFinance ? selectedPeriodByFinance.uuid : null;

  const { refetchExportExpendituresToExcel } = useGetExpendituresToExcel({
    servicePeriodUuid: periodId,
    onSuccess: () => {
      enqueueSnackbar('Файл был сформирован и отправлен на ваш E-mail', { variant: 'success' });
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  return (
    <Button
      disabled={!isFronting}
      sx={() => mainBtnStyle(true, false)}
      variant="text"
      onClick={refetchExportExpendituresToExcel}
      startIcon={
        <DoneOutlinedIcon
          sx={{
            width: '12px',
            height: '12px',
            borderRadius: '2px',
            backgroundColor: '#fff',
            color: !isFronting ? 'rgba(109, 109, 109, 1)' : 'rgba(255, 109, 109, 1)',
          }}
        />
      }
    >
      Выгрузить данные
    </Button>
  );
};
