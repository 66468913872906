import { SxProps } from "@mui/system/styleFunctionSx";

export const modalContainer: SxProps = {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '560px',
    },
  };

export const textContent: SxProps = {
    margin: '0 24px 0 24px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: 'rgba(62, 65, 77, 0.6)',
    textAlign: 'left'
  }

export const buttonsBlock: SxProps = {
    display: 'flex',
    gap: '32px',
    marginRight: '24px',
    marginTop: '20px',
    marginBottom: '20px',
  }  