import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { GetOneUserPermissionData, GetOneUserPermissionParams } from 'interfaces/hooks/permissionsHooksInterfaces';
import { ListDetailUserCompanyPermissionsResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-permissions/types';
import { FetchError } from 'utils/FetchError';

export const useGetOneUserPermissions = ({
  companyUuid,
  userUuid,
  onSuccess,
  onError,
}: GetOneUserPermissionParams): GetOneUserPermissionData => {
  const { data: userRoleData } = useQuery(
    ['users_roles', userUuid, companyUuid],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/company_permissions/${userUuid}/?company=${companyUuid}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!companyUuid,
      select: (res: ListDetailUserCompanyPermissionsResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) =>
        res.data,
    }
  );

  return {
    userRoleData,
  };
};
