import { NewPassModal } from 'shared/modals/NewPassModal/NewPassModal';
import { EmailModal } from 'shared/modals/EmailModal/EmailModal';
import { SMSModal } from 'shared/modals/SMSModal/SMSModal';
import React, { useEffect, useState } from 'react';
import { usePasswordReset } from 'hooks/resetPassword/usePasswordReset';
import { SuccessModal } from 'shared/modals/SuccessModal/SuccessModal';

interface ResetPasswordProps {
  handleModalPass: () => void;
  isModalPass: boolean;
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({ handleModalPass, isModalPass }) => {
  const [isSMSModal, setSMSModal] = useState<boolean>(false);
  const [isModalNewPass, setModalNewPass] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const {
    sendEmail,
    timer,
    emailIsLoading,
    emailIsError,
    sendSms,
    smsIsLoading,
    smsIsError,
    sendPassword,
    passwordIsError,
    passwordIsLoading,
    isSuccess,
  } = usePasswordReset();

  const handleModalNewPass = (): void => {
    setModalNewPass(false);
  };

  const handleClickSMSModalOpen = (): void => {
    setSMSModal(true);
  };

  const handleSMSModalClose = (): void => {
    setSMSModal(false);
  };

  const handleOpenModalNewPass = (): void => {
    setModalNewPass(true);
  };

  const handleSuccessModalClose = (): void => setSuccess(false);

  useEffect(() => {
    let timeoutId: number;
    if (isSuccess) {
      setSuccess(true);
      timeoutId = window.setTimeout(() => setSuccess(false), 10000);
    }
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isSuccess]);

  return (
    <>
      <EmailModal
        sendEmail={sendEmail}
        open={isModalPass}
        handleClose={handleModalPass}
        handleClickSMSModalOpen={handleClickSMSModalOpen}
        emailIsLoading={emailIsLoading}
        emailIsError={emailIsError}
      />
      <SMSModal
        sendEmail={sendEmail}
        timer={timer}
        open={isSMSModal}
        handleClose={handleSMSModalClose}
        handleNextStep={handleOpenModalNewPass}
        sendSms={sendSms}
        smsIsLoading={smsIsLoading}
        smsIsError={smsIsError}
      />
      <NewPassModal
        sendPassword={sendPassword}
        passwordIsError={passwordIsError}
        passwordIsLoading={passwordIsLoading}
        open={isModalNewPass}
        handleClose={handleModalNewPass}
      />
      <SuccessModal handleClose={handleSuccessModalClose} open={success} />
    </>
  );
};
