import { UsePasswordReset, ResetPasswordRequestData } from 'interfaces/hooks/passwordResetHooksInterfaces';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

export const usePasswordReset = (): UsePasswordReset => {
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(0);
  const [codeUrl, setCodeUrl] = useState('');
  const [newPasswordUrl, setNewPasswordUrl] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        }
        return prevTimer;
      });
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timer]);

  const challenge = useMutation<void, Error, string>(async (mail?: string) => {
    if (mail) {
      setEmail(mail);
    }
    const challengeData: ResetPasswordRequestData = await fetch(`${API_URL}/authentication/`, {
      method: 'POST',
      headers: { Accept: `${HEADER_ACCEPT_HR}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: email || mail,
      }),
    })
      .then((result) => result.json())
      .catch((err) => {
        throw new Error(`${err.status}. ${err.statusText}`);
      });
    const passwordData: ResetPasswordRequestData = await fetch(challengeData.data.allowed_steps[0].url, {
      method: 'POST',
      headers: { Accept: `${HEADER_ACCEPT_HR}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        challenge_type: 'email',
      }),
    })
      .then(async (result) => {
        const res: ResetPasswordRequestData = await result.json();
        setTimer(res.data.allowed_steps.find((item) => item.type === 'challenge')?.wait_seconds || 0);
        return res;
      })
      .catch((err) => {
        throw new Error(`${err.status}. ${err.statusText}`);
      });
    const tokenStep = passwordData.data.allowed_steps.find((item) => item.type === 'token');
    if (!tokenStep) {
      throw new Error();
    }
    tokenStep && setCodeUrl(tokenStep.url);
  });

  const sms = useMutation(async (code: number) => {
    const result = await fetch(codeUrl, {
      method: 'POST',
      headers: { Accept: `${HEADER_ACCEPT_HR}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        code,
      }),
    });
    if (result.ok) {
      const data: ResetPasswordRequestData = await result.json();
      const step = data.data.allowed_steps.find((item) => item.type === 'reset_password');
      if (!step) {
        throw new Error();
      }
      step?.url && setNewPasswordUrl(step.url);
      step?.access_token && setToken(step.access_token);
      return;
    }
    throw new Error(`${result.status}. ${result.statusText}`);
  });

  const newPassword = useMutation(async (password: string) => {
    const res = await fetch(newPasswordUrl, {
      method: 'POST',
      headers: { Accept: `${HEADER_ACCEPT_HR}`, 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        password,
      }),
    });
    if (!res.ok) {
      throw new Error(`${res.status}. ${res.statusText}`);
    }
    return res;
  });

  return {
    sendEmail: challenge.mutateAsync,
    emailIsLoading: challenge.isLoading,
    emailIsError: challenge.isError,
    email,
    setEmail,
    timer,
    sendSms: sms.mutateAsync,
    smsIsLoading: sms.isLoading,
    smsIsError: sms.isError,
    sendPassword: newPassword.mutateAsync,
    passwordIsLoading: newPassword.isLoading,
    passwordIsError: newPassword.isError,
    isSuccess: newPassword.isSuccess,
  };
};
