import { GetTemplateForDetachToExcelData, GetTemplateForDetachToExcelParams } from 'interfaces/hooks/otherHooksInterfaces';
import { useMutation } from 'react-query';
import { FetchError } from 'utils/FetchError';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { handleRefreshToken } from 'utils/refreshToken';

export const useGetTemplateForDetachToExcel = ({
  onSuccess,
  onError,
}: GetTemplateForDetachToExcelParams): GetTemplateForDetachToExcelData => {
  const { mutate, isLoading } = useMutation(
    ['dataTemplateForDetachExcel'],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/bulk_detach_from_file_template/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'template.xlsx';
        a.click();
        a.remove();

        return res;
      }
      throw new FetchError(res);
    },
    {
      onSuccess,
      onError,
    }
  );

  const handleTemplateForDetachToExcel: () => Promise<void> = mutate;
  const isLoadingTemplateForDetachToExcel: boolean = isLoading;

  return {
    handleTemplateForDetachToExcel,
    isLoadingTemplateForDetachToExcel,
  };
};
