import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { ListCompanyRoleResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-roles/types';
import { GetCompaniesPermissionsData, GetCompaniesPermissionsParams } from 'interfaces/hooks/companiesHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetCompaniesPermissions = ({ onError }: GetCompaniesPermissionsParams): GetCompaniesPermissionsData => {
    const { data: companiesPermissionsData, isLoading: isLoadingCompanies } = useQuery(
        ['companies_permissions'],
        async () => {
            await handleRefreshToken();

            const token = localStorage.getItem('token');

            const res = await fetch(`${API_URL}/company_roles/?role__in=hr_account,hr_admin&per_page=200`, {
                method: 'GET',
                headers: {
                    Accept: `${HEADER_ACCEPT_HR}`,
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.ok) {
                return res.json();
            }
            throw new FetchError(res);
        },
        {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            select: (res: ListCompanyRoleResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => res?.data?.map((item) =>
            ({
                title: item?.company?.title,
                uuid: item?.company?.uuid,
            })
            ),
            onError
        }
    );

    return { companiesPermissionsData, isLoadingCompanies };
};