import React, { useCallback, ReactElement } from 'react';

import { TextField, Box, IconButton } from '@mui/material';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { ru } from 'date-fns/locale';
import isSameDay from 'date-fns/isSameDay';

import { IFiltersHistory, IEventTypes } from 'interfaces/patients/patientInterfaces';

import { modalFormInputStyle } from 'ui/modals/modalGlobal';
import { inputFormStyle } from 'ui/inputs/inputsForm';
import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';

import { AutocompleteInputMultiple } from 'shared/inputs/AutocompleteInputMultiple/AutocompleteInputMultiple';
import { isValid } from 'date-fns';
import { isDateActual } from 'utils/dateHelpers';
import { TableHistory } from '../TableHistory/TableHistory';

interface DNA {
  [key: string]: number;
}

export const FiltersHistory: React.FC<IFiltersHistory> = ({
  historyActions,
  hasNextPageCompanyActions,
  isFetchingNextPageCompanyActions,

  eventTypes,

  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  eventType,
  handleEventType,

  isLoadingCompanyActions,
  fetchNextPageCompanyActions,

  orderingTableHistory,
  handleOrderingTableHistory,
}) => {
  const rendersPickerDayStartDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = startDate !== null ? isSameDay(date, startDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDayEndDate = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = endDate !== null ? isSameDay(date, endDate) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  return (
    <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: 'flex',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            width: '100%',
          }}
        >
          <Box sx={() => modalFormInputStyle(false)}>
            <DesktopDatePicker
              label="Период данных с"
              value={startDate}
              renderDay={rendersPickerDayStartDate}
              PopperProps={{
                sx: datePickerPopperStyled,
              }}
              disabled={isLoadingCompanyActions}
              onChange={(newValue: Date | null) => {
                if (newValue && isValid(newValue) && isDateActual(newValue)) {
                  handleStartDate(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  label="Период данных"
                  variant="standard"
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingCompanyActions}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          sx={{ width: '28px', height: '28px' }}
                          disabled={isLoadingCompanyActions}
                          onClick={() => {
                            handleStartDate(null);
                          }}
                        >
                          <CloseOutlinedIcon fontSize="small" />
                        </IconButton>

                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Box sx={() => modalFormInputStyle(false)}>
            <DesktopDatePicker
              label="Период данных до"
              value={endDate}
              renderDay={rendersPickerDayEndDate}
              PopperProps={{
                sx: datePickerPopperStyled,
              }}
              disabled={isLoadingCompanyActions}
              onChange={(newValue: Date | null) => {
                if (newValue && isValid(newValue) && isDateActual(newValue)) {
                  handleEndDate(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  label="Период данных до"
                  variant="standard"
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingCompanyActions}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          sx={{ width: '28px', height: '28px' }}
                          onClick={() => {
                            handleEndDate(null);
                          }}
                        >
                          <CloseOutlinedIcon fontSize="small" />
                        </IconButton>

                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <AutocompleteInputMultiple
            label="Тип события"
            placeholder={eventType?.length === 0 || eventType ? '' : 'Тип события'}
            data={eventTypes}
            value={eventType}
            handleChange={useCallback(
              (_event: React.SyntheticEvent, newValue: IEventTypes[]) => {
                const array = newValue;
                const counter = array.reduce(
                  // eslint-disable-next-line no-return-assign, no-sequences
                  (acc: DNA, current: IEventTypes) => ((acc[current.title] = (acc[current.title] || 0) + 1), acc),
                  {}
                );
                const result = array.filter((obj: IEventTypes) => counter[obj.title] === 1);

                const selectedOptions = result.reduce((arr: IEventTypes[], obj: IEventTypes) => {
                  const exist: boolean = arr.some(({ title }) => obj.title === title);
                  if (!exist) {
                    arr.push(obj);
                  }
                  return arr;
                }, []);

                handleEventType(selectedOptions);
              },
              [handleEventType]
            )}
            check
            disableCloseOnSelect
          />
        </Box>
      </Box>

      <Box sx={{ margin: '32px 0 16px', overflowX: 'scroll' }}>
        <TableHistory
          historyActions={historyActions}
          isLoadingCompanyActions={isLoadingCompanyActions}
          hasNextPageCompanyActions={hasNextPageCompanyActions}
          isFetchingNextPageCompanyActions={isFetchingNextPageCompanyActions}
          fetchNextPageCompanyActions={fetchNextPageCompanyActions}
          orderingTableHistory={orderingTableHistory}
          handleOrderingTableHistory={handleOrderingTableHistory}
        />
      </Box>
    </LocalizationProvider>
  );
};
