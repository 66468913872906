import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { SendWelcomeLetterHookData, SendWelcomeLetterHookParams } from 'interfaces/hooks/patientsHooksInterfaces';

export const useSendWelcomeLetter = ({ onSuccess, onError, slotUuid }: SendWelcomeLetterHookParams): SendWelcomeLetterHookData => {
  const { mutate } = useMutation(
    async () => {
      await handleRefreshToken();
      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/slots/${slotUuid}/send_welcome/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
        }
      });

      if (res.ok) {
        return res.json();
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!slotUuid,
      onSuccess,
      onError,
    }
  );

  return {
    handleSendWelcomeLetter: mutate,
  };
};
