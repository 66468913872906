import { SystemStyleObject, Theme } from '@mui/system';

import { themeColor, grayDarkColor, grayLightDarkOpacity } from 'utils/uiConstants';

export const dialogModalStyle = (width: string): SystemStyleObject<Theme> => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: width,
    overflow: 'auto',
  },
});

export const modalHeaderStyle: SystemStyleObject<Theme> = {
  margin: '20px 24px 0',
  display: 'flex',
  justifyContent: 'space-between',
};

export const modalTitleStyle: SystemStyleObject<Theme> = {
  margin: 0,
  color: grayDarkColor,
};

export const modalSubtitleStyle: SystemStyleObject<Theme> = {
  margin: '12px 0 0',
  color: grayLightDarkOpacity,
};

export const modalDescriptionStyle: SystemStyleObject<Theme> = {
  margin: '0 0 4px',
  fontSize: '12px',
  lineHeight: '14px',
  color: grayLightDarkOpacity,
};

export const modalBlockRowStyle: SystemStyleObject<Theme> = {
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between',
};

export const modalBlockBorderStyle: SystemStyleObject<Theme> = {
  marginBottom: '8px',
  paddingBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px dashed #bcbec8',
};

export const modalBlockFooterStyle: SystemStyleObject<Theme> = {
  marginTop: '28px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '32px'
};

export const modalTypographyColor = (color: string): SystemStyleObject<Theme> => ({
  margin: '0 0 4px',
  color,
});

export const modalFormStyle: SystemStyleObject<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  overflow: 'auto',
};

export const modalFormScrollStyle = (number: number): SystemStyleObject<Theme> => ({
  maxHeight: `${number}px`,
  overflowX: 'hidden',
  overflowY: 'visible',
  paddingRight: '16px',
});

export const modalFormWrapStyle = (boolean: boolean): SystemStyleObject<Theme> => ({
  display: 'flex',
  flexDirection: boolean ? 'row' : 'column',
  marginBottom: boolean ? 0 : '40px',
  paddingTop: boolean ? '22px' : 0,
  // ? justifyContent: boolean ? 'space-between' : 'space-between'
  justifyContent: 'space-between',
  alignItems: boolean ? 'center' : 'normal',
});

export const modalFormWrapInputsStyle: SystemStyleObject<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
};

export const modalFormInputStyle = (boolean: boolean): SystemStyleObject<Theme> => ({
  width: '100%',
  marginTop: boolean ? '16px' : 0,
  marginBottom: '18px',

  '& > div': {
    width: '100%',
  },
});

export const modalAlarmIconStyle: SystemStyleObject<Theme> = {
  width: '16px',
  height: '16px',
  color: themeColor,
};

export const modalListItemBtnStyle: SystemStyleObject<Theme> = {
  width: '20px',
  height: '20px',
  maxWidth: '20px',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
};

export const modalListItemTextStyle: SystemStyleObject<Theme> = {
  flex: 'initial',

  '& > span': {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: themeColor,
  },
};
