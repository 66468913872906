import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { GetExpendituresToExcel, GetExpendituresToExcelParams } from 'interfaces/hooks/financeHookInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetExpendituresToExcel = ({ servicePeriodUuid, onSuccess, onError }: GetExpendituresToExcelParams): GetExpendituresToExcel => {
  const { isLoading, error, refetch } = useQuery(
    ['dataExportExpendituresToExcel'],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(
        `${API_URL}/service_periods/${servicePeriodUuid}/reports/expenditures/service_period_excel/`,
        {
          method: 'GET',
          headers: {
            Accept: `${HEADER_ACCEPT_HR}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: false,
      onSuccess,
      onError,
    }
  );

  return {
    isLoadExportExpendituresToExcel: isLoading,
    isErrExportExpendituresToExcel: error,

    refetchExportExpendituresToExcel: refetch,
  };
};
