import React, { useCallback } from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { IListAddAgreementAndPayments } from 'interfaces/documents/documentsInterfaces';
import { useSendToRevision } from 'hooks/documents/useSendToRevision';
import { useSnackbar } from 'notistack';
import { ErrorData } from 'interfaces/global/globalInterfaces';

interface ApproveDocumentModalProps {
  open: boolean;
  handleClose: () => void;
  handleSetDocumentModal: () => void;
  data: IListAddAgreementAndPayments | null;
  refetchSupplementaryAgreements: () => void
}

export const ApproveDocumentModal: React.FC<ApproveDocumentModalProps> = ({
  open,
  handleClose,
  handleSetDocumentModal,
  data,
  refetchSupplementaryAgreements
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { handleSendToRevision } = useSendToRevision({
    uuid: data?.uuid,
    onSuccess: () => {
      refetchSupplementaryAgreements();
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });


  return (
    <Dialog open={open} onClose={handleClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            Выберите форму подписания
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        component="p"
        sx={{
          margin: '0 0 0 24px',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
          color: 'rgba(62, 65, 77, 0.6)',
        }}
      >
        Укажите формат
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            marginRight: '24px',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <MainButton
            textButton="ЭДО"
            mainBtn
            handleClick={useCallback(() => {
              const formData: FormData = new FormData();
              formData.append('hr_status', 'agreed');
              formData.append('signed_with', 'electronic');
              handleSendToRevision(formData);
              handleClose();
            }, [handleClose, handleSendToRevision])}
          />
          <MainButton
            textButton="Бумажный носитель"
            mainBtn
            disabled={data?.signed_with === 'electronic'}
            handleClick={useCallback(() => {
              handleSetDocumentModal();
              handleClose();
            }, [handleClose, handleSetDocumentModal])}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
