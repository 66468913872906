import React from 'react';

import { styled } from '@mui/material/styles';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  marginTop: '32px',
  borderRadius: '4px',
  border: 'none',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded:first-of-type': {
    marginTop: '32px',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreOutlinedIcon />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '8px 32px',
}));

interface MainAccordionProps {
  title: string;
  expanded: string | false;
  disabled?: boolean;
  children?: React.ReactElement;
  handleChange: (_event: React.SyntheticEvent, newExpanded: boolean) => void;
}

const MainAccordion: React.FC<MainAccordionProps> = ({ title, expanded, disabled, children, handleChange }) => (
  <Accordion expanded={expanded === 'panel1'} onChange={handleChange} disabled={disabled}>
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      sx={{
        padding: '0px 22px',
        backgroundColor: '#fff',
        border: 'none',
        borderRadius: '4px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'inherit',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
        }}
      >
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export { MainAccordion };
