/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { blackColor, whiteColor, encycolorpediaColor, grayDarkColor, themeColor } from 'utils/uiConstants';

export const TooltipActions = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '320px',
    width: '100%',
    padding: '8px 0',
    color: blackColor,
    backgroundColor: whiteColor,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
  },
}));

export const TooltipActionsInfo = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '4px 12px',
    backgroundColor: 'rgba(62, 65, 77, 0.6)',
    borderRadius: '2px',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: whiteColor,
  },
}));

export const TooltipActionsWarning = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '4px 12px',
    backgroundColor: themeColor,
    borderRadius: '16px',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: whiteColor,
  },
}));

export const TooltipActionsMainBtn = styled(Button)({
  width: '100%',
  height: 'max-content',
  padding: '10px 16px',
  borderRadius: '4px',
  color: whiteColor,
  backgroundColor: themeColor,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.02em',

  '@media(max-width:800px)': {
    fontSize: '10px',
    lineHeight: '12px',
  },

  '&:hover': {
    backgroundColor: 'rgba(255, 109, 109, 0.8)',
  },
});

export const TooltipActionsBtn = styled(Button)({
  width: '100%',
  padding: '15px 32px',
  display: 'flex',
  justifyContent: 'flex-start',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  textTransform: 'none',
  color: grayDarkColor,

  '&:hover': {
    boxShadow: 'none',
    backgroundColor: encycolorpediaColor,
  },
});

export const tooltipBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const tooltipActionBtnStyle = {
  width: '100%',
  padding: '15px 32px',
  display: 'flex',
  justifyContent: 'flex-start',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  textTransform: 'none',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  color: grayDarkColor,

  '&:hover': {
    boxShadow: 'none',
    backgroundColor: encycolorpediaColor,
  },
};

export const actionsIcon = (
  <DoneOutlinedIcon
    sx={{
      width: '12px',
      height: '12px',
      borderRadius: '2px',
      backgroundColor: whiteColor,
      color: themeColor,
    }}
  />
);

export const actionsIconFilled = (
  <DoneOutlinedIcon
    sx={{
      width: '12px',
      height: '12px',
      borderRadius: '2px',
      backgroundColor: themeColor,
      color: whiteColor,
    }}
  />
);
