import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { GetStatsCompanyByPatientsData, GetStatsCompanyByPatientsParams } from 'interfaces/hooks/patientsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useGetStatsCompanyByPatients = ({
  company,
  period,

  onSuccess,
  isSlotsAviable,
  onError
}: GetStatsCompanyByPatientsParams): GetStatsCompanyByPatientsData => {
  const companyUuid = company?.uuid;
  const periodUuid = period?.uuid;

  const { isLoading, error } = useQuery(
    ['statsCompanyByPatients', companyUuid, periodUuid],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/service_periods/${periodUuid}/reports/employees/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!companyUuid && !!periodUuid && isSlotsAviable,
      onSuccess,
      onError
    }
  );

  const isLoadingStatsCompanyByPatients: boolean = isLoading;
  const isErrorStatsCompanyByPatients: boolean = error;

  return {
    isLoadingStatsCompanyByPatients,
    isErrorStatsCompanyByPatients,
  };
};
