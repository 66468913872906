import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import { whiteColor, themeColor, themeColorHighOpacity } from 'utils/uiConstants';

type DatePickerDayProps = PickersDayProps<Date> & {
  isToday: boolean;
};

export const DatePickerDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isToday',
})<DatePickerDayProps>(({ isToday }) => ({
  ...(isToday
    ? {
        color: whiteColor,
        backgroundColor: themeColor,
        fontSize: '12px',
        lineHeight: '16px',
        '&:focus': {
          backgroundColor: themeColor,
        },
        '&.Mui-selected': {
          backgroundColor: themeColor,
        },
        '&.Mui-selected:focus': {
          backgroundColor: themeColor,
        },
        '&.Mui-selected:hover, &.Mui-selected:focus': {
          backgroundColor: themeColor,
        },
      }
    : {
        fontSize: '12px',
        lineHeight: '16px',
        '&:hover': {
          backgroundColor: themeColorHighOpacity,
        },
        '&.Mui-selected': {
          backgroundColor: themeColor,
        },
        '&.Mui-selected:focus': {
          backgroundColor: themeColor,
        },
        '&.Mui-selected:hover, &.Mui-selected:focus': {
          backgroundColor: themeColor,
        },
      }),
})) as React.ComponentType<DatePickerDayProps>;

export const datePickerPopperStyled: SxProps = {
  '& .MuiPaper-root': {
    padding: '8px 16px',
    backgroundColor: whiteColor,
    borderRadius: '8px',
    boxShadow: '0px 1.5px 3px rgba(0, 0, 0, 0.1), 0px 12px 24px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiCalendarPicker-root': {},
  '& .PrivatePickersFadeTransitionGroup-root': {
    fontSize: '18px',
    lineHeight: '20px',
  },
  '& .PrivatePickersSlideTransition-root': {},
  '& .MuiPickersDay-dayWithMargin': {},
  '& .MuiTabs-root': {
    backgroundColor: 'rgba(120, 120, 120, 0.4)',
  },
};
