import React from 'react';

import { Button } from '@mui/material';

import { mainBtnStyle } from 'ui/buttons/buttonsMain';

interface ButtonProps {
  textButton: any;
  mainBtn?: boolean;
  typeBtn?: 'button' | 'submit' | 'reset';
  border?: boolean;
  disabled?: boolean;
  form?: string;
  icon?: React.ReactNode;
  handleClick?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const MainButton: React.FC<ButtonProps> = ({
  textButton,
  mainBtn,
  typeBtn,
  border,
  disabled,
  icon,
  form,
  handleClick,
}) => (
  <Button
    sx={() => mainBtnStyle(mainBtn, border, disabled)}
    variant="text"
    type={typeBtn || 'button'}
    startIcon={icon}
    disabled={disabled}
    form={form}
    onClick={handleClick}
  >
    {textButton}
  </Button>
);
