import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { Loader } from 'shared/loaders/Loader';
import {
  IExpensesTableStaffProps,
  IListExpenses,
  ISelectedFilter,
  ListExpensesTableEnhancedTableProps,
  ListExpensesTableHeading,
} from 'interfaces/finance/financeInterfaces';
import { stableSort, getComparator, moneyRowCurrentView, nextSortState } from 'utils/tablesMethods';

import { tableCellStyle } from 'ui/tables/tables';
import { filterStyle } from 'ui/main/main';
import { Sorting } from 'interfaces/other/otherInterfaces';

function createData(uuid: string, title: string, money: number, visits: number): IListExpenses {
  return {
    uuid,
    title,
    money,
    visits,
  };
}

const heading: readonly ListExpensesTableHeading[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'ФИО пациента',
  },
  {
    id: 'money',
    numeric: false,
    disablePadding: false,
    label: 'Общие траты',
  },
  {
    id: 'visits',
    numeric: false,
    disablePadding: false,
    label: 'Обращений',
  },
];

const EnhancedTableHead: React.FC<ListExpensesTableEnhancedTableProps> = (
  props: ListExpensesTableEnhancedTableProps
) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IListExpenses) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === head.id ? order : false}
            sx={tableCellStyle}
          >
            <TableSortLabel
              active={!order || orderBy === head.id}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {head.label}
              {orderBy === head.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ExpensesTableStaff: React.FC<IExpensesTableStaffProps> = ({ dataListExpenses }) => {
  const [order, setOrder] = useState<Sorting>(undefined);
  const [orderBy, setOrderBy] = useState<keyof IListExpenses>('uuid');
  const [selected, setSelected] = useState<readonly string[]>([]);

  const listExpenses: readonly IListExpenses[] = dataListExpenses || [];

  const rows = listExpenses?.map((item: IListExpenses) => createData(item.uuid, item.title, item.money, item.visits));

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof IListExpenses): void => {
    setOrder(nextSortState(order));
    setOrderBy(property);
  };

  const handleSelectAllClick = (_event: React.ChangeEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line no-restricted-globals
    if (_event.target.checked) {
      const newSelecteds = rows.map((n) => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              borderCollapse: 'inherit',
              borderSpacing: '0 4px',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    // eslint-disable-next-line react/no-array-index-key
                    key={row.uuid}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: '8px',
                        fontFamily: 'inherit',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '18px',
                        letterSpacing: '0.02em',
                        color: '#3e414d',
                        border: 'none',
                      }}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell align="right" sx={tableCellStyle}>
                      {moneyRowCurrentView(row.money)}
                    </TableCell>
                    <TableCell align="right" sx={tableCellStyle}>
                      {row.visits}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

interface IListExpensesTableStaffProps {
  dataListExpenses: IListExpenses[] | [];

  optionsFilterByTitle: ISelectedFilter[] | [];
  selectedFilterByTitle: string | null;
  handleSelectedFilterByTitle: (value: string | null) => void;

  isFetchingListExpenses: boolean;
  isLoadingListExpenses: boolean;
}

export const ListExpensesTableStaff: React.FC<IListExpensesTableStaffProps> = ({
  dataListExpenses,

  optionsFilterByTitle,
  selectedFilterByTitle,
  handleSelectedFilterByTitle,

  isFetchingListExpenses,
  isLoadingListExpenses,
}) => (
  <Box sx={{ margin: ' 28px 0' }}>
    <Box sx={filterStyle}>
      <AutocompleteInput
        label="ФИО пациента"
        placeholder="ФИО пациента"
        data={optionsFilterByTitle}
        handleClear={() => {
          sessionStorage.setItem('tableFilterFinance', '');
          handleSelectedFilterByTitle(null);
          
        }}
        value={selectedFilterByTitle || ''}
        handleChangeObj={(_event: React.SyntheticEvent, newValue: ISelectedFilter | null) => {
          sessionStorage.setItem('tableFilterFinance', JSON.stringify(newValue?.title || ''));
          handleSelectedFilterByTitle(newValue?.title || null);
        }}
      />
    </Box>

    <Box sx={{ padding: '12px 8px' }}>
      <ExpensesTableStaff dataListExpenses={dataListExpenses} />

      {(isFetchingListExpenses || isLoadingListExpenses) && <Loader />}
    </Box>
  </Box>
);
