import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import {
  ViewedAllNotificationsHook, ViewedAllNotificationsHookData
} from 'interfaces/notifications/notificationsInterfaces';

export const useViewedAllNotifications = ({
  notificationUuids,
  onSuccess,
}: ViewedAllNotificationsHook): ViewedAllNotificationsHookData => {
  const { mutate, error, isLoading } = useMutation(
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/notifications/selected_notifications_status_update/`, {
        method: 'PUT',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          notification_uuids: notificationUuids
        }),
      });

      if (res.ok || res.status === 400 || res.status === 420) {
        return res;
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      onSuccess,
    }
  );

  return {
    handleViewedAllNotifications: mutate,
    isLoadingViewedAllNotifications: isLoading,
    isErrorViewedAllNotifications: !!error,
  };
};
