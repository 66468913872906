import React from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { z } from 'zod';
import AddIcon from '@mui/icons-material/Add';
import {
  modalAlarmIconStyle,
  modalFormInputStyle,
  modalFormWrapInputsStyle,
  modalFormWrapStyle,
  modalListItemBtnStyle,
  modalListItemTextStyle,
  modalTypographyColor,
} from 'ui/modals/modalGlobal';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { mainBtnStyle } from 'ui/buttons/buttonsMain';
import { CheckboxInput } from 'shared/inputs/CheckboxInput/CheckboxInput';
import { grayLightDarkOpacity, themeColor } from 'utils/uiConstants';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { inputFormStyle } from 'ui/inputs/inputsForm';
import { validationAdditionalServices } from './validationAdditionalServices';

export interface AdditionalServicesModalProps {
  open: boolean;
  handleClose: () => void;
}

const mocker = [
  {
    title: 'Пётр',
    uuid: '1',
  },
  {
    title: 'Василий',
    uuid: '2',
  },
  {
    title: 'Кондратий',
    uuid: '3',
  },
  {
    title: 'Василиса',
    uuid: '4',
  },
  {
    title: 'Ирина',
    uuid: '5',
  },
  {
    title: 'Александр',
    uuid: '5',
  },
];

export const AdditionalServicesModal: React.FC<AdditionalServicesModalProps> = ({ open, handleClose }) => {
  type ValidationAdditionalServices = z.infer<typeof validationAdditionalServices>;
  const { setValue, watch, handleSubmit, control, register } = useForm<ValidationAdditionalServices>({
    mode: 'onTouched',
    resolver: zodResolver(validationAdditionalServices),
    defaultValues: {
      isAllPatients: false,
      patients: [
        {
          title: null,
          uuid: null,
        },
        {
          title: null,
          uuid: null,
        },
        {
          title: null,
          uuid: null,
        },
        {
          title: null,
          uuid: null,
        },
      ],
      fileList: [],
      scopeServices: null,
      clinics: null,
      otherDetails: null,
      isLimitOrganizations: false,
      isContactPatient: false,
      phoneNumber: null,
    },
  });

  const patientsFields = watch('patients');

  const fileList = watch('fileList');

  const handleSelectPatient = (
    patient: {
      title: string;
      uuid: string;
    },
    index: number
  ): void => {
    const oldValue = watch('patients');
    oldValue[index] = patient;
    setValue('patients', oldValue);
  };

  const handleClearPatient = (index: number): void => {
    const oldValue = watch('patients');
    oldValue[index] = {
      title: null,
      uuid: null,
    };
    setValue('patients', oldValue);
  };

  const handleCreatePatientFields = (): void => {
    const oldValue = watch('patients');
    const updatedValue = [
      ...oldValue,
      {
        title: null,
        uuid: null,
      },
    ];
    setValue('patients', updatedValue);
  };

  const handleDeletePatientField = (index: number): void => {
    const oldValue = watch('patients');
    oldValue.splice(index, 1);
    setValue('patients', oldValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          minWidth: '800px',
          overflowX: 'hidden',
          padding: '20px 24px 24px',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Typography variant="h3" sx={() => modalTypographyColor('#3e414d')}>
              Запрос дополнительной услуги
            </Typography>

            <Typography variant="subtitle1" sx={() => modalTypographyColor('rgba(62, 65, 77, 0.6)')}>
              Из средств депозита
            </Typography>
          </Box>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box component="form" onSubmit={handleSubmit(() => null)}>
        <Box sx={modalFormWrapInputsStyle}>
          <Box sx={() => modalFormInputStyle(false)}>
            {patientsFields?.map((_item, index: number) => (
              <Stack
                sx={{
                  marginTop: '16px',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <AutocompleteInput
                  label="Фио пациента"
                  placeholder="Фио пациента"
                  data={mocker}
                  handleClear={() => handleClearPatient(index)}
                  handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                    if (newValue) {
                      handleSelectPatient(newValue, index);
                    }
                  }}
                />
                <IconButton onClick={() => handleDeletePatientField(index)}>
                  <DeleteIcon
                    sx={{
                      color: themeColor,
                      height: '24px',
                      width: '24px',
                    }}
                  />
                </IconButton>
              </Stack>
            ))}
          </Box>
        </Box>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: '32px',
            marginBottom: '16px',
          }}
        >
          <Stack>
            <CheckboxInput name="isAllPatients" control={control} label="Все пациенты" />
          </Stack>
          <Button
            sx={() => mainBtnStyle(true, false)}
            variant="text"
            onClick={handleCreatePatientFields}
            startIcon={
              <AddIcon
                sx={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '2px',
                  color: 'white',
                }}
              />
            }
          >
            Добавить
          </Button>
        </Stack>
        <Stack direction="column">
          <Box sx={() => modalFormInputStyle(false)}>
            <AutocompleteInput
              label="Объём услуг"
              placeholder="Объём услуг"
              data={mocker}
              handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                if (newValue) {
                  setValue('scopeServices', newValue?.uuid);
                }
              }}
            />
          </Box>
          <Box sx={() => modalFormInputStyle(false)}>
            <AutocompleteInput
              label="ЛПУ для организации услуги"
              placeholder="ЛПУ для организации услуги"
              data={mocker}
              handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                if (newValue) {
                  setValue('clinics', newValue?.uuid);
                }
              }}
            />
          </Box>
          <Box sx={() => modalFormInputStyle(false)}>
            <CheckboxInput
              name="isLimitOrganizations"
              control={control}
              label="Лимит по организации запрашиваемой услуги"
            />
          </Box>
          <Box sx={() => modalFormInputStyle(false)}>
            <AutocompleteInput
              label="Иные условия"
              placeholder="Иные условия"
              data={mocker}
              handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                if (newValue) {
                  setValue('otherDetails', newValue?.uuid);
                }
              }}
            />
          </Box>
          <Stack direction="row">
            <Stack width="50%">
              <CheckboxInput
                name="isContactPatient"
                control={control}
                label={
                  <>
                    <Typography>Нужно ли связаться с пациентом?</Typography>
                    <Typography variant="subtitle2" sx={() => modalTypographyColor('rgba(62, 65, 77, 0.6)')}>
                      Для записи в клинику
                    </Typography>
                  </>
                }
              />
            </Stack>
            <Stack width="50%">
              <TextField
                label="Телефон для связи"
                variant="standard"
                sx={() => inputFormStyle(false)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ width: '28px', height: '28px' }}
                      onClick={() => {
                        setValue('phoneNumber', '', { shouldDirty: true });
                      }}
                    >
                      <CloseOutlinedIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('phoneNumber')}
              />
            </Stack>
          </Stack>
        </Stack>
        <Box>
          <List sx={{ padding: '32px 0px 0px 0px', gap: '4px', marginTop: '18px' }}>
            {fileList.map((item, index) => {
              const key = index;
              return (
                <ListItem
                  sx={{
                    padding: '0',
                  }}
                  key={key}
                >
                  <ListItemIcon sx={{ display: 'flex', alignSelf: 'center', minWidth: 0 }}>
                    <DescriptionIcon sx={modalAlarmIconStyle} />
                  </ListItemIcon>
                  <ListItemText sx={modalListItemTextStyle}>{item.name}</ListItemText>
                  <ListItemButton
                    sx={modalListItemBtnStyle}
                    onClick={() => {
                      const toDeleteItem = item.name;
                      fileList.indexOf(item) > -1 &&
                        setValue(
                          'fileList',
                          Array.from(fileList).filter((file) => !toDeleteItem.includes(file.name))
                        );
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <CloseOutlinedIcon sx={modalAlarmIconStyle} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={() => modalFormWrapStyle(true)}>
          <Typography variant="caption" sx={{ color: grayLightDarkOpacity }}>
            <Typography variant="caption" sx={{ color: themeColor }}>
              *{' '}
            </Typography>
            - обязательные поля.
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap="16px">
            <Button component="label" sx={() => mainBtnStyle()}>
              Приложить ГП
              <input
                hidden
                type="file"
                onChange={(evt) => {
                  if (evt.target.files) {
                    const oldValue = watch('fileList');
                    setValue('fileList', [...oldValue, evt.target.files[0]]);
                  }
                }}
              />
            </Button>
            <MainButton textButton="Отправить" mainBtn />
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};
