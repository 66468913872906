import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { FetchError } from 'utils/FetchError';
import { SendPermissionSettingParams, SendPermissionSettingData } from 'interfaces/hooks/permissionsHooksInterfaces';

export const useSendPermissionSetting = ({
  onSuccess,
  onError,
}: SendPermissionSettingParams): SendPermissionSettingData => {
  const { mutate, isLoading } = useMutation(
    async (values) => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/company_permissions/company_permissions_update_for_user/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (res.ok) {
        return res.json();
      }

      throw new FetchError(res);
    },
    {
      onSuccess,
      onError,
    }
  );

  return {
    handleSendPermission: mutate,
    isLoadingSendPermission: isLoading,
  };
};
