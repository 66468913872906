import { useSetPasswordParams, useSetPasswordData } from 'interfaces/hooks/passwordResetHooksInterfaces';
import { useMutation } from 'react-query';
import { FetchError } from 'utils/FetchError';
import { HEADER_ACCEPT_HR } from 'utils/apiConstants';

export const useSetPassword = ({ onSuccess, newPasswordUrl, token, onError }: useSetPasswordParams): useSetPasswordData => {
    const { mutate } = useMutation(
        async (value: RequestInfo | URL) => {
            const objBody = {
                password: value
            }
            const res = await fetch(newPasswordUrl, {
                method: 'POST',
                headers: { Accept: `${HEADER_ACCEPT_HR}`, 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify(objBody),
            });

            if (res.ok) {
                return res;
            }
            throw new FetchError(res);
        },
        {
            onSuccess, onError
        }
    );

    return {
        handleSetPassword: mutate
    };
};
