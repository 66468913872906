import React from 'react';
import './EmailModal.scss';
import Dialog from '@mui/material/Dialog';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputForm } from 'shared/inputs/InputForm/InputForm';
import { EmailModalData, emailModalValidation } from './emailModalValidation';

interface EmailModalProps {
  open: boolean;
  handleClose: () => void;
  handleClickSMSModalOpen: () => void;
  sendEmail: (email?: string) => Promise<void>;
  emailIsLoading: boolean;
  emailIsError: boolean;
}

export const EmailModal: React.FC<EmailModalProps> = ({
  open,
  handleClose,
  handleClickSMSModalOpen,
  sendEmail,
  emailIsLoading,
  emailIsError,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<EmailModalData>({
    resolver: zodResolver(emailModalValidation),
    defaultValues: { email: '' },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue('email', e.target.value);
  };
  const handleClean = (): void => {
    reset({ email: '' });
  };
  const onSubmit = ({ email }: EmailModalData): void => {
    sendEmail(email).then(() => {
      handleClose();
      handleClickSMSModalOpen();
      handleClean();
    });
  };
  const onClose = (): void => {
    handleClose();
    handleClean();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '560px',
          padding: '20px 24px 24px',
        },
      }}
    >
      <div className="modal-pass__header">
        <div className="modal-pass__head">
          <h4 className="modal-pass__title">Смена пароля</h4>
          <p className="modal-pass__text">Забыли пароль? Укажите ниже свой e-mail для восстановления доступа.</p>
        </div>
      </div>

      <form name="modal-pass" className="modal-pass__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-pass__form-wrapper">
          <div className="modal-pass__input">
            <InputForm
              idInput="modal-pass-email"
              textLabel="E-mail"
              value={watch('email')}
              error={!!errors.email || emailIsError}
              helperText={errors.email?.message || emailIsError ? errors.email?.message || 'Возникла ошибка' : ''}
              isPass={false}
              showPass={false}
              handleChange={handleChange}
              handleCleanInput={handleClean}
            />
          </div>
        </div>
        <div className="modal-pass__btn-wrapper">
          <MainButton disabled={emailIsLoading} typeBtn="submit" textButton="Далее" mainBtn />
        </div>
      </form>
    </Dialog>
  );
};
