import { Box } from '@mui/system';
import { Company } from 'interfaces/global/globalInterfaces';
import React from 'react';
import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';

interface PermissionsFilterProps {
  selectedCompany: Company | null;
  allCompanies: Company[];
  handleSelectedCompany: (value: Company) => void;
}

export const PermissionsFilter: React.FC<PermissionsFilterProps> = ({
  selectedCompany,
  allCompanies,
  handleSelectedCompany,
}) => (
  <Box>
    <AutocompleteInput
      label="Компания"
      placeholder="Компания"
      data={allCompanies}
      value={selectedCompany}
      handleChangeObj={(_event: React.SyntheticEvent, newValue: Company) => {
        sessionStorage.setItem('permissionsFilterCompany', JSON.stringify(newValue || ''));
        handleSelectedCompany(newValue);
      }}
    />
  </Box>
);
