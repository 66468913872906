import React from 'react';

import { Box } from '@mui/system';
import { ClickAwayListener } from '@mui/material';

import { tooltipContainerStyle } from 'ui/tooltips/tooltipInfo';

import { TooltipActions, TooltipActionsBtn, tooltipBoxStyle } from 'ui/tooltips/tooltipAction';

import { ITooltipActionBtnsProps } from 'interfaces/shared/tooltips/tooltipsInterfaces';
import { useDownloadDocument } from 'hooks/documents/useDownloadDocument';
import { Company } from 'interfaces/global/globalInterfaces';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { useSnackbar } from 'notistack';

export const TooltipActionBtns: React.FC<ITooltipActionBtnsProps> = ({
  open,
  isAgreements,
  uuid,
  data,
  children,
  status,
  isActs,
  handleDocumentsChangeHistoryModal,
  handleApproveDocumentModal,
  handleActionDoc,
  handleTooltipClose,
  onScrollOpen,
  accessList,
  defaultPermissions,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { handleDownloadDocument } = useDownloadDocument({
    fileUrl: data?.uuid,
    onError: () => {
      enqueueSnackbar('Произошла ошибка при скачивании', { variant: 'error' });
    },
  });

  const mainFilterCompanyDocuments: Company | null = sessionStorageGetItem<Company>('mainFilterCompanyDocuments');

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={tooltipBoxStyle}>
        <TooltipActions
          open={`tooltip-key-${data?.uuid}` === uuid && open && onScrollOpen}
          placement="bottom-end"
          title={
            <Box onClick={handleTooltipClose}>
              <Box sx={tooltipContainerStyle}>
                {isAgreements && (
                  <Box>
                    {status === 'need_approval' &&
                      isAccessAllowed(
                        mainFilterCompanyDocuments?.uuid,
                        'HR_docs_agreement',
                        accessList,
                        defaultPermissions
                      ) && (
                        <>
                          <TooltipActionsBtn onClick={handleApproveDocumentModal}>Согласовать</TooltipActionsBtn>
                          <TooltipActionsBtn onClick={handleActionDoc}>Отправить на доработку</TooltipActionsBtn>
                        </>
                      )}

                    <TooltipActionsBtn
                      onClick={() => {
                        handleDownloadDocument();
                      }}
                    >
                      Выгрузить проект
                    </TooltipActionsBtn>

                    {isAccessAllowed(
                      mainFilterCompanyDocuments?.uuid,
                      'HR_docs',
                      accessList,
                      defaultPermissions
                    ) && (
                      <TooltipActionsBtn onClick={handleDocumentsChangeHistoryModal}>
                        История изменений
                      </TooltipActionsBtn>
                    )}

                    {status === 'signed' && (
                      <TooltipActionsBtn
                        onClick={() => {
                          window.open(data?.original_file);
                        }}
                      >
                        Выгрузить скан оригинала
                      </TooltipActionsBtn>
                    )}
                  </Box>
                )}

                {isActs && <TooltipActionsBtn>Скачать все файлы</TooltipActionsBtn>}
              </Box>
            </Box>
          }
        >
          {children}
        </TooltipActions>
      </Box>
    </ClickAwayListener>
  );
};
