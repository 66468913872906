import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { SearchProgramsForPeriodDetailsHook, SearchProgramsForPeriodDetailsHookData } from 'interfaces/hooks/patientsHooksInterfaces';
import { FetchError } from 'utils/FetchError';

export const useSearchProgramsForPeriodDetails = ({
    url,
    onSuccess,
    onError
}: SearchProgramsForPeriodDetailsHook): SearchProgramsForPeriodDetailsHookData => {
    const { data } = useQuery(
        ['dataProgramsForPeriod', url],
        async () => {
            await handleRefreshToken();


            const token = localStorage.getItem('token');

            const res = await fetch(`${url}&per_page=200`, {
                method: 'GET',
                headers: {
                    Accept: `${HEADER_ACCEPT_HR}`,
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.ok) {
                return res.json();
            }
            throw new FetchError(res);
        },

        {
            onError,
            onSuccess,
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            enabled: !!url,
        }
    );



    return {
        programsForPeriodDetails: data
    };
};
