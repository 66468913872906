import { useMutation } from 'react-query';
import { handleRefreshToken } from 'utils/refreshToken';
import { HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { isActive } from 'itson/ItsOn';
import { GetTemplateForAddToExcelParams, GetTemplateForAddToExcelData } from 'interfaces/hooks/otherHooksInterfaces';

export const useGetTemplateForAddToExcel = ({
  companies,
  periodInMainFilter,
  onSuccess,
  onError,
}: GetTemplateForAddToExcelParams): GetTemplateForAddToExcelData => {
  const link = companies?.length === 1 ? companies[0].link_to_slots_create_file_template : '';
  const companyUuid = companies?.length === 1 ? companies[0].uuid : '';

  const { mutate, isLoading } = useMutation(
    ['dataTemplateForAddToExcel', companyUuid, link],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${link}${isActive('hr-494.add_period_uuid_in_template_link') ? `&service_period_uuid=${periodInMainFilter?.uuid}` : ''}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'template.xlsx';
        a.click();
        a.remove();

        return res;
      }
      throw new Error(`${res.status}. ${res.statusText}`);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!link,
      onSuccess,
      onError,
    }
  );

  const handleTemplateForAddToExcel: () => Promise<void> = mutate;
  const isLoadingTemplateForAddToExcel: boolean = isLoading;

  return {
    handleTemplateForAddToExcel,
    isLoadingTemplateForAddToExcel,
  };
};
