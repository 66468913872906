import { useMutation } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

export const useCancelPatientAttach = ({onSuccess}) => {
  const { mutate, isLoading } = useMutation(
    async (patientUuid: string) => {

      const data = {
        proposal_action_uuid: patientUuid
      };

      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/employees/cancel_patient_attachment_proposal_action/`, {
        method: 'PUT',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      return res;
    },
    {
      onSuccess
    }
  );

  return {
    handleCancelPatientAttach: mutate,
    isLoadingCancelPatientAttach: isLoading,
  };
};
