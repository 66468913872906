import { z } from 'zod';

export const validationPasswordData = z
  .object({
    password: z.string().min(8, { message: 'Пароль должен содержать не менее 8 символов' }),
    confirmation: z.string().min(8, { message: 'Пароль должен содержать не менее 8 символов' }),
  })
  .refine((data) => data.password === data.confirmation, {
    message: 'Пароли не совпадают',
    path: ['confirmation'],
  });;

export type ResetPasswordData = z.infer<typeof validationPasswordData>;
