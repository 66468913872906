import React, { useState, SyntheticEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { CustomTabs, CustomTab } from 'ui/tabs/tabs';

import { ITab } from 'interfaces/other/otherInterfaces';

import { mainTabs } from 'utils/constants';
import { boxNavStyle } from 'ui/navigation/navigation';
import { Box } from '@mui/system';
import { DataPeriod } from 'interfaces/global/globalInterfaces';

interface BasicNavigationProps {
  children?: React.ReactNode;
  selectedPeriodByDocuments?: DataPeriod | null;
}

export const BasicNavigation: React.FC<BasicNavigationProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const [markedPath, setMarkedPath] = useState(path);

  const handleChange = (_event: SyntheticEvent, newValue: string): void => {
    setMarkedPath(newValue);

    navigate(newValue);
  };

  return (
    <Box component="section" sx={boxNavStyle}>
      <CustomTabs value={markedPath} onChange={handleChange} aria-label="Навигация">
        {mainTabs.map((tab: ITab) => (
          <CustomTab key={tab.id} value={tab.href} label={tab.name} />
        ))}
      </CustomTabs>
      {children}
    </Box>
  );
};
