export const titleStyle = {
    margin: '0',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: '#3e414d'
}

export const dataStyle = {
    display: 'flex',
    margin: '28px 0 0',
    padding: '0',
    listStyleType: 'none'
}

export const dataItemStyle = (isHighLighting?: boolean) => ({
    marginRight: '16px',
    padding: '4px 8px',
    borderRadius: '4px',

    '&:last-child': {
        marginRight: '0'
    },

    backgroundColor: isHighLighting ? '#ffe9e9' : ''
})

export const dataCurrentStyle = (opacity) => ({
    margin: '0',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: `rgba(62, 65, 77, ${opacity})`
})