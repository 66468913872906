import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IExpensesChart } from 'interfaces/finance/financeInterfaces';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, ChartDataLabels);

const ExpensesChart: React.FC<IExpensesChart> = ({ dataFinancePeriodsStats }) => {
  const labels = dataFinancePeriodsStats.map((el) => el.month);
  const dataValues = dataFinancePeriodsStats.map((el) => el.money);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataValues,
        backgroundColor: '#ffe9e9',
      },
    ],
  };

  const options: object = {
    layout: {
      padding: {
        top: 30,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        title: {
          display: false,
          text: 'Траты',
          font: {
            family: 'inherit',
            style: 'normal',
            weight: 400,
            size: 12,
            lineHeight: '14px',
          },
          color: '#bcbec8',
        },

        labels: {
          boxWidth: 0,
          font: {
            family: 'inherit',
            style: 'normal',
            weight: 400,
            size: 12,
            lineHeight: '14px',
          },
        },
      },
      datalabels: {
        font: {
          family: 'inherit',
          style: 'normal',
          weight: 400,
          size: 12,
          lineHeight: '14px',
        },
        color: '#FF6D6D',
        borderRadius: 4,
        backgroundColor: '#fff',
        align: 'end',
        anchor: 'end',
        borderWidth: 1,
        borderColor: '#FF6D6D',
        formatter: (value: number) => `${value.toLocaleString().split(' ')} ₽`,
      },
    },
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          font: {
            family: 'inherit',
            size: 12,
            style: 'normal',
            weight: 400,
            lineHeight: '14px',
          },
          color: 'rgba(62, 65, 77, 0.6)',
          backdropColor: '#ffe9e9',
          callback: (context: number) => labels[context]?.toLocaleString(),
        },
      },
      y: {
        position: 'right',
        beginAtZero: true,
        ticks: {
          font: {
            family: 'inherit',
            size: 12,
            style: 'normal',
            weight: 400,
            lineHeight: '14px',
          },
          color: '#BCBEC8',
          maxTicksLimit: 4,
          callback: (context: number) => `${context.toLocaleString().split(' ')} ₽`,
        },
      },
    },
  };

  return <Bar options={options} data={data} height="326px" width="1200px" />;
};

export { ExpensesChart };
