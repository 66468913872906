import { SystemStyleObject, Theme } from '@mui/system';

export const notificationsTooltipPaper = (bgcolor: string, read: boolean): SystemStyleObject<Theme> => ({
  width: '100%',
  padding: '2px 8px 8px 8px',
  bgcolor,
  // boxShadow: '0px 0.4px 0.8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.06)',
  boxShadow: 'none',
  cursor: read ? 'pointer' : 'default',
});

export const notificationsTooltipText = (color: string): SystemStyleObject<Theme> => ({
  color,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'end',
});

export const notificationsTooltipPaperBox: SystemStyleObject<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
};

export const notificationsTooltipBoxFlex: SystemStyleObject<Theme> = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
};

export const notificationsTooltipBoxWrapper: SystemStyleObject<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '8px',
  overflow: 'auto',
  maxWidth: '380px',
  maxHeight: '254px',
  paddingBottom: '3px',
};
