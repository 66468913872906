import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import { ListSlotResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/slots/types';
import { format } from 'date-fns';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL } from 'utils/apiConstants';

import { getPagesQuantity } from 'utils/hooksHelpers';
import {
  SearchPatientsHook,
  SearchPatientsHookData,
  SearchPatientsHookPaginationResponseData,
  SearchPatientsQueryParams,
} from 'interfaces/hooks/patientsHooksInterfaces';
import { getFormattedDate } from 'utils/dateHelpers';
import { SlotsSlotHrRq } from '@bestdoctor/core-arch-api/models';
import { FetchError } from 'utils/FetchError';
import { DataPeriod } from 'interfaces/global/globalInterfaces';

export const useSearchPatients = ({
  userStartDate,
  userEndDate,
  companies,
  period,
  search,
  programs,
  patient,
  types,
  activePeriods,
  orderList,
  patientRelativeTypes,
  onSuccess,
  onError,
  searchType,
  isSlotsAviable,
}: SearchPatientsHook): SearchPatientsHookData => {
  const companyValue = companies && !!companies.length ? companies.map((company) => company.uuid).join() : '';
  const patientId: string = patient?.patientUuid || '';
  const programsUuid: string = programs && !!programs.length ? programs.map((item) => item.value).join(',') : '';
  const typesArr = types ? types.map((item) => item.value) : null;
  const relativesArr = patientRelativeTypes ? patientRelativeTypes.map((item) => item.value) : null;
  const activePeriodsUuids = activePeriods
    ?.reduce((acc: string[], item: DataPeriod) => {
      if (item.activePeriod) {
        acc.push(item.uuid);
      }
      return acc;
    }, [])
    .join(',');

  const mainFilterPeriodStaff =
    'mainFilterPeriodStaff' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '') : '';

  const getServicePeriodsId = (): string | null | undefined => {
    if (!companies) {
      return undefined;
    }

    if (companies.length !== 1) {
      return activePeriodsUuids;
    }

    return undefined;
  };

  const startDateGte = (): string | null => {
    let startDate;
    if (userStartDate) {
      startDate = format(new Date(userStartDate), 'yyyy-MM-dd 00:00:00');
    } else if (period) {
      startDate = null;
    }
    return startDate;
  };

  const startDateLte = (): string | null => {
    let endDate;
    if (userEndDate) {
      endDate = format(new Date(userEndDate), 'yyyy-MM-dd 00:00:00');
    } else if (period) {
      endDate = null;
    }
    return endDate;
  };

  const { data, isFetching, isLoading, isError, fetchNextPage, refetch, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [
        'dataCompanyPatients',
        period,
        companies,
        companyValue,
        userStartDate,
        userEndDate,
        startDateGte,
        startDateLte,
        patientId,
        programsUuid,
        search,
        orderList,
        typesArr,
        activePeriods,
        relativesArr,
      ],
      async ({ pageParam = 1 }) => {
        await handleRefreshToken();

        const token = localStorage.getItem('token');

        const queryParams: SearchPatientsQueryParams = {
          start_date__gte: startDateGte(),
          start_date__lte: startDateLte(),
          patient: patientId,
          service_period__in: companies && companies.length === 1 ? mainFilterPeriodStaff.uuid : getServicePeriodsId(),
          program__in: programsUuid,
          status: typesArr,
          ordering: orderList || '',
          page: pageParam,
          per_page: 20,
          patient_type: relativesArr,
        };

        const res = await fetch(`${API_URL}/employees/?${queryString.stringify(queryParams)}&${searchType}=${search}`, {
          method: 'GET',
          headers: {
            Accept: 'application/vnd.bestdoctor.v1-hr.hr:1',
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          return res.json();
        }
        throw new FetchError(res);
      },

      {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        enabled: isSlotsAviable && (companies && companies.length > 1 ? !!activePeriodsUuids : !!period),
        getNextPageParam: (lastPage: ListSlotResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => {
          const allPage: number = getPagesQuantity(lastPage);
          const currentPage: number = lastPage.meta?.page || 0;
          const nextPage = currentPage + 1;

          return nextPage <= allPage ? nextPage : undefined;
        },
        onError,
        onSuccess,
        select: (res: SearchPatientsHookPaginationResponseData) => {
          const pageParams = res.pages[0].meta;

          const slotsPatients = res.pages
            .map((page: ListSlotResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => page.data)
            .flat();

          const patients = slotsPatients.map((item: SlotsSlotHrRq | undefined) => {
            if (item === undefined) return;

            const birthDate = getFormattedDate(item.patient.birth_date, 'dd.MM.yyyy');
            const startDate = getFormattedDate(item.start_date, 'dd.MM.yyyy');
            const endDate = getFormattedDate(item.end_date, 'dd.MM.yyyy');

            return {
              id: item?.patient?.id || 'Нет данных',
              fullname: item?.patient?.full_name || 'Нет данных',
              patientUuid: item?.patient?.uuid,
              birthday: birthDate || 'Нет данных',
              company: item?.company_title || 'Нет данных',
              greetings_email: item?.greetings_email || 'Нет данных',
              policy_number: item?.policy_number || 'Нет данных',
              number: item?.number || 'Нет данных',
              companyUuid: item?.company_uuid,
              program: item?.program?.title || 'Нет данных',
              programUuid: item?.program?.uuid,
              period: `${startDate} - ${endDate}` || 'Нет данных',
              startDate,
              endDate,
              type: item?.status?.text,
              uuid: item?.uuid,
              patientType: item?.patient_type,
              additional_info: item?.additional_info || 'Нет данных',
            };
          });
          return { patients, pageParams };
        },
      }
    );

  return {
    searchPatientsData: data,

    isLoadingSearchPatients: isLoading,
    isErrorSearchPatients: isError,

    hasNextPageSearchPatients: hasNextPage,
    isFetchingNextPageSearchPatients: isFetchingNextPage,
    isFetchingSearchPatients: isFetching,

    fetchNextPageSearchPatients: fetchNextPage,
    refetchSearchPatients: refetch,
  };
};
