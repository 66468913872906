import { SystemStyleObject, Theme } from "@mui/system"

export const listpatientTitleStyle = (isCurrent: boolean): SystemStyleObject<Theme> => ({
  margin: '0',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.01em',
  color: isCurrent ? '#bcbec8' : '#3e414d'
})

export const listPatientFilters = (): SystemStyleObject<Theme> => ({
  position: 'sticky',
  top: '276px',
  zIndex: '10',

  display: 'flex',
  marginTop: '28px',
  padding: '8px 32px',

  flexDirection: 'row',
  alignItems: 'end',
  gap: '32px',
  backgroundColor: '#fff',
  borderRadius: '4px',
})
