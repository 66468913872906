import React, { useState, ReactElement, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box } from '@mui/system';
import { Dialog, IconButton, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { endOfDay, format, isSameDay, isValid, parse, startOfDay } from 'date-fns';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ActionStaffModal } from 'shared/modals/ActionStaffModal/ActionStaffModal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import { inputFormStyle } from 'ui/inputs/inputsForm';

import {
  modalHeaderStyle,
  modalTitleStyle,
  modalSubtitleStyle,
  modalFormStyle,
  modalFormScrollStyle,
  modalFormWrapStyle,
  modalFormWrapInputsStyle,
  modalFormInputStyle,
} from 'ui/modals/modalGlobal';

import { themeColor, grayLightDarkOpacity } from 'utils/uiConstants';
import { modalAddFromFileContent, optionsGender } from 'utils/constants';

import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';

import { useGetTaskActionStaff } from 'hooks/other/useGetTaskActionStaff';
import { useSearchPrograms } from 'hooks/programs/useSearchProgram';
import { useAddPatientFromFile } from 'hooks/patients/useAddPatientFromFile';
import { useAddPatient } from 'hooks/patients/useAddPatient';

import { useSnackbar } from 'notistack';

import { IAddPatientModalProps } from 'interfaces/patients/patientInterfaces';
import { IDataHooksProgram, IProgram, SearchParams } from 'interfaces/program/programInterfaces';

import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import { requiredPhoneByCompanies, requiredPassByCompanies } from 'utils/requiredCheck';
import { AddPatientHookResponseError, TaskId } from 'interfaces/hooks/patientsHooksInterfaces';
import { moneyRowCurrentView } from 'utils/tablesMethods';
import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { validationPatient } from './validationAddPatient';
import { ActionWithPatientFromFileModal } from '../ActionWithPatientFromFileModal/ActionWithPatientFromFileModal';

export const AddPatientModal: React.FC<IAddPatientModalProps> = ({
  open,
  companiesHR,
  selectedCompanies,
  periodInMainFilter,
  isAddingForbiddenByDates,
  handleClose,
  handleTaskIdAttach,
  celeryType,
}) => {
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalAttachFromFileOpen, setModalAttachFromFileOpen] = useState<boolean>(false);
  const [programs, setPrograms] = useState<SearchParams[]>([]);

  // id задачи по откреплению
  const [createSlotTaskId, setCreateSlotTaskId] = useState<string>('');

  // стейты для дата пикеров
  const [dateBirth, setDateBirth] = useState<Date | null>(null);
  const [datePassport, setDatePassport] = useState<Date | null>(null);
  const [dateStartProgram, setDateStartProgram] = useState<Date | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const reqPhone: boolean = requiredPhoneByCompanies(companiesHR);
  const reqPass: boolean = requiredPassByCompanies(companiesHR);

  const validPatient = validationPatient(reqPhone, reqPass);
  type PatientParams = z.infer<typeof validPatient>;

  const rendersPickerDateBirth = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateBirth !== null ? isSameDay(date, dateBirth) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDatePassport = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = datePassport !== null ? isSameDay(date, datePassport) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const rendersPickerDateStartProgram = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateStartProgram !== null ? isSameDay(date, dateStartProgram) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: periodInMainFilter,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const disabledDays = (date: Date): boolean => {
    const actualDayTimeStamp = startOfDay(date).getTime();

    const canAttachSinceTimestamp = periodStaffAction
      ? startOfDay(parse(periodStaffAction.canAttachSince, 'yyyy-MM-dd', new Date())).getTime()
      : null;

    const canAttachSinceTimestampMax = periodStaffAction
      ? endOfDay(parse(periodStaffAction.canAttachOrDetachMaxDate, 'yyyy-MM-dd', new Date())).getTime()
      : null;

    if (canAttachSinceTimestamp && canAttachSinceTimestampMax) {
      return actualDayTimeStamp < canAttachSinceTimestamp || actualDayTimeStamp > canAttachSinceTimestampMax;
    }
    if (canAttachSinceTimestamp) {
      return actualDayTimeStamp < canAttachSinceTimestamp;
    }
    if (canAttachSinceTimestampMax) {
      return actualDayTimeStamp > canAttachSinceTimestampMax;
    }
    return false;
  };

  const { isLoadingSearchPrograms } = useSearchPrograms({
    company: selectedCompanies[0],
    period: periodInMainFilter,
    selectedProgram: null,
    selectedServices: [],
    selectedСity: null,

    onSuccess: (data: IDataHooksProgram) => {
      const loadProgrammArr: SearchParams[] | [] = data.programs
        ? data.programs.map((item: IProgram) => ({
            id: item.programUuid,
            title: `${item.programTitle}\n(страховая премия - ${moneyRowCurrentView(item.budget)})`,
            uuid: item.programUuid,
          }))
        : programs;

      const filterLoadProgrammArr = loadProgrammArr
        .filter((a, i) => loadProgrammArr.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

      setPrograms(filterLoadProgrammArr);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const {
    setValue,
    register,
    reset,
    formState: { errors, isDirty, dirtyFields },
    setError,
    handleSubmit,
  } = useForm<PatientParams>({
    mode: 'onTouched',
    resolver: zodResolver(validationPatient(reqPhone, reqPass)),
    defaultValues: {
      last_name: '',
      first_name: '',
      middle_name: '',
      gender: '',
      birth_date: '',
      nationality: '',
      address: '',
      phone: '',
      email: '',
      position: '',
      number: '',
      passport_number: '',
      passport_date: '',
      passport_given: '',
      program: '',
      start_date: '',
    },
  });

  const { isLoadingAddPatient, handleAddPatient } = useAddPatient({
    dirtyFields,
    onSuccess: (res) => {
      if (res.ok) {
        enqueueSnackbar('Пациент будет прикреплен в ближайшее время', { variant: 'success' });

        queryClient.invalidateQueries('dataCompanyPatients');

        setTimeout(() => {
          handleClose();
          reset();

          setDateBirth(null);
          setDatePassport(null);
          setDateStartProgram(null);
        }, 5000);
      } else {
        res.json().then((data: AddPatientHookResponseError) => {
          data.errors.forEach((item) => {
            const { field } = item;

            setError(field, {
              type: 'custom',
              message: item.message,
            });

            enqueueSnackbar(item.message, { variant: 'error' });
          });
        });
      }
    },
    onError: (res) => {
      res.json().then((data: AddPatientHookResponseError) => {
        data.errors.forEach((item) => {
          const { field } = item;

          setError(field, {
            type: 'custom',
            message: item.message,
          });

          enqueueSnackbar(item.message, { variant: 'error' });
        });
      });
    },
  });

  const { handleAddFromFile } = useAddPatientFromFile({
    companyId: selectedCompanies?.[0]?.uuid || '',
    onSuccess: (data: TaskId) => {
      setCreateSlotTaskId(data.data.task_id);

      setIsModalOpen(true);
    },
    onError: () => enqueueSnackbar('Ошибка при отправке заявки на прикрепление', { variant: 'error' }),
  });

  const { dataTaskActionStaff, refetchTaskActionStaff } = useGetTaskActionStaff({
    taskId: createSlotTaskId,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const handleChangeAddFromFile = async (evt: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    evt.preventDefault();

    const fileList: FileList | null = evt.target.files ? evt.target.files : null;
    const file: File | null = fileList !== null ? fileList[0] : null;
    const formData: FormData = new FormData();

    if (file) {
      formData.append('file', file);

      await handleAddFromFile(formData);
    } else {
      enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' });

      handleTaskIdAttach('');
    }
  };

  const refetch = (): void => {
    refetchTaskActionStaff();
  };

  useEffect((): any => {
    if (isModalOpen && dataTaskActionStaff?.status !== 'success') {
      const interval = setInterval(() => {
        refetch();
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, dataTaskActionStaff]);

  return (
    <Dialog open={open} onClose={handleClose} sx={dialogStyleAddModal}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            Прикрепление нового пациента
          </Typography>
          <Typography variant="subtitle1" sx={modalSubtitleStyle}>
            Пользователь будет подключен к программе в самое ближайшее время!
            <br />
            Заполните анкету, чтобы прикрепить нового пациента, либо воспользуйтесь инструментом загрузки из файла
            (возможна загрузка сразу нескольких пациентов)
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box component="form" sx={modalFormStyle} onSubmit={handleSubmit(handleAddPatient)}>
        <Box sx={() => modalFormScrollStyle(600)}>
          <Box sx={() => modalFormWrapStyle(false)}>
            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Фамилия"
                  variant="standard"
                  error={!!errors.last_name?.message}
                  helperText={errors.last_name?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('last_name', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('last_name')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Имя"
                  variant="standard"
                  error={!!errors.first_name?.message}
                  helperText={errors.first_name?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('first_name', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('first_name')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Отчество"
                  variant="standard"
                  error={!!errors.middle_name?.message}
                  helperText={errors.middle_name?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('middle_name', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('middle_name')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <AutocompleteInput
                  label="Пол"
                  data={optionsGender || []}
                  disabled={isLoadingAddPatient}
                  required
                  handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; value: string }) => {
                    setValue('gender', newValue.value, { shouldDirty: true });
                    register('gender', { value: newValue.value });
                  }}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Дата рождения"
                    value={dateBirth}
                    renderDay={rendersPickerDateBirth}
                    PopperProps={{
                      sx: datePickerPopperStyled,
                    }}
                    disabled={isLoadingAddPatient}
                    onChange={(newValue: Date | null) => {
                      if (newValue && isValid(newValue)) {
                        register('birth_date', {
                          value: format(newValue, 'yyyy-MM-dd'),
                        });
                        setValue('birth_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });

                        setDateBirth(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Дата рождения"
                        variant="standard"
                        error={!!errors.birth_date?.message}
                        helperText={errors.birth_date?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        required
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        InputProps={{
                          endAdornment: (
                            <>
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setDateBirth(null);
                                  setValue('birth_date', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>

                              {params.InputProps?.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Гражданство"
                  variant="standard"
                  error={!!errors.nationality?.message}
                  helperText={errors.nationality?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('nationality', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('nationality')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Адрес фактического проживания"
                  variant="standard"
                  error={!!errors.address?.message}
                  helperText={errors.address?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('address', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('address')}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={() => modalFormWrapStyle(false)}>
            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Телефон"
                  variant="standard"
                  error={!!errors.phone?.message}
                  helperText={errors.phone?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required={reqPhone}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('phone', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('phone')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="E-mail"
                  variant="standard"
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('email', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('email')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Должность"
                  variant="standard"
                  error={!!errors.position?.message}
                  helperText={errors.position?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('position', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('position')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Табельный номер"
                  variant="standard"
                  error={!!errors.number?.message}
                  helperText={errors.number?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('number', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('number')}
                />
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Номер паспорта"
                  variant="standard"
                  error={!!errors.passport_number?.message}
                  helperText={errors.passport_number?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required={reqPass}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('passport_number', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('passport_number')}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Дата выдачи"
                    value={datePassport}
                    renderDay={rendersPickerDatePassport}
                    PopperProps={{
                      sx: datePickerPopperStyled,
                    }}
                    disabled={isLoadingAddPatient}
                    onChange={(newValue: Date | null) => {
                      if (newValue && isValid(newValue)) {
                        register('passport_date', {
                          value: format(newValue, 'yyyy-MM-dd'),
                        });
                        setValue('passport_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });

                        setDatePassport(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Дата выдачи"
                        variant="standard"
                        error={!!errors.passport_date?.message}
                        helperText={errors.passport_date?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        required={reqPass}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        InputProps={{
                          endAdornment: (
                            <>
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setDatePassport(null);
                                  setValue('passport_date', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>

                              {params.InputProps?.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <TextField
                  label="Кем выдан"
                  variant="standard"
                  error={!!errors.passport_given?.message}
                  helperText={errors.passport_given?.message}
                  sx={() => inputFormStyle(false)}
                  disabled={isLoadingAddPatient}
                  required={reqPass}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ width: '28px', height: '28px' }}
                        disabled={isLoadingAddPatient}
                        onClick={() => {
                          setValue('passport_given', '', { shouldDirty: true });
                        }}
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('passport_given')}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={() => modalFormWrapStyle(false)}>
            <Box sx={modalFormWrapInputsStyle}>
              <Box sx={() => modalFormInputStyle(false)}>
                <AutocompleteInput
                  label="Выберите программу"
                  placeholder="Выберите программу"
                  data={programs || []}
                  required
                  disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                  handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    setValue('program', newValue.uuid, { shouldDirty: true });
                    register('program', { value: newValue.uuid });
                  }}
                />
              </Box>
              <Box sx={() => modalFormInputStyle(false)}>
                <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Дата начала обслуживания"
                    value={dateStartProgram}
                    shouldDisableDate={(date) => disabledDays(date)}
                    renderDay={rendersPickerDateStartProgram}
                    PopperProps={{
                      sx: datePickerPopperStyled,
                    }}
                    disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                    onChange={(newValue: Date | null) => {
                      if (newValue && isValid(newValue)) {
                        setDateStartProgram(newValue);
                        register('start_date', {
                          value: format(newValue, 'yyyy-MM-dd'),
                        });
                        setValue('start_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Дата начала обслуживания"
                        variant="standard"
                        error={!!errors.start_date?.message}
                        helperText={errors.start_date?.message}
                        sx={() => inputFormStyle(false)}
                        required
                        disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        InputProps={{
                          endAdornment: (
                            <>
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                                onClick={() => {
                                  setDateStartProgram(null);
                                  setValue('start_date', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>

                              {params.InputProps?.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={() => modalFormWrapStyle(true)}>
          <Typography variant="caption" sx={{ color: grayLightDarkOpacity }}>
            <Typography variant="caption" sx={{ color: themeColor }}>
              *{' '}
            </Typography>
            - обязательные поля.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
            }}
          >
            <Box>
              <MainButton handleClick={() => setModalAttachFromFileOpen(open)} textButton="Прикрепить из файла" />
            </Box>
            <TooltipActionsInfo
              placement="left"
              title={isAddingForbiddenByDates ? 'Нет доступных дат для прикрепления пациента' : ''}
            >
              <Box component="span">
                <MainButton
                  textButton="Прикрепить"
                  typeBtn="submit"
                  mainBtn
                  disabled={isLoadingAddPatient || !isDirty || isAddingForbiddenByDates}
                />
              </Box>
            </TooltipActionsInfo>
          </Box>
        </Box>
      </Box>

      <ActionStaffModal
        celeryType={celeryType}
        textContent={modalAddFromFileContent}
        open={isModalOpen}
        displayErrors
        dataTaskActionStaff={dataTaskActionStaff?.data}
        handleClose={() => {
          handleClose();
          setIsModalOpen(false);
          setModalAttachFromFileOpen(false);
        }}
      />

      <ActionWithPatientFromFileModal
        isActionMuted={isAddingForbiddenByDates}
        type="attach"
        companies={companiesHR}
        open={isModalAttachFromFileOpen}
        handleClose={() => setModalAttachFromFileOpen(false)}
        periodInMainFilter={periodInMainFilter}
        handleChangeAttachFromFile={handleChangeAddFromFile}
      />
    </Dialog>
  );
};
