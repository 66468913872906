import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';

import { RetrieveReportFinanceResourceResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-service-periods/types';
import { GetMainFinanceStats } from 'interfaces/finance/financeInterfaces';
import { FetchError } from 'utils/FetchError';
import { GetMainFinanceStatsParams } from 'interfaces/hooks/financeHookInterfaces';

export const useGetMainFinanceStats = ({ servicePeriod, isStatsAllowed, onSuccess, onError }: GetMainFinanceStatsParams): GetMainFinanceStats => {
  const servicePeriodId = servicePeriod?.uuid;

  const {
    isFetching,
    isLoading,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    data: newDataFinanceStats,
  } = useQuery(
    ['dataMainFinanceStats', servicePeriodId],
    async () => {
      await handleRefreshToken();

      const token = localStorage.getItem('token');

      const res = await fetch(`${API_URL}/service_periods/${servicePeriodId}/reports/finance/`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: !!servicePeriodId && isStatsAllowed,
      select: ({ data }: RetrieveReportFinanceResourceResponsesByAccept['application/vnd.bestdoctor.v1-hr']) => data,
      onSuccess,
      onError
    }
  );

  return {
    isLoadingMainFinanceStats: isLoading,
    isErrorMainFinanceStats: error,

    fetchNextPage,

    hasNextPageMainFinanceStats: hasNextPage,
    isFetchingNextPageMainFinanceStats: isFetchingNextPage,
    isFetchingMainFinanceStats: isFetching,

    refetchMainFinanceStats: refetch,

    newDataFinanceStats,
  };
};
