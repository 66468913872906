import { SystemStyleObject, Theme } from '@mui/system';
import {
  whiteColor,
  themeColor,
  themeColorLowOpacity,
  themeColorHighOpacity,
  graylightColor,
  grayLightDarkOpacity,
} from 'utils/uiConstants';

export const mainBtnStyle = (boolean?: boolean, borderBtn?: boolean, disabled?: boolean): SystemStyleObject<Theme> => {
  const colorBorder = disabled ? grayLightDarkOpacity : themeColor

  return ({
    width: '100%',
    maxWidth: 'max-content',
    height: 'max-content',
    padding: '10px 16px',
    borderRadius: '4px',
    color: boolean ? whiteColor : themeColor,
    backgroundColor: boolean ? themeColor : 'transparent',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    border: borderBtn ? `1px solid ${colorBorder}` : 'none',
    boxSizing: 'content-box',
    '@media(max-width:800px)': {
      fontSize: '10px',
      lineHeight: '12px',
    },
    '&:hover': {
      backgroundColor: boolean ? themeColorLowOpacity : themeColorHighOpacity,
    },
    '&:active': {},
    '&:focus': {},
    '&.Mui-disabled': {
      color: boolean ? whiteColor : grayLightDarkOpacity,
      backgroundColor: boolean ? graylightColor : '',
    },
  })
};

export const tooltipBtnStyle: SystemStyleObject<Theme> = {
  width: '100%',
  padding: '15px 32px',
  display: 'flex',
  justifyContent: 'flex-start',
  fontFamily: 'inherit',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  textTransform: 'none',
  textAlign: 'start',
  letterSpacing: '0.02em',
  color: '#3e414d',
  '&:hover': {
    backgroundColor: '#ffe9e9',
  },
  '&:active': {},
  '&:focus': {},
};
