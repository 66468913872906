import React, { useEffect, useState } from 'react';

import { BorderLinearProgress } from 'components/Loader/Loader';

import { Company, ErrorData } from 'interfaces/global/globalInterfaces';
import { UsersList } from 'components/UsersList/UsersList';
import { PermissionsNavigation } from 'components/Navigation/PermissionsNavigation';
import { Box, Typography } from '@mui/material';
import { listpatientTitleStyle } from 'ui/patients/patients';
import { useGetUsersRoles } from 'hooks/permissions/useGetUsersRoles';
import { useGetCompaniesPermissions } from 'hooks/companies/useGetCompaniesPermissions';
import { PermissionsFilter } from 'components/MainFilters/PermissionsFilters';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { useSnackbar } from 'notistack';
import { InfoText } from 'ui/other/Typography';

export const Permissions: React.FC = () => {
  const permissionsFilterCompany: Company | null = sessionStorageGetItem<Company>('permissionsFilterCompany');

  const [menuValue, setMenuValue] = useState<string>('user_rights');

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { companiesPermissionsData, isLoadingCompanies } = useGetCompaniesPermissions({
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { usersRolesData } = useGetUsersRoles({
    companyUuid: selectedCompany?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  useEffect(() => {
    if (companiesPermissionsData && !selectedCompany) {
      setSelectedCompany(permissionsFilterCompany);
    }
  }, [companiesPermissionsData, permissionsFilterCompany, selectedCompany]);

  return (
    <>
      {isLoadingCompanies && <BorderLinearProgress />}
      <PermissionsNavigation menuValue={menuValue} handleMenuValue={setMenuValue} />
      <PermissionsFilter
        selectedCompany={selectedCompany}
        handleSelectedCompany={setSelectedCompany}
        allCompanies={companiesPermissionsData}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '40px',
          marginBottom: '37px',
        }}
      >
        <Typography variant="h4" sx={listpatientTitleStyle(false)}>
          Учётные записи
          <Box component="span" sx={listpatientTitleStyle(true)}>{` (всего: ${usersRolesData?.length || 0})`}</Box>
        </Typography>
      </Box>

      <UsersList usersRolesData={usersRolesData} />
      <InfoText variant="h5">
        Для подключения дополнительных пользователей направьте{' '}
        <a href="https://forms.yandex.ru/cloud/63e9f1b784227c0fdf87cec3/">заявку</a>{' '}с указанием ФИО, должности и email
        <br/>
        После подключения новых пользователей у вас будет возможность самостоятельно настроить права.
      </InfoText>
    </>
  );
};
