import { LoadClinicsListParams, LoadClinicsListData } from 'interfaces/hooks/programsHooksInterfaces';
import { useMutation } from 'react-query';
import { FetchError } from 'utils/FetchError';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { handleRefreshToken } from 'utils/refreshToken';

export const useLoadClinicsList = ({
  programUuid,
  programName,
  onError
}: LoadClinicsListParams): LoadClinicsListData => {
  const { mutate, isLoading } = useMutation(['dataLoadClinicsList'], async () => {
    await handleRefreshToken();

    const token = localStorage.getItem('token');

    const res = await fetch(`${API_URL}/programs/${programUuid}/export_clinics_in_program/`, {
      method: 'GET',
      headers: {
        Accept: `${HEADER_ACCEPT_HR}`,
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Список клиник для программы ${programName}.xlsx`;
      a.click();
      a.remove();

      return res.blob();
    }
    throw new FetchError(res);
  }, {
    onError
  });

  const handleLoadClinicsList: () => Promise<void> = mutate;
  const isLoadingLoadClinicsList: boolean = isLoading;

  return {
    handleLoadClinicsList,
    isLoadingLoadClinicsList,
  };
};
