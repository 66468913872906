import React from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Iframe from 'react-iframe';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { usePrintDocument } from 'hooks/documents/usePrintDocument';
import { downloadDoc } from 'utils/downloadDoc';
import { DocumentViewModalProps } from 'interfaces/shared/modals/modalsInterfaces';

export const DocumentViewModal: React.FC<DocumentViewModalProps> = ({ open, title, handleClose, link }) => {
  let url = '';

  url = link || '';

  const { handlePrintDocument } = usePrintDocument(url);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ ...dialogStyleAddModal, fullWidth: 'true' }}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ ...modalTitleStyle, marginBottom: '52px' }}>
            {title}
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Iframe
          url={url}
          styles={{
            width: '752px',
            height: '452px',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '54px',
            marginRight: '24px',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <MainButton textButton="Распечатать" handleClick={handlePrintDocument} />
          <MainButton textButton="Скачать" mainBtn handleClick={() => downloadDoc(url)} />
        </Box>
      </Box>
    </Dialog>
  );
};
