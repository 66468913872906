import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { visuallyHidden } from '@mui/utils';
import DescriptionIcon from '@mui/icons-material/Description';

import { Loader } from 'shared/loaders/Loader';
import {
  ITablePaymentProps,
  ITablePaymentData,
  ITablePaymentEnhancedTableProps,
  ITablePaymentHeading,
} from 'interfaces/finance/financeInterfaces';

import { tableHistoryCellStyle, tableCellStatusStyle, tablePaymentsBodyStyle } from 'ui/tables/tables';
import { moneyRowCurrentView, nextSortState, throttle } from 'utils/tablesMethods';
import { Sorting } from 'interfaces/other/otherInterfaces';

function createData(
  periodPay: string,
  beforeDatePay: string,
  actualDatePay: string,
  amountPay: number,
  statusPay: string,
  basisPay: string,
  billDate: string,
  billFile: string,
): ITablePaymentData {
  return {
    periodPay,
    beforeDatePay,
    actualDatePay,
    amountPay,
    statusPay,
    basisPay,
    billDate,
    billFile,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Sorting,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const heading: readonly ITablePaymentHeading[] = [
  {
    id: 'periodPay',
    numeric: false,
    disablePadding: true,
    label: 'Период платежа',
  },
  {
    id: 'beforeDatePay',
    numeric: false,
    disablePadding: false,
    label: 'План. дата платежа',
  },
  {
    id: 'actualDatePay',
    numeric: false,
    disablePadding: false,
    label: 'Факт. дата платежа',
  },
  {
    id: 'amountPay',
    numeric: false,
    disablePadding: false,
    label: 'Сумма платежа, ₽',
  },
  {
    id: 'statusPay',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'basisPay',
    numeric: false,
    disablePadding: false,
    label: 'Основание',
  },
  {
    id: 'billDate',
    numeric: false,
    disablePadding: false,
    label: 'Счет',
  },
];

const EnhancedTableHead: React.FC<ITablePaymentEnhancedTableProps> = (props: ITablePaymentEnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof ITablePaymentData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
      }}
    >
      <TableRow sx={{ width: '100%', display: 'flex', whiteSpace: 'no-wrap' }}>
        {heading.map((head) => (
          <TableCell
            key={head.id}
            align={head.numeric ? 'right' : 'left'}
            padding={head.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === head.id ? order : false}
            sx={() => tableHistoryCellStyle()}
          >
            <TableSortLabel
              active={!order || (orderBy === head.id)}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              IconComponent={ExpandLessIcon}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              {head.label}
              {orderBy === head.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePayment: React.FC<ITablePaymentProps> = ({
  dataPayments,
  hasNextPagePayments,
  isFetchingNextPagePayments,

  fetchNextPage,
}) => {
  const [order, setOrder] = useState<Sorting>(undefined);
  const [orderBy, setOrderBy] = useState<keyof ITablePaymentData>('statusPay');
  const [selected, setSelected] = useState<readonly string[]>([]);

  const rows = dataPayments.map((item: ITablePaymentData) =>
    createData(
      item.periodPay,
      item.beforeDatePay,
      item.actualDatePay,
      item.amountPay,
      item.statusPay,
      item.basisPay,
      item.billDate,
      item.billFile
    )
  );

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof ITablePaymentData): void => {
    setOrder(nextSortState(order))
    setOrderBy(property);
  };

  const handleSelectAllClick = (_event: React.ChangeEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line no-restricted-globals
    if (_event.target.checked) {
      const newSelecteds = rows.map((n) => n.periodPay);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleScroll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const node = event.target;

    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;

    if (bottom) {
      fetchNextPage();
    }
  };

  const paneDidMount = (node: HTMLTableSectionElement): void => {
    if (node && hasNextPagePayments && !isFetchingNextPagePayments) {
      // eslint-disable-next-line no-restricted-globals
      node.addEventListener('scroll', throttle(handleScroll, 1000));
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', boxShadow: 'none' }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              display: 'block',
              emptyCells: 'show',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody ref={paneDidMount} sx={tablePaymentsBodyStyle}>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${row.actualDatePay}-${index}`}
                    sx={{
                      width: '100%',
                      display: row.amountPay !== 0 ? 'flex' : 'none',
                    }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={() => tableHistoryCellStyle()}
                    >
                      {row.periodPay}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>
                      {row.beforeDatePay ? `${row.beforeDatePay}` : '-'}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>
                      {row.actualDatePay ? `${row.actualDatePay}` : '-'}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>{moneyRowCurrentView(row.amountPay)}</TableCell>
                    <TableCell sx={() => tableCellStatusStyle(row)}>
                      {row.amountPay > 0 ? row.statusPay : 'Возврат'}
                    </TableCell>
                    <TableCell sx={() => tableHistoryCellStyle()}>
                      {row.basisPay ? `${row.basisPay}` : 'Договор'}
                    </TableCell>
                    <TableCell
                      sx={() => tableHistoryCellStyle(row.billFile !== '-' ? 'pointer' : 'default')}
                      onClick={() => (row.billFile !== '-' ? window.open(row.billFile.toString()) : null)}
                    >
                      <DescriptionIcon
                        sx={{
                          marginRight: '6px',
                          width: '14px',
                          height: '14px',
                          color: '#BCBEC8',
                        }}
                      />
                      <span
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        {row.billDate ? `Счет ${row.billDate}` : '-'}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {isFetchingNextPagePayments && <Loader />}
    </Box>
  );
};

export { TablePayment };
