import React from 'react';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { AutocompleteInputMultiple } from 'shared/inputs/AutocompleteInputMultiple/AutocompleteInputMultiple';
import { IListProgramProps, SearchParams } from 'interfaces/program/programInterfaces';
import { Box, Typography } from '@mui/material';
import { listProgramFilters, listProgramHeaderStyle, listProgramTitleStyle } from 'ui/programs/programs';

import { TableProgram } from './TableProgram/TableProgram';

const scopeService: readonly SearchParams[] = [
  { id: '1', title: 'Поликлиника', value: 'app' },
  { id: '2', title: 'Стоматология', value: 'stomatology' },
  { id: '3', title: 'Вызов врача на дом', value: 'home_doctor' },
  { id: '4', title: 'Скорая помощь', value: 'ambulance' },
  { id: '5', title: 'Плановая госпитализация', value: 'planned_hospitalization' },
  { id: '6', title: 'Экстренная госпитализация', value: 'emergency_hospitalization' },
];

export const ListProgram: React.FC<IListProgramProps> = ({
  programsQuantity,
  programsData,
  selectedProgramFilter,
  handleSelectedProgramFilter,
  searchByProgrammArr,
  searchByCityArr,
  selectedServicesForProgramsFilter,
  handleSelectedServices,
  selectedСityForProgram,
  handleSelectedСity,
  selectedPrograms,
  handleSelectedPrograms,
  handleActionsWithProgramsTooltip,
}) => (
  <Box component="section" sx={{ marginTop: '32px' }}>
    <Box sx={listProgramHeaderStyle}>
      <Typography variant="h4" sx={() => listProgramTitleStyle(false)}>
        Программы обслуживания
        {programsQuantity !== 0 && (
          <Box component="span" sx={{ color: '#bcbec8', margin: '0 8px' }}>
            (всего: {programsQuantity})
          </Box>
        )}
      </Typography>
    </Box>
    <Box sx={listProgramFilters}>
      <AutocompleteInput
        label="Поиск по программе"
        placeholder="Поиск по программе"
        data={searchByProgrammArr}
        value={selectedProgramFilter || ''}
        handleChangeObj={(_event: React.SyntheticEvent, newValue: SearchParams | null) => {
          sessionStorage.setItem('tableProgramsFilterProgram', JSON.stringify(newValue || ''));

          handleSelectedProgramFilter(newValue);
        }}
      />

      <AutocompleteInputMultiple
        label="Поиск по услугам"
        placeholder=""
        data={scopeService}
        value={selectedServicesForProgramsFilter}
        handleChange={(_event: React.SyntheticEvent, newValue: SearchParams[]) => {
          sessionStorage.setItem('tableProgramsFilterServices', JSON.stringify(newValue || ''));

          handleSelectedServices(newValue || []);
        }}
        check
      />

      <AutocompleteInput
        label="Город"
        placeholder="Город"
        data={searchByCityArr}
        value={selectedСityForProgram || ''}
        handleChangeObj={(_event: React.SyntheticEvent, newValue: SearchParams | null) => {
          sessionStorage.setItem('tableProgramsFilterCity', JSON.stringify(newValue || ''));

          handleSelectedСity(newValue);
        }}
      />
    </Box>

    <TableProgram
      programsData={programsData}
      selectedPrograms={selectedPrograms}
      handleSelectedPrograms={handleSelectedPrograms}
      handleActionsWithProgramsTooltip={handleActionsWithProgramsTooltip}
    />
  </Box>
);
