import React from 'react';

import { Box } from '@mui/system';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';

import { Company, DataPeriod, ProgramsFiltersProps } from 'interfaces/global/globalInterfaces';
import { mainFiltersContainerStyle, mainFiltersSectionStyle, mainFiltersWrapperStyle } from 'ui/patients/mainFilters';

export const ProgramsFilters: React.FC<ProgramsFiltersProps> = ({
  dataPeriods,
  allCompanies,

  selectedCompanyByProgram,
  handleSelectedCompanyByProgram,

  selectedPeriodByProgram,
  handleSelectedPeriodByProgram,
}) => (
  <Box component="section" sx={mainFiltersSectionStyle}>
    <Box sx={mainFiltersWrapperStyle}>
      <Box sx={mainFiltersContainerStyle}>
        <AutocompleteInput
          label="Компания"
          placeholder="Компания"
          data={allCompanies || []}
          value={selectedCompanyByProgram || null}
          handleChangeObj={(_event: React.SyntheticEvent, newValue: Company) => {
            sessionStorage.setItem('mainFilterCompanyPrograms', JSON.stringify(newValue || ''));
            sessionStorage.setItem('mainFilterPeriodPrograms', JSON.stringify(''));

            handleSelectedCompanyByProgram(newValue);
          }}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <AutocompleteInput
          label="Период данных"
          placeholder="Период данных"
          data={selectedCompanyByProgram ? dataPeriods || [] : []}
          value={selectedCompanyByProgram && selectedPeriodByProgram ? selectedPeriodByProgram : '(Активный период)'}
          handleChangeObj={(_event: React.SyntheticEvent, newValue: DataPeriod) => {
            sessionStorage.setItem('mainFilterPeriodPrograms', JSON.stringify(newValue || ''));

            handleSelectedPeriodByProgram(newValue);
          }}
        />
      </Box>
    </Box>
  </Box>
);
