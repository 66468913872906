import React, { SyntheticEvent } from 'react';

import { Box, Checkbox, TextField } from '@mui/material';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { AutocompletePopper, chipStyle, optionAutocompleteStyle } from 'ui/inputs/inputsAutocomplete';
import { inputFormStyle } from 'ui/inputs/inputsForm';
import { grayDarkColor } from 'utils/uiConstants';
import { truncateText } from 'utils/truncateText';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxOutlinedIcon fontSize="small" sx={{ color: '#ff6d6d' }} />;

interface AutocompleteInputMultipleProps {
  label: string;
  placeholder?: string;
  data: any;
  limitTags?: number;
  check?: boolean;
  disableCloseOnSelect?: boolean;
  required?: boolean;
  truncateTag?: boolean;
  allSelected?: boolean;
  value?: any;
  handleChange?:
    | ((
        event: SyntheticEvent<Element, Event>,
        value: any[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined
      ) => void)
    | undefined;
}

export const AutocompleteInputMultiple: React.FC<AutocompleteInputMultipleProps> = ({
  label,
  placeholder,
  data,
  limitTags,
  check,
  allSelected,
  truncateTag,
  required,
  value,
  handleChange,
}) => (
  <Autocomplete
    sx={{
      width: '100%',

      '& .css-oarg8b-MuiAutocomplete-root': {
        minWidth: '0 !important',
      },
      '& .MuiAutocomplete-inputRoot': {
        minWidth: '0 !important',
      },
      '& .MuiAutocomplete-input': {
        minWidth: '0 !important',
      },
    }}
    PopperComponent={AutocompletePopper}
    multiple
    limitTags={limitTags || 1}
    id="multiple-limit-tags"
    onChange={handleChange}
    options={data}
    value={value}
    noOptionsText="Данные отсутствуют"
    getOptionLabel={(option: string | any) => (option.title ? option.title : option)}
    disableCloseOnSelect
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="standard"
        label={label}
        placeholder={placeholder}
        sx={() => inputFormStyle(!check)}
        required={required}
      />
    )}
    renderTags={(
      values: {
        title: string;
        value: string;
      }[]
    ) =>
      values.length <= 1 ? (
        values.map((option, index) => {
          const key = index;
          return (
            <Box key={key} sx={{ ...chipStyle, maxWidth: '95%', width: 'max-content', padding: '4px' }}>
              {option.title}
            </Box>
          );
        })
      ) : (
        <Box
          sx={{
            ...chipStyle,
            maxWidth: '95%',
            width: 'max-content',
            padding: '4px',
          }}
        >
          {truncateTag ? truncateText(values[0].title, 5) : values[0].title}
          (+{values.length - 1})
        </Box>
      )
    }
    renderOption={(props, option: string | any, { selected }) => {
      const exist: boolean = value.some(({ title }) => option.title === title);

      return (
        <Box
          component="li"
          sx={() => optionAutocompleteStyle(value, option, exist)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {check && !allSelected && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, padding: '0px' }}
              checked={selected || exist}
            />
          )}
          {allSelected && (
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, padding: '0px' }} checked />
          )}
          <span style={{ color: option.color || grayDarkColor }}>{option.title ? option.title : option}</span>
        </Box>
      );
    }}
  />
);
