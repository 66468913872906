import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { FetchError } from 'utils/FetchError';
import { GetTaskActionStaff, GetTaskActionStaffParams } from 'interfaces/hooks/otherHooksInterfaces';

export const useGetTaskActionStaff = ({ taskId, onSuccess, onError }: GetTaskActionStaffParams): GetTaskActionStaff => {

  const { isLoading, error, data, refetch } = useQuery(
    ['getTaskActionStaff', taskId],
    async () => {
      await handleRefreshToken();

      if (!taskId) return;

      const token = localStorage.getItem('token');
      const res = await fetch(`${API_URL}/employees/check_celery_task/?celery_task_id=${taskId}`, {
        method: 'GET',
        headers: {
          Accept: `${HEADER_ACCEPT_HR}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        return res.json();
      }
      throw new FetchError(res);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      onSuccess,
      onError,
      enabled: !!taskId,
      select: (response) => response.data,
    }
  );

  return {
    refetchTaskActionStaff: refetch,
    isLoadingGetTaskActionStaff: isLoading,
    isErrorGetTaskActionStaff: !!error,
    dataTaskActionStaff: data,
  };
};
